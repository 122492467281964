import { getThemeValue } from 'theming'

const nano = (theme) => getThemeValue(theme, 'uiActionElements.sizes.nano')
const nanoOval = (theme) => getThemeValue(theme, 'uiActionElements.sizes.nanoOval')
const tiny = (theme) => getThemeValue(theme, 'uiActionElements.sizes.tiny')
const tinyOval = (theme) => getThemeValue(theme, 'uiActionElements.sizes.tinyOval')
const small = (theme) => getThemeValue(theme, 'uiActionElements.sizes.small')
const smallOval = (theme) => getThemeValue(theme, 'uiActionElements.sizes.smallOval')
const medium = (theme) => getThemeValue(theme, 'uiActionElements.sizes.medium')
const mediumOval = (theme) => getThemeValue(theme, 'uiActionElements.sizes.mediumOval')
const large = (theme) => getThemeValue(theme, 'uiActionElements.sizes.large')
const largeOval = (theme) => getThemeValue(theme, 'uiActionElements.sizes.largeOval')

export { nano, nanoOval, tiny, tinyOval, small, smallOval, medium, mediumOval, large, largeOval }
