export const colors = {
  brand: {
    primary: {
      lighten: '#098DA5',
      lightener: '#81c1cc',
      default: '#00697C',
      darken: '#004F5E',
      shallow: '#e9f2f2',
      shallowDarken: '#cfe6e8',
    },
    secondary: {
      lighten: '#EFFC70',
      default: '#CFDE40',
      darken: '#AAB91F',
    },
  },

  black: 'hsl(0,100%,0%)',
  white: '#FFF',

  lightgray: {
    50: 'hsl(255 0% 99.5%)',
    60: 'hsl(255 0% 99.4%)',
    70: 'hsl(255 0% 99.3%)',
    100: 'hsl(255 0% 99%)',
    130: 'hsl(255 0% 98.7%)',
    170: 'hsl(255 0% 98.3%)',
    200: 'hsl(255 0% 98%)',
    230: 'hsl(255 0% 97.7%)',
    270: 'hsl(255 0% 97.3%)',
    300: 'hsl(255 0% 97%)',
    330: 'hsl(255 0% 96.7%)',
    370: 'hsl(255 0% 96.3%)',
    400: 'hsl(255 0% 96%)',
    430: 'hsl(255 0% 95.7%)',
    470: 'hsl(255 0% 95.3%)',
    500: 'hsl(255 0% 95%)',
    530: 'hsl(255 0% 94.7%)',
    570: 'hsl(255 0% 94.3%)',
    600: 'hsl(255 0% 94%)',
    630: 'hsl(255 0% 93.7%)',
    670: 'hsl(255 0% 93.3%)',
    700: 'hsl(255 0% 93%)',
    800: 'hsl(255 0% 91.7%)',
  },

  gray: {
    100: 'hsl(0,0%,90%)',
    130: 'hsl(0,0%,87%)',
    170: 'hsl(0,0%,83%)',
    200: 'hsl(0,0%,80%)',
    230: 'hsl(0,0%,77%)',
    270: 'hsl(0,0%,73%)',
    300: 'hsl(0,0%,70%)',
    330: 'hsl(0,0%,67%)',
    370: 'hsl(0,0%,63%)',
    400: 'hsl(0,0%,60%)',
    430: 'hsl(0,0%,57%)',
    470: 'hsl(0,0%,53%)',
    500: 'hsl(0,0%,50%)',
    530: 'hsl(0,0%,47%)',
    570: 'hsl(0,0%,43%)',
    600: 'hsl(0,0%,40%)',
    630: 'hsl(0,0%,37%)',
    670: 'hsl(0,0%,33%)',
    700: 'hsl(0,0%,30%)',
    750: 'hsl(0,0%,25%)',
    800: 'hsl(0,0%,20%)',
    850: 'hsl(0,0%,15%)',
    900: 'hsl(0,0%,10%)',
    950: 'hsl(0,0%,5%)',
    1000: 'hsl(0,0%,2.5%)',
  },

  success: {
    lighten: 'rgb(114, 212, 119)',
    default: 'rgb(5, 150, 75)',
    darken: 'rgb(3, 99, 49)',
  },

  error: {
    lighten: 'rgb(251, 132, 132)',
    default: 'rgb(212, 8, 8)',
    darken: 'rgb(148, 5, 5)',
  },

  location: {
    yellow: {
      default: {
        fill: 'rgba(248, 229, 88, .5)',
        stroke: 'rgb(248, 229, 88)',
      },
      checked: {
        fill: 'rgb(0, 0, 0)',
        stroke: 'rgb(248, 229, 88)',
      },
    },
    blue: {
      fill: 'rgba(0, 72, 122, .5)',
      stroke: 'rgb(0, 72, 122)',
    },
    green: {
      fill: 'rgba(169, 202, 92, .5)',
      stroke: 'rgb(169, 202, 92)',
    },

    // blue: {
    //   fill: 'rgb(0,72,122)',
    // },
    // yellow: {
    //   fill: 'rgb(248,229,88)',
    // },
    // lightgreen: {
    //   fill: 'rgb(169,202,92)',
    // },
    // violet: {
    //   fill: 'rgb(130,85,152)',
    // },
    // darkgreen: {
    //   fill: 'rgb(41,172,154)',
    // },

    badge: {
      blue: {
        default: {
          bg: 'rgb(204,218,228)',
          color: '',
        },
        checked: {
          bg: 'rgb(153,182,202)',
          color: '',
        },
        hover: {
          bg: 'rgb(128,164,189)',
          color: '',
        },
      },
      yellow: {
        default: {
          bg: 'rgb(254,250,222)',
          color: '',
        },
        checked: {
          bg: 'rgb(252,245,188)',
          color: '',
        },
        hover: {
          bg: 'rgb(252,242,172)',
          color: '',
        },
      },
      lightgreen: {
        default: {
          bg: 'rgb(238,244,222)',
          color: '',
        },
        checked: {
          bg: 'rgb(221,234,190)',
          color: '',
        },
        hover: {
          bg: 'rgb(212,229,174)',
          color: '',
        },
      },
      violet: {
        default: {
          bg: 'rgb(230,221,234)',
          color: '',
        },
        checked: {
          bg: 'rgb(205,187,214)',
          color: '',
        },
        hover: {
          bg: 'rgb(193,170,204)',
          color: '',
        },
      },
      darkgreen: {
        default: {
          bg: 'rgb(212,238,235)',
          color: '',
        },
        checked: {
          bg: 'rgb(169,222,215)',
          color: '',
        },
        hover: {
          bg: 'rgb(148,214,205)',
          color: '',
        },
      },
    },
    pin: {
      blue: {
        fill: 'rgb(0,72,122)',
      },
      yellow: {
        fill: 'rgb(248,229,88)',
      },
      lightgreen: {
        fill: 'rgb(169,202,92)',
      },
      violet: {
        fill: 'rgb(130,85,152)',
      },
      darkgreen: {
        fill: 'rgb(41,172,154)',
      },
    },
  },

  l3plus: {
    purple: '#660066',
    preussian: '#144066',
    darkorange: '#EF3C2B',
    darkgreen: '#01441B',
    warmgray: '#8F8675',
    castanian: '#723110',
    violet: '#807DBA',
    darkyellow: '#F6BC1C',
    acidgreen: '#36978F',
    skin: '#FFB580',
    locationCompare: {
      bg: 'hsl(0,0%,30%)',
      color: '#fff',
    },
  },
}

export const themeColors = {
  app: {
    bg: colors.lightgray[100],
    color: colors.gray[900],
  },

  dark: {
    default: {
      bg: colors.gray[200],
      color: colors.gray[900],
    },
    disabled: {
      bg: colors.gray[470],
      color: colors.gray[230],
    },
    focus: {
      bg: colors.gray[750],
      color: colors.lightgray[100],
    },
    hover: {
      bg: colors.gray[750],
      color: colors.lightgray[100],
    },
    placeholder: {
      color: colors.gray[230],
    },
    progress: {
      bg: colors.gray[530],
      borderColor: colors.gray[530],
      color: colors.lightgray[700],
    },
  },
  ghost: {
    default: {
      bg: 'transparent',
      color: colors.gray[900],
    },
    disabled: {
      bg: 'transparent',
      color: colors.gray[300],
    },
    focus: {
      bg: colors.lightgray[130],
      color: colors.gray[1000],
    },
    hover: {
      bg: colors.lightgray[130],
      color: colors.gray[1000],
    },
    placeholder: {
      color: colors.gray[300],
    },
    progress: {
      bg: colors.lightgray[270],
      borderColor: colors.gray[430],
      color: colors.gray[470],
    },
  },
  gray: {
    default: {
      bg: colors.gray[470],
      color: colors.lightgray[170],
    },
    disabled: {
      bg: colors.gray[470],
      color: colors.gray[230],
    },
    hover: {
      bg: colors.gray[630],
      color: colors.lightgray[170],
    },
    focus: {
      bg: colors.gray[630],
      color: colors.lightgray[170],
    },
    placeholder: {
      color: colors.gray[230],
    },
    progress: {
      bg: colors.gray[270],
      borderColor: colors.gray[270],
      color: colors.lightgray[230],
    },
  },
  light: {
    checked: {
      bg: colors.gray[630],
      color: colors.lightgray[330],
    },
    default: {
      bg: colors.lightgray[100],
      color: colors.gray[600],
    },
    disabled: {
      bg: colors.lightgray[100],
      color: colors.gray[270],
    },
    focus: {
      bg: colors.lightgray[230],
      color: colors.gray[670],
    },
    hover: {
      bg: colors.lightgray[230],
      borderColor: colors.lightgray[530],
      color: colors.gray[670],
    },
    placeholder: {
      color: colors.gray[230],
    },
    progress: {
      bg: colors.lightgray[330],
      borderColor: colors.lightgray[100],
      color: colors.gray[400],
    },
    unchecked: {
      bg: colors.lightgray[100],
      color: colors.gray[530],
    },
  },

  lightgray: {
    checked: {
      bg: colors.brand.primary.shallow,
      color: colors.brand.primary.darken,
    },
    /* checked: {
      bg: colors.gray[170],
      color: colors.gray[1000],
    }, */
    default: {
      bg: colors.lightgray[600],
      color: colors.gray[800],
    },
    disabled: {
      bg: colors.lightgray[530],
      color: colors.gray[300],
    },
    focus: {
      bg: colors.lightgray[700],
      color: colors.gray[1000],
    },
    hover: {
      bg: colors.lightgray[800],
      color: colors.gray[850],
    },
    placeholder: {
      color: colors.gray[300],
    },
    progress: {
      bg: colors.lightgray[430],
      borderColor: colors.lightgray[530],
      color: colors.gray[470],
    },
  },

  primary: {
    checked: {
      bg: colors.brand.primary.default,
      color: colors.white,
    },
    default: {
      bg: colors.brand.primary.lighten,
      color: colors.white,
    },
    disabled: {
      bg: colors.brand.primary.lightener,
      color: colors.brand.primary.shallow,
    },
    focus: {
      bg: colors.white,
      color: colors.brand.primary.default,
    },
    hover: {
      bg: colors.brand.primary.shallow,
      color: colors.brand.primary.default,
    },
    placeholder: {
      color: colors.gray[100],
    },
    progress: {
      bg: colors.brand.primary.default,
      borderColor: colors.brand.primary.lighten,
      color: colors.white,
    },
  },
  primaryGhost: {
    default: {
      bg: 'transparent',
      color: colors.brand.primary.default,
    },
    disabled: {
      bg: 'transparent',
      color: colors.brand.primary.lightener,
    },
    focus: {
      bg: 'transparent',
      color: colors.brand.primary.darken,
    },
    hover: {
      bg: 'transparent',
      color: colors.brand.primary.darken,
    },
    placeholder: {
      color: colors.gray[100],
    },
    progress: {
      bg: colors.brand.primary.shallow,
      borderColor: colors.brand.primary.lighten,
      color: colors.brand.primary.lighten,
    },
  },
  primaryShallow: {
    default: {
      bg: colors.brand.primary.shallow,
      color: colors.brand.primary.darken,
    },
    disabled: {
      bg: 'transparent',
      color: colors.brand.primary.lightener,
    },
    focus: {
      bg: 'transparent',
      color: colors.brand.primary.darken,
    },
    hover: {
      bg: colors.brand.primary.shallowDarken,
      color: colors.brand.primary.darken,
    },
    placeholder: {
      color: colors.brand.primary.lighten,
    },
    progress: {
      bg: colors.brand.primary.shallow,
      borderColor: colors.brand.primary.lighten,
      color: colors.brand.primary.lighten,
    },
  },
  success: {
    default: {
      bg: colors.success.default,
      color: colors.white,
    },
    disabled: {
      bg: colors.success.lighten,
      color: colors.white,
    },
    focus: {
      bg: colors.success.darken,
      color: colors.white,
    },
    hover: {
      bg: colors.success.darken,
      color: colors.white,
    },
  },
  error: {
    default: {
      bg: colors.error.default,
      color: colors.white,
    },
    disabled: {
      bg: colors.error.lighten,
      color: colors.white,
    },
    focus: {
      bg: colors.error.darken,
      color: colors.white,
    },
    hover: {
      bg: colors.error.darken,
      color: colors.white,
    },
  },

  buttonCapital: {
    gray: {
      default: {
        bg: colors.gray[500],
        fg: colors.white,
      },
      hover: {
        bg: colors.gray[600],
        fg: colors.white,
      },
      active: {
        bg: colors.gray[800],
        fg: colors.white,
      },
    },
    lightgray: {
      default: {
        bg: colors.lightgray[600],
        fg: colors.gray[630],
      },
      hover: {
        bg: colors.lightgray[700],
        fg: colors.gray[800],
      },
      active: {
        bg: colors.lightgray[700],
        fg: colors.gray[800],
      },
    },
    ghost: {
      default: {
        bg: 'transparent',
        fg: colors.gray[700],
      },
      hover: {
        bg: 'transparent',
        fg: colors.gray[800],
      },
      active: {
        bg: 'transparent',
        fg: colors.gray[1000],
      },
    },
  },
  buttonIcon: {
    gray: {
      default: {
        bg: colors.gray[500],
        fg: colors.white,
      },
      hover: {
        bg: colors.gray[800],
        fg: colors.white,
      },
    },
    white: {
      default: {
        bg: colors.white,
        fg: colors.gray[900],
      },
      hover: {
        bg: colors.gray[900],
        fg: colors.white,
      },
    },
    ghost: {
      default: {
        bg: 'transparent',
        fg: colors.gray[700],
      },
      hover: {
        bg: 'transparent',
        fg: colors.gray[1000],
      },
    },
  },
  header: {
    bg: colors.gray[200],
  },
  navigationMain: {
    bg: colors.gray[800],
    child: {
      bg: {
        default: 'none',
        hover: colors.gray[400],
        active: 'initial',
      },
      text: {
        default: colors.gray[900],
        unfolded: colors.gray[500],
        hover: colors.gray[1000],
        active: colors.brand.secondary.default,
      },
    },
    parent: {
      bg: {
        default: 'none',
        hover: colors.gray[200],
        active: 'initial',
      },
      icon: {
        foreground: {
          default: colors.brand.primary.default,
          active: colors.gray[800],
        },
        bg: {
          active: colors.gray[100],
        },
      },
      text: {
        default: colors.gray[900],
        unfolded: colors.gray[200],
        hover: colors.gray[1000],
        active: colors.gray[100],
      },
    },
    menu: {
      bg: {
        active: colors.gray[100],
      },
    },
  },
  tabs: {
    bg: {
      default: colors.white,
      hover: colors.gray[400],
      active: colors.brand.primary.default,
    },
    text: {
      default: colors.gray[700],
      hover: colors.gray[1000],
      active: colors.gray[1000],
    },
    separator: colors.gray[500],
  },
  table: {
    header: {
      bg: colors.gray[300],
      color: colors.gray[800],
    },
    data: {
      odd: {
        bg: colors.gray[100],
        color: colors.gray[800],
      },
      even: {
        bg: colors.gray[200],
        color: colors.gray[800],
      },
      hover: {
        bg: colors.gray[300],
        color: colors.gray[1000],
      },
    },
    border: {
      color: colors.gray[400],
    },
  },
  loadingIndicator: {
    default: colors.brand.primary.default,
    invert: colors.gray[200],
  },
}
