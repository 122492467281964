import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Box, FlexItem } from '@layout/BuildingBlocks'
import { Minion } from '@typography'
import { Tooltip } from '@utilities/Tooltip'

import { BudiconLock } from 'bgag-budicons'

const StyledComponent = styled(FlexItem)`
  position: absolute;
  background-color: rgba(217, 217, 217, 0.75);
  top: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem;
  right: -0.5rem;
  border-radius: 5px;
  cursor: not-allowed;
`

export const LimitedFeatureMask = ({ ...props }) => {
  const { t } = useTranslation()

  return (
    <StyledComponent {...props}>
      <Box ml="auto" p=".75rem" pt=".25rem">
        <Tooltip minWidth="8rem">
          <Tooltip.Opener>
            <BudiconLock type="solid" />
          </Tooltip.Opener>
          <Tooltip.Content>
            <Minion>{t('locationSearch.noAuthorization')}</Minion>
          </Tooltip.Content>
        </Tooltip>
      </Box>
    </StyledComponent>
  )
}
