import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { getThemeValue } from 'theming'

export const useDisplaySizeSufficiency = () => {
  const theme = useTheme()
  const { minWidth, minHeight } = getThemeValue(theme, 'layout.page')
  const [{ width, height }, setRect] = useState({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
    const onResize = () => {
      setRect({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const isSufficient = width >= minWidth && height >= minHeight

  return [isSufficient, minWidth, minHeight]
}
