import { func, object, string } from 'prop-types'

const appearance = string.isRequired
const direction = string.isRequired
const icon = object.isRequired
const iconPosition = object
const onClick = func
const onMouseLeave = func
const shape = string.isRequired
const size = string.isRequired

export { appearance, direction, icon, iconPosition, onClick, onMouseLeave, shape, size }
