import React from 'react'
import styled, { useTheme } from 'styled-components'
import { layout, space } from 'styled-system'

import { getThemeValue } from 'theming'
import { defaultProps } from './defaultProps'
import { fill, height, stroke, strokeWidth, width } from './defaultPropTypes'

const ExclamationMarkSizer = styled.span.withConfig({
  shouldForwardProp: (props) => ['children'].includes(props),
})`
  display: block;
  ${layout}
  ${space}
`

const ExclamationMarkSVG = ({ fill, stroke, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
  >
    <path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" />
  </svg>
)

export const ExclamationMark = ({ fill, height, stroke, strokeWidth, width, ...rest }) => {
  const theme = useTheme()
  const svgFill = getThemeValue(theme, fill)
  const svgStroke = getThemeValue(theme, stroke)

  return (
    <ExclamationMarkSizer width={width} height={height} {...rest}>
      <ExclamationMarkSVG fill={svgFill} stroke={svgStroke} strokeWidth={strokeWidth} />
    </ExclamationMarkSizer>
  )
}

ExclamationMark.propTypes = {
  fill,
  height,
  stroke,
  strokeWidth,
  width,
}

ExclamationMark.defaultProps = defaultProps
