import React from 'react'

import { StyledText } from '../StyledText'
import { typeStyles } from '../typeStyles'

import { defaultProps } from '../defaultProps'
import { defaultPropTypes } from '../defaultPropTypes'

export const Copy = ({ children, tag, ...props }) => (
  <StyledText scale="copy" {...props} {...typeStyles.copy} as={tag}>
    {children}
  </StyledText>
)

export const CopyMedium = ({ children, tag, ...props }) => (
  <StyledText scale="copy" {...props} {...typeStyles.copyMedium} as={tag}>
    {children}
  </StyledText>
)

export const CopyMediumItalic = ({ children, tag, ...props }) => (
  <StyledText scale="copy" {...props} {...typeStyles.copyMediumItalic} as={tag}>
    {children}
  </StyledText>
)

export const CopySemiBold = ({ children, tag, ...props }) => (
  <StyledText scale="copy" {...props} {...typeStyles.copySemiBold} as={tag}>
    {children}
  </StyledText>
)

export const CopyBold = ({ children, tag, ...props }) => (
  <StyledText scale="copy" {...props} {...typeStyles.copyBold} as={tag}>
    {children}
  </StyledText>
)

export const CopyBlack = ({ children, tag, ...props }) => (
  <StyledText scale="copy" {...props} {...typeStyles.copyBlack} as={tag}>
    {children}
  </StyledText>
)

export const CopyAbstract = ({ children, tag, ...props }) => (
  <StyledText scale="copy" {...props} {...typeStyles.copyAbstract} as={tag}>
    {children}
  </StyledText>
)

Copy.propTypes = defaultPropTypes
CopyMedium.propTypes = defaultPropTypes
CopyMediumItalic.propTypes = defaultPropTypes
CopySemiBold.propTypes = defaultPropTypes
CopyBold.propTypes = defaultPropTypes
CopyBlack.propTypes = defaultPropTypes
CopyAbstract.propTypes = defaultPropTypes

Copy.defaultProps = defaultProps
CopyMedium.defaultProps = defaultProps
CopyMediumItalic.defaultProps = defaultProps
CopySemiBold.defaultProps = defaultProps
CopyBold.defaultProps = defaultProps
CopyBlack.defaultProps = defaultProps
CopyAbstract.defaultProps = defaultProps
