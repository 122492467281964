import React, { memo, useRef, useCallback, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { array, arrayOf, bool, number, shape as propsShape, string, oneOfType, oneOf } from 'prop-types'

import styled, { useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Button } from '@ui/Buttons'
import { FlexContainer, FlexItem, PosRelative, Box } from '@layout/BuildingBlocks'
import { Flyout } from '@utilities/Flyout'
import { OnClickOutside } from '@utilities/OnClickOutside'
import { FormElement, CheckboxGroup, Checkbox, withFormElementProps } from '@forms'
import {
  OptionsList,
  OptionsListItem,
  OptionsLabelTextCheckbox,
} from '@forms/DropdownSelectComponents/SubComponents'

import { useCursor, useKeyboardNavigation, withBadge, withButton } from '@forms/DropdownSelectComponents'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'

import { isFunction } from 'lib/util'
import { getThemeValue } from 'theming'

const ActionBar = styled(FlexContainer)`
  background: ${(props) => themeGet('dropdown.footer.bg', '#fff')(props)};
  padding: ${(props) => themeGet('dropdown.footer.py', 0)(props)}
    ${(props) => themeGet('dropdown.footer.px', 0)(props)};
  margin-top: ${(props) => themeGet('dropdown.footer.mt', 0)(props)};
`

const buildItem = (entry) => {
  const value = entry !== null && typeof entry.value !== 'undefined' ? entry.value : entry
  let label = entry.label ? entry.label : value
  if (entry !== null && typeof entry.amount !== 'undefined') {
    label += ' (' + entry.amount + ')'
  }

  return {
    label,
    value,
    disabled: !!entry.disabled,
  }
}

const groupStateToValue = (state) => {
  return state.value.checkboxValue.value
}

const valueToGroupState = (value, options) => {
  return {
    checkboxValue: { value },
    label: {
      value: value.length
        ? value
            .map((val) => options.findIndex((option) => option.value === val))
            .sort((a, b) => a - b)
            .map((index) => options?.[index]?.label ?? null)
            .filter((label) => label !== null)
            .join(', ')
        : null,
    },
  }
}

export const DropdownMultiSelectComponent = ({
  appearance,
  children,
  debounce,
  defaultValue,
  dropdownHeight,
  formElementRefs,
  items,
  leftAlign,
  keepButtonText,
  name,
  onChange,
  optionsListMaxHeight,
  optionsListPlacement,
  optionsMinWidth,
  optionsSize,
  placeholder,
  refBadge,
  shape,
  size,
  stretch,
  value,
  ...props
}) => {
  const { t } = useTranslation('translation')
  const theme = useTheme()
  const spacerBg = getThemeValue(theme, 'dropdown.spacer.bg')
  const spacerHeightTop = getThemeValue(theme, 'dropdown.spacer.height.top')

  const [initialValue] = useState(defaultValue ?? value ?? [])

  const onDebouncedUpdate = useCallback(
    (value, changeEmitter) => {
      isFunction(onChange) && onChange({ id: props.id, name, value, changeEmitter })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const internalOnChange = useCallback(
    (target) => {
      setInternalValue(groupStateToValue(target), target.changeEmitter)
    },
    [setInternalValue]
  )

  const options = useMemo(() => items.map((item) => buildItem(item)), [items])

  const formState = useMemo(() => valueToGroupState(internalValue, options), [internalValue, options])

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  const [isActive, setIsActive] = useState(false)
  const [isFocusedBadge, setIsFocusedBadge] = useState(false)

  const onClickAll = useCallback(() => {
    setInternalValue(options.filter((option) => !option.disabled).map((option) => option.value))
  }, [setInternalValue, options])

  const onClickNone = useCallback(() => {
    setInternalValue([])
  }, [setInternalValue])

  const onClickBadge = useCallback(() => {
    setIsActive((prev) => !prev)
  }, [])

  const onFocusBadge = useCallback(() => {
    setIsFocusedBadge(true)
  }, [])

  const onBlurBadge = useCallback(() => {
    setIsFocusedBadge(false)
  }, [])

  const refocusOnClose = useRef(false)
  const refOptionsList = useRef()
  const refOptionsListItems = useRef([])
  const activeByArrow = useRef(false)

  const { cursorValue, setNextCursorValue } = useCursor({
    options,
    isActive,
    refScrollContainer: refOptionsList,
    refOptions: refOptionsListItems,
  })

  const openDropdown = useCallback((byArrow) => {
    activeByArrow.current = byArrow
    setIsActive(true)
  }, [])

  const closeDropdown = useCallback((refocus = false) => {
    refocusOnClose.current = refocus
    setIsActive(false)
  }, [])

  const switchOption = useCallback(
    (value) => {
      setInternalValue((prev) =>
        value === null ? prev : prev.includes(value) ? prev.filter((val) => val !== value) : [...prev, value]
      )
    },
    [setInternalValue]
  )

  const selectCursor = useCallback(() => {
    if (cursorValue === null) {
      closeDropdown(true)
    } else {
      switchOption(cursorValue)
    }
  }, [cursorValue, switchOption, closeDropdown])

  useKeyboardNavigation({
    name,
    focused: isFocusedBadge,
    active: isActive,
    spacePreventDefault: true,
    closeDropdown,
    openDropdown,
    selectCursor,
    moveCursor: setNextCursorValue,
  })

  const closeDropdownNoFocus = useCallback(() => closeDropdown(false), [closeDropdown])

  const onClickOptionsListItem = useCallback(
    (evt, index) => {
      evt.preventDefault()
      if (options[index].disabled) return
      const value = options[index].value
      switchOption(value)
    },
    [options, switchOption]
  )

  useEffect(() => {
    if (isActive) {
      setNextCursorValue(activeByArrow.current)
      activeByArrow.current = false
    }
  }, [isActive, setNextCursorValue])

  useEffect(() => {
    if (!isActive) {
      if (refocusOnClose.current) {
        refocusOnClose.current = false
        refBadge.current.focus()
      }
    }
  }, [isActive, refBadge])

  return (
    <FormElement name={name} value={formState} onChange={internalOnChange}>
      <OnClickOutside handler={closeDropdownNoFocus}>
        {React.cloneElement(children, {
          ...{
            appearance,
            isActive,
            leftAlign,
            onBlur: onBlurBadge,
            onClick: onClickBadge,
            onFocus: onFocusBadge,
            placeholder,
            refBadge,
            shape,
            size,
            stretch,
          },
          ...(() => {
            if (keepButtonText) return
            return {
              text: formState.label.value,
            }
          })(),
        })}
        {isActive && (
          <Flyout minWidth="100%" refOpener={refBadge} placement={optionsListPlacement}>
            <PosRelative>
              <Box mt={spacerHeightTop} bg={spacerBg} />
              <OptionsList maxHeight={optionsListMaxHeight} ref={refOptionsList}>
                <CheckboxGroup name="checkboxValue" forElementName={name}>
                  {options.map((item, index) => (
                    <OptionsListItem
                      key={item.value}
                      optionsMinWidth={optionsMinWidth}
                      ref={(el) => (refOptionsListItems.current[index] = el)}
                      onClick={(evt) => onClickOptionsListItem(evt, index)}
                    >
                      <Checkbox value={item.value} disabled={item.disabled} forElementName="checkboxValue">
                        <OptionsLabelTextCheckbox size={size} hasCursor={item.value === cursorValue}>
                          {item.label}
                        </OptionsLabelTextCheckbox>
                      </Checkbox>
                    </OptionsListItem>
                  ))}
                </CheckboxGroup>
              </OptionsList>
              <ActionBar flexDirection="row" alignItems="center" justifyContent="space-between">
                <FlexItem>
                  <FlexItem marginRight="8px">
                    <Button
                      size="tiny"
                      appearance="gray"
                      text={t('actions.actionSelectAll')}
                      onClick={onClickAll}
                    />
                  </FlexItem>
                  <FlexItem>
                    <Button
                      size="tiny"
                      appearance="ghost"
                      text={t('actions.actionUnselectAll')}
                      onClick={onClickNone}
                    />
                  </FlexItem>
                </FlexItem>
              </ActionBar>
            </PosRelative>
          </Flyout>
        )}
      </OnClickOutside>
    </FormElement>
  )
}

const WithBadge = withBadge(DropdownMultiSelectComponent)
WithBadge.type = 'DropdownMultiSelect'

export const DropdownMultiSelect = withFormElementProps(memo(WithBadge))

const WithButton = withButton(DropdownMultiSelectComponent)
WithButton.type = 'DropdownMultiSelect'

export const DropdownMultiSelectMenu = withFormElementProps(memo(WithButton))

DropdownMultiSelectComponent.propTypes = {
  appearance: string,
  defaultValue: array,
  debounce: number,
  items: arrayOf(
    propsShape({
      label: string.isRequired,
      value: oneOfType([string, number]).isRequired,
      disabled: bool,
    })
  ).isRequired,
  keepButtonText: bool,
  leftAlign: bool,
  name: string.isRequired,
  optionsMinWidth: oneOfType([string, array]),
  optionsListMaxHeight: oneOfType([string, array]),
  optionsListPlacement: oneOf([
    'topStart',
    'rightStart',
    'bottomStart',
    'leftStart',
    'topEnd',
    'rightEnd',
    'bottomEnd',
    'leftEnd',
  ]),
  optionsSize: string,
  placeholder: string,
  size: string,
  shape: string,
  stretch: bool,
  value: array,
}

DropdownMultiSelectComponent.defaultProps = {
  appearance: 'lightgray',
  defaultValue: null,
  debounce: null,
  keepButtonText: false,
  leftAlign: true,
  optionsMinWidth: 'inherit',
  optionsListMaxHeight: null,
  optionsListPlacement: 'bottomStart',
  optionsSize: 'medium',
  placeholder: 'Bitte wählen',
  shape: 'sqaure',
  size: 'medium',
  stretch: true,
  value: null,
}
