import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const excludeProps = ['height', 'width', 'coloring', 'translate']

export const Disc = styled.span.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  background-color: ${(props) => themeGet('base.white', 'white')(props)};
  border-radius: 50px;
  box-shadow: 0 0 1px 1px #fff;
  height: ${(props) => props.height};
  transition: all 0.15s ease-out;
  transition: transform 0.15s;
  transform: ${(props) => (props.checked ? `translateX(${props.translate}em)` : 'unset')};
  width: ${(props) => props.width};
`
