import createStore from 'lib/flux-store'
import api from 'stores/api'
import functionQueue from 'lib/util/functionQueue'

import { availableCluster, assetClasses } from 'config/scoring'

const calcCache = (feature, existingCache = {}) => {
  const newCache = {}
  Object.values(feature.areas).forEach((current) => {
    if (typeof existingCache[current.gac] === 'undefined' && typeof newCache[current.gac] === 'undefined') {
      newCache[current.gac] = (current.type === '30' ? 'Lkr. ' : '') + current.name
    }
  })
  if (Object.keys(newCache).length) {
    return { ...existingCache, ...newCache }
  } else {
    return existingCache
  }
}

export const initialState = {
  isLoading: true,
  isFailure: false,
  messages: [],
  locations: {},
  visibleLocations: [],
  selectedAssetClass: 'office',
  yAxisChartState: '',
  filter: {
    cluster: [...availableCluster],
    riwisCategories: [],
    federalStates: [],
    rank: { from: null, to: null },
  },
  scoreCardGac: '',
  filterOverview: {
    selectedActiveAssetClasses: [...assetClasses],
    assetClassToSort: 'office',
    rank: { from: null, to: null },
  },
}

const ignoreKeysOnUpdate = {
  isLoading: true,
}

const setLoadingSrc = {}

const queuedSave = functionQueue(api.AppState.save)
let lastSavedState = initialState

const callbacks = {
  onUpdate: (state) => {
    if (state.isLoading || state.dontPreserveState) {
      return Promise.resolve()
    }

    const changed = Object.keys(state).reduce((changed, key) => {
      return changed || (ignoreKeysOnUpdate[key] ? false : !Object.is(lastSavedState[key], state[key]))
    }, false)

    if (changed) {
      lastSavedState = { ...state }

      return queuedSave(__NAME__, lastSavedState)
    } else {
      return Promise.resolve()
    }
  },
}

const __NAME__ = 'ScoringStateStore'

const actions = {
  dontPreserveState: () => ({}),
  loadState: () => (dispatch) => {
    api.AppState.get(__NAME__)
      .then((response) => {
        const state = response.data.data
        dispatch({ type: 'setLoadedState', payload: state })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  addLocation: (feature) => ({ feature }),
  removeLocation: (locationID) => ({ locationID }),
  setVisibleLocations: (visibleLocations) => ({ visibleLocations }),
  setSelectedAssetClass: (assetClass) => ({ assetClass }),
  setFilter: (filter) => ({ filter }),
  setFilterOverview: (filterOverview) => ({ filterOverview }),
  setYAxisChartState: (yAxisChartState) => ({ yAxisChartState }),
  setScoreCardGac: (scoreCardGac) => ({ scoreCardGac }),
}

const reducer = {
  dontPreserveState: (state, { payload }) => ({ ...state, dontPreserveState: true, isLoading: false }),
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setLoadedState: (state, { payload }) => {
    const set = Object.keys(initialState).reduce((set, key) => {
      if (typeof payload[key] !== 'undefined') {
        if (setLoadingSrc[key]) {
          set[key] = { ...payload[key] }
        } else {
          set[key] = payload[key]
        }
      }
      if (Array.isArray(initialState[key])) {
        if (!Array.isArray(set[key])) {
          set[key] = [...initialState[key]]
        }
      } else if (typeof set[key] === 'object' && typeof initialState[key] === 'object') {
        set[key] = { ...initialState[key], ...set[key] }
      } else if (typeof set[key] === 'undefined') {
        if (Array.isArray(initialState[key])) {
          set[key] = [...initialState[key]]
        } else if (typeof initialState[key] === 'object') {
          set[key] = { ...initialState[key] }
        } else {
          set[key] = initialState[key]
        }
      }
      return set
    }, {})

    if (typeof set.locations === 'object' && set.locations !== null) {
      set.gacsNameCache = {}
      Object.values(set.locations).forEach((feature) => {
        set.gacsNameCache = calcCache(feature, set.gacsNameCache)
      })
    }

    lastSavedState = { ...state, ...set, isLoading: false }
    return lastSavedState
  },
  addLocation: (state, { feature }) => {
    // Set ID for new location
    const currentIDs = Object.keys(state.locations)
    const currentColorIDs = currentIDs.map((id) => parseInt(id) % 5)
    const maxID = currentIDs.length > 0 ? Math.max(...currentIDs) : -1

    // new ID has to be bigger then current max, and colorID should not be in use
    let newID = new Array(5).findIndex((undef, index) => !currentColorIDs.includes(index))
    while (newID < maxID) newID += 5

    return {
      ...state,
      locations: { ...state.locations, [newID]: feature },
      visibleLocations: [...state.visibleLocations, newID],
      // Add all gac's of location to the gacsNameCache:
      gacsNameCache: calcCache(feature, state.gacsNameCache),
    }
  },
  removeLocation: (state, { locationID }) => {
    let remainingLocations = { ...state.locations }
    delete remainingLocations[locationID]

    return {
      ...state,
      locations: remainingLocations,
    }
  },
  setVisibleLocations: (state, { visibleLocations }) => {
    const nextVisible =
      typeof visibleLocations === 'function' ? visibleLocations(state.visibleLocations) : visibleLocations
    return { ...state, visibleLocations: nextVisible }
  },
  setSelectedAssetClass: (state, { assetClass }) => ({
    ...state,
    selectedAssetClass: assetClass,
  }),
  setFilter: (state, { filter }) => {
    const newState = typeof filter === 'function' ? filter(state.filter) : filter

    if (newState === state.filter) {
      return state
    }

    return {
      ...state,
      filter: { ...state.filter, ...newState },
    }
  },
  setFilterOverview: (state, { filterOverview }) => {
    const newState =
      typeof filterOverview === 'function' ? filterOverview(state.filterOverview) : filterOverview

    if (newState === state.filterOverview) {
      return state
    }

    return {
      ...state,
      filterOverview: { ...state.filterOverview, ...newState },
    }
  },
  setYAxisChartState: (state, { yAxisChartState }) => ({ ...state, yAxisChartState }),
  setScoreCardGac: (state, { scoreCardGac }) => ({ ...state, scoreCardGac }),
}

export const [ScoringStateContext, ScoringStateProvider, useScoringStateStore] = createStore(
  reducer,
  actions,
  initialState,
  callbacks,
  __NAME__
)
