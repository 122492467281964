import React, { useCallback } from 'react'

import { DropdownMenu } from '@ui/DropdownMenu'
import { BudiconEarth } from 'bgag-budicons'

import { languages } from 'i18n'
import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'
import { useTranslation } from 'react-i18next'

export const DropdownMenuLanguage = ({ iconColor }) => {
  const { hasLicence, hasAdminKey } = useAuthzChecks()
  const { t, i18n } = useTranslation()

  const allowedLanguages = [...languages, hasAdminKey('i18nCiMode') && 'cimode'].filter(Boolean)
  const entries = []

  const handleClick = useCallback(
    (lang) => {
      i18n.changeLanguage(lang)
    },
    [i18n]
  )

  allowedLanguages.forEach((lang) => {
    let entry = {
      label: t(`languages.${lang}`),
      handleClick: () => handleClick(lang),
    }
    entries.push(entry)
  })

  return (
    hasLicence('i18n') && (
      <DropdownMenu
        icon={{
          icon: {
            Icon: BudiconEarth,
            strokeWidth: '0',
            type: 'solid',
            fill: iconColor,
            width: ['15px', null, '17px', null, '20px'],
          },
          iconPosition: { top: ['-9px', null, '-9px', null, '-11px'] },
        }}
        placement="bottomEnd"
        items={entries}
      />
    )
  )
}
