// holds the next index per prefix
const names = {}

/** Returns a runtime unique name by prefix.
 *
 * Example
 * uniqueName('MyName') -> 'MyName'
 * uniqueName('MyName') -> 'MyName-2'
 * uniqueName('AnotherName') -> 'AnotherName'
 * uniqueName('Foo', {omit1: false}) -> 'Foo-1'
 *
 * @param {string} prefix
 * @param {object} options
 * @returns {string} prefix-<id>
 */
export function uniqueName(prefix = 'name', options = {}) {
  const { omit1 = true } = options
  if (typeof names[prefix] === 'undefined') names[prefix] = 0
  names[prefix] += 1
  const id = names[prefix]
  return id === 1 && omit1 ? prefix : prefix + '-' + id
}
