import React from 'react'

import { useTranslation } from 'react-i18next'

import { Box } from '@layout/BuildingBlocks'
import { LayoutPublic } from '@layout/LayoutPublic'
import { CardPublic } from '@layout/CardPublic'
import { Layout } from '@layout/Layout'
import { Header } from '@layout/Header'
import { Card } from '@layout/Card'

import { ButtonLink } from '@ui/Buttons'
import { NavigationMain } from '@ui/NavigationMain'
import { NavigationMeta } from '@ui/NavigationMeta'

import { CopyMedium, GreatPrimer } from 'stories/typography'

export const NotFound = () => {
  const { t } = useTranslation()
  return (
    <LayoutPublic>
      <LayoutPublic.Main>
        <CardPublic>
          <CardPublic.Logo />
          <CardPublic.Header>{t('errorPage.404.header')}</CardPublic.Header>
          <CardPublic.BodyLeft>
            <Box>
              <CopyMedium textAlign="center">{t('errorPage.404.body')}</CopyMedium> <br />
              <ButtonLink
                textAlign="center"
                linkType="reactRouterDomLink"
                appearance="bare"
                stretch={true}
                to="/"
                text={t('errorPage.404.backToHome')}
              />
            </Box>
          </CardPublic.BodyLeft>
        </CardPublic>
      </LayoutPublic.Main>
    </LayoutPublic>
  )
}

export const NotFoundInApp = () => {
  const { t } = useTranslation()
  return (
    <Layout hasMainPaddingTop={true} hasMainPaddingLeft={true}>
      <Layout.Header>
        <Header.AppLogo />
        <Header.NavigationMain>
          <NavigationMain />
        </Header.NavigationMain>
        <Header.NavigationMeta>
          <NavigationMeta />
        </Header.NavigationMeta>
      </Layout.Header>
      <Layout.Main>
        <Card>
          <Card.Header>
            <Card.Title>
              <GreatPrimer>{t('errorPage.404.header')}</GreatPrimer>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Box>
              <CopyMedium>{t('errorPage.404.body')}</CopyMedium> <br />
              <ButtonLink
                linkType="reactRouterDomLink"
                appearance="bare"
                to="/"
                text={t('errorPage.404.backToHome')}
              />
            </Box>
          </Card.Body>
        </Card>
      </Layout.Main>
    </Layout>
  )
}
