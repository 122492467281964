export const radii = {
  tiny: '3px',
  small: '4px',
  medium: '6px',
  large: '8px',
  extralarge: '10px',
  huge: '14px',
  extrahuge: '20px',
  full: '100px',
}
