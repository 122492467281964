import { generateFontSizeMediaQuery } from '@typography'
import { css } from 'styled-components'
// import { themeGet } from '@styled-system/theme-get'

/**
 * Override leaflet classes. Will be injected in GlobalStyle.js.
 * Take care when updating library!
 */
const leafletStylesheetOverrides = css`
  .leaflet-tooltip {
    padding: 1px 5px 2px 5px !important;
    font-size: 12px !important;
    border-radius: 0 !important;
  }

  .leaflet-tooltip.noContainer {
    font-weight: bold;
    color: #ffffff !important;
    padding: 0px !important;
    text-shadow: 1px 1px 1px black;

    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  .leaflet-tooltip.noContainer:before {
    border: none !important;
    content: '\\2022' !important;
    font-size: 15px !important;
  }
  .leaflet-tooltip-left.noContainer {
    transform-origin: right center;
  }
  .leaflet-tooltip-right.noContainer {
    transform-origin: -7px center;
  }
  .leaflet-tooltip-top.noContainer {
    transform-origin: center bottom;
  }
  .leaflet-tooltip-bottom.noContainer {
    transform-origin: center top;
  }
  .leaflet-tooltip-left.noContainer:before {
    margin-right: -11px !important;
  }
  .leaflet-tooltip-right.noContainer:before {
    margin-left: -11px !important;
  }
  .leaflet-tooltip-bottom.noContainer:before,
  .leaflet-tooltip-top.noContainer:before {
    left: 0 !important;
    margin-left: 0px !important;
  }
  .leaflet-tooltip-left.noContainer:before,
  .leaflet-tooltip-right.noContainer:before {
    top: 0 !important;
    margin-top: 0px !important;
  }

  .leaflet-container {
    font-family: inherit;
    // font-family: font-family: 'Whitney A', 'Whitney B';
    font: 16px/1.5 'Whitney A', 'Whitney B';
    font-weight: 400;
  }
  // needed to allow leaflet button style without actual anks
  .leaflet-bar.ank {
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    width: 26px;
    height: 26px;
    line-height: 26px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;
  }

  .leaflet-bar.ank:hover {
    background-color: #f4f4f4;
  }

  .leaflet-touch .leaflet-bar {
    border: none;
  }

  .leaflet-touch .leaflet-bar {
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.3);
  }
  .leaflet-touch .leaflet-bar a {
    width: 36px;
    height: 36px;
    border: none;
  }

  .leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-bottom: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .leaflet-touch .leaflet-control-layers,
  .leaflet-touch .leaflet-bar {
    border: none;
    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.3);
    background-clip: padding-box;
  }

  .leaflet-control-layers-expanded {
    padding: 16px 16px 12px;
  }

  .leaflet-control-layers label {
    margin: 0 0 6px;
  }

  .leaflet-control-layers label span {
    display: inline-block;
    position: relative;
    margin-left: 4px;
    top: 1px;
  }

  .leaflet-control-layers-selector {
    top: 2px;
  }

  .leaflet-marker-icon.leaflet-interactive,
  .leaflet-image-layer.leaflet-interactive,
  .leaflet-pane > svg path.leaflet-interactive,
  svg.leaflet-image-layer.leaflet-interactive path {
    outline: none;
  }
  .leaflet-control-attribution {
    ${generateFontSizeMediaQuery('minion')}
  }
`

export default leafletStylesheetOverrides
