export const allowedProps = [
  'tabIndex',
  'autoFocus',
  'autoComplete',
  'disabled',
  'name',
  'id',
  'onBlur',
  'onClick',
  'onChange',
  'onFocus',
  'onMouseEnter',
  'onMouseLeave',
  'onMouseOut',
  'onMouseOver',
  'onKeyDown',
  'placeholder',
  'readonly',
  'type',
  'value',
  'width',
  'min',
  'max',
  'step',
]
