import React from 'react'

import { Box, FlexRow } from '@layout/BuildingBlocks'

import luehrmannLogoUrl from 'assets/images/logos/luehrmann-logo.svg'
import { LogoRiwisText } from '@utilities/SVG/Logos'
import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'

export const L3PlusHeaderBanner = () => {
  const theme = useTheme()

  const backgroundBreakWidth = getThemeValue(theme, 'layout.headerFullscreen.backgroundBreakWidth')

  return (
    <FlexRow>
      <Box width={backgroundBreakWidth} alignSelf="end">
        <LogoRiwisText part="all" color="white" size="25" />
      </Box>
      <Box alignSelf="end" ml={backgroundBreakWidth}>
        <img alt="Lührmann" src={luehrmannLogoUrl} width="125px" height="33px" />
      </Box>
    </FlexRow>
  )
}
