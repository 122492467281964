import { createDataCache } from 'lib/cache'

import api from 'stores/api'

const conditionals = [{ name: 'type' }]

const getter = (type) => {
  return api.Downloads.getDocuments(type).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }

    return result.data.data
  })
}

export const [getList, bustList] = createDataCache(conditionals, getter, {
  name: 'iDownloadDocumentsCache',
})
