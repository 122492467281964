import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { bool, oneOfType, object, string, shape } from 'prop-types'

import { Badge, Container, Disc, Label } from './SubComponents'

import { getThemeValue } from 'theming'
import { defaultProps as textProps } from '@typography'

const defaultPropsColoring = {
  checkedBg: 'colors.theme.app.color',
  uncheckedBg: 'colors.base.gray.400',
  disabledBg: 'colors.base.gray.170',
  checkedText: 'colors.theme.app.color',
  uncheckedText: 'colors.base.gray.400',
  disabledText: 'colors.base.gray.270',
}

const useColor = (isChecked, isDisabled, coloring) => {
  const theme = useTheme()
  const { checkedBg, uncheckedBg, disabledBg, checkedText, uncheckedText, disabledText } = coloring
  const [textColor, setTextColor] = useState()
  const [bgColor, setBgColor] = useState()

  useEffect(() => {
    setBgColor(
      () =>
        getThemeValue(theme, isDisabled ? disabledBg : isChecked ? checkedBg : uncheckedBg) ||
        (isDisabled ? disabledBg : isChecked ? checkedBg : uncheckedBg)
    )
  }, [isChecked, isDisabled, disabledBg, checkedBg, uncheckedBg, theme])

  useEffect(() => {
    setTextColor(() =>
      getThemeValue(theme, isDisabled ? disabledText : isChecked ? checkedText : uncheckedText)
    )
  }, [isChecked, isDisabled, checkedText, disabledText, uncheckedText, theme])

  return [bgColor, textColor]
}

export const CheckboxToggle = ({ checked, children, coloring, disabled, typeScale, typeStyle }) => {
  const [bgColor, textColor] = useColor(checked, disabled, Object.assign({}, defaultPropsColoring, coloring))

  const width = 1.875
  const height = 0.9825
  const lineHeight = ['1.2', null, '1.25', '1.2']
  const translate = width - height

  return (
    <Container marginLeft={'2px'}>
      <Label
        {...textProps}
        color={textColor}
        disabled={disabled}
        lineHeight={lineHeight}
        typeScale={typeScale}
        typeStyle={typeStyle}
      >
        <Badge
          typeScale={typeScale}
          checked={checked}
          coloring={bgColor}
          disabled={disabled}
          height={height}
          width={width}
        >
          <Disc checked={checked} height={`${height}em`} width={`${height}em`} translate={translate} />
        </Badge>
        {children}
      </Label>
    </Container>
  )
}

CheckboxToggle.propTypes = {
  checked: bool,
  children: oneOfType([string, object]),
  coloring: shape({
    checkedBg: string,
    uncheckedBg: string,
    disabledBg: string,
    checkedText: string,
    uncheckedText: string,
    disabledText: string,
  }),
  disabled: bool,
  typeScale: string,
  typeStyle: string,
}

CheckboxToggle.defaultProps = {
  checked: false,
  coloring: defaultPropsColoring,
  disabled: false,
  typeScale: 'copy',
  typeStyle: 'copy',
}
