import { string, shape } from 'prop-types'
import { linkItemShape, iconShape } from '../../defaultPropTypes'

const itemProps = shape({
  icon: iconShape,
  label: string,
  link: linkItemShape,
})

export { itemProps }
