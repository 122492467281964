import createStore from 'lib/flux-store'

const initialState = {
  zone: null,
  isLoading: false,
  drawMode: false,
}

const actions = {
  setZone: (zone) => ({ zone }),
  setParams: (params) => ({ params }),
  setGeometry: (geometry) => ({ geometry }),
  setName: (name) => ({ name }),
  setLoading: (isLoading) => ({ isLoading }),
  toggleSelectedId: (gid, name) => ({ gid, name }),
  setDrawMode: (drawMode) => ({ drawMode }),
}

const reducer = {
  setZone: (state, { zone }) => ({ ...state, zone, drawMode: zone?.properties?.creationMethod === 'draw' }),
  setParams: (state, { params }) => ({
    ...state,
    zone: {
      ...state.zone,
      properties: {
        ...state.zone.properties,
        creationParams: { ...state.zone.properties.creationParams, ...params },
      },
    },
  }),
  setGeometry: (state, { geometry }) => ({ ...state, zone: state.zone ? { ...state.zone, geometry } : null }),
  setName: (state, { name }) => ({
    ...state,
    zone: {
      ...state.zone,
      properties: {
        ...state.zone.properties,
        name,
      },
    },
  }),
  setLoading: (state, { isLoading }) => ({ ...state, isLoading }),
  toggleSelectedId: (state, { gid, name }) => {
    const oldIds = state.zone.properties.creationParams.ids
    const oldNames = state.zone.properties.creationParams.names

    const isActive = oldIds.includes(gid)
    let newIds
    let newNames
    if (!isActive) {
      newIds = [...oldIds, gid]
      newNames = [...oldNames, name]
    } else {
      const idx = oldIds.findIndex((el) => el === gid)
      newIds = [...oldIds.slice(0, idx), ...oldIds.slice(idx + 1)]
      newNames = [...oldNames.slice(0, idx), ...oldNames.slice(idx + 1)]
    }

    return {
      ...state,
      zone: {
        ...state.zone,
        properties: {
          ...state.zone.properties,
          creationParams: {
            ...state.zone.properties.creationParams,
            ids: newIds,
            names: newNames,
          },
        },
      },
    }
  },
  setDrawMode: (state, { drawMode }) => ({ ...state, drawMode }),
}

export const [GeoToolsEditZoneStore, GeoToolsEditZoneStoreProvider, useGeoToolsEditZoneStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'GeoToolsEditZoneStore'
)
