export const getScrollbar = ({ colors }) => ({
  default: {
    thumb: {
      bg: colors.gray[300],
    },
    track: {
      bg: colors.lightgray[330],
    },
  },
  small: {
    thumb: {
      bg: colors.gray[300],
    },
    track: {
      bg: colors.lightgray[330],
    },
  },
})
