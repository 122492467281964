import { useEffect, useRef, useCallback } from 'react'

/**
 * Sometimes we need a callback like onChange that is allowed to change without
 * notifying hooks. When the callback is invoked however it shall call the
 * current instantiation of this callback.
 */
export const useStableCallback = (callback) => {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  return useCallback((...args) => callbackRef.current?.(...args), [])
}
