import React from 'react'
import { useTheme } from 'styled-components'

import { FlexItem } from '@layout/BuildingBlocks'

import { getThemeValue } from 'theming'

export const HeaderPartner = ({ children }) => {
  const theme = useTheme()
  const marginLeft = getThemeValue(theme, 'header.space.partner')

  return (
    <FlexItem alignItems="center" flexDirection="row" ml={marginLeft}>
      {children}
    </FlexItem>
  )
}
