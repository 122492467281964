import React from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const StyledComponent = styled.div`
  background-color: ${themeGet('card.colors.body.bg', 'lightgray')};
  border-radius: ${themeGet('card.border.radius', '0px')};
  border: ${themeGet('card.border.width', '0px')} solid ${themeGet('card.border.color', '')};
  display: flex;
  flex-direction: column;
  position: relative;
`

export const CardContainer = ({ id, children, ...props }) => (
  <StyledComponent id={id} {...props}>
    {children}
  </StyledComponent>
)
