export const allowedProps = [
  'dangerouslySetInnerHTML',
  'children',
  'id',
  'onBlur',
  'onClick',
  'onFocus',
  'onMouseOver',
  'onMouseOut',
  'onMouseMouve',
  'onMouseEnter',
  'onMouseLeave',
  'onScroll',
  'tabIndex',
  'disabled',
]
