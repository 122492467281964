export const getControlsSegment = ({ spaces, colors, typography }) => ({
  paddingPanel: spaces.rhythm.horizontal.small,
  spaceBottom: spaces.rhythm.horizontal.large,

  header: {
    spaceBottom: spaces.rhythm.vertical.tiny,
  },

  headline: {
    color: colors.grayScale[900],
    textTransform: typography.textTransforms.uppercase,
    spaceBottom: spaces.rhythm.vertical.tiny,
  },

  subHeadline: {
    color: colors.grayScale[900],
    textTransform: typography.textTransforms.uppercase,
    spaceBottom: spaces.rhythm.vertical.small,
  },

  section: {
    spaceBottom: spaces.rhythm.vertical.large,
    spaceTop: spaces.rhythm.vertical.large,
  },

  segmentsAsGroup: {
    spaceTop: spaces.rhythm.vertical.small,
    spaceTopBorderEquation: `${parseInt(spaces.rhythm.vertical.small.replace(/px/, '')) + 1}px`,
    spaceBottom: 0,
    header: {
      spaceBottom: {
        folded: spaces.rhythm.vertical.small,
        unfolded: spaces.rhythm.vertical.large,
      },
    },
    firstItem: {
      spaceTop: spaces.rhythm.vertical.small,
    },
    lastItem: {
      spaceBottom: spaces.rhythm.vertical.medium,
    },
  },

  segmentContentHighlighted: {
    bg: colors.lightgray[170],
    spaceBottom: spaces.rhythm.vertical.large,
    border: {
      color: colors.gray[170],
    },
  },
})
