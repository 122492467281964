import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const PortfolioIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#F9E07D',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16.5" cy="16.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M27.9583 8.15H25.875V6.05C25.875 5.77152 25.7653 5.50445 25.5699 5.30754C25.3746 5.11062 25.1096 5 24.8333 5H5.04167C4.7654 5 4.50045 5.11062 4.3051 5.30754C4.10975 5.50445 4 5.77152 4 6.05V21.8C4 22.0785 4.10975 22.3455 4.3051 22.5425C4.50045 22.7394 4.7654 22.85 5.04167 22.85H7.125V24.95C7.125 25.2285 7.23475 25.4955 7.4301 25.6925C7.62545 25.8894 7.8904 26 8.16667 26H27.9583C28.2346 26 28.4996 25.8894 28.6949 25.6925C28.8903 25.4955 29 25.2285 29 24.95V9.2C29 8.92152 28.8903 8.65445 28.6949 8.45754C28.4996 8.26062 28.2346 8.15 27.9583 8.15ZM5.04167 21.8V19.7H22.2292C22.3673 19.7 22.4998 19.6447 22.5975 19.5462C22.6951 19.4478 22.75 19.3142 22.75 19.175C22.75 19.0358 22.6951 18.9022 22.5975 18.8038C22.4998 18.7053 22.3673 18.65 22.2292 18.65H20.6667V8.675C20.6667 8.53576 20.6118 8.40223 20.5141 8.30377C20.4164 8.20531 20.284 8.15 20.1458 8.15C20.0077 8.15 19.8752 8.20531 19.7775 8.30377C19.6799 8.40223 19.625 8.53576 19.625 8.675V18.65H17.5417V16.025C17.5417 15.8858 17.4868 15.7522 17.3891 15.6538C17.2914 15.5553 17.159 15.5 17.0208 15.5C16.8827 15.5 16.7502 15.5553 16.6525 15.6538C16.5549 15.7522 16.5 15.8858 16.5 16.025V18.65H14.4167V13.925C14.4167 13.7858 14.3618 13.6522 14.2641 13.5538C14.1664 13.4553 14.034 13.4 13.8958 13.4C13.7577 13.4 13.6252 13.4553 13.5275 13.5538C13.4299 13.6522 13.375 13.7858 13.375 13.925V18.65H11.2917V10.775C11.2917 10.6358 11.2368 10.5022 11.1391 10.4038C11.0414 10.3053 10.909 10.25 10.7708 10.25C10.6327 10.25 10.5002 10.3053 10.4025 10.4038C10.3049 10.5022 10.25 10.6358 10.25 10.775V18.65H8.16667V16.025C8.16667 15.8858 8.11179 15.7522 8.01412 15.6538C7.91644 15.5553 7.78397 15.5 7.64583 15.5C7.5077 15.5 7.37522 15.5553 7.27755 15.6538C7.17987 15.7522 7.125 15.8858 7.125 16.025V18.65H5.04167V6.05H24.8333V21.8H5.04167ZM27.9583 24.95H8.16667V22.85H24.8333C25.1096 22.85 25.3746 22.7394 25.5699 22.5425C25.7653 22.3455 25.875 22.0785 25.875 21.8V9.2H27.9583V24.95Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
