import createStore from 'lib/flux-store'

const initialState = {
  notifications: {},
}

const actions = {
  addNotification: (notification, onlyNew) => ({ notification, onlyNew }),
  closeNotification: (id) => ({ id }),
  closeNotificationInit: (id) => ({ id }),
}
const reducer = {
  addNotification: (prevState, { notification }) => {
    if (!notification.id) {
      return prevState
    }

    const state = { ...prevState, notifications: { ...prevState.notifications } }

    state.notifications[notification.id] = {
      ...prevState.notifications?.[notification.id],
      ...notification,
      flags: { ...notification.flags }, // defaults flags to empty object
      meta: {
        ...(prevState.notifications?.[notification.id]?.meta || { closed: false }),
        ...(notification?.meta || {}),
      },
    }

    return state
  },
  closeNotification: (prevState, { id }) => {
    if (!prevState.notifications[id]) {
      return prevState
    }

    const state = { ...prevState, notifications: { ...prevState.notifications } }

    state.notifications[id] = {
      ...prevState.notifications[id],
      meta: { ...prevState.notifications[id].meta, closed: true },
    }

    return state
  },
  closeNotificationInit: (prevState, { id }) => {
    if (!prevState.notifications[id]) {
      return prevState
    }

    const state = { ...prevState, notifications: { ...prevState.notifications } }

    state.notifications[id] = {
      ...prevState.notifications[id],
      meta: { ...prevState.notifications[id].meta, isClosing: true },
    }

    return state
  },
}

export const [NotificationsContext, NotificationsProvider, useNotificationsStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'NotificationsStore'
)
