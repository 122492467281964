export const colors = {
  default: {
    checked: {
      stroke: 'colors.base.gray.300',
      fill: 'colors.base.lightgray.100',
      innerFill: 'colors.base.gray.900',
    },
    unchecked: {
      stroke: 'colors.base.gray.300',
      fill: 'colors.base.lightgray.100',
    },
  },
  primary: {
    checked: {
      stroke: 'colors.base.gray.500',
      fill: 'colors.base.brand.primary.shallow',
      innerFill: 'colors.base.brand.primary.default',
    },
    unchecked: {
      stroke: 'colors.base.brand.primary.default',
      fill: 'colors.base.brand.primary.shallow',
      innerFill: 'colors.base.brand.primary.shallow',
    },
  },
}
