import { MemoryStorage } from 'lib/storage'

import { hashArgs, fillConditionalsWithValue, promisify } from './lib'
import { validateConditionals, validateArgs } from './validators'
import { sleep } from 'lib/util'

/** Create a memoized function for fetching data
 *
 * @param {object[]} conditionals - Rules for the arguments. See ./validators for schema
 * @param {function} getter - A function defining how to fetch data
 * @param {object} options
 * @returns {function} - The memoized function
 */
export function createDataCache(conditionals, getter, options = {}) {
  const { Storage = MemoryStorage } = options
  const { name, maxSize = Infinity, log = false } = options

  validateConditionals(conditionals)

  const storage = new Storage({ prefix: name, maxSize, log })
  const promiseMemo = {}

  function get(...originalArgs) {
    const args = fillConditionalsWithValue(conditionals, originalArgs)
    validateArgs(args)

    const hash = args.length ? hashArgs(args) : 'noArgs'

    const cached_result = storage.getItem(hash)
    if (cached_result) {
      // sleep to unblock rendering
      return sleep(0).then(() => cached_result)
    }
    if (promiseMemo[hash]) {
      return promiseMemo[hash]
    }
    const result = promisify(getter(...args.map((arg) => arg.value)))
    promiseMemo[hash] = result

    return result.then((res) => {
      storage.setItem(hash, res)
      delete promiseMemo[hash]
      return res
    })
  }
  function bust() {
    storage.clear()
  }
  return [get, bust]
}
