// based on "taming line height" from CSS - Tricks https://css-tricks.com/how-to-tame-line-height-in-css/

import { useState, useEffect, useMemo } from 'react'

const gridRowHeight = 4
const capHeight = 0.76
const descenderHeightScale = 0.11
const preventCollapse = 1

const calculateTypeOffset = (lineHeight, fontSize, descenderHeightScale) => {
  let lineHeightScale = lineHeight / fontSize
  return (lineHeightScale - 1) / 2 + descenderHeightScale
}

export const useTamingLineheight = (ref, typeRowSpan) => {
  const [calculatedFontSize, setCalculatedFontSize] = useState()
  const lineHeight = typeRowSpan * gridRowHeight

  const typeOffset = useMemo(
    () => calculateTypeOffset(lineHeight, calculatedFontSize, descenderHeightScale),
    [lineHeight, calculatedFontSize]
  )

  const topSpace = useMemo(
    () => lineHeight - capHeight * calculatedFontSize,
    [calculatedFontSize, lineHeight]
  )

  const heightCorrection = topSpace > gridRowHeight ? topSpace - (topSpace % gridRowHeight) : 0

  useEffect(() => {
    const { fontSize } = window.getComputedStyle(ref?.current)
    setCalculatedFontSize(fontSize.replace(/px/, ''))
  }, [setCalculatedFontSize, ref])

  return {
    lineHeight,
    typeOffset,
    topSpace,
    heightCorrection,
    preventCollapse,
  }
}
