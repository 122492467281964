import React from 'react'
import styled from 'styled-components'
import { space, flexbox, layout } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

const StyledComponent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${themeGet('card.colors.body.public.bg', 'lightgray')};
  ${flexbox}
  ${space};
  ${layout};
`

export const CardBody = ({ children, ...props }) => {
  return <StyledComponent {...props}>{children}</StyledComponent>
}
