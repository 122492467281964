import React from 'react'
import { useTheme } from 'styled-components'

import { IconWrapper } from '@utilities/IconWrapper'

import { getThemeValue } from 'theming'

export const IconLeft = ({ ...props }) => {
  const theme = useTheme()
  const iconSpaceX = getThemeValue(theme, 'badge.icon.space.x')

  return (
    <IconWrapper
      height={props.iconLeftHeight}
      icon={props.iconLeft}
      iconPosition={props.iconLeftPosition}
      iconWrapper={props.iconWrapper}
      iconVariantSourceName={props.iconVariantSourceName}
      mr={iconSpaceX}
      size={props.size}
      width={props.iconLeftWidth}
    />
  )
}
