import React from 'react'
import { useTheme } from 'styled-components'

import { getThemeValue } from 'theming'

import { BudiconPowerSign } from 'bgag-budicons'

import { useDisplaySizeSufficiency } from 'lib/hooks'

import { FlexRow } from '@layout/BuildingBlocks'
import { ButtonLinkIcon } from '@ui/Buttons'

import {
  DropdownMenuAdministration,
  DropdownMenuLanguage,
  DropdownMenuSystemAdministration,
} from './SubComponents'

const Logout = ({ to, iconColor }) => (
  <ButtonLinkIcon
    appearance="bare"
    linkType="reactRouterDomLink"
    icon={{
      Icon: BudiconPowerSign,
      stroke: iconColor,
      strokeWidth: 1,
      width: ['18px', null, '20px', null, '24px'],
    }}
    iconPosition={{ top: ['-14px', '-14px', '-13.5px', '-13.5px', '-15px'] }}
    size="tiny"
    to={to}
  />
)

export const NavigationMeta = () => {
  const theme = useTheme()
  const iconColor = getThemeValue(theme, 'navigationMeta.icon.color')
  const gap = ['0px', null, null, getThemeValue(theme, 'spaces.1')]

  const [displayIsSufficient] = useDisplaySizeSufficiency()

  return (
    <FlexRow alignItems="center" justifyContent="flex-end" flexDirection="row" pt="2px" flexColumnGap={gap}>
      <DropdownMenuLanguage iconColor={iconColor} />
      {displayIsSufficient && (
        <>
          <DropdownMenuAdministration iconColor={iconColor} />
          <DropdownMenuSystemAdministration iconColor={iconColor} />
        </>
      )}
      <Logout to="/logout" iconColor={iconColor} />
    </FlexRow>
  )
}
