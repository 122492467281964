import React from 'react'
import styled from 'styled-components'

import { typography } from 'styled-system'
import { typeScale, typeStyle } from '@typography'

const excludeProps = [
  'color',
  'disabled',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'typeScale',
  'typeStyle',
  'scale',
]

const StyledText = styled.span.withConfig({
  shouldForwardProp: (prop) => !excludeProps.includes(prop),
})`
  ${typography};
  ${(props) => typeScale(props.typeScale)};
  ${(props) => typeStyle(props.typeStyle)};

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) => props.color};
  display: flex;
  flex-direction: row;
`

export const Label = ({ ...props }) => <StyledText {...props} />
