export const defaultProps = {
  width: ['26px', '26px', '30px', '30px', '34px'],
  height: ['26px', '26px', '30px', '30px', '34px'],
  fill: 'colors.base.gray.100',
  stroke: 'colors.base.gray.500',
  strokeWidth: '1',
  innerFill: null,
  innerStroke: null,
  innerStrokeWidth: '0',
}
