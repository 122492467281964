import React from 'react'
import styled, { useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { color } from 'styled-system'

import { getThemeValue } from 'theming'

import { FlexColumn, FlexItem, PosRelative, PosAbsolute } from '@layout/BuildingBlocks'
import { Minion, Royal as DigitText } from '@typography'

import { elementType, number, string, oneOfType } from 'prop-types'

const Label = styled(Minion)`
  margin: 2px 0;
`

const Digit = styled(DigitText)`
  @media (max-width: 1920px) {
    font-size: clamp(2rem, 2vw, 2.8rem);
  }
  @media (max-width: 1440px) {
    font-size: clamp(1.8rem, 2vw, 2.2rem);
  }
  @media (max-width: 1280px) {
    font-size: clamp(1.5rem, 2.8vw, 4.2rem);
  }
  @media (max-width: 992px) {
    font-size: clamp(1.8rem, 5.2vw, 4.8rem);
  }
  font-size: clamp(1.2rem, 1.6vw, 2.8rem);
`

export const DigitContainer = styled(FlexItem)`
  border-radius: ${themeGet('radii.large', '3px')};
  width: 100%;
  height: 100%;
  padding: 8px 0;
  ${color}
`

export const BadgeDigitWithIcon = ({
  Icon,
  bgColor,
  iconColor,
  textColorMeta,
  textColorValue,
  label,
  value,
}) => {
  const theme = useTheme()
  const iconCol = getThemeValue(theme, iconColor)
  const textColMeta = getThemeValue(theme, textColorMeta)
  const textColValue = getThemeValue(theme, textColorValue)
  const bgCol = getThemeValue(theme, bgColor)

  return (
    <FlexColumn alignItems="center" justifyContent="center" flex="1">
      <PosRelative>
        <FlexColumn alignItems="center" flex="1">
          <PosRelative marginBottom="18px">
            <Icon size="18px" type="solid" color={iconCol} />
          </PosRelative>
          <PosAbsolute bottom="5px">
            <Label tag="span" color={textColMeta}>
              {label}
            </Label>
          </PosAbsolute>
        </FlexColumn>
      </PosRelative>
      <DigitContainer
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        backgroundColor={bgCol}
      >
        <Digit tag="span" color={textColValue}>
          {value}
        </Digit>
      </DigitContainer>
    </FlexColumn>
  )
}

BadgeDigitWithIcon.propTypes = {
  Icon: elementType,
  bgColor: string,
  iconColor: string,
  label: string,
  textColorMeta: string,
  textColorValue: string,
  value: oneOfType([string, number]),
}

BadgeDigitWithIcon.defaultProps = {
  bgColor: 'colors.base.lightgray.400',
  iconColor: 'colors.base.gray.900',
  label: 'Shops',
  textColorMeta: 'colors.base.gray.700',
  textColorValue: 'colors.base.gray.700',
  value: '100',
}
