import React from 'react'

import { IconWrapper } from '@utilities/IconWrapper'
import { BudiconView, BudiconHidePassword } from 'bgag-budicons'
import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'

export const VisibilityCheckbox = ({ checked, ...props }) => {
  const theme = useTheme()
  const fill = getThemeValue(theme, `colors.base.gray.900`)
  if (checked) {
    return (
      <IconWrapper
        icon={{ Icon: BudiconView, strokeWidth: 0, width: props.iconWidth, fill }}
        iconVariantSourceName="buttonIcons"
        {...props}
      />
    )
  }
  return (
    <IconWrapper
      icon={{ Icon: BudiconHidePassword, strokeWidth: 0, width: props.iconWidth, fill }}
      iconVariantSourceName="buttonIcons"
      {...props}
    />
  )
}
