import React, { memo } from 'react'
import { createGlobalStyle, css } from 'styled-components'
import rsTableStylesheetOverrides from './RsTableStylesheetOverride'
import leafletStylesheetOverrides from './LeafletStylesheetOverride'
import highchartsStylesheetOverrides from './HighchartsStylesheetOverride'
import 'rsuite-table/dist/css/rsuite-table.css'
import 'rc-slider/assets/index.css'

// leafletMapPrint.css needs to be css to allow manipulation of document.styleSheets by
// src/lib/util/cssManipulation.js
// Styles added by createGlobalStyle will be rewritten every render!
import './leafletMapPrint.css'

import { themeGet } from '@styled-system/theme-get'
import { generateFontSizeMediaQuery, typeStyle } from '@typography'

const bodyStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    min-height: 100%;

    #root {
      height: 100%;
    }
  }

  body {
    ${typeStyle('copy')}
    ${generateFontSizeMediaQuery('copy')}
    background: ${(props) =>
      props.media === 'print' ? 'white' : themeGet('colors.theme.app.bg', 'white')(props)};
  }

  p:first-child {
    margin-block-start: 0px;
  }
  p:last-child {
    margin-block-end: 0px;
  }

  .blackAndWhiteFilter {
    filter: saturate(0);
  }
`

const Style = createGlobalStyle`
  ${bodyStyles}
  ${rsTableStylesheetOverrides}
  ${leafletStylesheetOverrides}
  ${highchartsStylesheetOverrides}
  `

export const GlobalStyle = memo(({ media }) => {
  return <Style media={media} />
})
