import styled, { css, keyframes } from 'styled-components'

const pulsate = keyframes`
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
`

export const LoadingIndicator = styled.span`
  ${(props) =>
    css`
      border: ${props.borderWidth || '3px'} solid ${props.color};
    `}
  display: block;
  opacity: 0;
  position: relative;
  ${(props) =>
    typeof props.size !== 'undefined'
      ? css`
          width: ${props.size};
          height: ${props.size};
          border-radius: ${parseFloat(props.size) * 2 + props.size.replace(/[0-9.]+/g, '')};
        `
      : css`
          width: 100%;
          height: 100%;
          border-radius: 30px;
        `}
  animation: ${pulsate} 1s ease-out;
  animation-iteration-count: infinite;
`
