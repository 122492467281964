import createStore from 'lib/flux-store'

import { getList } from './Cache'

const initialState = {
  isLoading: false,
  isFailure: false,
  messages: null,
  list: [],
}

const actions = {
  fetchList: (type) => (dispatch) => {
    dispatch({ type: 'setLoading', payload: true })
    getList(type)
      .then((list) => {
        dispatch({
          type: 'setList',
          payload: {
            list,
          },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
}
const reducer = {
  setLoading: (state, { payload }) => ({ ...state, isLoading: payload, isFailure: false }),
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setList: (state, { payload }) => {
    return {
      ...state,
      isLoading: false,
      list: payload.list,
    }
  },
}

export const [DownloadDocumentsDataContext, DownloadDocumentsDataProvider, useDownloadDocumentsDataStore] =
  createStore(reducer, actions, initialState, undefined, 'DownloadDocumentsDataStore')
