import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const BookletIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#F9E07D',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="17.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M24.5 2H7.5C7.10218 2 6.72064 2.16436 6.43934 2.45691C6.15804 2.74947 6 3.14626 6 3.56V26.44C6 26.8537 6.15804 27.2505 6.43934 27.5431C6.72064 27.8356 7.10218 28 7.5 28H24.5C24.8978 28 25.2794 27.8356 25.5607 27.5431C25.842 27.2505 26 26.8537 26 26.44V3.56C26 3.14626 25.842 2.74947 25.5607 2.45691C25.2794 2.16436 24.8978 2 24.5 2V2ZM25 26.44C25 26.5779 24.9473 26.7102 24.8536 26.8077C24.7598 26.9052 24.6326 26.96 24.5 26.96H7.5C7.36739 26.96 7.24021 26.9052 7.14645 26.8077C7.05268 26.7102 7 26.5779 7 26.44V23.84H8V21.76H7V18.64H8V16.56H7V13.44H8V11.36H7V8.24H8V6.16H7V3.56C7 3.42209 7.05268 3.28982 7.14645 3.1923C7.24021 3.09479 7.36739 3.04 7.5 3.04H24.5C24.6326 3.04 24.7598 3.09479 24.8536 3.1923C24.9473 3.28982 25 3.42209 25 3.56V26.44ZM22 15C22 15.1379 21.9473 15.2702 21.8536 15.3677C21.7598 15.4652 21.6326 15.52 21.5 15.52H11.5C11.3674 15.52 11.2402 15.4652 11.1464 15.3677C11.0527 15.2702 11 15.1379 11 15C11 14.8621 11.0527 14.7298 11.1464 14.6323C11.2402 14.5348 11.3674 14.48 11.5 14.48H21.5C21.6326 14.48 21.7598 14.5348 21.8536 14.6323C21.9473 14.7298 22 14.8621 22 15ZM22 19.16C22 19.2979 21.9473 19.4302 21.8536 19.5277C21.7598 19.6252 21.6326 19.68 21.5 19.68H11.5C11.3674 19.68 11.2402 19.6252 11.1464 19.5277C11.0527 19.4302 11 19.2979 11 19.16C11 19.0221 11.0527 18.8898 11.1464 18.7923C11.2402 18.6948 11.3674 18.64 11.5 18.64H21.5C21.6326 18.64 21.7598 18.6948 21.8536 18.7923C21.9473 18.8898 22 19.0221 22 19.16ZM21 6.16H12C11.7348 6.16 11.4804 6.26957 11.2929 6.46461C11.1054 6.65965 11 6.92417 11 7.2V10.32C11 10.5958 11.1054 10.8604 11.2929 11.0554C11.4804 11.2504 11.7348 11.36 12 11.36H21C21.2652 11.36 21.5196 11.2504 21.7071 11.0554C21.8946 10.8604 22 10.5958 22 10.32V7.2C22 6.92417 21.8946 6.65965 21.7071 6.46461C21.5196 6.26957 21.2652 6.16 21 6.16V6.16ZM21 10.32H12V7.2H21V10.32Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
