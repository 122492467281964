import React, { useCallback } from 'react'
import { Column, DataCell, HeaderCell, Table } from '@ui/Table'
import { Box, FlexCenter } from '@layout/BuildingBlocks'
import { LoadingIndicator, LoadingIndicatorWrapper } from '@utilities/LoadingIndicator'
import { Copy } from 'stories/typography'

export const Suggestions = ({
  options,
  renderCell,
  className,
  cursorValue,
  currentValue,
  size,
  isLoading,
  loadingText,
  isError,
  errorText,
  columns = [{ key: 'label', flexGrow: 1, align: 'left', headerCell: {}, dataCell: { dataKey: 'label' } }],
  ignoreRowClassNameList = [],
  ...props
}) => {
  const rowClassName = useCallback(
    (rowData) => {
      const classNames = ['dropdown-option']
      if (rowData.disabled && !ignoreRowClassNameList.includes('dropdown-option-disabled')) {
        classNames.push('dropdown-option-disabled')
      } else {
        if (
          cursorValue !== null &&
          rowData.value === cursorValue &&
          !ignoreRowClassNameList.includes('dropdown-option-cursor')
        ) {
          classNames.push('dropdown-option-cursor')
        }
        if (
          currentValue !== null &&
          rowData.value === currentValue &&
          !ignoreRowClassNameList.includes('dropdown-option-selected')
        ) {
          classNames.push('dropdown-option-selected')
        }
        if (classNames.length === 1 && !ignoreRowClassNameList.includes('dropdown-option-default')) {
          classNames.push('dropdown-option-default')
        }
      }

      return classNames.join(' ')
    },
    [ignoreRowClassNameList, cursorValue, currentValue]
  )

  if (isError) {
    return (
      <Box p={3} minHeight={'40px'}>
        <Copy color="base.error.default">{errorText}</Copy>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <FlexCenter height={'40px'}>
        <LoadingIndicatorWrapper>
          <LoadingIndicator />
        </LoadingIndicatorWrapper>
        <Box ml={2}>
          <Copy>{loadingText}</Copy>
        </Box>
      </FlexCenter>
    )
  }

  return (
    <Table
      className={className}
      bordered={false}
      cellBordered={false}
      data={options}
      rowHeight={props.rowHeight}
      showHeader={false}
      virtualized={true}
      rowClassName={rowClassName}
      {...props}
    >
      {columns.map(({ key, dataCell, headerCell, ...rest }) => (
        <Column key={key || dataCell?.dataKey} {...rest}>
          <HeaderCell {...headerCell} />
          <DataCell dataKey={dataCell.dataKey} render={renderCell} size={size} {...dataCell} />
        </Column>
      ))}
    </Table>
  )
}
