/*
###############  HAUPTTYPEN  ###############
5: Stadtteile                quarter
10: Gemeinde                 municipality
20: Stadt                    city
30: Landkreis                district
40: Regierungsbezirk
45: Regionen (ROR)           region
50: Bundesland               federalState
60: Staat                    state
100: EU-Regionen             euRegion

###############  TEILRÄUME  ###############
105: ~ High Streets???
106: ??? ???
107: Bürolagen                              (-> Teilraumdaten Büro)
108: Wohnlagen                              (-> Teilraumdaten Wohnen)
109: Stadtbezirke             borough       (-> Teilraumdaten Planungsüberblick)
200: Logistik-Regionen
400: Out-Of-Town-Regionen

###############  SONSTIGES  ###############
SG: Samtgemeinde
GF: Gemeindefreies Gebiet
SO: Sonstige Gebiete (nicht benötigt)
*/

import createStore from 'lib/flux-store'

import { getRawDataSources, getAreas, getDataSourcesYears, getTopics } from './Cache'

import { marketDataInitialGroups } from 'config/marketDataTopics'

const initialState = {
  isLoading: true,
  isFailure: false,
  loading: {
    initialData: true,
  },
  messages: null,

  topics: [],
  filteredTopics: {
    DE: [],
    AT: [],
  },

  dataSources: {
    DE: [],
    AT: [],
  },
  dataSourcesByTopics: {
    DE: {},
    AT: {},
  },
  topicsByDatasources: {
    DE: {},
    AT: {},
  },
  areas: {
    DE: [],
    AT: [],
  },
  areasByTableKey: {},
  federalStates: [],
  minMaxYears: [],
}

const actions = {
  fetchInitialData: (currentUser) => (dispatch) => {
    dispatch({ type: 'setLoading', payload: 'initialData' })
    const promises = [getRawDataSources(), getAreas(), getDataSourcesYears(), getTopics()]
    Promise.all(promises)
      .then((results) => {
        const [allDataSources = [], allAreas = [], allMinMaxYears, allTopics = []] = results

        const availableAreas =
          currentUser.availableAreas?.rawData || currentUser.availableAreas?.default || []

        let areas = { DE: [], AT: [] }
        let federalStates = []
        const dataSourcesByTopics = { DE: {}, AT: {} }
        const topicsByDatasources = { DE: {}, AT: {} }
        const filteredTopics = { DE: [], AT: [] }

        const availableDistricts = availableAreas.reduce((districts, area) => {
          const district =
            area.gac.length === 5
              ? area.gac
              : area.gac.length === 8 && area.gac.endsWith('000')
              ? area.gac.substring(0, 5)
              : null
          if (district) {
            districts[district] = true
          }
          return districts
        }, {})

        const availableGacsByFederalState = availableAreas.reduce((federalStates, area) => {
          const state = area.gac.substring(0, 2)
          if (typeof federalStates[state] === 'undefined') {
            federalStates[state] = []
          }

          const district = area.gac.length >= 5 ? area.gac.substring(0, 5) : null
          if (!district || !availableDistricts[district]) {
            federalStates[state].push(area.gac)
          }

          return federalStates
        }, {})

        allAreas.forEach((area) => {
          area = { ...area }
          const type = parseInt(area.type)
          const country = area.gac.startsWith('at') ? 'AT' : 'DE'
          const federalState = area.gac.substring(0, 2)
          const district = area.gac.substring(0, 5)
          if (
            type === 50 &&
            country === 'DE' &&
            typeof availableGacsByFederalState[federalState] !== 'undefined'
          ) {
            federalStates.push({ ...area })
          }
          if (type > 5) {
            const available =
              (type === 50 && typeof availableGacsByFederalState[federalState] !== 'undefined') ||
              area.gac === '20000000' ||
              ((availableDistricts[district] ||
                (typeof availableGacsByFederalState[federalState] !== 'undefined' &&
                  availableGacsByFederalState[federalState].some((gac) => area.gac.startsWith(gac)))) &&
                (country === 'DE' || (area.market_category === 'AT' && type <= 30)))
            if (available) {
              if (type <= 100 || [107, 108, 109, 200].includes(type)) {
                if (
                  parseInt(area.type) > 100 &&
                  area.name !== area.parent_name &&
                  area.parent_name !== null
                ) {
                  area.name = area.parent_name + ' - ' + area.name
                }

                areas[country].push({ ...area, tableKey: area.gac + '::' + area.type })
              }
            }
          }
        })

        const areasByTableKey = areas.DE.concat(areas.AT).reduce((areas, area) => {
          areas[area.tableKey] = area
          return areas
        }, {})

        const accessableDataSources = allDataSources
          .find((sources) => sources.key === 'rawData')
          .entries.filter((entry) => entry.access)
          .toSorted((a, b) => {
            let order = a.sortCategoryPos - b.sortCategoryPos
            if (order === 0) {
              order = a.posInSortCategory - b.posInSortCategory
            }
            if (order === 0) {
              const keyA = a.key.replace(/\.gen_/, '.')
              const keyB = b.key.replace(/\.gen_/, '.')
              order = keyA.localeCompare(keyB)
            }
            return order
          })

        const dataSources = { DE: [], AT: [] }
        const availableSourceKeys = []
        accessableDataSources.forEach((source) => {
          const country = source.key.replace(/^[^_]+_(de|at)\..*$/, '$1').toUpperCase()
          dataSources[country].push(source)
          availableSourceKeys.push(source.key)
        })

        const topicSegments = Object.entries(marketDataInitialGroups)
          .reduce((previous, [segment, topics]) => {
            topics.forEach((key) => {
              let topic = previous.find((entry) => entry.key === key)
              if (!topic) {
                topic = { key, segments: [] }
                previous.push(topic)
              }
              if (topic.segments.length === 0 || !['microLiving', 'seniorLiving'].includes(segment)) {
                topic.segments.push(segment)
              }
            })
            return previous
          }, [])
          .filter((topic) => topic.segments.length === 1)
          .reduce((prev, topic) => {
            prev[topic.key] = topic.segments[0]
            return prev
          }, {})

        allTopics.forEach((topic) => {
          const sources = {
            DE: new Set(),
            AT: new Set(),
          }

          topic.dataSources.forEach((sourceObj) => {
            currentUser.availableCountries.forEach((country) => {
              const addTopicsByDatasources = (source) => {
                if (availableSourceKeys.includes(source)) {
                  sources[country].add(source)
                  if (!topicsByDatasources[country][source]?.length) {
                    topicsByDatasources[country][source] = []
                  }
                  topicsByDatasources[country][source].push(topic.key)
                }
              }

              const source = sourceObj.source?.[country]?.key ?? null
              addTopicsByDatasources(source)

              if (source !== null && !source.includes('.virtual.')) {
                const submarketSource = source.replace(/([^.]+)\.([^.]+)\.([^.]+)/, '$1.$2_submarket.$3')
                addTopicsByDatasources(submarketSource)
              }
            })
          })
          currentUser.availableCountries.forEach((country) => {
            if (sources[country].size) {
              dataSourcesByTopics[country][topic.key] = Array.from(sources[country])
              filteredTopics[country].push({ group: topic.group, key: topic.key })
            }
          })
        })
        const minMaxYears = allMinMaxYears.filter((entry) => availableSourceKeys.includes(entry.key))

        dispatch({
          type: 'setInitialData',
          payload: {
            areas,
            areasByTableKey,
            federalStates,
            topics: allTopics.map((topic) => topic.key),
            topicSegments,
            filteredTopics,
            dataSources,
            dataSourcesByTopics,
            topicsByDatasources,
            minMaxYears: minMaxYears,
          },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
}
const reducer = {
  setLoading: (state, { payload }) => {
    const loading = { ...state.loading, [payload]: true }
    return { ...state, isLoading: true, loading, isFailure: false }
  },
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setInitialData: (state, { payload }) => {
    const loading = { ...state.loading, initialData: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)
    return {
      ...state,
      isLoading,
      loading,
      ...payload,
    }
  },
}

export const [RawDataDataContext, RawDataDataProvider, useRawDataDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'RawDataDataStore'
)
