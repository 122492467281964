import React, { useMemo, useEffect, useState, useCallback, useRef, memo, forwardRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { typography } from 'styled-system'

import { getThemeValue } from 'theming'

import { isFunction } from 'lib/util'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { withFormElementProps } from '@forms'

import { appearance, shape, size } from './variants'
import { typeMap, typeScale, typeStyle, defaultProps as textProps } from '@typography'
import { allowedProps } from './config'

import { ButtonIcon } from '@ui/Buttons/ButtonIcon'
import { IconWrapper } from '@utilities/IconWrapper'
import { Flyout } from '@utilities/Flyout'
import { Box } from '@layout/BuildingBlocks'

import { BudiconCrossUi, BudiconEyeSight, BudiconHidePassword } from 'bgag-budicons'
import { ExclamationMark } from '@utilities/SVG/ExclamationMark'

import { bool, number, string } from 'prop-types'

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => allowedProps.includes(prop) || prop === 'children',
})`
  ${(props) => (props.width && { width: props.width }) || { width: '100%' }}
  ${(props) => props.height && { height: props.height }}
  ${(props) => props.color && { color: props.color }}
  position: relative;
`

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) =>
    props.positionLeft && {
      left: props.positionLeft,
    }}
  ${(props) =>
    props.positionRight && {
      right: props.positionRight,
    }}
`

const IconLeft = ({ icon, iconPosition, positionLeft }) => {
  return (
    <IconContainer positionLeft={positionLeft}>
      <IconWrapper size="large" iconVariantSourceName="badgeIcons" icon={icon} iconPosition={iconPosition} />
    </IconContainer>
  )
}

const iconError = {
  Icon: ExclamationMark,
  fill: 'colors.theme.error.default.bg',
  stroke: 'colors.theme.error.default.color',
  strokeWidth: 1,
}

const IconError = ({ icon, iconPosition, positionLeft, message }) => {
  return (
    <IconContainer positionLeft={positionLeft}>
      <IconWrapper
        size="large"
        iconVariantSourceName="badgeIcons"
        icon={iconError}
        iconPosition={iconPosition}
      />
    </IconContainer>
  )
}

const ToggleShowPassword = ({ positionRight, onClick = () => {}, isVisible = false }) => {
  const icon = isVisible ? BudiconHidePassword : BudiconEyeSight
  return (
    <IconContainer positionRight={positionRight}>
      <ButtonIcon
        onClick={onClick}
        appearance="bare"
        shape="square"
        size="tiny"
        equalSides={true}
        icon={{ Icon: icon }}
        iconVariantSize="large"
        tabIndex={-1}
      />
    </IconContainer>
  )
}

const Reset = ({ positionRight, onClick = () => {} }) => {
  return (
    <IconContainer positionRight={positionRight}>
      <ButtonIcon
        onClick={onClick}
        appearance="bare"
        shape="square"
        size="tiny"
        equalSides={true}
        icon={{ Icon: BudiconCrossUi, type: 'shady' }}
        iconVariantSize="large"
        tabIndex={-1}
      />
    </IconContainer>
  )
}

const StyledComponent = styled.input.withConfig({
  shouldForwardProp: (prop) => allowedProps.includes(prop),
})`
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => (props.width && { width: props.width }) || { width: '100%' }}
  ${(props) => props.height && { height: props.height }}

  ${(props) => props.iconLeftSpace && { paddingLeft: props.iconLeftSpace + ' !important' }}
  ${(props) => props.iconRightSpace && { paddingRight: props.iconRightSpace + ' !important' }}

  outline: none;
  /*
  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:autofill:active {
    transition: color 9999s ease-out, background-color 9999s ease-out;
    transition-delay: 9999s;
  }
  */
`

export const InputComponent = forwardRef(
  (
    {
      debounce,
      formElementRefs,
      onChange,
      value,
      width,
      height,
      iconLeft,
      iconLeftPosition,
      resetable = false,
      showPassword = false,
      type = 'text',
      error = null,
      appearance: appearanceProp = 'lightgray',
      appearanceError = 'errorGhost',
      ...props
    },
    ref
  ) => {
    const innerRef = useRef(null)
    const inputRef = ref || innerRef
    const refOpener = useRef(null)
    const theme = useTheme()
    const iconLeftSpace =
      iconLeft || error
        ? 2 * parseFloat(getThemeValue(theme, 'spaces.rhythm.horizontal.medium')) +
          parseFloat(getThemeValue(theme, 'sizes.icons.button.large.width')) +
          'px'
        : null
    const iconRightSpace =
      resetable || showPassword
        ? (resetable && showPassword ? 3 : 2) *
            parseFloat(getThemeValue(theme, 'spaces.rhythm.horizontal.small')) +
          (resetable && showPassword ? 2 : 1) *
            parseFloat(getThemeValue(theme, 'sizes.icons.button.large.width')) +
          'px'
        : null

    const positionIconLeft = getThemeValue(theme, 'spaces.rhythm.horizontal.medium')
    const positionReset = getThemeValue(theme, 'spaces.rhythm.horizontal.small')
    const positionTogglePassword = resetable
      ? parseFloat(getThemeValue(theme, 'spaces.rhythm.horizontal.small')) +
        parseFloat(getThemeValue(theme, 'sizes.icons.button.large.width')) +
        parseFloat(getThemeValue(theme, 'spaces.rhythm.horizontal.tiny')) +
        'px'
      : getThemeValue(theme, 'spaces.rhythm.horizontal.small')

    const color = appearance()({
      theme,
      appearance: error !== null ? appearanceError : appearanceProp,
    }).color

    const { typeStyle, typeScale } = typeMap[props.size]
    const [defaultValue] = useState(props.defaultValue ?? value ?? '')

    const onDebouncedUpdate = useCallback(
      (value) => {
        isFunction(onChange) && onChange({ id: props.id, name: props.name, value })
      },
      [onChange, props.id, props.name]
    )

    const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
      value,
      onDebouncedUpdate,
      debounce
    )

    const [localValue, setValue] = useControllableState(
      defaultValue,
      internalValueDebouncer,
      onControllableStateChange
    )

    const internalOnChange = useCallback(
      (evt) => {
        setValue(evt.target.value)
      },
      [setValue]
    )

    const onReset = useCallback(() => {
      setValue(defaultValue || '')
    }, [setValue, defaultValue])

    const onResetWithFocus = useCallback(() => {
      setValue(defaultValue || '')
      inputRef?.current?.focus?.()
    }, [setValue, defaultValue, inputRef])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.reset.current = onReset
      }
    }, [onReset, formElementRefs])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.value.current = localValue
      }
    }, [localValue, formElementRefs])

    const [_type, _setType] = useState(type)

    const toggleShowPassword = useCallback(() => {
      _setType((prev) => (prev === 'password' ? 'text' : 'password'))
    }, [])

    const [isFocus, setIsFocus] = useState(false)
    const [isHover, setIsHover] = useState(false)

    const focusTest = useCallback((evt) => {
      switch (evt.type) {
        case 'focus':
          setIsFocus(true)
          break
        case 'blur':
          setIsFocus(false)
          break
        case 'mouseenter':
          setIsHover(true)
          break
        case 'mouseleave':
          setIsHover(false)
          break
        default:
          break
      }
    }, [])

    const showErrorFlyout = useMemo(() => {
      return error && (isFocus || isHover)
    }, [error, isFocus, isHover])

    return (
      <>
        <Container
          width={width}
          height={height}
          color={color}
          ref={refOpener}
          onFocus={focusTest}
          onBlur={focusTest}
          onMouseEnter={focusTest}
          onMouseLeave={focusTest}
        >
          {iconLeft && error === null && (
            <IconLeft icon={iconLeft} iconPosition={iconLeftPosition} positionLeft={positionIconLeft} />
          )}
          {error && <IconError positionLeft={positionIconLeft} message={error} />}
          {showPassword && (
            <ToggleShowPassword
              positionRight={positionTogglePassword}
              onClick={toggleShowPassword}
              isVisible={_type === 'text'}
            />
          )}
          {resetable && <Reset positionRight={positionReset} onClick={onResetWithFocus} />}
          <StyledComponent
            typeScale={typeScale}
            typeStyle={typeStyle}
            onChange={internalOnChange}
            ref={inputRef}
            value={localValue}
            width={width}
            height={height}
            iconLeftSpace={iconLeftSpace}
            iconRightSpace={iconRightSpace}
            type={_type}
            appearance={error !== null ? appearanceError : appearanceProp}
            autoComplete="off"
            {...props}
          />
        </Container>
        {error && (
          <Flyout
            refOpener={refOpener}
            active={showErrorFlyout}
            renderTriangle={false}
            placement="bottomStart"
            minWidth="360px"
            width={width}
            distance={5}
          >
            <Box padding="12px 24px">{error}</Box>
          </Flyout>
        )}
      </>
    )
  }
)

InputComponent.type = 'Input'
InputComponent.defaultProps = {
  ...textProps,
  ...{
    appearance: 'lightgray',
    size: 'medium',
    shape: 'square',
  },
  debounce: null,
  disabled: false,
}

InputComponent.propTypes = {
  appearance: string,
  shape: string,
  size: string,
  debounce: number,
  disabled: bool,
}

export const Input = withFormElementProps(memo(InputComponent))
