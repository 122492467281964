import createStore from 'lib/flux-store'
import api from 'stores/api'
import functionQueue from 'lib/util/functionQueue'

const initialState = {
  stateIsLoading: true,
  objectFilter: {
    pipelineUse: [],
    tenantUse: [],
    salesUse: [],
    measureType: [],
    status: [],
    propertyType: [],
    tenantCategory: [],
    date: {
      from: { quarter: null, year: null, asString: '' },
      to: { quarter: null, year: null, asString: '' },
      quickselect: '',
    },
    dateSales: {
      from: { quarter: null, year: null, asString: '' },
      to: { quarter: null, year: null, asString: '' },
      quickselect: '',
    },
    dateTenant: {
      from: { quarter: null, year: null, asString: '' },
      to: { quarter: null, year: null, asString: '' },
      quickselect: '',
    },
    rent: { from: null, to: null },
    price: { from: null, to: null },
    yield: { from: null, to: null },
    space: { from: null, to: null },
    name: '',
    address: '',
    investor: '',
    salesBuyerSeller: '',
    tenantName: '',
    tenure: {
      global: {
        value: {
          space: { from: '', to: '' },
          yearRange: { from: '', to: '' },
        },
      },
      retail: {
        active: false,
        value: { propertyType: [], space: { from: '', to: '' } },
      },
      office: {
        active: false,
        value: { space: { from: '', to: '' } },
      },
      hotel: {
        active: false,
        value: {
          hotelTyp: [],
          rooms: { from: '', to: '' },
          hotelBrand: [],
          space: { from: '', to: '' },
        },
      },
      logistic: {
        active: false,
        value: {
          propertyType: [],
          space: { from: '', to: '' },
        },
      },
      seniorLiving: {
        active: false,
        value: {
          propertyType: [],
          units: { from: '', to: '' },
          beds: { from: '', to: '' },
          space: { from: '', to: '' },
          daycareMarker: { checked: false },
        },
      },
      microLiving: {
        active: false,
        value: {
          propertyType: [],
          units: { from: '', to: '' },
          beds: { from: '', to: '' },
          space: { from: '', to: '' },
        },
      },
    },
  },
  objectType: 'pipeline',
  objectSort: { key: null, dir: null },
  salesSort: { key: null, dir: null },
  tenantSort: { key: null, dir: null },
}

const ignoreKeysOnUpdate = {
  stateIsLoading: true,
  setBy: true,
}

const setLoadingSrc = {
  objectFilter: true,
}

const addDefaults = {
  objectFilter: true,
  objectSort: true,
  tenantSort: true,
  salesSort: true,
  objectType: true,
}

const queuedSave = functionQueue(api.AppState.save)
let lastSavedState = initialState

const onUpdate = (state) => {
  const changed = Object.keys(state).reduce((changed, key) => {
    return changed || !Object.is(lastSavedState[key], state[key])
  }, false)
  if (changed) {
    lastSavedState = { ...state }
    return queuedSave(__NAME__, lastSavedState)
  } else {
    return Promise.resolve()
  }
}

const __NAME__ = 'ObjectStateStore'

const actions = {
  loadState: () => (dispatch) => {
    api.AppState.get(__NAME__)
      .then((response) => {
        const state = response.data.data
        dispatch({ type: 'setLoadedState', payload: state })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  setObjectFilter: (filter) => ({ filter }),
  resetObjectFilter: () => ({
    type: 'setObjectFilter',
    filter: { ...initialState.objectFilter, setBy: 'reset' },
  }),
  resetObjectSort: () => ({}),
  setObjectSort: (objectSort) => ({ objectSort }),
  setObjectType: (objectType) => ({ objectType }),
  resetSalesSort: () => ({}),
  setSalesSort: (salesSort) => ({ salesSort }),
  resetTenantSort: () => ({}),
  setTenantSort: (tenantSort) => ({ tenantSort }),
}

const reducer = {
  setFailure: (state, { payload }) => ({
    ...state,
    stateIsLoading: false,
    isFailure: true,
    messages: payload,
  }),
  setLoadedState: (state, { payload }) => {
    const keep = Object.keys(ignoreKeysOnUpdate).reduce((keep, key) => {
      keep[key] = state[key]
      delete payload[key]
      return keep
    }, {})
    const set = Object.keys(initialState).reduce((set, key) => {
      if (typeof payload[key] !== 'undefined') {
        if (state[key] instanceof Map) {
          set[key] = new Map(payload[key])
        } else if (setLoadingSrc[key]) {
          set[key] = { ...payload[key], setBy: 'loader' }
        } else {
          set[key] = payload[key]
        }
      }
      if (addDefaults[key]) {
        if (typeof set[key] === 'object' && typeof initialState[key] === 'object') {
          set[key] = { ...initialState[key], ...set[key] }
        } else if (typeof set[key] === 'undefined') {
          if (typeof initialState[key] === 'object') {
            set[key] = { ...initialState[key] }
          } else {
            set[key] = initialState[key]
          }
        }
      }
      return set
    }, {})

    lastSavedState = { ...initialState, ...keep, ...set, stateIsLoading: false }
    return lastSavedState
  },
  setObjectFilter: (state, { filter }) => {
    const newFilter = typeof filter === 'function' ? filter(state.objectFilter) : filter
    // filter needs to be of format { key: value }
    return {
      ...state,
      objectFilter: { ...state.objectFilter, ...newFilter },
    }
  },
  resetObjectSort: (state) => ({ ...state, objectSort: initialState.objectSort }),
  setObjectSort: (state, { objectSort }) => ({ ...state, objectSort }),
  resetSalesSort: (state) => ({ ...state, salesSort: initialState.salesSort }),
  setSalesSort: (state, { salesSort }) => ({ ...state, salesSort }),
  resetTenantSort: (state) => ({ ...state, tenantSort: initialState.tenantSort }),
  setTenantSort: (state, { tenantSort }) => ({ ...state, tenantSort }),
  setObjectType: (state, { objectType }) => ({ ...state, objectType }),
}

export const [ObjectStateContext, ObjectStateProvider, useObjectStateStore] = createStore(
  reducer,
  actions,
  initialState,
  onUpdate,
  __NAME__
)
