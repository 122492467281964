import { useState, useEffect } from 'react'

import { warnOnce } from 'lib/util'

import { usePrevious } from './usePrevious'

export const useDragAndDropListItems = (originalList, onDrop) => {
  const [dragged, setDragged] = useState(null)
  const [draggedTo, setDraggedTo] = useState(null)
  const [orderedList, setOrderedList] = useState(originalList)
  const previousOriginal = usePrevious(originalList)

  const handleDragStart = (evt) => {
    // fix for webkit browser - without it dragover won't trigger
    evt.dataTransfer.setData('text/html', '')
    const idx = Number(evt.currentTarget.dataset.position)
    if (isNaN(idx)) {
      warnOnce(NO_DATA_POSITION, NO_DATA_POSITION_MSG)
      return
    }
    setDragged(idx)
  }
  const handleDragOver = (evt) => {
    // cancel early if drag not initiated by this hook
    if (dragged === null) return
    // drop targets have to preventDefault
    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets
    evt.preventDefault()
    const idx = Number(evt.currentTarget.dataset.position)
    if (isNaN(idx)) {
      warnOnce(NO_DATA_POSITION, NO_DATA_POSITION_MSG)
      return
    }
    setDraggedTo(idx)
  }

  const handleDragEnd = (evt) => {
    // cancel early if drag not initiated by this hook
    if (dragged === null) return
    const cancelled = evt.dataTransfer?.dropEffect === 'none'
    if (cancelled) {
      setDragged(null)
      setDraggedTo(null)
      setOrderedList(originalList)
    } else {
      setDragged(null)
      setDraggedTo(null)
      onDrop?.(orderedList)
    }
  }

  const itemProperties = {
    draggable: true,
    onDragStart: handleDragStart,
    onDragOver: handleDragOver,
    onDragEnd: handleDragEnd,
  }

  useEffect(() => {
    setOrderedList(originalList)
  }, [originalList])

  useEffect(() => {
    if (dragged === null || draggedTo === null) {
      setOrderedList(originalList)
      return
    }
    const item = originalList[dragged]
    const remaining = originalList.filter((_, idx) => idx !== dragged)
    const newOrder = [...remaining.slice(0, draggedTo), item, ...remaining.slice(draggedTo)]
    setOrderedList(newOrder)
  }, [dragged, draggedTo, originalList])

  const returnList = previousOriginal !== originalList ? originalList : orderedList
  return [returnList, itemProperties, draggedTo, dragged]
}

const NO_DATA_POSITION = 'DND_NO_DATA_POSITION'
const NO_DATA_POSITION_MSG = `Drag and drop not working!

Please provide the "data-position" property on the item container. The property should correspond to the index of the item in the list.
`
