import createStore from 'lib/flux-store'

const initialState = {
  group: 'population',
  topic: 'data_de.virtual.st_einw_prog_2035',
  year: 2019,
  displayCityLabels: true,
  groupsFolded: {},
}

const actions = {
  setGroup: (group) => ({ group }),
  setTopic: (topic) => ({ topic }),
  setYear: (year) => ({ year }),
  setCityLabels: (displayCityLabels) => ({ displayCityLabels }),
  setGroupsFolded: (groupsFolded) => ({ groupsFolded }),
}

const reducer = {
  setGroup: (state, { group }) => (state.group === group ? state : { ...state, group }),
  // set to maximum to let the yearSlider decide the max Year
  setTopic: (state, { topic }) => (state.topic === topic ? state : { ...state, topic, year: 2060 }),
  setYear: (state, { year }) => ({ ...state, year }),
  setCityLabels: (state, { displayCityLabels }) => ({ ...state, displayCityLabels }),
  setGroupsFolded: (state, { groupsFolded }) => {
    const nextFolded = typeof groupsFolded === 'function' ? groupsFolded(state.groupsFolded) : groupsFolded
    return { ...state, groupsFolded: nextFolded }
  },
}

export const [ThematicMapsContext, ThematicMapsProvider, useThematicMapsStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'ThematicMapsStore'
)
