import React, { forwardRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { system, grid, layout } from 'styled-system'

import {
  gridAutoRows,
  gridColumnGap,
  gridRowGap,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  marginLeft,
  marginRight,
} from './defaultPropTypes'
import { defaultProps } from './defaultProps'
import { getThemeValue } from 'theming'

export const StyledElement = styled('div').withConfig({
  shouldForwardProp: (prop) => ['children'].includes(prop),
})(
  () => ({
    display: 'grid',
  }),
  grid,
  layout,
  system({
    gridColumnGap: {
      property: 'gridColumnGap',
      scale: 'gridColumnGaps',
    },
    gridRowGap: {
      property: 'gridRowGap',
      scale: 'gridRowGaps',
    },
    gridTemplateColumns: {
      property: 'gridTemplateColumns',
      scale: 'gridTemplateColumns',
    },
    gridTemplateRows: {
      property: 'gridTemplateRows',
      scale: 'gridTemplateRows',
    },
  })
)

export const GridContainer = ({ children, ...props }) => {
  const theme = useTheme()
  const gridRowGap = props.gridRowGap ?? getThemeValue(theme, 'spaces.gridGaps.row')
  const gridColumnGap = props.gridColumnGap ?? getThemeValue(theme, 'spaces.gridGaps.column')

  return (
    <StyledElement gridRowGap={gridRowGap} gridColumnGap={gridColumnGap} {...props}>
      {children}
    </StyledElement>
  )
}

export const GridContainerRef = forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledElement ref={ref} {...props}>
      {children}
    </StyledElement>
  )
})

GridContainer.propTypes = {
  gridAutoRows,
  gridColumnGap,
  gridRowGap,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  marginLeft,
  marginRight,
}

GridContainer.defaultProps = defaultProps

GridContainerRef.propTypes = {
  gridAutoRows,
  gridColumnGap,
  gridRowGap,
  gridTemplateAreas,
  gridTemplateColumns,
  gridTemplateRows,
  marginLeft,
  marginRight,
}

GridContainerRef.defaultProps = defaultProps
