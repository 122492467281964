import createStore from 'lib/flux-store'
import api from 'stores/api'
import functionQueue from 'lib/util/functionQueue'

const initialState = {
  isLoading: true,
  isFailure: false,
  messages: [],
  showEntries: ['retail', 'office', 'residential', 'commercial'],
  activeTextId: null,
  globalView: 'grid',
  version: null,
}

const ignoreKeysOnUpdate = {
  activeTextId: true,
  setBy: true,
}

const setLoadingSrc = {}

const queuedSave = functionQueue(api.AppState.save)
let lastSavedState = initialState

const onUpdate = (state) => {
  const changed = Object.keys(state).reduce(
    (changed, key) =>
      changed || (ignoreKeysOnUpdate[key] ? false : !Object.is(lastSavedState[key], state[key])),
    false
  )

  if (changed) {
    lastSavedState = { ...state }
    const saveState = {}
    Object.keys(lastSavedState).forEach((key) => {
      if (!ignoreKeysOnUpdate[key]) {
        saveState[key] = lastSavedState[key]
      }
    })

    return queuedSave(__NAME__, saveState)
  } else {
    return Promise.resolve()
  }
}

const __NAME__ = 'MarketTextStateStore'

const actions = {
  loadState: () => (dispatch) => {
    api.AppState.get(__NAME__)
      .then((response) => {
        const state = response.data.data
        dispatch({ type: 'setLoadedState', payload: state })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  loadVersion: () => (dispatch) => {
    api.Text.getVersion()
      .then((res) => {
        const version = res.data.data.version
        dispatch({ type: 'setVersion', version })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  toggleShowEntries: (id) => ({ id }),
  setActiveTextId: (id) => ({ id }),
  setGlobalView: (globalView) => ({ globalView }),
}

const reducer = {
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setLoadedState: (state, { payload }) => {
    const set = Object.keys(initialState).reduce((set, key) => {
      if (typeof payload[key] !== 'undefined') {
        if (setLoadingSrc[key]) {
          set[key] = { ...payload[key], setBy: 'loader' }
        } else {
          set[key] = payload[key]
        }
      }
      if (Array.isArray(initialState[key])) {
        if (!Array.isArray(set[key])) {
          set[key] = [...initialState[key]]
        }
      } else if (typeof set[key] === 'object' && typeof initialState[key] === 'object') {
        set[key] = { ...initialState[key], ...set[key] }
      } else if (typeof set[key] === 'undefined') {
        if (Array.isArray(initialState[key])) {
          set[key] = [...initialState[key]]
        } else if (typeof initialState[key] === 'object') {
          set[key] = { ...initialState[key] }
        } else {
          set[key] = initialState[key]
        }
      }
      return set
    }, {})
    lastSavedState = { ...state, ...set, isLoading: false }
    return lastSavedState
  },

  setVersion: (state, { version }) => {
    return { ...state, version }
  },

  toggleShowEntries: (state, { id }) => {
    return { ...state, showEntries: id }
  },

  setActiveTextId: (state, { id }) => {
    return { ...state, activeTextId: id }
  },
  setGlobalView: (state, { globalView }) => ({ ...state, globalView }),
}

export const [MarketTextStateContext, MarketTextStateProvider, useMarketTextStateStore] = createStore(
  reducer,
  actions,
  initialState,
  onUpdate,
  __NAME__
)
