/*
derived to the styled system appoach this hook returns the current matching
breakpoint value from an array of values for use cases where a single value is
required (e.g. attributes which will not parsed by styled system) 

example 1)
const arr = ['10px', '20px', '30px', '40px', '50px']
const val = useArrayValueByBreakpoint(arr)

return 10px for default
return 20px for breakpoint md
return 30px for breakpoint lg
return 40px for breakpoint xl
return 50px for breakpoint xxl

example 2)
const arr = ['10px', null, '30px', null, '50px']
const val = useArrayValueByBreakpoint(arr)

return 10px for default
return 10px for breakpoint md
return 30px for breakpoint lg
return 30px for breakpoint xl
return 50px for breakpoint xxl

example 3)
const arr = ['10px', null, null, '50px']
const val = useArrayValueByBreakpoint(arr)

return 10px for default
return 10px for breakpoint md
return 10px for breakpoint lg
return 50px for breakpoint xl
return 50px for breakpoint xxl

example 4)
const arr = [null, null, '30px', '40px']
const val = useArrayValueByBreakpoint(arr)

return null for default
return null for breakpoint md
return 30px for breakpoint lg
return 40px for breakpoint xl
return 40px for breakpoint xxl

---
note that
const arr = [null, null, '30px', '40px'] is equal to 
const arr = [null, null, '30px', '40px', null]
*/

import { useBreakpointAliases } from './useBreakpointAliases'
import { useCurrentBreakpoint } from './useCurrentBreakpoint'

export const useArrayValueByBreakpoint = (arrayValue) => {
  const breakpoints = useBreakpointAliases()
  const { currentBreakpointIndex } = useCurrentBreakpoint()

  if (typeof arrayValue === 'number' || typeof arrayValue === 'string' || typeof arrayValue === 'undefined')
    return arrayValue

  const findNext = (arr) => {
    let returnValue = null
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== null && i <= currentBreakpointIndex) returnValue = arr[i]
    }
    return returnValue
  }

  const matchedValue = breakpoints.reduce((arr, curr) => {
    return arrayValue[currentBreakpointIndex] || findNext(arrayValue)
  }, 0)

  return matchedValue
}
