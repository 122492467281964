import { FlexRow } from '@layout/BuildingBlocks'
import React from 'react'
import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'

export const SlotRight = ({ children, ...props }) => {
  const theme = useTheme()
  const iconSpaceLeft = [
    getThemeValue(theme, 'spaces.rhythm.horizontal.tiny'),
    null,
    getThemeValue(theme, 'spaces.rhythm.horizontal.medium'),
  ]

  return (
    <FlexRow {...props} ml={iconSpaceLeft} alignItems="center">
      {children}
    </FlexRow>
  )
}
