import React from 'react'

import { GlobalStyle } from 'theming/styles/GlobalStyle'
import { getThemeValue } from 'theming'

import { useSlots } from 'lib/hooks/useSlots'

import { Background } from '../Background'
import { MainStage } from './SubComponents/MainStage'
import { HeaderFullscreen } from './SubComponents/HeaderFullscreen'
import { useTheme } from 'styled-components'

export const LayoutPublic = ({ children, media, bgImage }) => {
  const theme = useTheme()
  const [headerFullscreen, main] = useSlots(['headerFullscreen', 'main'], children)

  const mainHeight = headerFullscreen
    ? 'calc(100vh - ' + getThemeValue(theme, 'layout.headerFullscreen.height') + ')'
    : '100vh'

  const pxHeaderFullscreen = getThemeValue(theme, 'layout.headerFullscreen.px')
  const pyHeaderFullscreen = getThemeValue(theme, 'layout.headerFullscreen.py')

  return (
    <>
      <GlobalStyle media={media} />
      <Background image={bgImage}>
        {headerFullscreen && (
          <HeaderFullscreen px={pxHeaderFullscreen} py={pyHeaderFullscreen}>
            {headerFullscreen}
          </HeaderFullscreen>
        )}
        {main && <MainStage height={mainHeight}>{main}</MainStage>}
      </Background>
    </>
  )
}

LayoutPublic.HeaderFullscreen = ({ children }) => children
LayoutPublic.HeaderFullscreen.displayName = 'headerFullscreen'

LayoutPublic.Main = ({ children }) => children
LayoutPublic.Main.displayName = 'main'
