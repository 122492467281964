import { getThemeValue } from 'theming'

export const argTypeOptionsAppearance = [
  'bare',
  'dark',
  'ghost',
  'gray',
  'light',
  'lightgray',
  'primary',
  'primaryGhost',
  'primaryShallow',
  'success',
  'error',
  'errorGhost',
  'errorShallow',
  'white',
]

export const argTypeOptionsAppearanceForProgress = [
  'dark',
  'ghost',
  'gray',
  'light',
  'lightgray',
  'primary',
  'primaryGhost',
]

export const argTypeOptionsAppearanceForLocationBadges = [
  'locationOne',
  'locationOneChecked',
  'locationTwo',
  'locationTwoChecked',
  'locationThree',
  'locationThreeChecked',
  'locationFour',
  'locationFourChecked',
  'locationFive',
  'locationFiveChecked',
]

export const bare = (theme) => {
  return {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'transparent',
    color: getThemeValue(theme, 'uiActionElements.light.default.color'),
    '&:hover ': {
      color: getThemeValue(theme, 'uiActionElements.light.hover.color'),
    },
    '&:disabled ': {
      color: getThemeValue(theme, 'uiActionElements.light.disabled.color'),
    },
  }
}

export const dark = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.dark.default.bg'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'uiActionElements.dark.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.dark.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.dark.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.dark.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.dark.hover.bg'),
      color: getThemeValue(theme, 'uiActionElements.dark.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.dark.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.dark.disabled.bg'),
      color: getThemeValue(theme, 'uiActionElements.dark.disabled.color'),
    },
  }
}

export const ghost = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.ghost.default.bg'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.ghost.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.ghost.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.ghost.hover.color'),
      color: getThemeValue(theme, 'uiActionElements.ghost.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.ghost.disabled.bg'),
      border: '1px solid',
      borderColor: getThemeValue(theme, 'uiActionElements.ghost.disabled.color'),
      color: getThemeValue(theme, 'uiActionElements.ghost.disabled.color'),
    },
  }
}

export const gray = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.gray.default.bg'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'uiActionElements.gray.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.gray.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.gray.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.gray.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.gray.hover.bg'),
      color: getThemeValue(theme, 'uiActionElements.gray.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.gray.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.gray.disabled.bg'),
      color: getThemeValue(theme, 'uiActionElements.gray.disabled.color'),
    },
  }
}

export const light = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.light.default.bg'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'uiActionElements.light.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.light.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.light.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.light.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.light.hover.bg'),
      color: getThemeValue(theme, 'uiActionElements.light.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.light.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.light.disabled.bg'),
      color: getThemeValue(theme, 'uiActionElements.light.disabled.color'),
    },
  }
}

export const lightgray = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.lightgray.default.bg'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'uiActionElements.lightgray.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.lightgray.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.lightgray.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.lightgray.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.lightgray.hover.bg'),
      color: getThemeValue(theme, 'uiActionElements.lightgray.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.lightgray.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.lightgray.disabled.bg'),
      color: getThemeValue(theme, 'uiActionElements.lightgray.disabled.color'),
    },
  }
}

export const primary = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.primary.default.bg'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'uiActionElements.primary.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.primary.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.primary.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.primary.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.primary.hover.bg'),
      color: getThemeValue(theme, 'uiActionElements.primary.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.primary.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.primary.disabled.bg'),
      color: getThemeValue(theme, 'uiActionElements.primary.disabled.color'),
      '&:hover ': {
        color: getThemeValue(theme, 'uiActionElements.primary.disabled.color'),
      },
    },
  }
}

export const primaryGhost = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.primaryGhost.default.bg'),
    border: '1px solid',
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    borderColor: getThemeValue(theme, 'uiActionElements.primaryGhost.default.color'),
    color: getThemeValue(theme, 'uiActionElements.primaryGhost.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.primaryGhost.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.primaryGhost.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.primaryGhost.hover.color'),
      color: getThemeValue(theme, 'uiActionElements.primaryGhost.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.primaryGhost.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.primaryGhost.disabled.color'),
      color: getThemeValue(theme, 'uiActionElements.primaryGhost.disabled.color'),
    },
  }
}

export const primaryShallow = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.primaryShallow.default.bg'),
    border: '1px solid',
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    borderColor: getThemeValue(theme, 'uiActionElements.primaryShallow.default.bg'),
    color: getThemeValue(theme, 'uiActionElements.primaryShallow.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.primaryShallow.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.primaryShallow.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.primaryShallow.hover.bg'),
      color: getThemeValue(theme, 'uiActionElements.primaryShallow.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.primaryShallow.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.primaryShallow.disabled.color'),
      color: getThemeValue(theme, 'uiActionElements.primaryShallow.disabled.color'),
    },
  }
}

export const success = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.success.default.bg'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'uiActionElements.success.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.success.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.success.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.success.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.success.hover.bg'),
      color: getThemeValue(theme, 'uiActionElements.success.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.success.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.success.disabled.bg'),
      color: getThemeValue(theme, 'uiActionElements.success.disabled.color'),
      '&:hover ': {
        color: getThemeValue(theme, 'uiActionElements.success.disabled.color'),
      },
    },
  }
}

export const error = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.error.default.bg'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'uiActionElements.error.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.error.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.error.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.error.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.error.hover.bg'),
      color: getThemeValue(theme, 'uiActionElements.error.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.error.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.error.disabled.bg'),
      color: getThemeValue(theme, 'uiActionElements.error.disabled.color'),
      '&:hover ': {
        color: getThemeValue(theme, 'uiActionElements.error.disabled.color'),
      },
    },
  }
}

export const errorGhost = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.errorGhost.default.bg'),
    border: '1px solid',
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    borderColor: getThemeValue(theme, 'uiActionElements.errorGhost.default.borderColor'),
    color: getThemeValue(theme, 'uiActionElements.errorGhost.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.errorGhost.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.errorGhost.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.errorGhost.hover.borderColor'),
      color: getThemeValue(theme, 'uiActionElements.errorGhost.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.errorGhost.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.errorGhost.disabled.borderColor'),
      color: getThemeValue(theme, 'uiActionElements.errorGhost.disabled.color'),
    },
  }
}

export const errorShallow = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.errorShallow.default.bg'),
    border: '1px solid',
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    borderColor: getThemeValue(theme, 'uiActionElements.errorShallow.default.borderColor'),
    color: getThemeValue(theme, 'uiActionElements.errorShallow.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.errorShallow.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.errorShallow.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.errorShallow.hover.borderColor'),
      color: getThemeValue(theme, 'uiActionElements.errorShallow.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.errorShallow.disabled.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.errorShallow.disabled.borderColor'),
      color: getThemeValue(theme, 'uiActionElements.errorShallow.disabled.color'),
    },
  }
}

export const l3plusLocationCurrent = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.l3plus.locationCurrent.bg'),
    border: '1px solid',
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    borderColor: getThemeValue(theme, 'colors.base.l3plus.locationCurrent.bg'),
    color: getThemeValue(theme, 'colors.base.l3plus.locationCurrent.color'),
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'colors.base.l3plus.locationCurrent.bg'),
      borderColor: getThemeValue(theme, 'colors.base.l3plus.locationCurrent.bg'),
      color: getThemeValue(theme, 'colors.base.l3plus.locationCurrent.color'),
    },
  }
}

export const l3plusLocationCompare = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.l3plus.locationCompare.bg'),
    border: '1px solid',
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    borderColor: getThemeValue(theme, 'colors.base.l3plus.locationCompare.bg'),
    color: getThemeValue(theme, 'colors.base.l3plus.locationCompare.color'),
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'colors.base.l3plus.locationCompare.bg'),
      borderColor: getThemeValue(theme, 'colors.base.l3plus.locationCompare.bg'),
      color: getThemeValue(theme, 'colors.base.l3plus.locationCompare.color'),
    },
  }
}

export const white = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'uiActionElements.white.default.bg'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'uiActionElements.white.default.borderColor'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.white.default.color'),
    '::placeholder , input::placeholder ': {
      color: getThemeValue(theme, 'uiActionElements.white.placeholder.color'),
    },
    '&:hover ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.white.hover.bg'),
      borderColor: getThemeValue(theme, 'uiActionElements.white.hover.borderColor'),
      color: getThemeValue(theme, 'uiActionElements.white.hover.color'),
    },
    '&:disabled ': {
      backgroundColor: getThemeValue(theme, 'uiActionElements.white.disabled.bg'),
      border: '1px solid',
      borderColor: getThemeValue(theme, 'uiActionElements.white.disabled.borderColor'),
      color: getThemeValue(theme, 'uiActionElements.white.disabled.color'),
      '&:hover ': {
        color: getThemeValue(theme, 'uiActionElements.white.disabled.color'),
      },
    },
  }
}

export const locationOne = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.location.badge.blue.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'colors.base.location.badge.blue.default.bg'),
    '&:hover': {
      backgroundColor: getThemeValue(theme, 'colors.base.location.badge.blue.hover.bg'),
    },
  }
}

export const locationOneChecked = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.location.badge.blue.checked.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'colors.base.location.badge.blue.checked.bg'),
    '&:hover': {
      backgroundColor: getThemeValue(theme, 'colors.base.location.badge.blue.hover.bg'),
    },
  }
}

export const locationTwo = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.location.badge.yellow.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'colors.base.location.badge.yellow.default.bg'),
    '&:hover': {
      backgroundColor: getThemeValue(theme, 'colors.base.location.badge.yellow.hover.bg'),
    },
  }
}

export const locationTwoChecked = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.location.badge.yellow.checked.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'colors.base.location.badge.yellow.checked.bg'),
    '&:hover': {
      backgroundColor: getThemeValue(theme, 'colors.base.location.badge.yellow.hover.bg'),
    },
  }
}

export const locationThree = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.location.badge.lightgreen.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'colors.base.location.badge.lightgreen.default.bg'),
    '&:hover': {
      backgroundColor: getThemeValue(theme, 'colors.base.location.badge.lightgreen.hover.bg'),
    },
  }
}

export const locationThreeChecked = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.location.badge.lightgreen.checked.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'colors.base.location.badge.lightgreen.checked.bg'),
    '&:hover': {
      backgroundColor: getThemeValue(theme, 'colors.base.location.badge.lightgreen.hover.bg'),
    },
  }
}

export const locationFour = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.location.badge.violet.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'colors.base.location.badge.violet.default.bg'),
    '&:hover': {
      backgroundColor: getThemeValue(theme, 'colors.base.location.badge.violet.hover.bg'),
    },
  }
}

export const locationFourChecked = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.location.badge.violet.checked.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'colors.base.location.badge.violet.checked.bg'),
    '&:hover': {
      backgroundColor: getThemeValue(theme, 'colors.base.location.badge.violet.hover.bg'),
    },
  }
}

export const locationFive = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.location.badge.darkgreen.default.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'colors.base.location.badge.darkgreen.default.bg'),
    '&:hover': {
      backgroundColor: getThemeValue(theme, 'colors.base.location.badge.darkgreen.hover.bg'),
    },
  }
}

export const locationFiveChecked = (theme) => {
  return {
    backgroundColor: getThemeValue(theme, 'colors.base.location.badge.darkgreen.checked.bg'),
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.default'),
    color: getThemeValue(theme, 'uiActionElements.ghost.default.color'),
    border: '1px solid',
    borderColor: getThemeValue(theme, 'colors.base.location.badge.darkgreen.checked.bg'),
    '&:hover': {
      backgroundColor: getThemeValue(theme, 'colors.base.location.badge.darkgreen.hover.bg'),
    },
  }
}

export const locationPinOne = (theme) => {
  return {
    color: getThemeValue(theme, 'colors.base.location.pin.blue.fill'),
  }
}

export const locationPinTwo = (theme) => {
  return {
    color: getThemeValue(theme, 'colors.base.location.pin.yellow.fill'),
  }
}

export const locationPinThree = (theme) => {
  return {
    color: getThemeValue(theme, 'colors.base.location.pin.lightgreen.fill'),
  }
}

export const locationPinFour = (theme) => {
  return {
    color: getThemeValue(theme, 'colors.base.location.pin.violet.fill'),
  }
}

export const locationPinFive = (theme) => {
  return {
    color: getThemeValue(theme, 'colors.base.location.pin.darkgreen.fill'),
  }
}
