import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'

import { useSlots } from 'lib/hooks/useSlots'
import { useMediaQuery } from 'lib/hooks'

import {
  CardHeader,
  CardFooter,
  CardBody,
  CardShadow,
  CardSubHeader,
  CardContainer,
  CardLogo,
} from './SubComponents'

import { ScrollContainer } from '@layout/Layout/SubComponents/ScrollContainer'
import { Box, FlexContainer } from '@layout/BuildingBlocks'

export const CardComponent = ({ children, id, pxBody, pyBody }) => {
  const theme = useTheme()

  const [header, subheader, footer, bodyLeft, bodyRight, logo] = useSlots(
    ['header', 'subheader', 'footer', 'bodyLeft', 'bodyRight', 'logo'],
    children
  )

  const cardHeaderRef = useRef()
  const [cardHeaderHeight, setCardHeaderHeight] = useState()

  useLayoutEffect(() => {
    setCardHeaderHeight(cardHeaderRef?.current?.clientHeight)
  }, [])

  const spaces = getThemeValue(theme, 'spaces')
  const cardTheme = getThemeValue(theme, 'card')

  const flexDirection = ['column', 'column', 'row', 'row']
  const alignItems = ['center', 'center', 'flex-start', 'flex-start']

  const bodyLeftWidth = cardTheme.sizes.bodyLeft.public.width
  const bodyRightWidth = cardTheme.sizes.bodyRight.public.width

  const px = cardTheme.space.x
  const pb = cardTheme.space.public.bottom
  const pt = cardTheme.space.public.top

  const paddingxBody = pxBody || cardTheme.space.public.bodyX
  const paddingyBody = pyBody || cardTheme.space.public.bodyY
  const ptHeader = cardTheme.space.public.headerTop
  const pbHeader = subheader ? 0 : cardTheme.space.public.headerBottom
  const pbSubHeader = cardTheme.space.public.headerBottom
  const pxHeader = cardTheme.space.public.headerX

  const bodyMaxHeight = 'calc(100vh - ' + cardHeaderHeight + 'px - ' + useMediaQuery(paddingyBody) + ')'
  const scrollWidth = useMediaQuery(getThemeValue(theme, 'layout.areaMain.pr'))
  // padding right dependent on scrollbar width
  const paddingrBody = useMemo(
    () =>
      paddingxBody
        ? Array.isArray(paddingxBody)
          ? paddingxBody.map((item) => (item ? 'calc(' + item + ' - ' + scrollWidth + ')' : null))
          : 'calc(' + paddingxBody + ' - ' + scrollWidth + ')'
        : null,
    [paddingxBody, scrollWidth]
  )

  return (
    <CardContainer id={id}>
      {logo && <CardLogo px={px} pt={pt} {...logo.props} />}
      {header && (
        <CardHeader ref={cardHeaderRef} pt={ptHeader} pb={pbHeader} px={pxHeader}>
          {header}
        </CardHeader>
      )}
      {header && subheader && (
        <CardSubHeader pt={pt} pb={pbSubHeader} px={pxHeader}>
          {subheader}
        </CardSubHeader>
      )}
      <CardBody maxHeight={bodyMaxHeight} pl={paddingxBody} pr={paddingrBody} py={paddingyBody}>
        <ScrollContainer minWidth="inherit">
          <FlexContainer
            flexColumnGap={spaces.rhythm.vertical.extraLarge}
            flexRowGap={spaces.rhythm.vertical.extraLarge}
            flexDirection={flexDirection}
            justifyContent="center"
            alignItems={alignItems}
          >
            {bodyLeft && <Box width={bodyLeftWidth}>{bodyLeft}</Box>}
            {bodyRight && <Box width={bodyRightWidth}>{bodyRight}</Box>}
          </FlexContainer>
        </ScrollContainer>
      </CardBody>
      {footer && (
        <CardFooter px={px} pb={pb}>
          {footer}
        </CardFooter>
      )}
    </CardContainer>
  )
}

const withShadow =
  (CardComponent) =>
  ({ children, id, ...props }) => {
    return (
      props.visible !== false && (
        <CardShadow id={id}>
          <CardComponent {...props}>{children}</CardComponent>
        </CardShadow>
      )
    )
  }

export const CardPublic = withShadow(CardComponent)

CardPublic.BodyLeft = ({ children }) => children
CardPublic.BodyLeft.displayName = 'bodyLeft'

CardPublic.BodyRight = ({ children }) => children
CardPublic.BodyRight.displayName = 'bodyRight'

CardPublic.Header = ({ children }) => children
CardPublic.Header.displayName = 'header'

CardPublic.SubHeader = ({ children }) => children
CardPublic.SubHeader.displayName = 'subheader'

CardPublic.Footer = ({ children }) => children
CardPublic.Footer.displayName = 'footer'

CardPublic.Logo = ({ children }) => children
CardPublic.Logo.displayName = 'logo'
