import React from 'react'

import { defaultProps } from './defaultProps'
import { children } from './defaultPropTypes'

export const HeaderNavigationMeta = ({ children }) => {
  return <div>{children}</div>
}

HeaderNavigationMeta.propTypes = {
  children,
}

HeaderNavigationMeta.defaultProps = defaultProps
