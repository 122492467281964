import React from 'react'
import styled from 'styled-components'
import { typography } from 'styled-system'

import { IconWrapper } from '@utilities/IconWrapper'
import { FlexContainer } from '@layout/BuildingBlocks'

import { appearance, shape, size } from './variants'
import { checkboxAppearance } from 'theming/systemHelper/appearance-checkbox'
import { typeMap, typeScale, typeStyle, defaultProps as textProps } from '@typography'

import { bool, string, func, oneOfType } from 'prop-types'

export const Checkbox = styled.div`
  display: flex;
`

const Badge = styled(FlexContainer)`
  cursor: pointer;

  ${(props) => props.disabled && { cursor: 'not-allowed' }};
  ${(props) => props.disabled && { '> div': { pointerEvents: 'none' } }};

  ${typography};
  ${(props) => typeScale(props.typeScale)};
  ${(props) => typeStyle(props.typeStyle)};

  ${appearance};
  ${shape};
  ${size};
  ${checkboxAppearance};
  border-top-right-radius: ${({ firstItem, groupedView }) => firstItem && groupedView && '0px'};
  border-right-style: ${({ groupedView, lastItem }) => (!lastItem && groupedView ? 'none' : 'solid')};
  border-bottom-right-radius: ${({ firstItem, groupedView }) => firstItem && groupedView && '0px'};
  border-top-left-radius: ${({ lastItem, groupedView }) => lastItem && groupedView && '0px'};
  border-bottom-left-radius: ${({ lastItem, groupedView }) => lastItem && groupedView && '0px'};
  border-radius: ${({ firstItem, lastItem, groupedView }) =>
    groupedView && firstItem === false && lastItem === false && '0px'};
`

export const CheckboxIconBadge = ({
  appearance,
  checked,
  disabled,
  handleMouseMove,
  handleMouseLeave,
  handleMouseOver,
  icon,
  iconPosition,
  iconWrapper,
  iconVariantSourceName,
  iconVariantSize,
  id,
  label,
  labelGap,
  name,
  shape,
  size,
  groupedView,
  firstItem,
  lastItem,
  ...props
}) => {
  const { typeStyle, typeScale } = typeMap[size]

  return (
    <Badge
      appearance={appearance}
      checked={checked}
      disabled={disabled}
      onMouseMove={handleMouseMove}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      shape={shape}
      size={size}
      typeScale={typeScale}
      typeStyle={typeStyle}
      groupedView={groupedView}
      firstItem={firstItem}
      lastItem={lastItem}
      {...props}
    >
      <Checkbox name={name} id={id} />
      <FlexContainer alignItems="center" flexColumnGap={labelGap}>
        <IconWrapper
          icon={icon}
          iconPosition={iconPosition}
          iconWrapper={iconWrapper}
          iconVariantSourceName={iconVariantSourceName}
          iconVariantSize={iconVariantSize}
        />

        {label}
      </FlexContainer>
    </Badge>
  )
}

CheckboxIconBadge.defaultProps = {
  ...textProps,
  ...{
    appearance: 'lightgray',
    checked: false,
    disabled: false,
    handleMouseMove: () => {},
    handleMouseLeave: () => {},
    handleMouseOver: () => {},
    id: 'checkbox-id',
    label: 'checkbox label',
    labelGap: null,
    name: 'checkbox-name',
    shape: 'square',
    size: 'medium',
    tag: 'div',
  },
}
CheckboxIconBadge.propTypes = {
  appearance: string,
  checked: bool,
  disabled: bool,
  handleMouseMove: func,
  handleMouseLeave: func,
  handleMouseOver: func,
  id: string,
  label: oneOfType([bool, string]),
  labelGap: string,
  name: string,
  shape: string,
  size: string,
  tag: string,
}
