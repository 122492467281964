import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexContainer, Box } from '@layout/BuildingBlocks'

import { Button } from '@ui/Buttons'

import { DropdownSelect } from '@forms/DropdownSelectComponents'

export const CalendarFoot = ({ onFocus, onBlur, onToday, forElementName }) => {
  const { t } = useTranslation()

  const quickselectItems = useMemo(
    () => [
      { value: 7, label: t('datepicker.quickselect.oneWeek') },
      { value: 14, label: t('datepicker.quickselect.twoWeek') },
    ],
    [t]
  )

  return (
    <FlexContainer flexColumnGap="3px">
      <Box flex="0 0 50%">
        <Button
          width="100%"
          shape="square"
          onClick={onToday}
          onFocus={onFocus}
          onBlur={onBlur}
          text={t('datepicker.quickselect.today')}
        />
      </Box>
      <Box flex="0 0 50%">
        <DropdownSelect
          forElementName={forElementName}
          placeholder={t('datepicker.quickselect.placeholder')}
          onFocus={onFocus}
          onBlur={onBlur}
          name="quickselect"
          appearance="lightgray"
          borderAppearance="noBorder"
          items={quickselectItems}
          sizeVariant="small"
          optionsListMaxHeight="240px"
        />
      </Box>
    </FlexContainer>
  )
}
