import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { background, border, compose, color, grid, layout, space } from 'styled-system'

import { defaultProps } from './defaultProps'
import { tag } from './defaultPropTypes'

const StyledComponent = styled.div.withConfig({
  shouldForwardProp: (props) =>
    ['children', 'onClick', 'onMouseOver', 'onMouseOut', 'onMouseMouve'].includes(props),
})`
  display: grid;
  ${compose(background, border, color, grid, layout, space)};
`

export const GridContainer = ({ children, tag, ...props }) => {
  return (
    <StyledComponent as={tag} {...props}>
      {children}
    </StyledComponent>
  )
}

export const GridContainerForwardedRef = forwardRef(({ children, tag, ...props }, ref) => {
  return (
    <StyledComponent ref={ref} as={tag} {...props}>
      {children}
    </StyledComponent>
  )
})

GridContainer.propTypes = {
  tag,
}

GridContainerForwardedRef.propTypes = {
  tag,
}

GridContainer.defaultProps = defaultProps

GridContainerForwardedRef.defaultProps = defaultProps
