export const getInformationPage = ({ colors }) => {
  return {
    content: {
      colors: { bg: colors.white },
    },
    infoBox: {
      padding: ['55px'],
      colors: { bg: colors.lightgray[300], bgGlass: 'rgba(255,255,255,0.7)' },
      lineHeight: '1.5rem',
      backdropFilter: 'blur(10px)',
    },
    spaces: {
      py: ['34px', '55px'],
      px: ['34px', '55px', null, null, '144px'],
    },
  }
}
