import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { layout } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { array, string, oneOfType } from 'prop-types'

import { getThemeValue } from 'theming'

import { zIndices } from 'config/zIndices'

import { useDisplaySizeSufficiency } from 'lib/hooks'
import { useSlots } from 'lib/hooks/useSlots'

import { SystemMessage } from '@utilities/SystemMessage'

import { Markdown } from 'lib/markdown'

import { FlexRow, FlexItem } from '@layout/BuildingBlocks'
import {
  HeaderAppLogo,
  HeaderTitle,
  HeaderTitleLogo,
  HeaderPartner,
  HeaderTabs,
  HeaderGlobalControls,
  HeaderNavigationMeta,
  HeaderNavigationMain,
} from '@layout/Header/SubComponents'

import { Copy } from '@typography'

const markdownComponents = {
  p: ({ node, ...props }) => <Copy tag="p" lineHeight={1.66} {...props} />,
}

const StyledHeader = styled.header.withConfig({
  shouldForwardProp: (prop) => ['children'].includes(prop),
})`
  align-items: center;
  background-color: ${themeGet('header.colors.bg', '#fff')};
  border-bottom: ${themeGet('header.borderBottom', '0')};
  display: block;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${zIndices.layoutHeader};
  ${layout}
`

export const Header = memo(({ children, headerHeight }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [displayIsSufficient] = useDisplaySizeSufficiency()
  const height = getThemeValue(theme, headerHeight)
  const mx = getThemeValue(theme, 'layout.header.mx')
  const spaces = getThemeValue(theme, 'spaces')
  const { minWidth, minHeight } = getThemeValue(theme, 'layout.page')

  const [appLogo, title, titleLogo, navigationMain, partner, tabs, globalControls, navigationMeta] = useSlots(
    [
      'appLogo',
      'title',
      'titleLogo',
      'navigationMain',
      'partner',
      'tabs',
      'globalControls',
      'navigationMeta',
    ],
    children
  )

  return (
    <StyledHeader>
      <FlexRow alignItems="center" justifyContent="space-between" mx={mx} height={height}>
        <FlexItem justifyContent="flex-start" flexDirection="row" height="inherit">
          {appLogo && <HeaderAppLogo>{appLogo}</HeaderAppLogo>}
          {title && <HeaderTitle>{title}</HeaderTitle>}
          {titleLogo && <HeaderTitleLogo>{titleLogo}</HeaderTitleLogo>}
          {navigationMain && <HeaderNavigationMain>{navigationMain}</HeaderNavigationMain>}
          {tabs && <HeaderTabs>{tabs}</HeaderTabs>}
          {partner && <HeaderPartner>{partner}</HeaderPartner>}
        </FlexItem>
        <FlexItem justifyContent="flex-end">
          {globalControls && <HeaderGlobalControls>{globalControls}</HeaderGlobalControls>}
          {navigationMeta && <HeaderNavigationMeta>{navigationMeta}</HeaderNavigationMeta>}
        </FlexItem>
      </FlexRow>
      {!displayIsSufficient && (
        <FlexRow mx={spaces.rhythm.horizontal.medium} my={spaces.rhythm.vertical.small}>
          <SystemMessage type="warning">
            <Markdown components={markdownComponents}>
              {t('display.insufficient', { minWidth, minHeight })}
            </Markdown>
          </SystemMessage>
        </FlexRow>
      )}
    </StyledHeader>
  )
})

Header.AppLogo = ({ children }) => children
Header.AppLogo.displayName = 'appLogo'

Header.Title = ({ children }) => children
Header.Title.displayName = 'title'

Header.TitleLogo = ({ children }) => children
Header.TitleLogo.displayName = 'titleLogo'

Header.Partner = ({ children }) => children
Header.Partner.displayName = 'partner'

Header.NavigationMain = ({ children }) => children
Header.NavigationMain.displayName = 'navigationMain'

Header.Tabs = ({ children }) => children
Header.Tabs.displayName = 'tabs'

Header.GlobalControls = ({ children }) => children
Header.GlobalControls.displayName = 'globalControls'

Header.NavigationMeta = ({ children }) => children
Header.NavigationMeta.displayName = 'navigationMeta'

Header.defaultProps = {
  headerHeight: 'layout.header.height',
}
Header.propTypes = { headerHeight: oneOfType([string, array]) }
