export const dateStringToIso = (dateString) => {
  if (dateString === null || dateString.length === 0) {
    return null
  }
  let day, month, year
  if (dateString.match(/^[1-9][0-9]{2,3}/)) {
    ;[, year, month, day] = dateString.match(/([0-9]*)[^0-9]?([0-9]*)[^0-9]?([0-9]*)/)
  } else {
    ;[, day, month, year] = dateString.match(/([0-9]*)[^0-9]?([0-9]*)[^0-9]?([0-9]*)/)
  }
  return `${year.padEnd(4, '0').slice(0, 4)}-${month.padStart(2, '0').slice(-2)}-${day
    .padStart(2, '0')
    .slice(-2)}`
}

export const dateStringToInput = (inputDate) => {
  const date = inputDate !== null ? new Date(inputDate) : null
  if (date !== null && !isNaN(date?.getTime())) {
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  } else {
    return null
  }
}
