import React from 'react'

const PlaceholderIcon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 24 124" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height}>
      <circle cx="50%" cy="50%" r="10" fill="lightgray" stroke="gray" strokeWidth="2px" />
    </svg>
  )
}

export const defaultPropsIcon = {
  icon: {
    Icon: PlaceholderIcon,
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: '0px',
    type: 'solid',
    height: '100%',
    width: '100%',
  },
}

export const defaultPropsIconPosition = {
  iconPosition: {
    top: null,
    right: null,
    bottom: null,
    left: null,
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
  },
}

export const defaultPropsIconWrapper = {
  iconWrapper: {
    height: 'auto',
    marginTop: null,
    marginRight: null,
    marginBottom: null,
    marginLeft: null,
    width: 'auto',
  },
}
