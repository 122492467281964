import React, { createContext, useContext, useState } from 'react'

const ControlsSegmentContext = createContext()

const ControlsSegmentContextProvider = ({
  children,
  folded = null,
  checked = null,
  initialFolded = null,
  initialChecked = null,
  foldDisabled,
}) => {
  const [internalFolded, setFolded] = useState(initialFolded)
  const [internalChecked, setChecked] = useState(initialChecked)

  const toggle = (bool) => {
    setFolded(bool)
  }

  const context = {
    folded: internalFolded ?? folded,
    setFolded,
    toggle,
    foldDisabled,
    checked: internalChecked ?? checked,
    setChecked,
  }

  return <ControlsSegmentContext.Provider value={context}>{children}</ControlsSegmentContext.Provider>
}

const useControlsSegmentContext = () => {
  const context = useContext(ControlsSegmentContext)

  if (!context) {
    throw new Error('Error in creating the context')
  }
  return context
}

export { useControlsSegmentContext, ControlsSegmentContextProvider }
