export const getLayout = ({ spaces, colors }) => {
  return {
    headerFullscreen: {
      height: '65px',
      py: spaces.rhythm.vertical.medium,
      px: spaces.rhythm.horizontal.medium,
      colors: {
        public: {
          bg: colors.black,
        },
      },
      background: 'linear-gradient(31deg, rgba(255,255,255,1) 125px, rgba(0,0,0,1) 125px)',
      backgroundBreakWidth: '125px',
    },
  }
}
