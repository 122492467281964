import { keyframes } from 'styled-components'

export const flyout = keyframes`
  0% {
    opacity: 0;
  }
  5% {
    opacity: .1;
  }
  10% {
    opacity: .2;
  }
  100% {
    opacity: 1;
  }
`
export const notification = keyframes`
  0% {
    opacity: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    max-height: 0px;
  }
  100% {
    opacity: 1;
    max-height: 200px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 4px;
    margin-bottom: 16px;
  }
`
export const notificationReverse = keyframes`
  0% {
    opacity: 1;
    max-height: 200px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 4px;
    margin-bottom: 16px;
  }
  100% {
    opacity: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    max-height: 0px;
  }
`
