import React from 'react'

import 'rsuite-table/dist/css/rsuite-table.css'
import { SvgFilterShady, BudiconContext } from 'bgag-budicons'
import { FlexContainer } from '@layout/BuildingBlocks'
import { GlobalStyle } from 'theming/styles/GlobalStyle'

const Layout = ({ children, showMainMenu = true, media = 'display' }) => {
  return (
    <>
      <GlobalStyle media={media} />
      <SvgFilterShady />
      <BudiconContext.Provider value={{ type: 'outline' }}>
        <FlexContainer width="100%" height="100%">
          {children}
        </FlexContainer>
        <div id="modal"></div> {/* Placeholder for modal */}
      </BudiconContext.Provider>
    </>
  )
}

export default Layout
