import moment from 'moment'

export function sortLocaleCompareAsc(val1, val2) {
  val1 = typeof val1?.localeCompare !== 'function' ? '' : val1
  val2 = typeof val2?.localeCompare !== 'function' ? '' : val2
  return val1.trim().localeCompare(val2.trim())
}

export function sorter({
  indices,
  dataSources,
  sort,
  sortDef,
  getValuesByKeys = null,
  filter = null,
  objectType = null,
}) {
  const sorted = [...indices]
  // console.time('sorting')
  if (sort.key !== null && sort.dir !== null) {
    const keys = sort.key.split('.')
    let curSortDef = sortDef.find((def) => def.keys.indexOf(sort.key) >= 0)
    if (curSortDef && curSortDef.type) {
      const sortType = curSortDef.type
      if (sortType === 'fct') {
        sorted.sort(curSortDef.sort(dataSources, sort.dir, sort.extra))
      } else {
        sorted.sort((index1, index2) => {
          if (sort.dir !== 'asc') {
            const tmp = index1
            index1 = index2
            index2 = tmp
          }
          let val1, val2
          if (typeof getValuesByKeys === 'function') {
            ;[val1, val2] = getValuesByKeys(keys, dataSources, index1, index2, filter, objectType)
          } else {
            val1 = dataSources[index1.index][keys[0]]
            val2 = dataSources[index2.index][keys[0]]
          }
          if (sortType === 'numeric') {
            val1 = parseFloat(val1)
            val2 = parseFloat(val2)
            // conditionals in following structure for sort performance!
            if (isNaN(val1) || isNaN(val2)) {
              /*
              if (isNaN(val1) && isNaN(val2)) {
                //val1 = 0
                //val2 = 0
              } else {
                val1 = isNaN(val1) ? val2 - 1 : val1
                val2 = isNaN(val2) ? val1 - 1 : val2
              }
              */
              if (isNaN(val1) && isNaN(val2)) {
                return 0
              } else if (isNaN(val1) && val1 !== val2) {
                return sort.dir === 'asc' ? 1 : -1
              } else if (isNaN(val2) && val1 !== val2) {
                return sort.dir === 'asc' ? -1 : 1
              }
            }
            return val1 - val2
          } else if (sortType === 'date') {
            if (val1 === null && val1 !== val2) {
              return sort.dir === 'asc' ? 1 : -1
            } else if (val2 === null && val1 !== val2) {
              return sort.dir === 'asc' ? -1 : 1
            } else if (val1 === null && val2 === null) {
              return 0
            }
            return Date.parse(val1) - Date.parse(val2)
          } else if (sortType === 'boolean') {
            return val1 === val2 ? 0 : val1 ? -1 : 1
          } else {
            val1 = !val1 || typeof val1.localeCompare !== 'function' ? '' : val1
            val2 = !val2 || typeof val2.localeCompare !== 'function' ? '' : val2
            return val1.trim().localeCompare(val2.trim())
          }
        })
      }
    }
  }
  // console.timeEnd('sorting')
  return sorted
}

export const getSorter = (sort, sortDef) => {
  return (a, b) => {
    if (sort.dir !== 'asc') {
      const tmp = a
      a = b
      b = tmp
    }
    let val1 = a[sort.key]
    let val2 = b[sort.key]

    const sortType = sortDef[sort.key]
    if (sortType === 'numeric') {
      val1 = parseFloat(val1)
      val2 = parseFloat(val2)
      if (isNaN(val1) || isNaN(val2)) {
        if (isNaN(val1) && isNaN(val2)) {
          val1 = 0
          val2 = 0
        } else {
          val1 = isNaN(val1) ? val2 - 1 : val1
          val2 = isNaN(val2) ? val1 - 1 : val2
        }
      }
      return val1 - val2
    } else if (sortType === 'date') {
      val1 = Date.parse(val1)
      val2 = Date.parse(val2)
      if (isNaN(val1) || isNaN(val2)) {
        if (isNaN(val1) && isNaN(val2)) {
          val1 = 0
          val2 = 0
        } else {
          val1 = isNaN(val1) ? val2 - 1 : val1
          val2 = isNaN(val2) ? val1 - 1 : val2
        }
      }
      return val1 - val2
    } else if (sortType === 'boolean') {
      return val1 === val2 ? 0 : val1 ? -1 : 1
    } else {
      val1 = !val1 || typeof val1.localeCompare !== 'function' ? '' : val1
      val2 = !val2 || typeof val2.localeCompare !== 'function' ? '' : val2
      return val1.trim().localeCompare(val2.trim())
    }
  }
}

/**
 * Sort Data by multiple criteria
 * @param sortKeysSequence: sequence of sorting keys
 * @param data: Dataset to sort
 * @param sort: selected sort key and direction
 * @param sortDef: types of sort keys
 * @returns {*}
 */
export const sortByMultiProps = ({ sortKeysSequence = [], data, sort, sortDef }) => {
  if (sort.key && sort.dir) {
    const sortKeyIdx = sortKeysSequence.findIndex(({ key, dir }) => key === sort.key)
    if (sortKeyIdx >= 0) {
      sortKeysSequence.splice(sortKeyIdx, 1)
    }
    sortKeysSequence.unshift(sort)
  }

  return data.sort((a, b) => {
    let comparison
    for (let [, { key, dir }] of sortKeysSequence.entries()) {
      const sortType = getSortKeyType(key, sortDef)
      comparison = getComparison(a[key], b[key], sortType, dir)

      if (comparison !== 0) {
        return comparison
      }
    }

    return comparison
  })
}

export const getSortKeyType = (key, sortDef) => sortDef.find((def) => def.keys.indexOf(key) >= 0)?.type

export const getComparison = (val1, val2, type, dir) => {
  if (dir !== 'asc') {
    const tmp = val1
    val1 = val2
    val2 = tmp
  }
  if (val1 === null || val2 === null) {
    if (val1 === val2) return 0
    return val1 === null ? -1 : 1
  }

  switch (type) {
    case 'numeric':
      return parseFloat(val1) - parseFloat(val2)
    case 'boolean':
      return val1 === val2 ? 0 : val1 ? 1 : -1
    case 'date':
      return moment(val2).diff(val1)
    default:
      val1 = !val1 || typeof val1.localeCompare !== 'function' ? '' : val1
      val2 = !val2 || typeof val2.localeCompare !== 'function' ? '' : val2
      return val1.trim().localeCompare(val2.trim())
  }
}
