import React, { memo, useEffect, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { layout, space } from 'styled-system'

import { useModalContext } from '../..'

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-x: ${(props) => (props.shouldShow ? 'auto' : 'hidden')};
  overflow-y: hidden;
  transition: all 0.2s ease-in-out;
  ${layout}
  ${space}
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const cssFadeIn = css`
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;
  animation-duration: 400ms;
`

const cssOut = css`
  opacity: 0;
`

const Content = styled.div.withConfig({
  shouldForwardProp: (prop) => !['shouldShow'].includes(prop),
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  ${(props) => (props.shouldShow ? cssFadeIn : cssOut)};
  ${space};
`

export const ModalBody = memo(({ children, scrollable, name, ...props }) => {
  const animationStarted = useRef(false)

  const {
    state: { activeModal, isAnimationRunning, isContentShown },
    dispatch,
  } = useModalContext()

  useEffect(() => {
    if (isAnimationRunning) {
      animationStarted.current = true
    }
  }, [isAnimationRunning])

  // setIsContentShown, if animation was running
  useEffect(() => {
    if (activeModal !== name) {
      return
    }
    if (animationStarted.current && !isAnimationRunning) {
      dispatch({ type: 'setIsContentShown', payload: true })
      animationStarted.current = false
    }
  }, [isAnimationRunning, name, activeModal, dispatch])

  // setIsContentShown, if no animation was running
  useEffect(() => {
    if (name !== activeModal) {
      return
    }
    const timeout = setTimeout(() => {
      if (!animationStarted.current && !isContentShown) {
        dispatch({ type: 'setIsContentShown', payload: true })
      }
    }, 300)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [dispatch, isContentShown, name, activeModal])

  return (
    <StyledComponent shouldShow={isContentShown}>
      <Content shouldShow={isContentShown} {...props}>
        {children}
      </Content>
    </StyledComponent>
  )
})

ModalBody.displayName = 'modalBody'
