import React from 'react'
import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const highlightedStyle = css`
  background-color: ${themeGet('card.colors.rim.highlighted.bg', 'black')};
  box-shadow: ${themeGet('card.colors.rim.highlighted.shadow', 'black')};
`

const StyledComponent = styled.div`
  border-radius: ${themeGet('card.rim.border.radius', '0px')};
  background-color: ${themeGet('card.colors.rim.bg', 'lightgray')};
  padding: ${themeGet('card.rim.padding', '0')};
  height: 100%;
  width: 100%;
  ${(props) => props.highlighted && highlightedStyle}
`

export const CardRim = ({ highlighted, id, children }) => (
  <StyledComponent highlighted={highlighted} id={id}>
    {children}
  </StyledComponent>
)
