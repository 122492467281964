export const getUiActionElements = ({ themeColors, colors, radii }) => ({
  primary: {
    default: {
      bg: colors.black,
      color: colors.l3plus.acidgreen,
    },
  },
  primaryShallow: {
    default: {
      bg: colors.black,
      color: colors.white,
    },
    hover: {
      bg: colors.black,
      color: colors.l3plus.lightgreen,
    },
  },
})
