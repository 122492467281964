const record_seperator = '\u001e'
const unit_seperator = '\u001f'

/**
 * Combines all args into a single string
 * The arguments are separated by the ascii character for record separation.
 * If a stringify method is defined for an argument it will be used to turn the argument to a string.
 * The stringify method gets passed the argument value and the ascii character for unit separation
 * if further separation of values is required (e.g list arguments)
 * @param {array} args
 * @returns {string}
 */
export function hashArgs(args) {
  return (
    args
      // apply toString if present
      .map((arg) => (arg.stringify ? arg.stringify(arg.value, unit_seperator) : arg.value))
      .join(record_seperator)
  )
}

/** Will fill in the value property of conditionals from the arguments array
 *
 * @param {object[]} conditionals - A set of rules for a parameter
 * @param {array} args - The concrete arguments passed
 * @returns {object[]} - An array of new objects with a shallow copy of conditionals
 */
export function fillConditionalsWithValue(conditionals, args) {
  return conditionals.map((rules, idx) => ({ ...rules, value: args[idx] }))
}

/** Turns the passed value into a promise if it isn't already */
export const promisify = (val) =>
  val && typeof val.then === 'function' ? val : new Promise((res) => res(val))
