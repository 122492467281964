import numbersFormats from 'config/numbersFormats'

export function getShortSurfaceFormat({ surface, fraction = null, allowMio = null }) {
  if (isNaN(surface)) {
    return { surface: 0 }
  }
  surface = parseInt(surface)
  let roundedFraction = 'roundedFraction2'
  if (fraction !== null && !!numbersFormats[`roundedFraction${fraction}`]) {
    roundedFraction = `roundedFraction${fraction}`
  }

  if (surface / 1000000 > 1 && allowMio) {
    return { surface: numbersFormats[roundedFraction].format(surface / 1000000), compact: ' Mio.' }
  }

  if (surface / 1000 > 1 || !allowMio) {
    return { surface: numbersFormats[roundedFraction].format(surface / 1000), compact: ' Tsd.' }
  }

  return { surface: numbersFormats[roundedFraction].format(surface) }
}

export function getSurfaceFormat({ surface, fraction = null }) {
  if (isNaN(surface)) {
    return { surface: 0 }
  }
  surface = parseInt(surface)
  let roundedFraction = 'roundedFraction2'
  if (fraction !== null && !!numbersFormats[`roundedFraction${fraction}`]) {
    roundedFraction = `roundedFraction${fraction}`
  }

  return { surface: numbersFormats[roundedFraction].format(surface) }
}
