import React, { useState, useRef, forwardRef, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, ColumnGroup, Column } from 'rsuite-table'

import { Copy } from '@typography'

import { useMediaQuery } from 'lib/hooks'

import { removeOverlay } from '.'

import {
  bordered,
  headerHeight,
  isTree,
  minHeight,
  rowHeight,
  rowKey,
  scrollTo,
  scrollToPx,
} from './defaultPropTypes'
import { defaultProps } from './defaultProps'

let mouseOverTimeout = null

const tablesToBeResized = new Set()

const WrappedTable = forwardRef((props = {}, ref) => {
  const { t } = useTranslation()
  const {
    data,
    dataIsIndexed = false,
    wordWrap,
    renderEmptyText,
    bordered,
    calculateHeight,
    children,
    defaultSortType,
    forceRecalculateHeight,
    headerHeight,
    height,
    minHeight,
    onSortColumn,
    onScroll,
    onRowClick,
    rowHeight,
    rowClassName,
    setSort,
    scrollTo,
    scrollToPx,
    sort,
    shouldUpdateScroll,
    propRef = null,
    ...rest
  } = props

  const [calculatedHeight, setHeight] = useState(height ?? calculateHeight ? 0 : undefined)

  const currentHeaderHeight = useMediaQuery(headerHeight)
  const currentRowHeight = useMediaQuery(rowHeight)

  const localTable = useRef()
  const table = ref || localTable

  if (propRef && typeof propRef.current !== 'undefined') {
    if (propRef.current === null || typeof propRef.current !== 'object') {
      propRef.current = {}
    }
    propRef.current.rowHeight = currentRowHeight
  }

  const lastSort = useRef({ key: null, dir: null })

  const localOnSortColumn = useCallback(
    (key) => {
      const lastKey = lastSort.current.key
      const lastDir = lastSort.current.dir
      let dir = 'asc'

      if (key === lastKey) {
        dir = lastDir === null ? 'asc' : lastDir === 'asc' ? 'desc' : null
        if (dir === null) {
          key = null
        }
      }
      lastSort.current = { key, dir }
      setSort({ key, dir })
      if (typeof onSortColumn === 'function') {
        onSortColumn(key)
      }
    },
    [setSort, onSortColumn]
  )

  const localOnRowClick = useCallback(
    (data) => {
      removeOverlay()
      if (typeof onRowClick === 'function') {
        onRowClick(data)
      }
    },
    [onRowClick]
  )

  const localData = useMemo(
    () =>
      dataIsIndexed
        ? data
        : data.map((entry, index) => {
            if (entry?.children?.length) {
              entry.children = entry.children.map((child, index) => ({ ...child, __index: index }))
            }
            return { ...entry, __index: entry?.__index ?? index }
          }),
    [data, dataIsIndexed]
  )

  const localRowClassName = useCallback(
    (rowData) => {
      if (typeof rowData === 'undefined') {
        return ''
      }
      const classNames = [rowData.__index % 2 === 0 ? 'even' : 'odd']
      if (Array.isArray(rowData.children)) {
        classNames.push('parent')
      }

      if (typeof rowClassName === 'string') {
        classNames.push(rowClassName)
      } else if (typeof rowClassName === 'function') {
        classNames.push(rowClassName(rowData))
      }
      return classNames.join(' ')
    },
    [rowClassName]
  )

  const localOnScroll = useCallback(
    (scrollX, scrollY) => {
      if (!table.current) {
        return
      }
      if (mouseOverTimeout !== null) {
        clearTimeout(mouseOverTimeout)
      }
      removeOverlay()
      /* fix for blurry text not possible like this after rsuite-table upgrade:
    // blurry text, if scrollY or scrollX gets not rounded
    const newScrollX = Math.round(scrollX * window.devicePixelRatio) / window.devicePixelRatio
    const newScrollY = Math.round(scrollY * window.devicePixelRatio) / window.devicePixelRatio
    // table.current.scrollX = scrollX
    // table.current.scrollY = scrollY
    // table.current.setState({ scrollX, scrollY })
    */
      if (typeof onScroll === 'function') {
        onScroll(scrollX, scrollY)
      }
    },
    [table, onScroll]
  )

  const sortColumn = sort && sort.key ? sort.key : null
  const sortType = sort && sort.dir ? sort.dir : null

  useEffect(() => {
    if (sort && sort.key) {
      lastSort.current = sort
    }
  }, [sort])

  // calculate table height, if calculateHeight is true, else set on prop height
  useEffect(() => {
    if (!calculateHeight) {
      if (height !== null && height >= 0) {
        setHeight(height)
      }
      return
    }

    const container = table.current.root.parentNode
    const refTable = { root: table.current.root }
    tablesToBeResized.add(refTable)

    const resetHeight = () => {
      for (const { root } of tablesToBeResized) {
        root.style.display = 'none'
      }
      const height =
        parseFloat(window.getComputedStyle(container).height) -
        parseFloat(window.getComputedStyle(container).paddingTop) -
        parseFloat(window.getComputedStyle(container).paddingBottom)

      for (const { root } of tablesToBeResized) {
        root.style.display = ''
      }

      setHeight(height > minHeight ? height : minHeight)
    }

    let timeout = setTimeout(resetHeight, 5)
    const onResize = () => {
      clearTimeout(timeout)
      timeout = setTimeout(resetHeight, 5)
    }
    window.addEventListener('resize', onResize)

    return () => {
      tablesToBeResized.delete(refTable)
      window.removeEventListener('resize', onResize)
      clearTimeout(timeout)
    }
  }, [calculateHeight, height, minHeight, table, forceRecalculateHeight])

  // react on scrollTo
  useEffect(() => {
    if (scrollTo === null && scrollToPx === null) {
      return
    }

    const maxScroll = data.length * currentRowHeight - calculatedHeight + currentHeaderHeight

    if (isNaN(maxScroll)) {
      return
    }

    let scrollY = scrollTo === null ? scrollToPx : scrollTo * currentRowHeight
    scrollY = Math.max(0, Math.min(scrollY, maxScroll))
    table.current.scrollTop(scrollY)
  }, [table, scrollTo, scrollToPx, currentRowHeight, currentHeaderHeight, calculatedHeight, data])

  const renderEmpty = useCallback(
    () => <Copy className="rs-table-body-info">{renderEmptyText || t('table.noResult')}</Copy>,
    [renderEmptyText, t]
  )

  return (
    <Table
      shouldUpdateScroll={shouldUpdateScroll ?? true}
      wordWrap={wordWrap}
      bordered={bordered}
      onSortColumn={localOnSortColumn}
      onScroll={localOnScroll}
      onRowClick={localOnRowClick}
      rowClassName={localRowClassName}
      defaultSortType={defaultSortType || 'asc'}
      height={calculatedHeight}
      rowHeight={currentRowHeight}
      headerHeight={currentHeaderHeight}
      ref={table}
      sortColumn={sortColumn}
      sortType={sortType}
      renderEmpty={renderEmpty}
      data={localData}
      {...rest}
    >
      {children}
    </Table>
  )
})

export { WrappedTable as Table, ColumnGroup, Column }

WrappedTable.defaultProps = defaultProps

WrappedTable.propTypes = {
  bordered,
  headerHeight,
  isTree,
  minHeight,
  rowHeight,
  rowKey,
  scrollTo,
  scrollToPx,
}
