import React, { useRef } from 'react'
import styled, { useTheme } from 'styled-components'

import { Input } from '@forms'

import { Badge } from '@utilities/Badge'
import { appearance } from '@utilities/Badge/variants'

import { BudiconChevronBottomA } from 'bgag-budicons'

const StyledInput = styled(Input)`
  cursor: pointer;
  pointer-events: none;
  color: ${(props) => props.color};
`

const BadgeComponent = ({ placeholder = null, refBadge, name, size, ...props }) => {
  const theme = useTheme()
  const color = appearance()({ theme, appearance: props.appearance }).color

  return (
    <Badge iconRight={{ Icon: BudiconChevronBottomA }} size={size} ref={refBadge} tabIndex="0" {...props}>
      <Badge.Label>
        <StyledInput
          placeholder={placeholder}
          forElementName={name}
          size={size}
          appearance={'bare'}
          name="label"
          readOnly="readonly"
          shape="shapeless"
          width="100%"
          color={color}
        />
      </Badge.Label>
    </Badge>
  )
}

export const withBadge =
  (DropdownSelectComponent) =>
  ({ stretch, ...props }) => {
    const refBadge = useRef()

    return (
      <DropdownSelectComponent refBadge={refBadge} stretch={stretch} {...props}>
        <BadgeComponent stretch={stretch} />
      </DropdownSelectComponent>
    )
  }
