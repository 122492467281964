import { useEffect, useReducer, useState, useCallback, useRef } from 'react'

// const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms))

const apiReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: action.payload.status === 'error' || false,
        data: action.payload.status === 'error' ? action.payload.message : action.payload.data,
      }
    case 'FETCH_FAILURE': {
      let messages = []
      let statusCode = null
      if (action.payload.response && action.payload.response.data && action.payload.response.data.messages) {
        messages = action.payload.response.data.messages
        statusCode = action.payload.response.status
      }
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: { messages, statusCode },
      }
    }
    default:
      throw new Error()
  }
}

const useApi = (fetcher, initialData) => {
  const [params, setParams] = useState(null)
  const isInit = useRef(true)

  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    isError: false,
    data: initialData,
    // accumulatedData: initialData,
  })

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' })

      try {
        const result = await fetcher(params)
        // await timeout(1000)
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: result.data })
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE', payload: error })
        }
      }
      isInit.current = true
    }

    if (!isInit.current) {
      fetchData()
    }

    return () => {
      didCancel = true
    }
  }, [fetcher, params])

  const doFetch = useCallback((params) => {
    isInit.current = false
    params = typeof params === 'undefined' ? {} : params
    setParams(params)
  }, [])

  return [doFetch, state.data, state.isLoading, state.isError]
}

export default useApi
