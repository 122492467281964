const osFontsSansSerif =
  '-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'

// font families
const fonts = {
  whitney: [`Whitney A, Whitney B, ${osFontsSansSerif}`],
  whitneySSm: [`Whitney SSm A, Whitney SSm B, ${osFontsSansSerif}`],
}

// base font size (px)
export const base = 16

// scaling factor (https://typescale.com/ -> minor second = 1.067)
const scale = 1.067

// bases for calculating responsive font sizes (xl = 16px = 1rem)
const baseScale = [14, 14, 15, base, 17]

const calculate = (i, base, func) => {
  const s = scale

  let result = base

  for (let k = 0; k < i; k++) {
    result = func(result, s)
  }

  return `${(result * 0.0625).toFixed(3)}rem`
}

export const typescale = (i) => {
  const scaleDown = (result, factor) => result / factor
  const scaleUp = (result, factor) => result * factor

  const iterations = i < 0 ? -i : i
  const func = i < 0 ? scaleDown : scaleUp

  return baseScale.map((base) => calculate(iterations, base, func))
}

export const scales = {
  minion: -6,
  pica: -4,
  copy: -3,
  greatPrimer: 0,
  canon: 2,
  royal: 6,
  imperial: 10,
}

// typescales
const minion = typescale(scales.minion)
const pica = typescale(scales.pica)
const copy = typescale(scales.copy)
const greatPrimer = typescale(scales.greatPrimer)
const canon = typescale(scales.canon)
const royal = typescale(scales.royal)
const imperial = typescale(scales.imperial)

export const textTransforms = {
  none: 'none',
  capitalize: 'capitalize',
  uppercase: 'uppercase',
  lowercase: 'lowercase',
}

export { fonts, minion, pica, copy, greatPrimer, canon, royal, imperial }
