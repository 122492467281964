import React from 'react'

export const withButton =
  (DropdownSelectComponent) =>
  ({ children, ...props }) => {
    return (
      <DropdownSelectComponent refBadge={props.refButton} {...props}>
        {children}
      </DropdownSelectComponent>
    )
  }
