import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const RealEstateObjectsIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#EFFC70',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14.5" cy="14.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M26 12.8868V23.5C26 23.8978 25.8336 24.2794 25.5375 24.5607C25.2414 24.842 24.8398 25 24.4211 25H7.57895C7.16018 25 6.75857 24.842 6.46246 24.5607C6.16635 24.2794 6 23.8978 6 23.5V12.8863V11.5295L15.6442 5.1311C15.7414 5.04676 15.8683 5 15.9999 5C16.1315 5 16.2584 5.04676 16.3556 5.1311L26 11.5297V12.8868ZM24.982 12.0001L16 6.17789L7.01789 12.0001H7.05263V23.5C7.05263 23.6326 7.10808 23.7598 7.20679 23.8536C7.30549 23.9473 7.43936 24 7.57895 24H24.4211C24.5606 24 24.6945 23.9473 24.7932 23.8536C24.8919 23.7598 24.9474 23.6326 24.9474 23.5V12.0001H24.982Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
