import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { allowedProps } from './config'
import { bool, func, oneOf, oneOfType, string } from 'prop-types'

const StyledComponent = styled.button.withConfig({
  shouldForwardProp: (prop) => allowedProps.includes(prop),
})`
  cursor: pointer;
  outline: none;
  text-decoration: none;

  &:disabled {
    cursor: not-allowed;
  }
`

export const HTMLButtonElement = forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledComponent className={props.className} ref={ref} {...props}>
      {props.iconAlign === 'left' && children}
      {props.text && props.text}
      {props.iconAlign === 'right' && children}
    </StyledComponent>
  )
})

const withIcon = (Component) => {
  const WithIcon = ({ children, ...props }) => {
    return (
      <Component ref={props.forwardedRef} {...props} text={null} iconAlign="left">
        {children}
      </Component>
    )
  }
  return forwardRef((props, ref) => {
    return <WithIcon {...props} forwardedRef={ref} />
  })
}

const withIconAndText = (Component) => {
  const WithIconAndText = ({ children, ...props }) => {
    return (
      <Component ref={props.forwardedRef} {...props}>
        {children}
      </Component>
    )
  }
  return forwardRef((props, ref) => {
    return (
      <WithIconAndText {...props} forwardedRef={ref}>
        {props.children}
      </WithIconAndText>
    )
  })
}

export const HTMLButtonElementWithIcon = withIcon(HTMLButtonElement)
export const HTMLButtonElementWithIconAndText = withIconAndText(HTMLButtonElement)

HTMLButtonElement.defaultProps = {
  disabled: false,
  text: 'Text',
  type: 'button',
  onClick: null,
}

HTMLButtonElement.propTypes = {
  disabled: bool,
  text: oneOfType([string, bool]),
  onClick: func,
  type: oneOf(['button', 'submit', 'reset']),
}

HTMLButtonElementWithIcon.defaultProps = {
  disabled: false,
  text: null,
  type: 'button',
  onClick: null,
}

HTMLButtonElementWithIcon.propTypes = {
  disabled: bool,
  onClick: func,

  type: oneOf(['button', 'submit', 'reset']),
}
