import React from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { FlexRow } from '@layout/BuildingBlocks'

import { useControlsSegmentContext } from '@ui/ControlsSegment'

const StyledComponent = styled(FlexRow)`
  align-items: center;
  margin-bottom: ${(props) =>
    props.segmentsAsGroup ? 0 : themeGet('controlsSegment.headline.spaceBottom', 0)};

  text-transform: ${themeGet('controlsSegment.headline.textTransform')};

  padding-bottom: ${(props) =>
    props.segmentsAsGroup
      ? !props.folded
        ? themeGet('controlsSegment.segmentsAsGroup.header.spaceBottom.unfolded', 0)(props)
        : themeGet('controlsSegment.segmentsAsGroup.header.spaceBottom.folded', 0)(props)
      : themeGet('controlsSegment.header.spaceBottom', 0)(props)};
`

export const ControlsSegmentHeader = ({ children, justifyContent, segmentsAsGroup }) => {
  const { folded, foldDisabled } = useControlsSegmentContext()

  return (
    <StyledComponent
      folded={folded}
      foldDisabled={foldDisabled}
      justifyContent={justifyContent}
      segmentsAsGroup={segmentsAsGroup}
    >
      {children}
    </StyledComponent>
  )
}
