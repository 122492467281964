export const getDatepicker = ({ colors }) => ({
  day: {
    default: {
      bg: colors.lightgray[100],
      color: colors.gray[900],
    },
    weekend: {
      bg: colors.lightgray[300],
      color: colors.gray[900],
    },
    today: {
      bg: colors.lightgray[500],
      color: colors.gray[1000],
    },
    hover: {
      bg: colors.lightgray[700],
      color: colors.gray[1000],
    },
    cursor: {
      bg: colors.lightgray[700],
      color: colors.gray[1000],
    },
    selected: {
      bg: colors.lightgray[500],
      color: colors.gray[1000],
      fontWeight: 600,
    },
    otherMonth: {
      opacity: 0.5,
    },
  },
})
