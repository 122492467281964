export const getCard = ({ colors, themeColors, radii, spaces }) => ({
  border: {
    width: '2px',
    color: colors.black,
  },
  colors: {
    header: {
      public: {
        color: colors.black,
      },
    },
    body: {
      public: {
        color: colors.black,
      },
    },
    footer: {
      public: {
        bg: colors.black,
      },
    },
  },
})
