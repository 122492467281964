import React, { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { getThemeValue } from 'theming'
import { BudiconEmail } from 'bgag-budicons'

import { Form, Input } from '@forms/index'
import { FlexColumn, FlexItem } from '@layout/BuildingBlocks'
import { Button } from '@ui/Buttons'

import api from 'stores/api'
import useApi from 'stores/useApi'

import { CopyMedium, MinionLight } from 'stories/typography'

export const ForgotPasswordForm = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')
  const colors = getThemeValue(theme, 'colors')

  const [sendMail, mailSent, isSendingMail, failedSendingMail] = useApi(api.Auth.sendForgotPasswordMail, {})

  const [email, setEmail] = useState('')

  const onSubmit = () => {
    sendMail({ email })
  }

  const invalidEmail = useMemo(() => {
    if (
      failedSendingMail &&
      Array.isArray(mailSent?.messages) &&
      mailSent.messages?.find((msg) => msg.msg === 'invalid')
    ) {
      return true
    }
    return false
  }, [mailSent, failedSendingMail])

  // sent success -> Text
  if (mailSent?.success === true) {
    return <CopyMedium dangerouslySetInnerHTML={{ __html: t('forgotPassword.success') }} />
  }

  return (
    <>
      <FlexColumn mb={spaces.rhythm.vertical.medium}>
        <CopyMedium lineHeight="1.66">{t('forgotPassword.intro')}</CopyMedium>
      </FlexColumn>
      {failedSendingMail && !invalidEmail && (
        <FlexColumn>
          <CopyMedium color={colors.base.error.default} tag="p">
            {t('forgotPassword.failedSendingMail')}
          </CopyMedium>
        </FlexColumn>
      )}
      <Form onSubmit={onSubmit}>
        <FlexColumn flexRowGap={spaces.rhythm.vertical.medium}>
          <FlexItem>
            <Input
              appearance="white"
              value={email}
              placeholder={t('forgotPassword.email')}
              iconLeft={{ Icon: BudiconEmail }}
              autoFocus={true}
              onChange={(target) => setEmail(target.value)}
              resetable={true}
              autoComplete="off"
            />
          </FlexItem>

          {invalidEmail && (
            <MinionLight tag="span" mt="8px" color={colors.base.error.default}>
              {t('forgotPassword.invalidEmail')}
            </MinionLight>
          )}
        </FlexColumn>
        <FlexColumn
          flexRowGap={spaces.rhythm.vertical.large}
          mt={spaces.rhythm.vertical.large}
          alignItems="center"
        >
          <FlexItem width="100%">
            <Button
              type="submit"
              appearance="primaryShallow"
              shape="square"
              isLoading={isSendingMail}
              stretch={true}
              text={t('forgotPassword.submit')}
            />
          </FlexItem>
        </FlexColumn>
      </Form>
    </>
  )
}
