import React from 'react'

import { useMergeDefaultProps, useArrayValueByBreakpoint } from 'lib/hooks'
import { defaultPropsIcon } from '../defaultProps'

export const IconComponent = ({ icon }) => {
  const { Icon, fill, height, stroke, strokeWidth, type, width } = useMergeDefaultProps(
    { icon },
    defaultPropsIcon
  )

  const widthValue = useArrayValueByBreakpoint(width)

  return (
    <Icon
      fill={fill}
      height={height}
      stroke={stroke}
      strokeWidth={strokeWidth}
      type={type}
      width={widthValue}
    />
  )
}
