import { useRef, useCallback, useMemo, useEffect } from 'react'

import { isTableScroll, getScrollParents } from 'lib/util/getScrollParents'

const getStyle = (node, prop) => getComputedStyle(node, null)?.getPropertyValue(prop)

const passiveListener = { passive: true }
const defaultClientRect = {
  top: null,
  left: null,
  bottom: null,
  right: null,
  x: null,
  y: null,
}

const containerContains = (containerClientRect, elementClientRect) => {
  const {
    left: leftView = 0,
    right: rightView = 0,
    top: topView = 0,
    bottom: bottomView = 0,
  } = containerClientRect
  const { left, right, top, bottom } = elementClientRect

  return (
    ((left >= leftView && left < rightView) || (right > leftView && right <= rightView)) &&
    ((top >= topView && top < bottomView) || (bottom > topView && bottom <= bottomView))
  )
}

const getInView = (elementClientRect, container) => {
  const rootContainer = container?._container ? container?._container : container
  const scrollParents = [rootContainer].concat(getScrollParents(rootContainer))
  return scrollParents.every((container) =>
    containerContains(container?.getBoundingClientRect?.() ?? {}, elementClientRect)
  )
}

export const usePositionObserver = ({ elementRef, onChange, active, leafletMap }) => {
  const lastTableTransform = useRef()
  const currentClientRect = useRef(defaultClientRect)

  const setNextClientRect = useCallback(
    (evt) => {
      if (elementRef.current) {
        const nextClientRect = elementRef.current.getBoundingClientRect()
        if (
          ['top', 'left', 'bottom', 'right'].some(
            (key) => nextClientRect[key] !== currentClientRect.current[key]
          ) ||
          evt.type === 'init' ||
          evt.type === 'windowResize'
        ) {
          currentClientRect.current = nextClientRect
          const inView = getInView(nextClientRect, evt.target)
          onChange(nextClientRect, inView)
        }
      }
    },
    [elementRef, onChange]
  )

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((targets) => {
        const scrollParents = getScrollParents(targets[0].target)
        setNextClientRect({ type: 'resizeObserver', target: scrollParents[0] })
      }),
    [setNextClientRect]
  )

  const mutationObserver = useMemo(() => {
    const callback = (mutationList, observer) => {
      if (elementRef.current !== null && mutationList?.[0]?.target) {
        const transform = getStyle(mutationList[0].target, 'transform')
        if (transform !== lastTableTransform.current) {
          lastTableTransform.current = transform
          setNextClientRect({ type: 'transformMutation', target: mutationList?.[0]?.target?.parentNode })
        }
      }
    }
    return new MutationObserver(callback)
  }, [elementRef, setNextClientRect])

  useEffect(() => {
    if (active) {
      resizeObserver.observe(elementRef.current)

      const scrollParents = getScrollParents(elementRef.current)
      let firstParent = null
      scrollParents.forEach((container) => {
        if (isTableScroll(container)) {
          mutationObserver.observe(container.querySelector(':scope > div[class*="wheel-area"]'), {
            attributes: true,
          })
          if (firstParent === null) {
            firstParent = container.parentNode
          }
        } else {
          container.addEventListener('scroll', setNextClientRect, passiveListener)
          if (firstParent === null) {
            firstParent = container
          }
        }
      })

      const onResize = () => {
        setNextClientRect({ type: 'windowResize', target: scrollParents[0] })
      }

      window.addEventListener('resize', onResize)

      if (leafletMap) {
        leafletMap.on('move', setNextClientRect)
      }

      setNextClientRect({ type: 'init', target: firstParent })

      return () => {
        resizeObserver.disconnect()
        let disconnectMutationObserver = false
        scrollParents.forEach((container) => {
          if (isTableScroll(container)) {
            disconnectMutationObserver = true
          } else {
            container.removeEventListener('scroll', setNextClientRect, passiveListener)
          }
        })
        if (disconnectMutationObserver) {
          mutationObserver.disconnect()
        }
        window.removeEventListener('resize', onResize)
        if (leafletMap) {
          leafletMap.off('move', setNextClientRect)
        }
      }
    }
  }, [elementRef, resizeObserver, mutationObserver, setNextClientRect, active, leafletMap])
}
