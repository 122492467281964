export const allowedProps = [
  'children',
  'href',
  'hreflang',
  'id',
  'name',
  'onClick',
  'onMouseOut',
  'onMouseOver',
  'onMouseLeave',
  'onFocus',
  'onBlur',
  'rel',
  'target',
  'type',
]
