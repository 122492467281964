const productGroupProperties = {
  '2,1': { color: '#005B9A', unselectedColor: '#E5F4FF', name: 'Modischer Bedarf' },
  '2,5': { color: '#774D8B', unselectedColor: '#F3EFF6', name: 'Sonstige Hartwaren' },
  '2,2': { color: '#29AC9A', unselectedColor: '#EAFAF8', name: 'Elektro / Technik' },
  4: { color: '#F8E558', unselectedColor: '#FEFBE7', name: 'Gastronomie' },
  '1,1': { color: '#256BB8', unselectedColor: '#EAF2FB', name: 'Nahrungs- und Genussmittel' },
  '1,2': { color: '#B12016', unselectedColor: '#FCEAE8', name: 'Drogerie/Gesundheit' },
  3: { color: '#A9CA5C', unselectedColor: '#F5F9EC', name: 'Einzelhandelsnahe Dienstleister' },
  '1,3': { color: '#E7755D', unselectedColor: '#FCECE9', name: 'Apotheke' },
  '2,3': { color: '#6A902C', unselectedColor: '#F4F9EB', name: 'DIY / Garten / Freizeit' },
  '1,4': { color: '#9C77AD', unselectedColor: '#F4EFF5', name: 'Sonstiger Periodischer Bedarf' },
  '2,4': { color: '#2C8CBE', unselectedColor: '#EAF5FA', name: 'Einrichtungsbedarf' },
  0: { color: '#52B6DE', unselectedColor: '#E9F6FB', name: 'Betriebe ohne Sortimentsschwerpunkt' },
  9: { color: '#B0B2B1', unselectedColor: '#F2F3F2', name: 'Sonstige Flächen' },
  5: { color: '#7c7e7d', unselectedColor: '#F2F2F2', name: 'Freizeit / Sonstiges' },
  6: { color: 'red', unselectedColor: 'black', name: 'Separate Lagerflächen / Nebenräume' },
  7: { color: '#00a294', unselectedColor: '#00a294', name: 'Leerstand' },
  8: {
    color: 'red',
    unselectedColor: 'black',
    name: 'Vermietete Werbeflächen, z. B. Fassadenwerbung, Leuchtreklame, Vitrinen in der Mall',
  },
  10: { color: '#e02054', unselectedColor: '#e02054', name: 'Neuvermietung' },
}

export const ignoreTogs = ['5', '9']

export const retailScoreColors = {
  steady: '#1487C0', // Stabilen
  risingStars: '#DB4935', // Rising Stars
  topPerformer: '#2BA18F', // Top Performer
  underestimated: '#774D8B', // die unterschätzten
  smallTreasures: '#F59540', // kleine Schätze
}

const shopLocationProperties = {
  '1a-lage': {
    color: '#005B9A',
    name: 'helpers.oneALocation',
    order: 1,
    key: 'oneALocation',
  },
  sc1a: { color: '#B12016', name: 'helpers.shoppingCenter', order: 2, key: 'sc1a' },
  scis: { color: '#B12016', name: 'helpers.shoppingCenter', order: 3, key: 'sc1a' },
}

const companyTypeProperties = {
  'internationaler filialist': {
    color: '#005B9A',
    order: 1,
    key: 'international',
    name: 'helpers.internationalChain',
  },
  'nationaler filialist': {
    color: '#1487C0',
    order: 2,
    key: 'national',
    name: 'helpers.nationalChain',
  },
  übrige: { color: '#B12016', order: 3, key: 'rest', name: 'helpers.rest' },
}

export const getShopLocationProperties = (id) => {
  const property = shopLocationProperties[id.toLowerCase()]
  if (!property) {
    console.warn('No location property found for', id)
    return { color: '#000', order: 4, key: 'error', name: 'bad ids' }
  }
  return property
}

export const getCompanyTypeProperties = (id) => {
  const property = companyTypeProperties[id.toLowerCase()]
  if (!property) {
    console.warn('No company type property found for', id)
    return { color: '#000', order: 4, key: 'error', name: 'bad ids' }
  }
  return property
}

export const getProductGroupProperty = (id, shop) => {
  const property = productGroupProperties[id]
  if (!property) {
    console.warn('No product group property found for', id, shop)
    return { color: '#000', order: 4, key: 'error', name: 'bad ids' }
  }
  return property
}

export const getRetailScoreClassification = (year) => {
  if (year === 2020) {
    return {
      topPerformer: '&ge;70,0',
      risingStars: '60,0-69,9',
      steady: '50,0-59,9',
      underestimated: '45,0-49,9',
      smallTreasures: '&lt;45,0',
    }
  }
  return {
    topPerformer: '&ge;80,0',
    risingStars: '70,0-79,9',
    steady: '60,0-69,9',
    underestimated: '55,0-59,9',
    smallTreasures: '&lt;55,0',
  }
}

export const getRetailScoreProperties = (year, score, key = null) => {
  if (year === 2020) {
    switch (true) {
      case key === 'smallTreasures' || (score > -1 && score < 45):
        return {
          color: retailScoreColors.smallTreasures,
          radius: score / 5,
          name: 'helpers.smallTreasures',
          key: 'smallTreasures',
          order: 5,
        }
      case key === 'underestimated' || (score >= 45 && score < 50):
        return {
          color: retailScoreColors.underestimated,
          radius: score / 5,
          name: 'helpers.underestimated',
          key: 'underestimated',
          order: 4,
        }
      case key === 'steady' || (score >= 50 && score < 60):
        return {
          color: retailScoreColors.steady,
          radius: score / 5,
          name: 'helpers.steady',
          key: 'steady',
          order: 3,
        }
      case key === 'risingStars' || (score >= 60 && score < 70):
        return {
          color: retailScoreColors.risingStars,
          radius: score / 5,
          name: 'helpers.risingStars',
          key: 'risingStars',
          order: 2,
        }
      case key === 'topPerformer' || score >= 70:
        return {
          color: retailScoreColors.topPerformer,
          radius: score / 5,
          name: 'helpers.topPerformer',
          key: 'topPerformer',
          order: 1,
        }
      default:
        return { color: '#000', radius: 1 }
    }
  } else {
    switch (true) {
      case key === 'smallTreasures' || (score > -1 && score < 55):
        return {
          color: retailScoreColors.smallTreasures,
          radius: score / 5,
          name: 'helpers.smallTreasures',
          key: 'smallTreasures',
          order: 5,
        }
      case key === 'underestimated' || (score >= 55 && score < 60):
        return {
          color: retailScoreColors.underestimated,
          radius: score / 5,
          name: 'helpers.underestimated',
          key: 'underestimated',
          order: 4,
        }
      case key === 'steady' || (score >= 60 && score < 70):
        return {
          color: retailScoreColors.steady,
          radius: score / 5,
          name: 'helpers.steady',
          key: 'steady',
          order: 3,
        }
      case key === 'risingStars' || (score >= 70 && score < 80):
        return {
          color: retailScoreColors.risingStars,
          radius: score / 5,
          name: 'helpers.risingStars',
          key: 'risingStars',
          order: 2,
        }
      case key === 'topPerformer' || score >= 80:
        return {
          color: retailScoreColors.topPerformer,
          radius: score / 5,
          name: 'helpers.topPerformer',
          key: 'topPerformer',
          order: 1,
        }
      default:
        return { color: '#000', radius: 1 }
    }
  }
}

export const getMarkerProperties = (feature, max, year) => {
  const properties = getRetailScoreProperties(year, feature.properties.retailScore)
  const radius = calculateRadius(max, feature.properties.shopCount)
  return {
    radius: radius,
    color: properties.color,
    opacity: 1,
    weight: 1,
    fillOpacity: 0.5,
  }
}

const calculateRadius = (max, currentCount) => {
  const absoluteMax = 1111
  const multiplier = absoluteMax / max
  let radius = Math.ceil(((multiplier * currentCount) / absoluteMax) * 40)
  if (radius < 3) radius = 2
  return radius
}

export const numberSeparator = (x, decimalPlaces = 0) => {
  let string = x.toFixed(decimalPlaces).toString()
  const [preDecimal, decimal] = string.split('.')
  return `${preDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}${decimal ? ',' + decimal : ''}`
}
