import React from 'react'

import { useTranslation } from 'react-i18next'

import { FlexColumn, FlexItem, FlexRow } from '@layout/BuildingBlocks'
import { LogoBulwiengesa, LogoRiwisText } from '@utilities/SVG/Logos/'

import { CopyBold } from 'stories/typography'

import luehrmannLogoWhiteUrl from 'assets/images/logos/luehrmann-logo-white.svg'
import { getThemeValue } from 'theming'
import { useTheme } from 'styled-components'

export const L3PlusFooter = () => {
  const { t } = useTranslation(['l3plus'])
  const theme = useTheme()
  return (
    <FlexColumn
      justifyContent="center"
      width="100%"
      alignItems="center"
      my={getThemeValue(theme, 'spaces.rhythm.vertical.extraLarge')}
    >
      <FlexItem textAlign="center" mb={getThemeValue(theme, 'spaces.rhythm.vertical.large')}>
        <CopyBold color="white">{t('footer.cooperation')}</CopyBold>
      </FlexItem>
      <FlexItem width="100%">
        <FlexRow
          width="100%"
          justifyContent="space-around"
          flexRowGap={getThemeValue(theme, 'spaces.rhythm.horizontal.large')}
        >
          <LogoBulwiengesa part="all" color="white" size="20px" />
          <img alt="Lührmann" src={luehrmannLogoWhiteUrl} height="20px" style={{ fill: 'white' }} />
          <LogoRiwisText part="all" color="white" size="20px" />
        </FlexRow>
      </FlexItem>
    </FlexColumn>
  )
}
