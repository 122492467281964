const regex = /(auto|scroll)/
const getStyle = (node, prop) => getComputedStyle(node, null)?.getPropertyValue(prop)
const overflow = (node) =>
  getStyle(node, 'overflow') + getStyle(node, 'overflow-y') + getStyle(node, 'overflow-x')

const isScroll = (node) => regex.test(overflow(node))

const getParents = (node, parents) => {
  if (!node?.parentNode) {
    return parents
  }
  return getParents(node?.parentNode, parents.concat([node]))
}

export const isTableScroll = (node) => node?.className && node.className?.includes?.('body-row-wrapper')

export const getScrollParents = (node) => {
  if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
    return
  }

  const scrollParents = []

  const parents = getParents(node.parentNode, [])

  parents.forEach((node) => {
    if (isScroll(node) || isTableScroll(node)) {
      scrollParents.push(node)
    }
  })

  scrollParents.push(document.scrollingElement || document.documentElement)

  return scrollParents
}
