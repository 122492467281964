export const allowedProps = [
  'tabIndex',
  'autofocus',
  'children',
  'disabled',
  'form',
  'formAction',
  'formEnctype',
  'formMethod',
  'formNoValidate',
  'formTarget',
  'name',
  'id',
  'onBlur',
  'onClick',
  'onFocus',
  'onMouseLeave',
  'onMouseOut',
  'onMouseOver',
  'target',
  'to',
  'type',
  'value',
  'title',
]
