import React, { useState, useCallback, createContext, useContext } from 'react'
import styled from 'styled-components'
import { space, layout } from 'styled-system'

import { isFunction, isUndefined } from 'lib/util'

export const formContext = createContext(null)
export const useForm = () => useContext(formContext)

const StyledForm = styled('form')`
  ${layout}
  ${space}
`

const FormComponent = ({ onSubmit, width = '100%', height = '100%', children, ...props }) => {
  const context = useForm()
  const [elements, setElements] = useState({})

  const hdlOnSubmit = useCallback(
    (evt) => {
      evt.preventDefault()
      evt.stopPropagation()
      const value = Object.entries(elements).reduce((value, [name, element]) => {
        value[name] = element.value.current
        return value
      }, {})
      isFunction(onSubmit) && onSubmit(value)
    },
    [onSubmit, elements]
  )

  const hdlOnReset = useCallback(
    (evt) => {
      evt.preventDefault()
      evt.stopPropagation()
      Object.entries(elements).forEach(([name, element]) => {
        isFunction(element.reset.current) && element.reset.current()
      })
    },
    [elements]
  )

  const addElement = useCallback((name, element) => {
    setElements((prev) => {
      if (isUndefined(prev[name])) {
        return { ...prev, [name]: element }
      }
      return prev
    })
  }, [])

  if (context.addElement === null) {
    context.addElement = addElement
  }

  return (
    <StyledForm width={width} height={height} onSubmit={hdlOnSubmit} onReset={hdlOnReset} {...props}>
      {children}
    </StyledForm>
  )
}
FormComponent.type = 'Form'

export const Form = ({ children, ...props }) => {
  return (
    <formContext.Provider
      value={{
        addElement: null,
        reset: null,
      }}
    >
      <FormComponent {...props}>{children}</FormComponent>
    </formContext.Provider>
  )
}
