import { variant } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const appearanceVariants = (props) =>
  variant({
    prop: 'appearance',
    variants: {
      gray: {
        '.text': {
          pointerEvents: 'none',
          color: themeGet('colors.theme.buttonCapital.gray.default.fg', 'black')(props),
          transition: 'all .2s ease-out',
        },

        '&:hover .text': {
          color: themeGet('colors.theme.buttonCapital.gray.hover.fg', 'lightgray')(props),
          transition: 'all .15s ease-in',
        },

        '.disk': {
          border: '1px solid',
          backgroundColor: props.checked
            ? themeGet('colors.theme.buttonCapital.gray.active.bg', 'lightgray')(props)
            : themeGet('colors.theme.buttonCapital.gray.default.bg', 'lightgray')(props),
          borderColor: props.checked
            ? themeGet('colors.theme.buttonCapital.gray.active.bg', 'lightgray')(props)
            : themeGet('colors.theme.buttonCapital.gray.default.bg', 'lightgray')(props),
          transition: 'all .2s ease-out',
        },

        '&:hover .disk': {
          backgroundColor: props.checked
            ? themeGet('colors.theme.buttonCapital.gray.active.bg', 'lightgray')(props)
            : themeGet('colors.theme.buttonCapital.gray.hover.bg', 'lightgray')(props),
          borderColor: props.checked
            ? themeGet('colors.theme.buttonCapital.gray.active.bg', 'lightgray')(props)
            : themeGet('colors.theme.buttonCapital.gray.hover.bg', 'lightgray')(props),
          transition: 'all .15s ease-in',
        },
      },
      ghost: {
        '.text': {
          pointerEvents: 'none',
          color: props.checked
            ? themeGet('colors.theme.buttonCapital.ghost.active.fg', 'black')(props)
            : themeGet('colors.theme.buttonCapital.ghost.default.fg', 'black')(props),
          transition: 'all .2s ease-out',
        },

        '&:hover .text': {
          color: props.checked
            ? themeGet('colors.theme.buttonCapital.ghost.active.fg', 'black')(props)
            : themeGet('colors.theme.buttonCapital.ghost.hover.fg', 'lightgray')(props),
          transition: 'all .15s ease-in',
        },

        '.disk': {
          border: '1px solid',
          backgroundColor: props.checked
            ? themeGet('colors.theme.buttonCapital.ghost.active.bg', 'lightgray')(props)
            : themeGet('colors.theme.buttonCapital.ghost.default.bg', 'lightgray')(props),
          transition: 'all .2s ease-out',
        },

        '&:hover .disk': {
          backgroundColor: props.checked
            ? themeGet('colors.theme.buttonCapital.ghost.active.bg', 'lightgray')(props)
            : themeGet('colors.theme.buttonCapital.ghost.hover.bg', 'lightgray')(props),
          transition: 'all .15s ease-in',
        },
      },
    },
  })

export const appearanceVariantsDisk = (props) =>
  variant({
    prop: 'appearance',
    variants: {
      gray: {},
      ghost: {},
    },
  })

export const sizeVariants = () =>
  variant({
    prop: 'size',
    variants: {
      small: {
        '.disk': {
          aspectRatio: '1',
          height: '2.3em',
        },
      },
      default: {
        '.disk': {
          aspectRatio: '1',
          height: '2.5em',
        },
      },
      large: {
        '.disk': {
          aspectRatio: '1',
          height: '2.5em',
        },
      },
    },
  })
