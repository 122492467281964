import { bool, number, string, array, oneOfType } from 'prop-types'

const bordered = bool
const headerHeight = oneOfType([number, array])
const isTree = bool
const minHeight = number
const rowHeight = oneOfType([number, array])
const rowKey = string
const scrollTo = number
const scrollToPx = number

export { bordered, headerHeight, isTree, minHeight, rowHeight, rowKey, scrollTo, scrollToPx }
