export const buildSelectedDate = (isoString) => {
  const selectedDate = new Date()
  if (typeof isoString !== 'string' || isoString.length === 0) {
    return selectedDate
  } else {
    let [, year, month, day] = isoString.match(/([0-9]*)[^0-9]?([0-9]*)[^0-9]?([0-9]*)/)
    day = parseInt(day)
    if (day > 0 && day < 32) {
      selectedDate.setDate(day)
    }
    month = parseInt(month)
    if (month > 0 && month < 13) {
      selectedDate.setMonth(month - 1)
    }
    year = parseInt(year)
    if (year > 0) {
      selectedDate.setFullYear(year)
    }
  }
  return selectedDate
}

export const dateToValue = (inputDate) => {
  const date = inputDate !== null ? new Date(inputDate) : null
  if (date !== null && !isNaN(date?.getTime())) {
    return date.toISOString().substring(0, 10)
  } else {
    return null
  }
}

const getLastDayOfPrevMonth = (date) => {
  const tmp = new Date(date)
  tmp.setDate(0)
  return tmp.getDate()
}
const getLastDayOfCurMonth = (date) => {
  const tmp = new Date(date)
  tmp.setMonth(tmp.getMonth() + 1)
  tmp.setDate(0)
  return tmp.getDate()
}
export const getDayOfWeekOfFirstDay = (date) => {
  const tmp = new Date(date)
  tmp.setDate(1)
  // (day + 6) % 7 since we use monday as first day of the week instead of sunday
  return (tmp.getDay() + 6) % 7
}

export const getOptions = (displayDate, selectedDate) => {
  const curMonth = displayDate.getMonth()
  const curYear = displayDate.getFullYear()
  const selectedDateKey = selectedDate !== null ? selectedDate.toDateString() : null

  const dateToOption = (year, month, day, monthOffset) => {
    const date = new Date(
      year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0')
    )
    const dateKey = date.toDateString()
    const dayOfWeek = date.getDay()

    let type = 'default'
    if (dateKey === selectedDateKey) {
      type = 'selected'
    } else if ([0, 6].includes(dayOfWeek)) {
      type = 'weekend'
    }

    return {
      label: `${date.getDate()}`,
      value: date,
      key: dateKey,
      type,
      monthOffset,
    }
  }

  const dayOfWeekFirstDayOfMonth = getDayOfWeekOfFirstDay(displayDate)
  const lastDayPrevMonth = getLastDayOfPrevMonth(displayDate)
  const lastDayCurMonth = getLastDayOfCurMonth(displayDate)

  const options = []
  for (let i = 0; i < dayOfWeekFirstDayOfMonth; i++) {
    options.unshift(
      dateToOption(
        curMonth === 0 ? curYear - 1 : curYear,
        curMonth === 0 ? 12 : curMonth,
        lastDayPrevMonth - i,
        -1
      )
    )
  }
  for (let i = 0; i < lastDayCurMonth; i++) {
    options.push(dateToOption(curYear, curMonth + 1, i + 1, 0))
  }

  const missingDays = options.length % 7 > 0 ? 7 - (options.length % 7) : 0
  for (let i = 0; i < missingDays; i++) {
    options.push(
      dateToOption(curMonth === 11 ? curYear + 1 : curYear, curMonth === 11 ? 1 : curMonth + 2, i + 1, 1)
    )
  }

  return options
}
