import React, { createContext, useContext, useReducer } from 'react'

const ModalContext = createContext()

const initialState = {
  activeModal: null,
  closeModal: null,
  activeView: 0,
  history: [],
  size: null,
  initialAnimationRunning: true,
  isAnimationRunning: false,
  isContentShown: false,
}

function reducer(state, action) {
  // console.log(action)

  switch (action.type) {
    case 'setActiveModal': {
      const { name, isActive } = action.payload

      if ((name === state.activeModal && isActive) || (name !== state.activeModal && !isActive)) {
        return state
      }

      if (isActive) {
        return {
          ...state,
          closeModal: null,
          history: [...state.history, name + '::view0'],
          activeModal: name,
          activeView: 0,
          isContentShown: false,
        }
      } else {
        const history = state.history.filter((historyName) => !historyName.startsWith(name + '::view'))
        const [activeModal, activeView] = history[history.length - 1]?.split?.('::view') ?? [null, 0]
        return {
          ...state,
          closeModal: null,
          history,
          activeModal,
          activeView: parseInt(activeView),
          size: activeModal !== null ? state.size : null,
          initialAnimationRunning: activeModal === null ? true : state.initialAnimationRunning,
          isContentShown: false,
        }
      }
    }

    case 'setActiveView': {
      if (action.payload === state.activeView) {
        return state
      }
      let history = [...state.history]
      history.push(state.activeModal + '::view' + action.payload)
      return {
        ...state,
        activeView: action.payload,
        isContentShown: false,
        history,
      }
    }

    case 'setCloseActiveModal': {
      return {
        ...state,
        closeModal: state.activeModal,
      }
    }

    case 'setInitialAnimationRunning':
      if (state.initialAnimationRunning === action.payload || state.activeModal === null) {
        return state
      }
      return {
        ...state,
        initialAnimationRunning: action.payload,
      }

    case 'setIsAnimationRunning':
      if (state.isAnimationRunning === action.payload) {
        return state
      }
      return {
        ...state,
        isAnimationRunning: action.payload,
      }

    case 'setIsContentShown':
      if (state.isContentShown === action.payload) {
        return state
      }
      return {
        ...state,
        isContentShown: action.payload,
      }

    case 'setSize': {
      if (state.size === action.payload) {
        return state
      }
      return {
        ...state,
        size: action.payload,
      }
    }

    default: {
      console.log('action.type', action.type, 'not found', action.payload)
      return state
    }
  }
}

const ModalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <ModalContext.Provider value={{ state, dispatch }}>{children}</ModalContext.Provider>
}

const useModalContext = () => {
  const context = useContext(ModalContext)

  if (!context) throw new Error('Error in creating the context')

  return context
}

export { useModalContext, ModalContextProvider }
