import { useCallback } from 'react'
import { useTheme } from 'styled-components'

import themeRiwis from 'theming/themes/riwis'
import themeClassic from 'theming/themes/classic'
import themeDark from 'theming/themes/dark'
import themeL3Plus from './themes/l3plus'

export const themes = {
  Riwis: themeRiwis,
  Classic: themeClassic,
  Dark: themeDark,
  L3Plus: themeL3Plus,
}

export const getThemeValue = (theme, objectPath) => {
  const tokens = objectPath.split('.')
  return tokens.reduce((acc, token) => acc[token], theme)
}

export const getTheme = (themeName) => {
  return themes[themeName]
}

/**
 * Simplify usage of theme values:
 *
 * Before:
 *     import { useTheme } from 'styled-components'
 *     import { getThemeValue } from 'theming'
 *
 *     // in component (at top):
 *     const theme = useTheme()
 *     // get actual value (e.g. in JSX)
 *     getThemeValue(theme, "path")
 *
 * After (unconditional statements):
 *
 *     import { useThemeValue } from 'theming'
 *
 *     // in component: get actual value
 *     useThemeValue("path")
 *
 * After (conditional statements):
 *
 *     import { useThemeValue } from 'theming'
 *
 *     // in component (at top):
 *     const getThemeValue = useThemeValue()
 *     // get actual value (e.g. in JSX)
 *     getThemeValue("path")
 */
export const useThemeValue = (objectPath, defaultValue) => {
  const theme = useTheme()

  const boundGetThemeValue = useCallback((...args) => getThemeValue(theme, ...args), [theme])

  if (objectPath) {
    return boundGetThemeValue(objectPath, defaultValue)
  }

  return boundGetThemeValue
}
