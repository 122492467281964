export const defaultProps = {
  appearance: 'default',
  checkmarkWidth: ['12px', '12px', '12px', '12px', '14px'],
  checkmarkHeight: ['18px', '18px', '18px', '18px', '20px'],
  checkmarkFill: 'colors.base.gray.900',
  checkmarkStroke: 'colors.base.white',
  checkmarkStrokeWidth: '0',
  height: ['18px', '18px', '18px', '18px', '20px'],
  width: ['18px', '18px', '18px', '18px', '20px'],
  checked: false,
  disabled: false,
}
