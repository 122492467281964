export const getSwitches = ({ themeColors, radii, spaces }) => ({
  radius: {
    default: radii.medium,
  },

  padding: {
    container: spaces.switchIcon,
  },

  light: {
    default: {
      bg: themeColors.light.unchecked.bg,
      color: themeColors.light.unchecked.color,
    },
    disabled: {
      bg: themeColors.light.disabled.bg,
      color: themeColors.light.disabled.color,
    },
    checked: {
      bg: themeColors.light.checked.bg,
      color: themeColors.light.checked.color,
    },
  },
})
