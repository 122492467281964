export const getLandingPage = ({ colors, spaces }) => {
  return {
    background: {
      gradient: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))',
    },
    colors: {
      bgTopRight: colors.white,
      bgBottomRight: colors.lightgray[230],
    },
    grid: {
      columns: [1, 1, null, 2],
      rows: [4, 4, null, 2],
      areas: [
        '"topLeft" "bottomLeft" "topRight" "bottomRight"',
        null,
        null,
        '"topLeft topRight" "bottomLeft bottomRight"',
      ],
      columnGap: 0,
      rowGap: 0,
    },
    spaces: {
      py: ['34px', '55px'],
      right: {
        paddingLeft: ['35px', '55px', null, '55px'],
        paddingRight: ['35px', '55px', null, '25px'],
        rowGap: '3rem',
      },
      left: {
        paddingLeft: ['35px', '55px', null, '40px', '40px'],
        paddingRight: ['35px', '55px', null, '60px', '80px'],
        rowGap: [spaces.rhythm.vertical.medium, null, spaces.rhythm.vertical.medium, '3rem'],
      },
    },
    textSection: {
      headline: {
        color: colors.brand.primary.default,
        mb: spaces.rhythm.vertical.large,
      },
    },
    card: {
      padding: ['2rem', '2rem 3.5rem 2rem 2rem'],
      iconSlotSize: ['30px', '40px'],
      rowGap: [spaces.rhythm.vertical.medium, spaces.rhythm.vertical.medium],
      contentRowGap: [spaces.rhythm.vertical.medium, null, null, spaces.rhythm.vertical.large],
      columnGap: ['1rem', '2rem'],
      headline: {
        color: colors.brand.primary.default,
        mb: spaces.rhythm.vertical.large,
      },
    },
  }
}
