import stringify from 'json-stable-stringify'

import { createDataCache } from 'lib/cache'

import api from 'stores/api'

import { hashCode } from 'lib/hashCode'

const conditionals = [
  { name: 'gacs', stringify: (gacs) => hashCode(stringify(gacs)).toString() },
  { name: 'language' },
]

const getter = ({ gacs, textTags }, language) => {
  return api.Text.getMarketText({
    textTags,
    gacs,
  }).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }

    const texts = Object.entries(result.data.data).reduce((texts, [gac, langs]) => {
      texts[gac] = langs[language]
      return texts
    }, {})

    return texts
  })
}

export const [getTexts, bustTexts] = createDataCache(conditionals, getter, {
  name: 'MarketTextCache',
})
