export const companyRoles = [
  { label: 'editUser.companyRoles.transactionManager', value: 'transactionManager' },
  { label: 'editUser.companyRoles.researcher', value: 'researcher' },
  { label: 'editUser.companyRoles.assetManager', value: 'assetManager' },
  { label: 'editUser.companyRoles.controller', value: 'controller' },
  { label: 'editUser.companyRoles.evaluator', value: 'evaluator' },
  { label: 'editUser.companyRoles.journalist', value: 'journalist' },
  { label: 'editUser.companyRoles.student', value: 'student' },
  { label: 'editUser.companyRoles.private', value: 'private' },
  { label: 'editUser.companyRoles.other', value: 'other' },
]
