import React, { memo } from 'react'
import styled from 'styled-components'

import { FlexItem } from '@layout/BuildingBlocks'
import { CardTitle, CardTitleLarge, CardTitleButtonContainer } from '../../SubComponents'

const StyledComponent = styled(FlexItem)`
  align-items: flex-start;
  height: 100%;
  flex: ${(props) => props.flex};
`

const Text = memo(({ type, color, children }) => {
  return type === 'large' ? (
    <CardTitleLarge color={color}>{children}</CardTitleLarge>
  ) : (
    <CardTitle color={color}>{children}</CardTitle>
  )
})

export const CardTitleContainer = ({ title, color, titleButton, type, flex }) => (
  <StyledComponent flex={flex}>
    {title && (
      <Text type={type} color={color}>
        {title}
      </Text>
    )}
    {titleButton && <CardTitleButtonContainer>{titleButton}</CardTitleButtonContainer>}
  </StyledComponent>
)
