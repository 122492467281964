import React, { useEffect, useState } from 'react'

import { CheckboxToggle } from '@utilities/Switches'

import { bool, string, shape } from 'prop-types'

export const CheckboxToggleAll = ({ checked, coloring, disabled, labelAllChecked, labelAllUnChecked }) => {
  const [label, setLabel] = useState(checked ? labelAllChecked : labelAllUnChecked)

  useEffect(() => {
    setLabel(() => (checked ? labelAllChecked : labelAllUnChecked))
  }, [labelAllUnChecked, labelAllChecked, checked])

  return (
    <CheckboxToggle checked={checked} coloring={coloring} disabled={disabled}>
      {label}
    </CheckboxToggle>
  )
}

CheckboxToggleAll.propTypes = {
  checked: bool,
  disabled: bool,
  coloring: shape({
    checkedBg: string,
  }),
  labelAllChecked: string,
  labelAllUnChecked: string,
}

CheckboxToggleAll.defaultProps = {
  checked: true,
  disabled: false,
  coloring: { checkedBg: 'colors.base.black' },
  labelAllChecked: 'all off',
  labelAllUnChecked: 'all on',
}
