import { createSelector } from 'reselect'

import createStore from 'lib/flux-store'
import { hashCode } from 'lib/hashCode'
import { sorter } from 'lib/helper/sorter'

import { filterObjects } from './ObjectStore.functions'
import { getObjects } from './ObjectsCache'

export const availableUnitsProperties = [
  {
    key: 'hotelRooms',
    condition: (filter, objectType) =>
      objectType === 'tenure' ? !!filter?.tenure?.hotel?.active : filter?.pipelineUse?.includes?.('HO'),
  },
  {
    key: 'seniorUnits',
    condition: (filter, objectType) =>
      objectType === 'tenure'
        ? !!filter?.tenure?.seniorLiving?.active
        : filter?.pipelineUse?.includes?.('SE'),
  },
  {
    key: 'microUnits',
    condition: (filter, objectType) =>
      objectType === 'tenure' ? !!filter?.tenure?.microLiving?.active : filter?.pipelineUse?.includes?.('ML'),
  },
]
export const availableOperatorProperties = [
  {
    key: 'hotelOperator',
    condition: (filter, objectType) =>
      objectType === 'tenure' ? !!filter?.tenure?.hotel?.active : filter?.pipelineUse?.includes?.('HO'),
  },
  {
    key: 'nursingHomeOperator',
    condition: (filter, objectType) =>
      objectType === 'tenure'
        ? !!filter?.tenure?.seniorLiving?.active
        : filter?.pipelineUse?.includes?.('SE'),
  },
  {
    key: 'microLivingOperator',
    condition: (filter, objectType) =>
      objectType === 'tenure' ? !!filter?.tenure?.microLiving?.active : filter?.pipelineUse?.includes?.('ML'),
  },
]
export const availableStarsProperties = [
  {
    key: 'hotelStars',
    condition: (filter, objectType) => objectType === 'tenure' && !!filter?.tenure?.hotel?.active,
  },
  {
    key: 'starsSeniorLiving',
    condition: (filter, objectType) => objectType === 'tenure' && !!filter?.tenure?.seniorLiving?.active,
  },
]

export const matchedObjectProperties = (property, entries, filter, objectType) =>
  entries
    .filter((item) => item.condition(filter, objectType))
    .map((item) => item.key)
    .includes(property)

const initialState = {
  isLoading: true,
  isFailure: false,
  messages: null,
  objectType: null,
  objects: {},
  dateOfExport: null,
  geometryHash: null,
  renderMap: [],
  filteredTable: [],
  renderTable: [],
  filteredWithout: { tenantCategory: [], propertyType: [] },
  filter: {},
  sort: {},
  curObject: { setBy: null, id: null },
}

// configuration for sort algorithms.
const sortDef = [
  {
    keys: [
      'object.id',
      'object.spaceTotal',
      'object.numberOfUnits',
      'object.starClassification',
      'object.seniorUnits',
      'object.nursingHomeBeds',
      'tenant.space',
      'tenant.rent',
      'sales.space',
      'sales.price',
      'sales.yield',
    ],
    type: 'numeric',
  },
  {
    keys: [
      'object.name',
      'object.address',
      'object.city',
      'object.officeMarketLocation',
      'object.operator',
      'object.investor',
      'object.status',
      'object.use',
      'object.investor_group',
      'tenant.name',
      'tenant.category',
      'tenant.contracttype',
      'sales.buyer',
      'sales.seller',
      'sales.propertystate',
      'sales.propertytype',
    ],
    type: 'string',
  },
  {
    keys: [
      'object.startOfConstruction',
      'object.completed',
      'object.constructionYear',
      'sales.date',
      'tenant.contractsigning',
    ],
    type: 'date',
  },
]
export const getValuesByKeys = (keys, dataSources, index1, index2, filter, objectType) => {
  let val1, val2
  const properties1 = dataSources[index1.objIndex].properties
  const properties2 = dataSources[index2.objIndex].properties
  if (keys[0] === 'object') {
    if (keys[1] === 'operator') {
      val1 = Object.entries(properties1)
        .filter(
          ([key, value]) =>
            matchedObjectProperties(key, availableOperatorProperties, filter, objectType) &&
            !!value &&
            `${value}`.replace('\u001d', '').trim().length
        )
        .map(([, value]) => {
          return value
        })
        .join('; ')
      val2 = Object.entries(properties2)
        .filter(
          ([key, value]) =>
            matchedObjectProperties(key, availableOperatorProperties, filter, objectType) &&
            !!value &&
            `${value}`.replace('\u001d', '').trim().length
        )
        .map(([, value]) => {
          return value
        })
        .join('; ')
    } else if (keys[1] === 'numberOfUnits') {
      val1 = Object.entries(properties1)
        .filter(([key]) => matchedObjectProperties(key, availableUnitsProperties, filter, objectType))
        .reduce((sum, [, value]) => {
          const units = parseInt(value)
          if (!isNaN(units)) {
            sum += units
          }
          return sum
        }, null)
      val2 = Object.entries(properties2)
        .filter(([key]) => matchedObjectProperties(key, availableUnitsProperties, filter, objectType))
        .reduce((sum, [, value]) => {
          const units = parseInt(value)
          if (!isNaN(units)) {
            sum += units
          }
          return sum
        }, null)
    } else if (keys[1] === 'starClassification') {
      val1 = Object.entries(properties1)
        .filter(([key]) => matchedObjectProperties(key, availableStarsProperties, filter, objectType))
        .reduce((max, [, value]) => {
          const stars = parseFloat(`${value}`)
          if (!isNaN(stars)) {
            return Math.max(max, stars)
          }
          return max
        }, null)
      val2 = Object.entries(properties2)
        .filter(([key]) => matchedObjectProperties(key, availableStarsProperties, filter, objectType))
        .reduce((max, [, value]) => {
          const stars = parseFloat(`${value}`)
          if (!isNaN(stars)) {
            return Math.max(max, stars)
          }
          return max
        }, null)
    } else {
      val1 = dataSources[index1.objIndex].properties[keys[1]]
      val2 = dataSources[index2.objIndex].properties[keys[1]]
    }
  } else {
    if (
      typeof index1.index === 'undefined' ||
      typeof index2.index === 'undefined' ||
      typeof dataSources[index1.objIndex].properties[keys[0]] === 'undefined' ||
      typeof dataSources[index2.objIndex].properties[keys[0]] === 'undefined'
    ) {
      return [0, 0]
    }
    val1 = dataSources[index1.objIndex].properties[keys[0]][index1.index][keys[1]]
    val2 = dataSources[index2.objIndex].properties[keys[0]][index2.index][keys[1]]
  }
  return [val1, val2]
}

const actions = {
  fetchObjects:
    ({ objectType, geometry, countryCodes }) =>
    (dispatch) => {
      const geometryHash = hashCode(
        JSON.stringify(geometry !== null ? geometry.coordinates : null)
      ).toString()

      if (geometry === null) {
        dispatch({ type: 'setObjects', payload: { objects: { features: [] }, objectType, geometryHash } })
        return
      }

      dispatch({ type: 'setLoading', payload: true })
      getObjects(objectType, [geometryHash, geometry], countryCodes)
        .then(({ dateOfExport, features }) => {
          dispatch({
            type: 'setObjects',
            payload: {
              dateOfExport,
              objects: { type: 'FeatureCollection', features },
              objectType,
              geometryHash,
            },
          })
        })
        .catch((err) => {
          dispatch({ type: 'setFailure', payload: err })
          throw err
        })
    },
  resetPreparedObjects: () => ({}),
  prepareObjects: ({ filter, sort, objects }) => ({ filter, sort, objects }),
  setCurObject: (curObject) => ({ curObject }),
}
const reducer = {
  setLoading: (state, { payload }) => {
    if (state.isLoading !== payload) {
      return { ...state, isLoading: payload, isFailure: false }
    }
    return state
  },
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setObjects: (state, { payload }) => {
    const { dateOfExport, ...rest } = payload
    const newState = {
      ...state,
      isLoading: false,
      renderMap: [],
      dateOfExport,
      renderTable: [],
      filteredWithout: { tenantCategory: [], propertyType: [] },
      sort: {},
      filter: {},
      ...rest,
    }
    const isValidCurObj = payload?.features?.findIndex(
      (feature) => feature.properties.id === state.curObject.id
    )
    if (isValidCurObj < 0) {
      newState.curObject = { setBy: null, id: null }
    }
    return newState
  },
  resetPreparedObjects: (state) => {
    if (
      !state.renderMap.length &&
      !state.renderTable.length &&
      !state.filteredWithout.tenantCategory.length &&
      !state.filteredWithout.propertyType.length
    ) {
      return state
    }
    return {
      ...state,
      renderMap: [],
      renderTable: [],
      filteredWithout: { tenantCategory: [], propertyType: [] },
    }
  },
  prepareObjects: (state, action) => {
    if (state.isLoading || state.isFailure || state.objectType === null) {
      return state
    }
    const newState = { ...state }
    let changed = false
    if (
      !Object.is(state.filter, action.filter) ||
      (action.objects && !Object.is(state.objects, action.objects))
    ) {
      changed = true
      let objects = action.objects || newState.objects
      objects = objects.features || []
      const { render, renderTable } = filterObjects({
        objectType: newState.objectType,
        filter: action.filter,
        objects,
      })
      if (newState.objectType === 'tenant') {
        if (Array.isArray(action.filter.tenantCategory) && action.filter.tenantCategory.length) {
          const filter = { ...action.filter }
          filter.tenantCategory = []
          const renderTableWithout = filterObjects({
            objectType: newState.objectType,
            filter,
            objects,
          }).renderTable
          newState.filteredWithout.tenantCategory = renderTableWithout
        } else {
          newState.filteredWithout.tenantCategory = renderTable
        }
      } else if (newState.objectType === 'sales') {
        if (Array.isArray(action.filter.propertyType) && action.filter.propertyType.length) {
          const filter = { ...action.filter }
          filter.propertyType = []
          const renderTableWithout = filterObjects({
            objectType: newState.objectType,
            filter,
            objects,
          }).renderTable
          newState.filteredWithout.propertyType = renderTableWithout
        } else {
          newState.filteredWithout.propertyType = renderTable
        }
      }
      newState.filter = action.filter
      newState.renderMap = render
      newState.filteredTable = renderTable
      newState.renderTable = renderTable
    }
    if (
      changed ||
      !Object.is(state.sort, action.sort) ||
      (action.objects && !Object.is(state.objects, action.objects))
    ) {
      changed = true
      newState.renderTable = sorter({
        indices: newState.filteredTable,
        dataSources: newState.objects.features,
        sort: action.sort,
        sortDef,
        getValuesByKeys,
        filter: newState.filter,
        objectType: newState.objectType,
      })
      newState.sort = action.sort
    }
    return changed ? newState : state
  },
  setCurObject: (state, { curObject }) => {
    if (
      curObject.setBy !== 'loadProfile' &&
      curObject.setBy !== 'uri-params' &&
      state.curObject.id === curObject.id
    ) {
      curObject = { ...initialState.curObject }
    }
    return { ...state, curObject }
  },
}

const $curObject = createSelector(
  (state) => state.objects,
  (state) => state.curObject,
  (state) => state.renderMap,
  (objects, curObject, renderMap) => {
    if (curObject.id === null) return null
    if (!renderMap.find((item) => item.id === curObject.id)) return null
    const { features = [] } = objects
    return features.find((feature) => feature.properties.id === curObject.id)
  }
)

export const [ObjectDataStoreContext, ObjectDataStoreProvider, useObjectDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'ObjectDataStore'
)

export const ObjectDataStoreSelector = { $curObject }
