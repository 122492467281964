/**
 * Calculate a 32bit integer hash from a string.
 *
 * By default it imitates Java's String.hashCode() method.
 *
 * References:
 *   - https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 *   - https://www.includehelp.com/code-snippets/how-to-create-hash-from-string-in-javascript.aspx
 *   - https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript/8831937#8831937
 *
 * @param {string} string
 * @param {object} options
 * @returns {number}
 */
export function hashCode(string, options = {}) {
  const { k = 5 } = options
  let hash = 0

  for (let i = 0; i < string.length; i++) {
    const charCode = string.charCodeAt(i)
    hash = (hash << k) - hash + charCode
    hash = hash & hash // Convert to 32bit integer
  }

  return hash
}
