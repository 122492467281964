import { useCallback, useMemo } from 'react'
import { useAuthStore } from 'stores/AuthStore'

const possibleDefaultRoutes = [
  '/home',
  '/market-analysis/market-data',
  '/market-analysis/objects',
  '/market-analysis/city-structure',
  '/market-analysis/market-text',
  '/objects/comparables',
  '/objects/pipeline',
  '/makro/economy',
  '/makro/propertyindices',
  '/makro/thematic-maps',
  '/features/developer',
  '/features/developer',
  '/features/scoring',
  '/features/l3plus',
  '/features/retail-markets',
  '/features/highstreet-report',
  '/download/documents',
  '/download/raw-data',
  '/download/reports',
  '/download/profile',
]
const getRoutes = ({ hasLicence, hasAdminKey }) => {
  return [
    { route: '/home', access: hasLicence('marketData_small') },
    { route: '/information/glossary', access: hasLicence('marketText') },
    { route: '/information/schedule', access: hasLicence('marketText') },
    { route: '/information/market-data-partners', access: hasLicence('marketText') },
    { route: '/information/about', access: hasLicence('marketData_small') },
    { route: '/information/contact', access: hasLicence('marketData_small') },
    { route: '/market-analysis/key-facts', access: hasLicence('keyFacts') },
    {
      route: '/market-analysis/market-data',
      access: hasLicence('marketData_small') || hasLicence('demographic_small'),
    },
    {
      route: '/market-analysis/objects',
      access:
        hasLicence('pipeline') ||
        hasLicence('pipelinePlus') ||
        hasLicence('comparablesSales') ||
        hasLicence('comparablesTenant') ||
        hasLicence('tenure'),
    },
    {
      route: '/market-analysis/city-structure',
      access: hasLicence('situationalLayer'),
    },
    {
      route: '/market-analysis/market-text',
      access: hasLicence('marketText'),
    },
    {
      route: '/objects/comparables',
      access: hasLicence('comparablesSales') || hasLicence('comparablesTenant'),
    },
    {
      route: '/objects/pipeline',
      access: hasLicence('pipeline'),
    },
    {
      route: '/objects/tenure',
      access: hasLicence('tenure'),
    },
    {
      route: '/makro/economy',
      access: hasLicence('economy'),
    },
    {
      route: '/makro/propertyindices',
      access: hasLicence('immoIndex'),
    },
    {
      route: '/makro/thematic-maps',
      access: hasLicence('thematicMaps'),
    },
    {
      route: '/features/hedonic/rentsAndBuy',
      access: hasLicence('prospect'),
    },
    {
      route: '/features/hedonic/house',
      access: hasLicence('prospect'),
    },
    {
      route: '/features/hedonic/office',
      access: hasLicence('prospectOffice'),
    },
    {
      route: '/features/hedonic/industry',
      access: hasLicence('prospectIndustry'),
    },
    {
      route: '/download/documents',
      access: true,
    },
    {
      route: '/download/raw-data',
      access: hasLicence('rawData'),
    },
    {
      route: '/download/profile',
      access:
        hasLicence('profile') ||
        hasLicence('reportsProfile') ||
        hasLicence('reportsValuation') ||
        hasLicence('reportsMarketProfile'),
    },
    {
      route: '/download/reports',
      access: hasAdminKey('editReports'),
    },
    { route: '/download/documentation', access: hasAdminKey('apiDocumentation') },
    {
      route: '/features/scoring',
      access: hasLicence('scoring'),
    },
    {
      route: '/features/scoring/home',
      access: hasLicence('scoring'),
    },
    {
      route: '/features/scoring/assetclasses',
      access: hasLicence('scoring'),
    },
    {
      route: '/features/scoring/overview',
      access: hasLicence('scoring'),
    },
    {
      route: '/features/scoring/scorecards',
      access: hasLicence('scoring'),
    },
    {
      route: '/features/disco',
      access: hasLicence('disco'),
    },
    {
      route: '/features/l3plus',
      access: hasLicence('l3plus'),
    },
    {
      route: '/features/l3plus/main',
      access: hasLicence('l3plus'),
    },
    {
      route: '/features/l3plus/location-compare',
      access: hasLicence('l3plus'),
    },
    {
      route: '/features/l3plus/tokenized-links',
      access: hasAdminKey('l3plusCreateTokenizedLink'),
    },
    {
      route: '/features/retail-markets',
      access: hasLicence('retailMarkets'),
    },
    {
      route: '/features/highstreet-report',
      access: hasLicence('highstreetReport'),
    },
    {
      route: '/features/developer',
      access: hasLicence('developer'),
    },
    { route: '/admin', access: hasAdminKey('isAdmin') },
    { route: '/admin/user', access: hasAdminKey('isAdmin') },
    {
      route: '/admin/group',
      access: hasAdminKey('isAdmin') && hasAdminKey('isSuperAdmin') && hasAdminKey('editGroup'),
    },
    {
      route: '/admin/role',
      access: hasAdminKey('isAdmin') && hasAdminKey('isSuperAdmin') && hasAdminKey('editRole'),
    },
    {
      route: '/admin/webtracking',
      access: hasAdminKey('isAdmin') && hasAdminKey('isSuperAdmin') && hasAdminKey('webtracking'),
    },
    { route: '/sysadmin/i18n', access: hasAdminKey('i18nRoute') && hasAdminKey('testModules') },
    { route: '/sysadmin/error-log', access: hasAdminKey('errorLog') && hasAdminKey('testModules') },
    { route: '/sysadmin/matviews', access: hasAdminKey('isSuperAdmin') && hasAdminKey('testModules') },
    { route: '/sysadmin/reports', access: hasAdminKey('isSuperAdmin') && hasAdminKey('editReports') },
    { route: '/logout', access: true },
    { route: '/export', access: hasAdminKey('genExportRoute') },
    { route: '/information', access: true },
  ]
}

const nestedRoutes = [
  {
    route: '/market-analysis/home',
    oneOf: [
      '/market-analysis/key-facts',
      '/market-analysis/market-data',
      '/market-analysis/objects',
      '/market-analysis/city-structure',
      '/market-analysis/market-text',
    ],
  },
  {
    route: '/makro/home',
    oneOf: ['/makro/economy', '/makro/propertyindices', '/makro/thematic-maps'],
  },
  {
    route: '/download/home',
    oneOf: [
      '/download/documents',
      '/download/raw-data',
      '/download/reports',
      '/download/profile',
      '/download/documentation',
    ],
  },
  {
    route: '/features/hedonic/home',
    oneOf: [
      '/features/hedonic/rentsAndBuy',
      '/features/hedonic/house',
      '/features/hedonic/office',
      '/features/hedonic/industry',
    ],
  },
  {
    route: '/objects/home',
    oneOf: ['/objects/comparables', '/objects/pipeline', '/objects/tenure'],
  },
]

export const useAuthzChecks = () => {
  const [currentUser] = useAuthStore((state) => [state.currentUser])
  const hasLicence = useCallback(
    (key, subModule = null) => {
      let licences = (currentUser.permissions?.licence?.modules || [])
        .concat(
          subModule !== null && currentUser.permissions?.licence?.assetClassesByModule?.[subModule]?.length
            ? currentUser.permissions.licence.assetClassesByModule[subModule]
            : currentUser.permissions?.licence?.assetClasses || []
        )
        .concat(currentUser.permissions?.licence?.developerClasses || [])
      return (
        licences.findIndex(
          (licence) =>
            licence === key || (typeof licence === 'object' && licence.key === key && licence.value)
        ) >= 0
      )
    },
    [currentUser]
  )

  const isRestricted = useCallback(
    (mod, key) => {
      const licence = currentUser.permissions?.licence ?? {
        restrictions: [],
        restrictionAllowedKeys: {},
      }
      if (
        licence?.restrictions?.includes?.(mod) &&
        Array.isArray(licence?.restrictionAllowedKeys?.[mod]) &&
        licence.restrictionAllowedKeys[mod].length &&
        !licence.restrictionAllowedKeys[mod].includes(key)
      ) {
        return true
      }
      return false
    },
    [currentUser]
  )

  const hasAdminKey = useCallback(
    (key) => {
      const adminKeys = currentUser.permissions?.admin || {}
      return adminKeys[key] || false
    },
    [currentUser]
  )

  // to open routes only for testing purposes, test for hasAdminKey('testModules')
  const routes = useMemo(() => {
    const routes = getRoutes({ hasLicence, hasAdminKey })
    nestedRoutes.forEach((nestedRoute) => {
      routes.push({
        route: nestedRoute.route,
        access: nestedRoute.oneOf.some(
          (oneOfRoute) => routes.findIndex((route) => route.route === oneOfRoute && route.access) >= 0
        ),
      })
    })
    return routes
  }, [hasLicence, hasAdminKey])

  const hasRouteAccess = useCallback(
    (pathname) => {
      const matchingRoutes = routes.filter((route) => pathname.indexOf(route.route) === 0)
      return matchingRoutes.every((route) => route.access)
    },
    [routes]
  )

  const getDefaultRoute = useCallback(() => {
    let defaultRoute = null
    for (let i = 0; i < possibleDefaultRoutes.length && defaultRoute === null; i++) {
      const route = possibleDefaultRoutes[i]
      if (hasRouteAccess(route)) {
        defaultRoute = route
      }
    }
    return defaultRoute
  }, [hasRouteAccess])

  return { hasLicence, isRestricted, hasAdminKey, hasRouteAccess, getDefaultRoute }
}
