import React from 'react'
import { radii } from 'theming/themes/default/variables/radii'
import { ButtonUp } from '../ButtonUp'
import { ButtonDown } from '../ButtonDown'

export const defaultProps = {
  appearance: 'white',
  borderRadius: radii.small,
  disabled: false,
  direction: 'left',
  forElementName: null,
  name: null,
  typeScale: 'pica',
  typeStyle: 'pica',
  buttonUp: <ButtonUp />,
  buttonDown: <ButtonDown />,
}
