import React from 'react'
import styled from 'styled-components'
import { typography } from 'styled-system'

import { FlexItem } from '@layout/BuildingBlocks'
import { CopyMedium as Text } from '@typography'

import { themeGet } from '@styled-system/theme-get'

const StyledComponent = styled(Text)`
  color: ${themeGet('card.colors.subtitle.color', 'gray')};
  ${typography}
`

export const CardSubTitle = ({ pl, pr, subTitle }) => (
  <FlexItem pl={pl} pr={pr}>
    <StyledComponent tag="h3" lineHeight="1.15" mb="0px" mt="4px">
      {subTitle}
    </StyledComponent>
  </FlexItem>
)
