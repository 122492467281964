import stringify from 'json-stable-stringify'

import { createDataCache } from 'lib/cache'

import api from 'stores/api'

import { hashCode } from 'lib/hashCode'

const conditionals = [
  { name: 'objectType' },
  {
    name: 'filter',
    stringify: (filter) => {
      return hashCode(stringify(filter)).toString()
    },
  },
  { name: 'countryCodes' },
]

const getter = (objectType, filter, countryCodes) => {
  return api.RiwisObject.byType({
    type: objectType,
    params: { filter, countryCodes },
  }).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    const { dateOfExport, features } = result.data.data
    return { dateOfExport, features }
  })
}

export const [getObjects, bustObjects] = createDataCache(conditionals, getter, {
  name: 'GlobalObjectsCache',
  maxSize: 10,
})
