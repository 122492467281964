import { variant } from 'styled-system'

import {
  bare,
  dark,
  ghost,
  gray,
  light,
  lightgray,
  primary,
  primaryGhost,
  primaryShallow,
  success,
  error,
  white,
} from 'theming/systemHelper/appearance'
import { oval, shapeless, square } from 'theming/systemHelper/shape'

import {
  nano,
  nanoOval,
  tiny,
  tinyOval,
  small,
  smallOval,
  medium,
  mediumOval,
  large,
  largeOval,
} from 'theming/systemHelper/size'

export const appearance = (props) =>
  variant({
    prop: 'appearance',
    variants: {
      bare,
      dark,
      ghost,
      gray,
      light,
      lightgray,
      primary,
      primaryGhost,
      primaryShallow,
      success,
      error,
      white,
    },
  })

export const size = (props) => {
  return props.shape === 'oval'
    ? variant({
        prop: 'size',
        variants: {
          nano: nanoOval,
          tiny: tinyOval,
          small: smallOval,
          medium: mediumOval,
          large: largeOval,
        },
      })
    : variant({
        prop: 'size',
        variants: {
          nano,
          tiny,
          small,
          medium,
          large,
        },
      })
}

export const shape = (props) =>
  variant({
    prop: 'shape',
    variants: {
      oval,
      shapeless,
      square,
    },
  })
