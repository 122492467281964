import React from 'react'
import { BudiconCrossUi } from 'bgag-budicons'

import appConfig from 'config/appConfig'
import { zIndices } from 'config/zIndices'

const buildCookieProperties = () => {
  const bgagServicesUrl = appConfig.bgagServicesUrl
  let props = ''
  if (bgagServicesUrl.indexOf('.') >= 0) {
    let domain = bgagServicesUrl
      .replace(/^https?:\/\//g, '')
      .replace(/^([^/]+).*/, '$1')
      .split('.')
    if (domain.length > 2) {
      domain.splice(0, domain.length - 2)
    }
    domain = domain.join('.')
    props += 'Domain=' + domain + ';'
    if (window.location.host.indexOf(domain) < 0) {
      console.log('IMPERSONATION PROBABLY NOT WORKING, SINCE COOKIE WILL NOT BE SEND')
      console.log(
        'Implementation will change, so cookies will be set, but on clients domain, and API sends data on each call to services, by post or get addendum, if cookie is set'
      )
      props += 'SameSite=None;'
    }
  }
  if (bgagServicesUrl.indexOf('https') === 0) {
    props += 'Secure;'
  }
  return props
}

const addCookies = (id) => {
  document.cookie = 'lastPath=' + window.location.href
  document.cookie = 'impersonate=' + id + ';path=/;' + buildCookieProperties()
  window.location.reload(true)
}
const rmvCookies = () => {
  const lastPath = readCookie('lastPath')
  document.cookie =
    'impersonate=;path=/;' + buildCookieProperties() + ' expires=Thu, 01 Jan 1970 00:00:00 GMT'
  document.cookie = 'lastPath=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
  if (lastPath !== null) {
    window.location = lastPath
  } else {
    window.location.reload(true)
  }
}
const readCookie = (name) => {
  const cookie = document.cookie.split(';').filter((entry) => entry.trim().startsWith(name + '='))
  if (!cookie.length) {
    return null
  }
  return cookie[0].trim().replace(name + '=', '')
}

const ImpersonateFrame = () => {
  const impersonateId = readCookie('impersonate')
  if (impersonateId === null) {
    return null
  }
  return (
    <div
      style={{
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        border: '5px solid red',
        pointerEvents: 'none',
        zIndex: zIndices.impersonateUser,
      }}
    >
      <div
        style={{
          width: '35px',
          height: '34px',
          padding: '2px',
          fontSize: '1.8rem',
          backgroundColor: 'red',
          color: 'white',
          pointerEvents: 'auto',
          cursor: 'pointer',
          position: 'relative',
          top: '-3px',
        }}
        onClick={rmvCookies}
      >
        <BudiconCrossUi />
      </div>
    </div>
  )
}

const setImpersonateId = (id) => {
  addCookies(id)
}

export { ImpersonateFrame, setImpersonateId }
