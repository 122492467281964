import { debounce } from 'debounce'

import { togglePresence } from 'lib/util'
import createStore from 'lib/flux-store'
import { rgbToHex } from 'lib/util/colors'
import api from 'stores/api'

import { colorAllocator } from 'lib/util/colorAllocator'
import { fixOldState } from './GeoToolsFixOldState'

const initialState = {
  collection: null,
  isLoading: true,
  isFailure: false,
  err: null,
  hoveredZone: null,
  localProperties: {}, // local updates of properties
  flyTo: null,
  algebraMode: null,
  selectedZones: [],
  intersectWith: null,
}

const debouncedLoadCollection = debounce((loadCollection, collectionId) => {
  return loadCollection(collectionId)
}, 200)

const actions = {
  loadCollection: (id, options) => async (dispatch, prevState) => {
    if (options?.noRefresh && id === prevState?.collection?.id) {
      return
    }
    if (id === null) {
      dispatch({ type: 'setCollection', collection: null })
      return
    }
    if (typeof id === 'undefined') {
      id = prevState.collection.id
    }
    dispatch({ type: 'setLoading' })

    try {
      const res = await api.Geotools.getCollection(id)
      const collection = res.data.data
      await fixOldState(collection)
      dispatch({ type: 'setCollection', collection })
    } catch (err) {
      dispatch({ type: 'setFailure', err })
    }
  },
  createCollection: (payload) => async (dispatch) => {
    dispatch({ type: 'setLoading' })
    try {
      const res = await api.Geotools.createCollection(payload)
      const collection = res.data.data
      dispatch({ type: 'setCollection', collection })
      return collection
    } catch (err) {
      console.error(err)
      dispatch({ type: 'setFailure', err })
    }
  },
  updateCollection: (payload) => async (dispatch, prevState) => {
    try {
      const res = await api.Geotools.updateCollection(prevState.collection.id, payload)
      const collection = res.data.data
      dispatch({ type: 'setCollection', collection })
    } catch (err) {
      console.error(err)
      dispatch({ type: 'setFailure', err })
    }
  },
  addZone: (payload) => async (dispatch, prevState, thunks) => {
    if (!payload.style) payload.style = {}
    if (!payload.style.color) {
      payload.style.color = rgbToHex(colorAllocator(prevState?.collection?.meta.featureCount))
    }
    if (!payload.creationParams) {
      payload.creationParams = {}
    }
    if (!payload.creationParams.intersectWith) {
      payload.creationParams.intersectWith = prevState?.intersectWith
    }

    const collectionId = prevState?.collection.id
    dispatch({ type: 'setLoading' })
    try {
      await api.Geotools.createFeature(collectionId, payload)
      await debouncedLoadCollection(thunks.loadCollection, collectionId)
    } catch (err) {
      console.error(err)
      dispatch({ type: 'setFailure', err })
    }
  },
  removeZone: (id) => async (dispatch, prevState, thunks) => {
    const collectionId = prevState.collection.id
    dispatch({ type: 'setLoading' })
    try {
      await api.Geotools.deleteFeature(collectionId, id)
      await debouncedLoadCollection(thunks.loadCollection, collectionId)
    } catch (err) {
      console.error(err)
      dispatch({ type: 'setFailure', err })
    }
  },
  removeAllZones: () => async (dispatch, prevState, thunks) => {
    dispatch({ type: 'setLoading' })
    try {
      const collection = prevState.collection
      await Promise.all(
        collection.features.map((feature) => api.Geotools.deleteFeature(collection.id, feature.id))
      )
      await debouncedLoadCollection(thunks.loadCollection, collection.id)
    } catch (err) {
      console.error(err)
      dispatch({ type: 'setFailure', err })
    }
  },
  updateZoneProps: (id, properties) => ({ id, properties }),
  resetZoneProps: (id) => ({ id }),
  persistZone: (id) => async (dispatch, prevState) => {
    if (typeof prevState.localProperties[id] === 'undefined') {
      return
    }
    const collectionId = prevState.collection.id
    const { color, opacity, weight, ...props } = prevState.localProperties[id]
    const style = { color, opacity, weight }
    props.style = style
    await api.Geotools.updateFeature(collectionId, id, props)
  },
  updateZoneGeometry: (id, payload) => async (dispatch, prevState, thunks) => {
    const collectionId = prevState.collection.id
    dispatch({ type: 'setLoading' })
    try {
      await api.Geotools.updateFeature(collectionId, id, payload)
      await debouncedLoadCollection(thunks.loadCollection, collectionId)
    } catch (err) {
      console.error(err)
      dispatch({ type: 'setFailure', err })
    }
  },
  setHoveredZone: (id) => ({ id }),
  setFlyTo: (id) => ({ id }),
  setActive: (featureId, active) => async (dispatch, prevState, thunks) => {
    const collectionId = prevState.collection.id
    dispatch({ type: 'setLoading' })
    try {
      await api.Geotools.updateFeature(collectionId, featureId, { active })
      await debouncedLoadCollection(thunks.loadCollection, collectionId)
    } catch (err) {
      console.error(err)
      dispatch({ type: 'setFailure', err })
    }
  },
  setFeatureOrder: (features) => async (dispatch, prevState) => {
    dispatch({ features })
    const collectionId = prevState.collection.id
    const order = features.map((f) => f.id)
    try {
      await api.Geotools.updateFeatureOrder(collectionId, order)
    } catch (err) {
      console.error(err)
      dispatch({ type: 'setFailure', err })
    }
  },
  setAlgebraMode: (algebraMode) => ({ algebraMode, selectedZones: [] }),
  selectZone: (zoneId) => async (dispatch, prevState, thunks) => {
    if (prevState.selectedZones.length === 1 && prevState.selectedZones[0] !== zoneId) {
      dispatch({ zoneId })
      const collectionId = prevState.collection.id
      const method = prevState.algebraMode
      const features = [prevState.selectedZones[0], zoneId]
      dispatch({ type: 'setLoading' })
      try {
        await api.Geotools.algebra(collectionId, { method, features })
        await thunks.loadCollection(collectionId)
        thunks.setAlgebraMode(null)
      } catch (err) {
        console.error(err)
        dispatch({ type: 'setFailure', err })
      }
    } else {
      dispatch({ zoneId })
    }
  },
  setIntersectWith: (intersectWith) => ({ intersectWith }),
}

const reducer = {
  setLoading: (state) => ({
    ...state,
    isLoading: true,
    isFailure: false,
  }),
  setFailure: (state, { err }) => ({ ...state, isLoading: false, isFailure: true, err }),
  setCollection: (state, { collection }) => {
    return {
      ...state,
      isLoading: false,
      isFailure: false,
      collection,
      localProperties: {},
    }
  },
  updateCollection: (state, { collection }) => ({
    ...state,
    isLoading: false,
    isFailure: false,
    collection: { ...state.collection, ...collection },
  }),
  updateZoneProps: (state, { id, properties }) => ({
    ...state,
    localProperties: { ...state.localProperties, [id]: { ...state.localProperties[id], ...properties } },
  }),
  resetZoneProps: (state, { id }) => {
    const localProperties = { ...state.localProperties }
    delete localProperties[id]
    return { ...state, localProperties }
  },
  setHoveredZone: (state, { id }) => ({ ...state, hoveredZone: id }),
  setFlyTo: (state, { id }) => ({ ...state, flyTo: id }),
  setFeatureOrder: (state, { features }) => ({
    ...state,
    collection: { ...state.collection, features },
  }),
  setAlgebraMode: (state, { algebraMode }) => ({ ...state, algebraMode, selectedZones: [] }),
  selectZone: (state, { zoneId }) => ({
    ...state,
    selectedZones: togglePresence(state.selectedZones, zoneId),
  }),
  setIntersectWith: (state, { intersectWith }) => ({ ...state, intersectWith }),
}

export const [GeotoolsZoneStore, GeotoolsZoneStoreProvider, useGeotoolsZoneStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'GeotoolsZoneStore'
)
