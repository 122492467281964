export const getButtonIcons = ({ radii, spaces, themeColors }) => ({
  radius: {
    default: radii.small,
  },

  spaces: {
    nano: {
      square: {
        oblong: {
          px: spaces.paddings.nanoPaddingX,
          pb: spaces.paddings.nanoPaddingBottom,
          pt: spaces.paddings.nanoPaddingTop,
        },
        equalSides: {
          px: 0,
          pb: spaces.paddings.nanoPaddingBottom,
          pt: spaces.paddings.nanoPaddingTop,
        },
      },
      oval: {
        oblong: {
          px: spaces.paddings.nanoPaddingXOval,
          pb: spaces.paddings.nanoPaddingBottom,
          pt: spaces.paddings.nanoPaddingTop,
        },
        equalSides: {
          px: 0,
          pb: spaces.paddings.nanoPaddingBottom,
          pt: spaces.paddings.nanoPaddingTop,
        },
      },
    },
    tiny: {
      square: {
        oblong: {
          px: spaces.paddings.tinyPaddingX,
          pb: spaces.paddings.tinyPaddingBottom,
          pt: spaces.paddings.tinyPaddingTop,
        },
        equalSides: {
          px: 0,
          pb: spaces.paddings.tinyPaddingBottom,
          pt: spaces.paddings.tinyPaddingTop,
        },
      },
      oval: {
        oblong: {
          px: spaces.paddings.tinyPaddingXOval,
          pb: spaces.paddings.tinyPaddingBottom,
          pt: spaces.paddings.tinyPaddingTop,
        },
        equalSides: {
          px: 0,
          pb: spaces.paddings.tinyPaddingBottom,
          pt: spaces.paddings.tinyPaddingTop,
        },
      },
    },
    small: {
      square: {
        oblong: {
          px: spaces.paddings.smallPaddingX,
          pt: spaces.paddings.smallPaddingTop,
          pb: spaces.paddings.smallPaddingBottom,
        },
        equalSides: {
          px: 0,
          pt: spaces.paddings.smallPaddingTop,
          pb: spaces.paddings.smallPaddingBottom,
        },
      },
      oval: {
        oblong: {
          px: spaces.paddings.smallPaddingXOval,
          pt: spaces.paddings.smallPaddingTop,
          pb: spaces.paddings.smallPaddingBottom,
        },
        equalSides: {
          px: 0,
          pt: spaces.paddings.smallPaddingTop,
          pb: spaces.paddings.smallPaddingBottom,
        },
      },
    },
    medium: {
      square: {
        oblong: {
          px: spaces.paddings.mediumPaddingX,
          pt: spaces.paddings.mediumPaddingTop,
          pb: spaces.paddings.mediumPaddingBottom,
        },
        equalSides: {
          px: 0,
          pt: spaces.paddings.mediumPaddingTop,
          pb: spaces.paddings.mediumPaddingBottom,
        },
      },
      oval: {
        oblong: {
          px: spaces.paddings.mediumPaddingXOval,
          pt: spaces.paddings.mediumPaddingTop,
          pb: spaces.paddings.mediumPaddingBottom,
        },
        equalSides: {
          px: 0,
          pt: spaces.paddings.mediumPaddingTop,
          pb: spaces.paddings.mediumPaddingBottom,
        },
      },
    },
    large: {
      square: {
        oblong: {
          px: spaces.paddings.largePaddingX,
          pt: spaces.paddings.largePaddingTop,
          pb: spaces.paddings.largePaddingBottom,
        },
        equalSides: {
          px: 0,
          pt: spaces.paddings.largePaddingTop,
          pb: spaces.paddings.largePaddingBottom,
        },
      },
      oval: {
        oblong: {
          px: spaces.paddings.largePaddingXOval,
          pt: spaces.paddings.largePaddingTop,
          pb: spaces.paddings.largePaddingBottom,
        },
        equalSides: {
          px: 0,
          pt: spaces.paddings.largePaddingTop,
          pb: spaces.paddings.largePaddingBottom,
        },
      },
    },
  },

  dark: {
    default: {
      bg: themeColors.dark.default.bg,
      color: themeColors.dark.default.color,
    },
    focus: {
      bg: themeColors.dark.focus.bg,
      color: themeColors.dark.focus.color,
    },
    hover: {
      bg: themeColors.dark.hover.bg,
      color: themeColors.dark.hover.color,
    },
    disabled: {
      bg: themeColors.dark.disabled.bg,
      color: themeColors.dark.disabled.color,
    },
  },

  gray: {
    default: {
      bg: themeColors.gray.default.bg,
      color: themeColors.gray.default.color,
    },
    focus: {
      bg: themeColors.gray.focus.bg,
      color: themeColors.gray.focus.color,
    },
    hover: {
      bg: themeColors.gray.hover.bg,
      color: themeColors.gray.hover.color,
    },
    disabled: {
      bg: themeColors.gray.disabled.bg,
      color: themeColors.gray.disabled.color,
    },
  },

  lightgray: {
    default: {
      bg: themeColors.lightgray.default.bg,
      color: themeColors.lightgray.default.color,
    },
    focus: {
      bg: themeColors.lightgray.focus.bg,
      color: themeColors.lightgray.focus.color,
    },
    hover: {
      bg: themeColors.lightgray.hover.bg,
      color: themeColors.lightgray.hover.color,
    },
    disabled: {
      bg: themeColors.lightgray.disabled.bg,
      color: themeColors.lightgray.disabled.color,
    },
  },

  light: {
    default: {
      bg: themeColors.light.default.bg,
      color: themeColors.light.default.color,
    },
    focus: {
      bg: themeColors.light.focus.bg,
      color: themeColors.light.focus.color,
    },
    hover: {
      bg: themeColors.light.hover.bg,
      color: themeColors.light.hover.color,
    },
    disabled: {
      bg: themeColors.light.disabled.bg,
      color: themeColors.light.disabled.color,
    },
  },

  ghost: {
    default: {
      bg: themeColors.ghost.default.bg,
      color: themeColors.ghost.default.color,
    },
    focus: {
      bg: themeColors.ghost.focus.bg,
      color: themeColors.ghost.focus.color,
    },
    hover: {
      bg: themeColors.ghost.hover.bg,
      color: themeColors.ghost.hover.color,
    },
    disabled: {
      bg: themeColors.ghost.disabled.bg,
      color: themeColors.ghost.disabled.color,
    },
  },

  primary: {
    default: {
      bg: themeColors.primary.default.bg,
      color: themeColors.primary.default.color,
    },
    focus: {
      bg: themeColors.primary.focus.bg,
      color: themeColors.primary.focus.color,
    },
    hover: {
      bg: themeColors.primary.hover.bg,
      color: themeColors.primary.hover.color,
    },
    disabled: {
      bg: themeColors.primary.disabled.bg,
      color: themeColors.primary.disabled.color,
    },
  },

  primaryGhost: {
    default: {
      bg: themeColors.primaryGhost.default.bg,
      color: themeColors.primaryGhost.default.color,
    },
    focus: {
      bg: themeColors.primaryGhost.focus.bg,
      color: themeColors.primaryGhost.focus.color,
    },
    hover: {
      bg: themeColors.primaryGhost.hover.bg,
      color: themeColors.primaryGhost.hover.color,
    },
    disabled: {
      bg: themeColors.primaryGhost.disabled.bg,
      color: themeColors.primaryGhost.disabled.color,
    },
  },
})
