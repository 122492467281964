import React from 'react'

import { BudiconCrossUi } from 'bgag-budicons'

import { ControlsSegment } from '@ui/ControlsSegment'
import { ButtonIcon } from '@ui/Buttons'

export const LayOver = ({ Component, onCloseClick, title, componentProps }) => {
  return (
    <ControlsSegment width="100%">
      <ControlsSegment.Headline>{title}</ControlsSegment.Headline>
      <ControlsSegment.HeaderControls>
        <ButtonIcon
          appearance="bare"
          icon={{ Icon: BudiconCrossUi, type: 'shady', strokeWidth: '1.2' }}
          onClick={onCloseClick}
          shape="shapeless"
          size="large"
        />
      </ControlsSegment.HeaderControls>
      <ControlsSegment.Body>
        <Component {...componentProps} />
      </ControlsSegment.Body>
    </ControlsSegment>
  )
}
