import { useModalContext } from '..'
import { useSlotsAsArray } from 'lib/hooks/useSlots'

export const useModalView = (name, children) => {
  const {
    state: { history },
  } = useModalContext()

  const activeView = parseInt(
    [...history]
      .filter((item) => item.startsWith(name + '::view'))
      .pop()
      ?.split?.('::view')?.[1] ?? 0
  )

  const [views] = useSlotsAsArray(['views'], children)
  const currentView = views[activeView].props.children

  return {
    currentView,
  }
}
