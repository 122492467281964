import React from 'react'

import { Box, FlexContainer } from '@layout/BuildingBlocks'
import l3PlusLogoUrl from 'assets/images/logos/l3plus_white.svg'
import { getThemeValue } from 'theming'
import { useTheme } from 'styled-components'

export const CardLogoL3Plus = () => {
  const theme = useTheme()

  const pbLogo = getThemeValue(theme, 'spaces.rhythm.vertical.medium')
  return (
    <FlexContainer flex-direction="column" justifyContent="center" alignItems="center">
      <Box pb={pbLogo} mt="-125px" display={['none', 'none', 'block']}>
        <img alt="Lührmann" src={l3PlusLogoUrl} height="150px" />
      </Box>
    </FlexContainer>
  )
}
