import createStore from 'lib/flux-store'
import api from 'stores/api'
import functionQueue from 'lib/util/functionQueue'
import {
  findAreaByMarketCategory,
  identifyFeature,
  reduceFeatures,
  setLocationSubmarket,
} from 'components/controls/locationHelpers'
import { createSelector } from 'reselect'
import { isNullOrUndefined } from 'lib/util'
import { gacNamePrefixes } from './helper'
import { marketDataInitialGroups } from 'config/marketDataTopics'

const currentYear = new Date().getFullYear()
const secureMainLocation = (id, mainLocation, locations) => {
  let newMain =
    Number(id) === Number(mainLocation)
      ? Object.keys(locations).length
        ? Object.keys(locations)[0]
        : null
      : mainLocation

  newMain = newMain !== null ? Number(newMain) : null

  return newMain
}

const initialState = {
  locationsGeotoolsProject: {
    DE: {},
    AT: {},
  },
  stateIsLoading: true,
  isFailure: false,
  messages: [],
  mapBounds: null,
  currentCountry: 'DE',
  currentTopicGroup: 'office',
  globalTableVisibility: true,
  globalLocationsInside: false,
  globalTableTreeView: true,
  referenceCurve: 'none',
  exportOverallData: true,
  templateGroup: null,
  topicsVisibility: {},
  topicGroupVisibility: {},
  topicsChartView: {},
  topicsAxisSource: {},
  topicsTableVisibility: {},
  topicsLocationsInside: {},
  topicsTableTreeView: {},
  locations: {
    DE: {},
    AT: {},
  },
  mainLocation: {
    DE: null,
    AT: null,
  },
  visibleLocations: { DE: [], AT: [] },
  locationListener: [],
  locationByGac: false,
  assetClass: 'residential',
  time: {
    from: 2010,
    to: currentYear,
  },
  year: currentYear,
}
const ignoreKeysOnUpdate = {
  stateIsLoading: true,
  isFailure: true,
  gacsNameCache: true,
  locationListener: true,
  mapBounds: true,
  setBy: true,
}

const setLoadingSrc = {}

let isSaving = false

export const waitForSave = () => {
  isSaving = true
  const requestStarted = Date.now()
  return new Promise((resolve, reject) => {
    const testSaving = () => {
      if (!isSaving || Date.now() - requestStarted > 4000) {
        isSaving = false
        resolve()
      } else {
        setTimeout(testSaving, 20)
      }
    }

    testSaving()
  })
}

const queuedSave = functionQueue(async (...args) => {
  await api.AppState.save(...args)
  isSaving = false
})

let lastSavedState = initialState

const onUpdate = (state, prevState) => {
  if (state.dontPreserveState) {
    return Promise.resolve()
  }

  if (prevState) {
    for (const prevLocId in prevState.locationsGeotoolsProject) {
      const prevProjectId = prevState.locationsGeotoolsProject[prevLocId]
      const curProjectId = state.locationsGeotoolsProject[prevLocId]
      if (!isNullOrUndefined(prevProjectId) && isNullOrUndefined(curProjectId)) {
        api.Geotools.deleteCollection(prevProjectId)
      }
    }
  }

  const changed = Object.keys(state).reduce(
    (changed, key) =>
      changed || (ignoreKeysOnUpdate[key] ? false : !Object.is(lastSavedState[key], state[key])),
    false
  )

  if (changed) {
    lastSavedState = { ...state }
    const saveState = {}
    Object.keys(lastSavedState).forEach((key) => {
      if (!ignoreKeysOnUpdate[key]) {
        if (
          lastSavedState[key] !== null &&
          Object.values(lastSavedState[key]).some((value) => value instanceof Map)
        ) {
          saveState[key] = Object.entries(lastSavedState[key]).reduce((obj, [country, value]) => {
            obj[country] = []
            value.forEach((v, k) => obj[country].push([k, v]))
            return obj
          }, {})
        } else {
          saveState[key] = lastSavedState[key]
        }
      }
    })

    return queuedSave(__NAME__, saveState)
  } else {
    return Promise.resolve()
  }
}

const calcCache = (feature, existingCache = {}) => {
  const newCache = {}
  Object.values(feature.areas).forEach((current) => {
    if (typeof existingCache[current.gac] === 'undefined' && typeof newCache[current.gac] === 'undefined') {
      const prefix = gacNamePrefixes[current.type] ? gacNamePrefixes[current.type] + '.' : ''
      newCache[current.gac] = prefix + current.name
    }
  })
  if (Object.keys(newCache).length) {
    return { ...existingCache, ...newCache }
  } else {
    return existingCache
  }
}

const __NAME__ = 'MarketDataStateStore'

const actions = {
  dontPreserveState: () => ({}),
  loadState: (forceTopicGroup) => (dispatch) => {
    api.AppState.get(__NAME__)
      .then((response) => {
        const state = response.data.data

        if (typeof state.topicsVisibility === 'undefined' || !Object.keys(state.topicsVisibility).length) {
          state.topicsVisibility = Object.entries(marketDataInitialGroups).reduce((obj, [group, topics]) => {
            obj[group] = [...topics]
            return obj
          }, {})
        } else {
          Object.entries(state?.topicsVisibility).forEach(([topicGroupKey, topicGroupValue]) => {
            // convert state if necessary
            if (!Array.isArray(topicGroupValue)) {
              state.topicsVisibility[topicGroupKey] = Object.keys(topicGroupValue)
            }
          })
        }

        if (forceTopicGroup !== null) {
          state.currentTopicGroup = forceTopicGroup
        }
        dispatch({ type: 'setLoadedState', payload: state })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  setMapBounds: (bounds) => ({ bounds }),
  setCurrentTopicGroup: (currentTopicGroup) => ({ currentTopicGroup }),
  setExportOverallData: (exportOverallData) => ({ exportOverallData }),
  setTemplateGroup: (templateGroup) => ({ templateGroup }),
  toggleTopicsVisibility: (group, topics) => ({ group, topics }),
  toggleTopicGroupVisibility: (assetClass, group) => ({ assetClass, group }),
  setTopicsChartView: (topic, chartView) => ({ topic, chartView }),
  setTopicsAxisSource: (topic, axisSource) => ({ topic, axisSource }),
  setReferenceCurve: (referenceCurve) => ({ referenceCurve }),
  toggleGlobalLocationsInside: () => ({}),
  toggleGlobalTableVisibility: () => ({}),
  toggleGlobaTablelTreeView: () => ({}),
  toggleTopicsLocationsInside: (topic, bool) => ({ topic, bool }),
  toggleTopicsTableVisibility: (topic) => ({ topic }),
  toggleTopicsTableTreeView: (topic, bool) => ({ topic, bool }),
  addLocation: (feature, setAsMain = false) => ({ feature, setAsMain }),
  removeLocation: (locationID) => ({ locationID }),
  setMainLocation: (locationID) => ({ locationID }),
  addLocationListener: (listener) => ({ payload: listener }),
  rmvLocationListener: (listener) => ({ payload: listener }),
  setLocationsByGac: (gac, baseColor, locationColor) => (dispatch) => {
    return api.Location.byGac(gac).then((location) => {
      const feature = { ...location.data.data }
      let preparedFeature = {}
      const areaType = parseInt(Object.keys(feature.areas)[0])
      if (parseInt(Object.keys(feature.areas)[0]) > 100) {
        const submarketType =
          areaType === 107 ? 'office' : areaType === 108 ? 'residential' : 'logisticsRegion'
        preparedFeature = setLocationSubmarket(feature, feature.areas[`${areaType}`], submarketType)
      } else {
        feature.properties.matchLevel = feature.areas['10'] || feature.areas['20'] ? 'city' : 'county'
        feature.areas = Object.values(feature.areas)
        preparedFeature = [feature].reduce(reduceFeatures, [])[0]
      }
      dispatch({ type: 'setLocationsByGac', payload: { ...preparedFeature, baseColor, locationColor } })
    })
  },
  setTime: (time) => ({ time }),
  setYear: (year) => ({ year }),
  setLocationsGeotoolsProject: (locationId, projectId) => ({ locationId, projectId }),
  setCurrentCountry: (currentCountry) => ({ currentCountry }),
  setLocationSettings: (mainLocation, visibleLocations) => ({ mainLocation, visibleLocations }),
}

const reducer = {
  setFailure: (state, { payload }) => ({
    ...state,
    stateIsLoading: false,
    isFailure: true,
    messages: payload,
  }),
  dontPreserveState: (state, { payload }) => ({ ...state, dontPreserveState: true }),
  setLoadedState: (state, { payload }) => {
    const set = Object.keys(initialState).reduce((set, key) => {
      if (ignoreKeysOnUpdate[key]) {
        return set
      }

      if (typeof payload[key] !== 'undefined') {
        if (
          key === 'mainLocation' &&
          payload[key] !== null &&
          Object.values(payload[key]).some((value) => typeof value !== 'number')
        ) {
          Object.entries(payload[key]).forEach(([country, value]) => {
            payload[key][country] = Number(value)
          })
        }
        if (state[key] instanceof Map) {
          set[key] = new Map(payload[key])
        } else if (state[key] !== null && Object.values(state[key]).some((value) => value instanceof Map)) {
          set[key] = Object.entries(payload[key]).reduce((obj, [country, value]) => {
            obj[country] = new Map(value)
            return obj
          }, {})
        } else if (setLoadingSrc[key]) {
          set[key] = { ...payload[key], setBy: 'loader' }
        } else {
          set[key] = payload[key]
        }
      }

      if (Array.isArray(initialState[key])) {
        if (!Array.isArray(set[key])) {
          set[key] = [...initialState[key]]
        }
      } else if (typeof set[key] === 'object' && typeof initialState[key] === 'object' && set[key]) {
        set[key] = { ...initialState[key], ...set[key] }
        if (key === 'locations') {
          Object.values(set[key]).forEach((features) => {
            Object.values(features).forEach((feature) => {
              const { addressLevel, displayedName } = feature
              feature = identifyFeature(feature)
              if (addressLevel === '5' || addressLevel === '200') {
                feature.addressLevel = addressLevel
                feature.displayedName = displayedName
              }
              if (typeof feature.properties.countryCode === 'undefined') {
                feature.properties.countryCode = Object.values(feature.areas)[0].country_code
              }
            })
          }, {})
        }
      } else if (typeof set[key] === 'undefined') {
        if (Array.isArray(initialState[key])) {
          set[key] = [...initialState[key]]
        } else if (typeof initialState[key] === 'object' && initialState[key] !== null) {
          set[key] = { ...initialState[key] }
        } else {
          set[key] = initialState[key]
        }
      }
      return set
    }, {})

    if (typeof set.locations === 'object' && set.locations !== null) {
      set.gacsNameCache = {}
      Object.values(set.locations[set.currentCountry]).forEach((feature) => {
        set.gacsNameCache = calcCache(feature, set.gacsNameCache)
      })
    }

    lastSavedState = { ...state, ...set, stateIsLoading: false }
    return lastSavedState
  },
  setMapBounds: (state, { bounds }) => {
    return { ...state, mapBounds: bounds }
  },
  setCurrentTopicGroup: (state, { currentTopicGroup }) => {
    return { ...state, currentTopicGroup }
  },
  setReferenceCurve: (state, { referenceCurve }) => {
    return { ...state, referenceCurve }
  },
  setExportOverallData: (state, { exportOverallData }) => {
    return { ...state, exportOverallData }
  },
  setTemplateGroup: (state, { templateGroup }) => {
    return { ...state, templateGroup }
  },
  toggleTopicsVisibility: (state, { group, topics }) => {
    let newGroup
    if (typeof state.topicsVisibility[group] === 'undefined') {
      newGroup = []
    } else if (Array.isArray(state.topicsVisibility[group])) {
      newGroup = topics
    }
    return { ...state, topicsVisibility: { ...state.topicsVisibility, [group]: newGroup } }
  },
  toggleTopicGroupVisibility: (state, { assetClass, group }) => {
    const newAssetClass =
      typeof state.topicGroupVisibility[assetClass] === 'undefined'
        ? {}
        : { ...state.topicGroupVisibility[assetClass] }

    newAssetClass[group] = typeof newAssetClass[group] === 'undefined' ? false : !newAssetClass[group]

    return { ...state, topicGroupVisibility: { ...state.topicGroupVisibility, [assetClass]: newAssetClass } }
  },
  toggleGlobalTableVisibility: (state) => {
    const globalTableVisibility = !state.globalTableVisibility
    const topicsTableVisibility = Object.keys(state.topicsTableVisibility).reduce(
      (topicsTableVisibility, key) => {
        topicsTableVisibility[key] = globalTableVisibility
        return topicsTableVisibility
      },
      {}
    )
    return { ...state, globalTableVisibility, topicsTableVisibility }
  },
  toggleGlobaTablelTreeView: (state) => {
    const globalTableTreeView = !state.globalTableTreeView
    const topicsTableTreeView = Object.keys(state.topicsTableTreeView).reduce((topicsTableTreeView, key) => {
      topicsTableTreeView[key] = globalTableTreeView
      return topicsTableTreeView
    }, {})
    return { ...state, globalTableTreeView, topicsTableTreeView }
  },
  toggleGlobalLocationsInside: (state) => {
    const globalLocationsInside = !state.globalLocationsInside
    const topicsLocationsInside = Object.keys(state.topicsLocationsInside).reduce(
      (topicsLocationsInside, key) => {
        topicsLocationsInside[key] = globalLocationsInside
        return topicsLocationsInside
      },
      {}
    )
    return { ...state, globalLocationsInside, topicsLocationsInside }
  },
  toggleTopicsTableVisibility: (state, { topic }) => {
    const visible =
      typeof state.topicsTableVisibility[topic] === 'undefined'
        ? !state.globalTableVisibility
        : !state.topicsTableVisibility[topic]

    return { ...state, topicsTableVisibility: { ...state.topicsTableVisibility, [topic]: visible } }
  },
  toggleTopicsLocationsInside: (state, { topic, bool }) => {
    const inside =
      typeof bool !== 'undefined'
        ? bool
        : typeof state.topicsLocationsInside[topic] === 'undefined'
        ? !state.globalLocationsInside
        : !state.topicsLocationsInside[topic]

    return { ...state, topicsLocationsInside: { ...state.topicsLocationsInside, [topic]: inside } }
  },
  toggleTopicsTableTreeView: (state, { topic, bool }) => {
    const isTreeView =
      typeof bool !== 'undefined'
        ? bool
        : typeof state.topicsTableTreeView[topic] === 'undefined'
        ? !state.globalTableTreeView
        : !state.topicsTableTreeView[topic]

    return { ...state, topicsTableTreeView: { ...state.topicsTableTreeView, [topic]: isTreeView } }
  },

  setTopicsChartView: (state, { topic, chartView }) => {
    const topicsAxisSource = { ...state.topicsAxisSource }
    delete topicsAxisSource[topic]
    return {
      ...state,
      topicsAxisSource,
      topicsChartView: { ...state.topicsChartView, [topic]: chartView },
    }
  },
  setTopicsAxisSource: (state, { topic, axisSource }) => {
    return { ...state, topicsAxisSource: { ...state.topicsAxisSource, [topic]: axisSource } }
  },
  addLocation: (state, { feature, setAsMain = false }) => {
    const countryCode = feature.properties.countryCode
    // Set ID for new location
    const currentIDs = Object.keys(state.locations[countryCode])
    const currentColorIDs = currentIDs.map((id) => parseInt(id) % 5)
    const maxID = currentIDs.length > 0 ? Math.max(...currentIDs) : -1

    // new ID has to be bigger then current max, and colorID should not be in use
    let newID = new Array(5).findIndex((undef, index) => !currentColorIDs.includes(index))
    while (newID < maxID) newID += 5

    const newLocations = { ...state.locations }
    newLocations[countryCode] = { ...state.locations[countryCode], [newID]: feature }

    const newMainLocation = { ...state.mainLocation }
    newMainLocation[countryCode] =
      currentIDs.length === 0 || setAsMain ? newID : state.mainLocation[countryCode]

    const newVisibleLocations = { ...state.visibleLocations }
    newVisibleLocations[countryCode] = [...newVisibleLocations[countryCode], newID]

    // const newLocationsVisibilityMap = { ...state.locationsVisibilityMap }
    // newLocationsVisibilityMap[countryCode] = new Map(state.locationsVisibilityMap[countryCode]).set(
    //   String(newID),
    //   true
    // )

    return {
      ...state,
      currentCountry: countryCode,
      locations: newLocations,
      mainLocation: newMainLocation,
      // Set default values for all new locations in the according Maps:
      visibleLocations: newVisibleLocations,
      // Add all gac's of location to the gacsNameCache:
      gacsNameCache: calcCache(feature, state.gacsNameCache),
    }
  },
  removeLocation: (state, { locationID }) => {
    let remainingLocations = { ...state.locations }
    remainingLocations[state.currentCountry] = { ...remainingLocations[state.currentCountry] }
    delete remainingLocations[state.currentCountry][locationID]

    const newVisibleLocations = { ...state.visibleLocations }
    newVisibleLocations[state.currentCountry] = [...state.visibleLocations[state.currentCountry]].filter(
      (visibleLocation) => visibleLocation !== locationID
    )

    const newLocationsGeotoolsProject = { ...state.locationsGeotoolsProject }
    newLocationsGeotoolsProject[state.currentCountry] = {
      ...newLocationsGeotoolsProject[state.currentCountry],
      [locationID]: null,
    }

    const newMainLocation = { ...state.mainLocation }
    newMainLocation[state.currentCountry] = secureMainLocation(
      locationID,
      state.mainLocation[state.currentCountry],
      remainingLocations[state.currentCountry]
    )

    return {
      ...state,
      locations: remainingLocations,
      visibleLocations: newVisibleLocations,
      locationsGeotoolsProject: newLocationsGeotoolsProject,
      mainLocation: newMainLocation,
    }
  },
  setMainLocation: (state, { locationID }) => {
    const newMainLocation = { ...state.mainLocation }
    newMainLocation[state.currentCountry] = Number(locationID)
    state.locationListener.forEach((listener) => {
      listener(state.mainLocation, Number(locationID), state.locations[state.currentCountry])
    })
    return {
      ...state,
      mainLocation: newMainLocation,
    }
  },
  addLocationListener: (state, { payload }) => {
    const listener = [...state.locationListener]
    if (listener.findIndex((entry) => Object.is(entry, payload)) < 0) {
      listener.push(payload)
    }
    return {
      ...state,
      locationListener: listener,
    }
  },
  rmvLocationListener: (state, { payload }) => {
    const listener = state.locationListener.filter((entry) => !Object.is(entry, payload))
    return {
      ...state,
      locationListener: listener,
    }
  },
  setLocationsByGac: (state, { payload }) => {
    const countryCode = payload.properties.countryCode
    return {
      ...state,
      locationByGac: true,
      locations: { [countryCode]: { 1: payload } },
      mainLocation: { [countryCode]: 1 },
      currentCountry: countryCode,
      // Set default values for all new locations in the according Maps:
      visibleLocations: { [countryCode]: [1] },
      // Add all gac's of location to the gacsNameCache:
      gacsNameCache: calcCache(payload, {}),
    }
  },
  setAssetclass: (state, payload) => ({ ...state, assetClass: payload }),
  setTime: (state, { time }) => ({ ...state, time }),
  setYear: (state, { year }) => ({ ...state, year }),

  setLocationsGeotoolsProject: (state, { locationId, projectId }) => {
    const newLocationsGeotoolsProject = { ...state.locationsGeotoolsProject }
    newLocationsGeotoolsProject[state.currentCountry] = {
      ...state.locationsGeotoolsProject[state.currentCountry],
      [locationId]: projectId,
    }
    return {
      ...state,
      locationsGeotoolsProject: newLocationsGeotoolsProject,
    }
  },
  setCurrentCountry: (state, { currentCountry }) => {
    const gacsNameCache = Object.values(state.locations[currentCountry]).reduce(
      (newGacsNameCache, feature) => {
        newGacsNameCache = { ...newGacsNameCache, ...calcCache(feature, state.gacsNameCache) }
        return newGacsNameCache
      },
      {}
    )
    return { ...state, currentCountry, gacsNameCache }
  },
  setLocationSettings: (state, { mainLocation, visibleLocations }) => {
    const newMainLocation = { ...state.mainLocation }
    newMainLocation[state.currentCountry] = Number(mainLocation)
    const newVisibleLocations = { ...state.visibleLocations }
    newVisibleLocations[state.currentCountry] = visibleLocations.map((visibleLocation) =>
      Number(visibleLocation)
    )
    return { ...state, mainLocation: newMainLocation, visibleLocations: newVisibleLocations }
  },
}

export const [
  MarketDataStateContext,
  MarketDataStateProvider,
  useMarketDataStateStore,
  useMarketDataStateSelectors,
] = createStore(reducer, actions, initialState, onUpdate, __NAME__, selectorFactory)

// const $curLocation = (state) => {
//   return state.locations[state.currentCountry][state.mainLocation]
// }

function selectorFactory() {
  const $curCountry = (state) => state.currentCountry
  const $curLocations = (state) => state.locations
  const $curMainLocation = (state) => state.mainLocation
  const $curLocationsGeotoolsProject = (state) => state.locationsGeotoolsProject
  const $visibleLocations = (state) => state.visibleLocations

  const $curLocationCoords = createSelector(
    $curLocations,
    $curMainLocation,
    $curCountry,
    (curLocations, curMainLocation, curCountry) =>
      curLocations[curCountry][curMainLocation] &&
      curLocations[curCountry][curMainLocation].geometry.coordinates.slice().reverse()
  )

  const $curCountryLocations = createSelector(
    $curLocations,
    $curCountry,
    (curLocations, curCountry) => curLocations[curCountry]
  )

  const $curCountryMainLocation = createSelector(
    $curMainLocation,
    $curCountry,
    (curMainLocation, curCountry) => curMainLocation[curCountry]
  )

  const $curCountryVisibleLocations = createSelector(
    $visibleLocations,
    $curCountry,
    (visibleLocations, curCountry) => visibleLocations[curCountry]
  )

  const $curCountryLocation = createSelector(
    $curCountryLocations,
    $curCountryMainLocation,
    (curLocations, curMainLocation) => curLocations[curMainLocation]
  )

  const $curCountryLocationData = createSelector(
    $curCountryLocations,
    $curCountryMainLocation,
    $curCountryVisibleLocations,
    (curCountryLocations, curCountryMainLocation, curCountryVisibleLocations) => ({
      locations: curCountryLocations,
      mainLocation: curCountryMainLocation,
      visibleLocations: curCountryVisibleLocations,
    })
  )

  const $curCountryLocationsGeotoolsProject = createSelector(
    $curLocationsGeotoolsProject,
    $curCountry,
    (curLocationsGeotoolsProject, curCountry) => curLocationsGeotoolsProject[curCountry]
  )

  const $curLocationsMeta = createSelector(
    $curCountryLocations,
    $curCountryVisibleLocations,
    (curLocations, curCountryVisibleLocations) => {
      const locations = Object.entries(curLocations).reduce((locations, [id, location]) => {
        if (curCountryVisibleLocations.includes(Number(id))) {
          locations.push(location)
        }
        return locations
      }, [])
      const areaTypesPresent = Array.from(
        new Set(
          locations.reduce((types, location) => {
            Object.keys(location.areas).forEach((key) => {
              types.push(key)
            })
            return types
          }, [])
        )
      )
      return {
        countryCodesPresent: Object.entries(curLocations).reduce((arr, [, location]) => {
          const { countryCode } = location.properties
          if (!arr.includes(countryCode)) {
            arr.push(countryCode)
          }
          return arr
        }, []),
        cityTypesPresent: {
          A: locations.some((location) => findAreaByMarketCategory(location, 'A') !== undefined),
          B: locations.some((location) => findAreaByMarketCategory(location, 'B') !== undefined),
          C: locations.some((location) => findAreaByMarketCategory(location, 'C') !== undefined),
          D: locations.some((location) => findAreaByMarketCategory(location, 'D') !== undefined),
        },
        areaTagsPresent: {
          retail_forecast_available: locations.some((location) =>
            Object.values(location.areas).some((area) => area.retail_forecast_available === true)
          ),
          office_forecast_available: locations.some((location) =>
            Object.values(location.areas).some((area) => area.office_forecast_available === true)
          ),
          market_data_basis: locations.some((location) =>
            Object.values(location.areas).some((area) => area.market_data_basis === true)
          ),
          logistics_region_available: locations.some((location) =>
            Object.values(location.areas).some((area) => area.logistics_region_available === true)
          ),
        },
        areaTypesPresent,
      }
    }
  )

  const $curGac = createSelector($curCountryLocation, (curLocation) => {
    return curLocation ? gacFromLocation(curLocation) : null
  })
  const $curGacs = createSelector($curCountryLocations, (locations) => {
    return locations ? Object.values(locations).map((loc) => gacFromLocation(loc)) : null
  })

  return {
    $curCountryLocation,
    $curLocationCoords,
    $curLocationsMeta,
    $curGac,
    $curGacs,
    $curCountryLocations,
    $curCountryMainLocation,
    $curCountryLocationData,
    $curCountryLocationsGeotoolsProject,
    $curCountryVisibleLocations,
  }
}

export const MarketDataSelectors = Object.fromEntries(Object.keys(selectorFactory()).map((key) => [key, key]))

const gacFromLocation = (curLocation) => {
  const area = Object.entries(curLocation.areas).reduce((current, [type, area]) => {
    type = parseInt(type)
    if (
      type === 20 || // is city
      (type === 10 && (current === null || parseInt(current.type) === 30)) || // is municipality
      (type === 30 && current === null) || // is district
      (parseInt(curLocation.addressLevel) === 200 && type === 200)
    ) {
      current = area
    }
    return current
  }, null)
  return area ? area.gac : null
}
