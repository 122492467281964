import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { getThemeValue } from 'theming'

import api from 'stores/api'
import { useAuthStore } from 'stores/AuthStore'
import { useAppStore } from 'stores/AppStore'

import { ButtonLink } from '@ui/Buttons/ButtonLink'
import { Button } from '@ui/Buttons/Button'
import { FlexColumn, FlexItem } from '@layout/BuildingBlocks'

import { Form, Input } from '@forms'

import { CopyMedium } from 'stories/typography'

import { BudiconUnlock, BudiconEmail } from 'bgag-budicons'
import { LogoRiwisText } from '@utilities/SVG/Logos'

import { CTInquiryLink } from '../CTInquiryLink'

export const LoginForm = ({
  setRedirectToReferrer,
  showMessage = '',
  type,
  forgotPasswordLink = '/forgotPassword',
}) => {
  const translations = ['translation']
  let translationSpace = 'authentication'
  if (type === 'ct') {
    translations.unshift('highstreetReport')
    translationSpace = 'login'
  }

  const { t } = useTranslation(translations)
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')
  const colors = getThemeValue(theme, 'colors')

  const [currentUser, actions] = useAuthStore((state, actions) => [state.currentUser, actions])
  const [, { loadState: loadAppState }] = useAppStore()

  const [loginError, setLoginError] = useState(null)
  const [loginLoading, setLoginLoading] = useState(false)
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  })
  const setLocalCredentials = useCallback((target) => {
    setCredentials((prevState) => ({ ...prevState, [target.name]: target.value }))
  }, [])

  // texts
  const buttonText = type === 'ct' ? t(translationSpace + '.loginButton') : t('menu.login')
  const fogotPasswordText =
    type === 'ct' ? t(translationSpace + '.forgotPassword') : t(translationSpace + '.actionForgotPassword')
  const loginErrorText =
    type === 'ct' ? t(translationSpace + '.authenticationError') : t(translationSpace + '.loginError')

  useEffect(() => {
    if (typeof currentUser === 'object') {
      setRedirectToReferrer(true)
    }
  }, [currentUser, setRedirectToReferrer])

  const login = async () => {
    setLoginError(null)
    setLoginLoading(true)
    try {
      await api.Auth.login(credentials)
      actions.pullCurrentUser()
      loadAppState()
    } catch (error) {
      console.error('error', error)
      if (error?.response?.data?.messages?.[0] === 'User expired') {
        setLoginError(t(translationSpace + '.accountExpired'))
      } else {
        setLoginError(loginErrorText)
      }
      setLoginLoading(false)
    }
  }

  return (
    <>
      {type === 'l3plus' && (
        <CopyMedium tag="p" textAlign="center">
          {t(translationSpace + '.poweredBy')} <LogoRiwisText part="all" size="10" />
        </CopyMedium>
      )}
      {loginError && (
        <CopyMedium color={colors.base.error.default} tag="p">
          {loginError}
        </CopyMedium>
      )}
      {showMessage === 'passwordSet' && (
        <CopyMedium color={colors.base.success.default} tag="p">
          {t(translationSpace + '.passwordSet')}
        </CopyMedium>
      )}
      {showMessage === 'mailSent' && (
        <CopyMedium color={colors.base.success.default} tag="p">
          {t(translationSpace + '.mailSent')}
        </CopyMedium>
      )}
      <Form onSubmit={login}>
        <FlexColumn flexRowGap={spaces.rhythm.vertical.medium}>
          <FlexItem>
            <Input
              appearance="white"
              value={credentials.username}
              placeholder={t(translationSpace + '.username')}
              iconLeft={{ Icon: BudiconEmail }}
              name="username"
              autoFocus={true}
              onChange={setLocalCredentials}
              resetable={true}
            />
          </FlexItem>
          <FlexItem>
            <Input
              appearance="white"
              type="password"
              name="password"
              value={credentials.password}
              placeholder={t(translationSpace + '.password')}
              iconLeft={{ Icon: BudiconUnlock }}
              onChange={setLocalCredentials}
              resetable={true}
              showPassword={true}
            />
          </FlexItem>
        </FlexColumn>
        <FlexColumn
          flexRowGap={spaces.rhythm.vertical.large}
          mt={spaces.rhythm.vertical.large}
          alignItems="center"
        >
          <FlexItem width="100%">
            <Button
              type="submit"
              appearance="primaryShallow"
              shape="square"
              isLoading={loginLoading}
              stretch={true}
              text={buttonText}
            />
          </FlexItem>
          {type === 'ct' && (
            <FlexItem>
              <CTInquiryLink />
            </FlexItem>
          )}
          <FlexItem>
            <ButtonLink
              linkType="reactRouterDomLink"
              appearance="bare"
              to={forgotPasswordLink}
              text={fogotPasswordText}
            />
          </FlexItem>
        </FlexColumn>
      </Form>
    </>
  )
}
