import React from 'react'

import { StyledText } from '../StyledText'
import { typeStyles } from '../typeStyles'

import { defaultProps } from '../defaultProps'
import { defaultPropTypes } from '../defaultPropTypes'

export const MinionLight = ({ children, tag, ...props }) => (
  <StyledText scale="minion" {...props} {...typeStyles.minionLight} as={tag}>
    {children}
  </StyledText>
)

export const Minion = ({ children, tag, ...props }) => (
  <StyledText scale="minion" {...props} {...typeStyles.minion} as={tag}>
    {children}
  </StyledText>
)

export const MinionMedium = ({ children, tag, ...props }) => (
  <StyledText scale="minion" {...props} {...typeStyles.minionMedium} as={tag}>
    {children}
  </StyledText>
)

MinionLight.propTypes = defaultPropTypes
Minion.propTypes = defaultPropTypes
MinionMedium.propTypes = defaultPropTypes

MinionLight.defaultProps = defaultProps
Minion.defaultProps = defaultProps
MinionMedium.defaultProps = defaultProps
