import React from 'react'
import { ButtonDown, ButtonUp } from './SubComponents'

export const defaultProps = {
  buttonUp: <ButtonUp />,
  buttonDown: <ButtonDown />,
  debounce: null,
  defaultValue: null,
  disabled: false,
  formElementRefs: null,
  gap: '16px',
  id: null,
  maxFrom: null,
  maxTo: null,
  minFrom: null,
  minTo: null,
  name: 'inputRangeNumbers',
  nameFrom: 'from',
  nameTo: 'to',
  placeholderFrom: null,
  placeholderTo: null,
  onChange: () => {},
  stepFrom: null,
  stepTo: null,
  value: null,
  size: 'medium',
}
