import React from 'react'
import styled from 'styled-components'
import { compose, typography, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { defaultProps } from './defaultProps'
import { label } from './defaultPropTypes'
import {
  typeScale,
  typeStyle,
  defaultProps as textProps,
  defaultPropTypes as textPropTypes,
} from '@typography'

const excludeProps = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'isOpen',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
]

const StyledComponent = styled('div').withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  align-items: center;
  border-radius: 0 ${themeGet('navigationMain.badge.radius', '0')}
    ${themeGet('navigationMain.badge.radius', '0')} 0;
  background-color: ${themeGet('navigationMain.badge.colors.default.bg', 'lightgray')};
  color: ${themeGet('navigationMain.badge.colors.default.color', 'black')};
  cursor: pointer;
  display: flex;
  width: max-content;

  ${compose(typography, space)}

  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  
  &:hover {
    background-color: ${themeGet('navigationMain.badge.colors.hover.bg', 'black')};
    color: ${themeGet('navigationMain.badge.colors.hover.color', 'black')};
  }
`

export const NavigationLabel = ({ label, onClick, isOpen, ...props }) => {
  return (
    <StyledComponent
      onClick={onClick}
      isOpen={isOpen}
      typeScale="copy"
      typeStyle="copySemiBold"
      p={['0 10px', null, null, null, '1px 10px']}
      {...props}
    >
      {label}
    </StyledComponent>
  )
}

NavigationLabel.defaultProps = {
  ...defaultProps,
  ...textProps,
}
NavigationLabel.propTypes = {
  label,
  ...textPropTypes,
}
