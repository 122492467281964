import React, { useRef, useMemo, useEffect, useState, useCallback, memo, forwardRef } from 'react'
import { bool, number, string, oneOf } from 'prop-types'

import { isFunction } from 'lib/util'

import { FormElement, withFormElementProps, InputDate } from '@forms'
import { ButtonIcon } from '@ui/Buttons'
import { BudiconCalendar } from 'bgag-budicons'

import { OnClickOutside } from '@utilities/OnClickOutside'
import { Flyout } from '@utilities/Flyout'
import { FlexRowForwardedRef } from '@layout/BuildingBlocks'

import { buildSelectedDate, dateToValue, getDayOfWeekOfFirstDay, getOptions } from './util'
import { Calendar, CalendarHead, CalendarFoot } from './SubComponents'

import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { useCursor, useKeyboardNavigation } from '.'

import { defaultProps as textProps, defaultPropTypes as textPropTypes } from '@typography'

const groupStateToValue = (state) => {
  return state.value.date.value
}

const valueToGroupState = (value, displayDate) => {
  return {
    date: { value },
    displayMonth: { value: displayDate?.getMonth?.() ?? null },
    displayYear: { value: displayDate?.getFullYear?.() ?? null },
  }
}

const defaultOptions = []

const DatepickerComponent = forwardRef(
  (
    {
      appearance,
      calendarPlacement,
      name,
      placeholder,
      defaultValue,
      value,
      debounce,
      onChange,
      formElementRefs,
      size,
      ...props
    },
    ref
  ) => {
    const [initialValue] = useState(defaultValue ?? value ?? null)

    const onDebouncedUpdate = useCallback(
      (value) => {
        isFunction(onChange) && onChange({ id: props.id, name, value })
      },
      [onChange, props.id, name]
    )

    const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
      value,
      onDebouncedUpdate,
      debounce
    )

    const [internalValue, setInternalValue] = useControllableState(
      initialValue,
      internalValueDebouncer,
      onControllableStateChange
    )

    const selectedDate = useMemo(
      () =>
        typeof internalValue !== 'string' || internalValue.length === 0
          ? null
          : buildSelectedDate(internalValue),
      [internalValue]
    )

    const [displayDate, setDisplayDate] = useState(selectedDate === null ? new Date() : selectedDate)

    useEffect(() => {
      setDisplayDate(selectedDate === null ? new Date() : selectedDate)
    }, [selectedDate])

    const formState = useMemo(
      () => valueToGroupState(internalValue, displayDate),
      [internalValue, displayDate]
    )

    const onPreviousMonth = useCallback(() => {
      setDisplayDate((prev) => {
        const date = new Date(prev)
        date.setDate(1)
        date.setMonth(date.getMonth() - 1)
        return date
      })
    }, [])

    const onNextMonth = useCallback(() => {
      setDisplayDate((prev) => {
        const date = new Date(prev)
        date.setDate(1)
        date.setMonth(date.getMonth() + 1)
        return date
      })
    }, [])

    const onReset = useCallback(() => {
      setInternalValue(initialValue)
    }, [setInternalValue, initialValue])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.reset.current = onReset
      }
    }, [onReset, formElementRefs])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.value.current = internalValue
      }
    }, [internalValue, formElementRefs])

    const refocusOnClose = useRef(false)
    const [isActive, setIsActive] = useState()

    const options = useMemo(
      () => (!isActive ? defaultOptions : getOptions(displayDate, selectedDate)),
      [isActive, displayDate, selectedDate]
    )

    const [isFocusedOpener, setIsFocusedOpener] = useState(false)
    const [isFocusedHeadFoot, setIsFocusedHeadFoot] = useState(false)

    const onClickOpener = useCallback(() => {
      setIsActive((prev) => !prev)
    }, [])

    const onFocusOpener = useCallback(() => {
      setIsFocusedOpener(true)
    }, [])

    const onBlurOpener = useCallback(() => {
      setIsFocusedOpener(false)
    }, [])

    const onFocusHeadFoot = useCallback(() => {
      setIsFocusedHeadFoot(true)
    }, [])

    const onBlurHeadFoot = useCallback(() => {
      setIsFocusedHeadFoot(false)
    }, [])

    const refOpener = useRef()
    const refOpenerPositionContainer = useRef()
    const activeByArrow = useRef(false)

    const { cursorIndex, setNextCursorIndex } = useCursor({
      options,
      isActive,
      onPrevious: onPreviousMonth,
      onNext: onNextMonth,
      colsPerRow: 7,
    })

    useEffect(() => {
      if (cursorIndex >= options.length) {
        setNextCursorIndex(false, cursorIndex - 7)
      }
    }, [cursorIndex, options, setNextCursorIndex])

    const openDropdown = useCallback((byArrow) => {
      activeByArrow.current = byArrow
      setIsActive(true)
    }, [])

    const closeDropdown = useCallback((refocus = false) => {
      refocusOnClose.current = refocus
      setIsActive(false)
    }, [])

    const selectCursor = useCallback(() => {
      const cursorValue = dateToValue(options?.[cursorIndex]?.value)
      setInternalValue((prev) => {
        if (cursorValue !== prev) {
          return cursorValue
        }
        return prev
      })
      closeDropdown(true)
    }, [options, cursorIndex, setInternalValue, closeDropdown])

    useKeyboardNavigation({
      focused: !isFocusedHeadFoot && isFocusedOpener,
      active: !isFocusedHeadFoot && isActive,
      closeOnTab: false,
      closeDropdown,
      openDropdown,
      selectCursor,
      moveCursor: setNextCursorIndex,
    })

    const closeDropdownNoFocus = useCallback(() => closeDropdown(false), [closeDropdown])

    const jumpToDate = useCallback(
      (days = 0) => {
        const jumpTo = new Date()
        jumpTo.setDate(jumpTo.getDate() + days)

        const dayOfWeekFirstOfMonth = getDayOfWeekOfFirstDay(jumpTo)
        const index = jumpTo.getDate() - 1 + dayOfWeekFirstOfMonth

        setDisplayDate(jumpTo)
        setNextCursorIndex(false, index)
      },
      [setNextCursorIndex]
    )

    const onToday = useCallback(() => {
      jumpToDate()
    }, [jumpToDate])

    const onClickDate = useCallback(
      (date) => {
        setInternalValue(dateToValue(date))
        closeDropdown(true)
      },
      [setInternalValue, closeDropdown]
    )

    const indexInternalValue = useRef(null)
    const indexToday = useRef(null)

    useEffect(() => {
      indexInternalValue.current = options.findIndex((option) => option.type === 'selected')
      if (indexInternalValue.current < 0) {
        indexInternalValue.current = null
      }
      const todayDateKey = new Date().toDateString()
      indexToday.current = options.findIndex((option) => option.key === todayDateKey)
      if (indexToday.current < 0) {
        indexToday.current = null
      }
    }, [options])

    const onceOnActive = useRef()

    useEffect(() => {
      if (isActive && !onceOnActive.current) {
        setNextCursorIndex(activeByArrow, indexInternalValue.current ?? indexToday.current)
        onceOnActive.current = true
        activeByArrow.current = false
      }
    }, [isActive, setNextCursorIndex])

    useEffect(() => {
      if (!isActive) {
        onceOnActive.current = false
        if (refocusOnClose.current) {
          refocusOnClose.current = false
          refOpener.current.focus()
        }
      }
    }, [isActive, refOpener])

    const internalOnChange = useCallback(
      (target) => {
        if (target?.changeEmitter?.name === 'displayMonth') {
          setDisplayDate((prev) => {
            const date = new Date(prev)
            date.setDate(1)
            date.setMonth(target.value.displayMonth.value)
            return date
          })
        } else if (target?.changeEmitter?.name === 'displayYear') {
          setDisplayDate((prev) => {
            const date = new Date(prev)
            date.setDate(1)
            date.setFullYear(target.value.displayYear.value)
            return date
          })
        } else if (target?.changeEmitter?.name === 'quickselect') {
          jumpToDate(target.value.quickselect.value)
        } else {
          setInternalValue(groupStateToValue(target))
        }
      },
      [setInternalValue, jumpToDate]
    )

    return (
      <FormElement name={name} value={formState} onChange={internalOnChange}>
        <OnClickOutside handler={closeDropdownNoFocus}>
          <FlexRowForwardedRef flexColumnGap="2px" ref={refOpenerPositionContainer}>
            <InputDate
              appearance={appearance}
              forElementName={name}
              name="date"
              placeholder={placeholder}
              onFocus={closeDropdownNoFocus}
              {...props}
            />
            <ButtonIcon
              icon={{ Icon: BudiconCalendar, type: 'outline' }}
              appearance="gray"
              size="small"
              iconVariantSize="small"
              shape="square"
              ref={refOpener}
              onClick={onClickOpener}
              onFocus={onFocusOpener}
              onBlur={onBlurOpener}
            />
          </FlexRowForwardedRef>
          {isActive && (
            <Flyout placement={calendarPlacement} refOpener={refOpenerPositionContainer}>
              <CalendarHead
                forElementName={name}
                onFocus={onFocusHeadFoot}
                onBlur={onBlurHeadFoot}
                onPreviousMonth={onPreviousMonth}
                onNextMonth={onNextMonth}
              />
              <Calendar options={options} onClickDate={onClickDate} cursorIndex={cursorIndex} />
              <CalendarFoot
                forElementName={name}
                onFocus={onFocusHeadFoot}
                onBlur={onBlurHeadFoot}
                onToday={onToday}
              />
            </Flyout>
          )}
        </OnClickOutside>
      </FormElement>
    )
  }
)

DatepickerComponent.type = 'Datepicker'
DatepickerComponent.defaultProps = {
  appearance: 'ghost',
  calendarPlacement: 'bottomStart',
  size: 'small',
  debounce: null,
  disabled: false,
  placeholder: 'DD.MM.YYYY',
  ...textProps,
}

DatepickerComponent.propTypes = {
  appearance: string,
  calendarPlacement: oneOf([
    'topStart',
    'rightStart',
    'bottomStart',
    'leftStart',
    'topEnd',
    'rightEnd',
    'bottomEnd',
    'leftEnd',
  ]),
  debounce: number,
  disabled: bool,
  placeholder: string,
  size: string,
  ...textPropTypes,
}

export const Datepicker = withFormElementProps(memo(DatepickerComponent))
