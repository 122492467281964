const min = 1975
const max = 2050

export const defaultProps = {
  name: 'SliderYearRangeComponent',
  min,
  max,
  defaultValue: { from: min, to: max },
  debounce: 100,
  appearance: 'default',
}
