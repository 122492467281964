export const getCard = ({ colors, themeColors, radii, spaces }) => ({
  border: {
    radius: radii.tiny,
  },
  borderRimless: {
    radius: radii.extrahuge,
    highlighted: {
      radius: radii.huge,
      border: '6px solid',
    },
  },
  sizes: {
    bodyLeft: {
      public: {
        width: ['350px', '350px', '476px', '476px'],
      },
    },
    bodyRight: {
      public: {
        width: '350px',
      },
    },
  },
  space: {
    x: spaces.rhythm.horizontal.large,
    bottom: spaces.rhythm.vertical.large,
    top: spaces.rhythm.vertical.tiny,
    public: {
      top: spaces.rhythm.vertical.medium,
      bottom: spaces.rhythm.vertical.medium,
      headerTop: [spaces.rhythm.vertical.extraLarge, '55px'],
      headerBottom: [spaces.rhythm.vertical.extraLarge, '55px'],
      headerX: '42px',
      bodyY: [spaces.rhythm.vertical.large, spaces.rhythm.vertical.extraLarge],
      bodyX: ['89px', '144px'],
      minHeight: '150px',
    },
  },
  colors: {
    header: {
      public: {
        color: colors.brand.primary.default,
      },
      color: themeColors.app.color,
      highlighted: colors.highlighted,
    },
    body: {
      bg: colors.white,
      color: themeColors.app.color,
      highlighted: colors.lightgray[230],
      public: {
        bg: colors.lightgray[230],
      },
    },
    footer: {
      public: {
        bg: colors.lightgray[230],
      },
    },
    subtitle: {
      color: colors.gray[370],
    },
    rim: {
      bg: colors.lightgray[370],
      highlighted: {
        bg: colors.brand.primary.default,
        color: colors.highlighted,
        shadow: '0px 3px 3px 1px rgba(0,0,0,0.4)',
      },
    },
    tab: {
      bg: colors.grayScale[200],
      active: {
        bg: colors.white,
        color: themeColors.app.color,
      },
      inActive: {
        bg: colors.grayScale[200],
        color: colors.gray[270],
      },
    },
  },
  rim: {
    padding: spaces[2],
    border: {
      radius: radii.large,
    },
  },
  header: {
    columnGap: spaces.rhythm.horizontal.large,
    space: {
      top: spaces.rhythm.vertical.small,
      bottom: spaces.rhythm.vertical.nano,
    },
  },
  buttonBar: {
    space: {
      right: spaces.rhythm.horizontal.small,
    },
  },
  tab: {
    border: {
      radius: radii.tiny,
    },
  },
})
