import React, { memo, useEffect, useCallback, useState, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { border, compose, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { Cell } from 'rsuite-table'

import {
  excludeProps,
  getStyleHeader,
  getFlexStyle,
  mouseOutEllipsis,
  mouseOverEllipsis,
  styleEllipsis,
  typeMap,
  TableDataCellInput,
} from '@ui/Table'

import { typeScale, typeStyle, defaultProps as textProps } from '@typography'
import { defaultProps } from './defaultProps'
import { size } from './defaultPropTypes'

const StyledDataCell = styled(Cell).withConfig({
  shouldForwardProp: (prop) => !excludeProps.includes(prop),
})`
  color: ${(props) => themeGet('colors.theme.table.data.color', '#000')(props)};
  align-items: flex-start;
  box-sizing: border-box;
  white-space: normal;
  word-break: normal;
  word-wrap: normal;

  ${compose(typography, border)}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
`

export const TableDataCell = memo((props) => {
  const {
    render,
    onMouseEnter,
    onMouseLeave,
    outsideListenerElement = 'body',
    applyKey = 'Enter',
    discardKey = 'Esc',
    CustomInput,
    size,
    ...rest
  } = props

  const theme = useTheme()
  const [editMode, setEditMode] = useState(false)
  const inputRef = useRef()
  const { typeStyle, typeScale } = typeMap[size]
  const computedProps = {
    style: {},
    ellipsis: typeof rest.wordWrap !== 'undefined' ? !rest.wordWrap : true,
    ...rest,
    ...render?.(rest),
  }

  const value =
    computedProps.value ??
    props.children?.(props.rowData[props.dataKey], props.rowData) ??
    props.rowData[props.dataKey]

  const ellipsis = computedProps.ellipsis

  delete computedProps.ellipsis
  delete computedProps.onChange

  const styleAlign = rest.align ? getFlexStyle(rest.align) : getFlexStyle('left')
  const styleHeader = props.rowData?.isHeaderCell ? getStyleHeader(theme) : {}

  computedProps.style = { ...styleHeader, ...styleAlign, ...computedProps.style }

  if (!computedProps.onMouseEnter && onMouseEnter) {
    computedProps.onMouseEnter = (evt) => onMouseEnter(rest, evt)
  }
  if (!computedProps.onMouseLeave && onMouseLeave) {
    computedProps.onMouseLeave = (evt) => onMouseLeave(rest, evt)
  }

  const enableEditMode = useCallback(() => {
    if (rest.onChange && typeof rest.onChange === 'function') {
      setEditMode(true)
    }
  }, [rest.onChange])

  const onClose = useCallback(() => {
    setEditMode(false)
  }, [])

  const onOutsideClick = useCallback(
    (evt) => {
      if (
        (editMode && evt.target.id !== 'cellInputChange') ||
        (computedProps.inputId && evt.target.id !== computedProps.inputId)
      ) {
        setEditMode(false)
      }
    },
    [editMode, computedProps.inputId]
  )

  useEffect(() => {
    if (editMode === true) {
      inputRef.current.focus()
      let listener = inputRef.current
      while (listener.parentNode && (!listener.id || !['modal', 'root'].includes(listener.id))) {
        listener = listener.parentNode
      }
      const timeOut = setTimeout(() => {
        listener.addEventListener('click', onOutsideClick)
      }, 5)
      return () => {
        listener.removeEventListener('click', onOutsideClick)
        clearTimeout(timeOut)
      }
    }
  }, [editMode, onOutsideClick, outsideListenerElement, inputRef])

  return (
    <StyledDataCell {...computedProps} typeScale={typeScale} typeStyle={typeStyle} onClick={enableEditMode}>
      {editMode ? (
        CustomInput ? (
          <CustomInput {...rest} ref={inputRef} />
        ) : (
          <TableDataCellInput
            initValue={value}
            cellData={rest}
            applyKey={applyKey}
            discardKey={discardKey}
            onClose={onClose}
            id={computedProps.inputId}
            ref={inputRef}
            {...rest}
          />
        )
      ) : ellipsis ? (
        <span style={styleEllipsis}>
          <div>{/* empty div prevents auto hover on ellipsis of safari browser */}</div>
          <span
            className="mouseOverSelector"
            onMouseOver={mouseOverEllipsis}
            onMouseOut={mouseOutEllipsis}
            style={{ maxWidth: 'max-content' }}
          >
            {value}
          </span>
        </span>
      ) : (
        value
      )}
    </StyledDataCell>
  )
})

TableDataCell.defaultProps = {
  ...defaultProps,
  ...textProps,
}

TableDataCell.propTypes = {
  size,
}
