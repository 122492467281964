import React from 'react'
import themeGet from '@styled-system/theme-get'
import styled from 'styled-components'

const Container = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  padding-top: ${(props) => !props.isRimlessHeader && themeGet('spaces.2', '0')};
  width: ${(props) => props.stretch && '100%'};
`

export const CardButtonBar = ({ isRimlessHeader, stretch, children }) => (
  <Container isRimlessHeader={isRimlessHeader} stretch={stretch}>
    {children}
  </Container>
)
