import { string, shape } from 'prop-types'
import { iconShape } from '../../defaultPropTypes'

const itemProps = shape({
  icon: iconShape,
  label: string.isRequired,
  route: string,
})

export { itemProps }
