import React, { useState, useCallback, useEffect, useRef, useReducer } from 'react'
import { func, string, arrayOf, oneOfType, shape, oneOf } from 'prop-types'

import { useId } from 'react-id-generator'
import styled from 'styled-components'

import { Flyout } from '@utilities/Flyout'
import { ButtonIcon, ButtonLink } from '@ui/Buttons'
import { DropdownList, ListItemWrapper, ListItem } from '@ui/DropdownList'

import { useKeyPress } from 'lib/hooks'

const Dropdown = styled.div`
  position: relative;
`

const DropdownHeader = styled.div`
  display: flex;
  width: max-content;
  position: relative;
`

export const DropdownMenu = ({ placement, icon, name, items }) => {
  const [isActive, setIsActive] = useState(false)
  const refsListItems = useRef([])
  const refParent = useRef()
  const refOpener = useRef()

  const arrowUpPressed = useKeyPress('ArrowUp')
  const arrowDownPressed = useKeyPress('ArrowDown')

  const dropdownItemId = useId(items.length, 'dropdown-')
  const [dropdownId] = useId()

  const reducer = (state, action) => {
    switch (action.type) {
      case 'arrowUp':
        return state !== 0 && state !== null ? state - 1 : refsListItems.current.length - 1
      case 'arrowDown':
        return state === null ? 0 : state !== refsListItems.current.length - 1 ? state + 1 : 0

      default:
        throw new Error()
    }
  }

  const [focusedIndex, dispatch] = useReducer(reducer, null)

  const handleMouseEnter = () => {
    setIsActive(true)
  }

  const handleMouseLeave = () => {
    setIsActive(false)
  }

  const handleClick = () => {
    setIsActive((isActive) => !isActive)
  }

  const handleDocumentKeyEvent = useCallback((event) => {
    if (refParent?.current?.contains(event.target) !== true) {
      event.stopImmediatePropagation()
      setIsActive(false)
    }
  }, [])

  useEffect(() => {
    if (isActive && arrowUpPressed) {
      dispatch({ type: 'arrowUp' })
    }
  }, [isActive, arrowUpPressed])

  useEffect(() => {
    if (isActive && arrowDownPressed) {
      dispatch({ type: 'arrowDown' })
    }
  }, [isActive, arrowDownPressed])

  useEffect(() => {
    focusedIndex !== null && focusedIndex >= 0 && refsListItems.current[focusedIndex].focus()
  }, [focusedIndex])

  useEffect(() => {
    isActive
      ? document.addEventListener('keyup', handleDocumentKeyEvent)
      : document.removeEventListener('keyup', handleDocumentKeyEvent)
  }, [isActive, handleDocumentKeyEvent])

  return (
    <Dropdown onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={refParent} id={dropdownId}>
      <DropdownHeader alignItems="center" onClick={handleClick} ref={refOpener}>
        <ButtonIcon
          appearance="bare"
          shape="shapeless"
          width="32px"
          height="30px"
          isActive={isActive}
          {...icon}
        />
      </DropdownHeader>
      <Flyout
        refOpener={refOpener}
        active={isActive}
        placement={placement}
        renderTriangle={true}
        style={{ left: '-12px' }}
        distance={0}
        alignOffset={0}
      >
        <DropdownList>
          {items.map((item, index) => (
            <ListItemWrapper key={dropdownItemId[index]}>
              <ListItem>
                {item.to && (
                  <ButtonLink
                    appearance="light"
                    leftAlign={true}
                    linkType="reactRouterDomLink"
                    ref={(el) => (refsListItems.current[index] = el)}
                    stretch={true}
                    shape="square"
                    text={item.label}
                    to={item.to}
                  />
                )}
                {item.handleClick && (
                  <ButtonLink
                    appearance="light"
                    href="#0"
                    leftAlign={true}
                    onClick={(event) => {
                      event.preventDefault()
                      item.handleClick()
                    }}
                    ref={(el) => (refsListItems.current[index] = el)}
                    shape="square"
                    stretch={true}
                    text={item.label}
                  />
                )}
              </ListItem>
            </ListItemWrapper>
          ))}
        </DropdownList>
      </Flyout>
    </Dropdown>
  )
}

DropdownMenu.propTypes = {
  placement: oneOf([
    'topStart',
    'rightStart',
    'bottomStart',
    'leftStart',
    'topEnd',
    'rightEnd',
    'bottomEnd',
    'leftEnd',
  ]),
  items: arrayOf(
    oneOfType([
      shape({
        label: string,
        to: string,
      }),
      shape({
        label: string,
        onClick: func,
      }),
    ])
  ),
  name: string,
}

DropdownMenu.defaultProps = {
  placement: 'bottomStart',
  items: [
    {
      label: 'Dropdown Item 1',
      to: '/abc',
    },
    {
      label: 'Dropdown Item 2',
      to: '/abc',
    },
  ],
  name: 'Dropdown Menu',
}
