import { variant } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const appearance = (props) =>
  variant({
    prop: 'type',
    variants: {
      info: {
        backgroundColor: themeGet('colors.base.white', 'whote')(props),
        borderColor: themeGet('colors.base.gray.500', 'gray')(props),
        color: themeGet('colors.app.color', 'black')(props),
        div: {
          backgroundColor: themeGet('colors.base.gray.500', 'white')(props),
          svg: {
            fill: themeGet('colors.base.white', 'white')(props),
            stroke: themeGet('colors.base.white', 'white')(props),
          },
        },
      },
      warning: {
        backgroundColor: themeGet('colors.base.white', 'whote')(props),
        borderColor: themeGet('colors.base.warning.default', 'yellow')(props),
        color: themeGet('colors.base.gray.900', 'black')(props),
        div: {
          backgroundColor: themeGet('colors.base.warning.default', 'yellow')(props),
          svg: {
            fill: themeGet('colors.base.white', 'white')(props),
            stroke: themeGet('colors.base.white', 'white')(props),
          },
        },
      },
      error: {
        backgroundColor: themeGet('colors.base.white', 'white')(props),
        borderColor: themeGet('colors.base.error.darken', 'red')(props),
        color: themeGet('colors.base.gray.900', 'black')(props),
        div: {
          backgroundColor: themeGet('colors.base.error.darken', 'red')(props),
          svg: {
            fill: themeGet('colors.base.white', 'white')(props),
            stroke: themeGet('colors.base.white', 'white')(props),
          },
        },
      },
      success: {
        backgroundColor: themeGet('colors.base.white', 'white')(props),
        borderColor: themeGet('colors.base.success.darken', 'green')(props),
        color: themeGet('colors.base.gray.900', 'black')(props),
        div: {
          backgroundColor: themeGet('colors.base.success.darken', 'green')(props),
          svg: {
            fill: themeGet('colors.base.white', 'white')(props),
            stroke: themeGet('colors.base.white', 'white')(props),
          },
        },
      },
    },
  })
