import React, { useMemo } from 'react'

import { Box } from '@layout/BuildingBlocks'

import { bgImages } from 'assets/modules/bgImages'

const _outerStyle = {
  height: '100vh',
  width: '100vw',
  padding: '0px',
}

const _style = {
  height: '100vh',
  width: '100vw',
  position: 'absolute',
  padding: '0px',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}

const _backdropStyle = {
  height: '50vh',
  width: '100vw',
  position: 'absolute',
  bottom: '0px',
  backgroundColor: 'rgba(249,249,249,0.4)',
  backdropFilter: 'blur(10px)',
}
const _altBackdropStyle = {
  height: '100vh',
  width: '100vw',
  position: 'absolute',
  background: `linear-gradient(328.46deg, rgba(207, 222, 64, 0.435) 0.16%, rgba(255, 255, 255, 0) 33.43%),
               linear-gradient(120.87deg, rgba(0, 105, 124, 0.6) -2.74%, rgba(196, 196, 196, 0) 83.7%)`,
}

const _containerStyle = {
  position: 'relative',
}

export const Background = ({ image = null, children, gradient = '', withFilter = true, style = {} }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const oldFilter = urlParams.get('oldFilter') === 'true'

  const bgStyle = useMemo(() => {
    let bgImage
    if (image !== null) {
      bgImage = image
    } else {
      const images = bgImages()
      bgImage = images.length ? images[Math.floor(Math.random() * images.length)] : 'nope.jpg'
    }
    return {
      ..._style,
      backgroundImage: !gradient.length ? `url(${bgImage})` : `${gradient}, url(${bgImage})`,
      ...style,
    }
  }, [image, style, gradient])

  return (
    <Box style={_outerStyle}>
      <Box style={bgStyle} />
      {withFilter &&
        (oldFilter ? (
          <Box style={_altBackdropStyle}></Box>
        ) : withFilter ? (
          <Box style={_backdropStyle}></Box>
        ) : (
          ''
        ))}
      <Box style={_containerStyle}>{children}</Box>
    </Box>
  )
}
