import React, { memo, useEffect, useCallback, useMemo, useState } from 'react'

import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { isFunction } from 'lib/util'

import { FormElement, Checkbox, withFormElementProps } from '@forms'
import { FlexContainer, FlexItem } from '@layout/BuildingBlocks'

import { defaultProps } from './defaultProps'
import {
  alignItems,
  flexGrowItems,
  name,
  itemsSpaceX,
  itemsSpaceY,
  justifyItems,
  width,
} from './defaultPropTypes'

const groupStateToValue = (state) =>
  Object.entries(state)
    .filter((entry) => entry[1].checked)
    .map((entry) => entry[0])

const valueToGroupState = (value) => Object.fromEntries(value.map((key) => [key, { checked: true }]))

const Row = ({
  key,
  name,
  alignItems,
  justifyContent,
  flexColumnGap,
  flexRowGap,
  flexWrap,
  flexGrowItems,
  width,
  items,
}) => {
  return (
    <FlexContainer
      key={key}
      alignItems={alignItems}
      justifyContent={justifyContent}
      flexColumnGap={flexColumnGap}
      flexRowGap={flexRowGap}
      flexWrap={flexWrap}
      width={width}
    >
      {items.map((item, index) => (
        <FlexItem flexGrow={flexGrowItems} key={index} {...(item?.style ?? {})}>
          <Checkbox
            value={item.value}
            disabled={item.disabled}
            forElementName={name}
            labelStyle={{ width: '100%' }}
          >
            {item.renderChild}
          </Checkbox>
        </FlexItem>
      ))}
    </FlexContainer>
  )
}

export const CheckboxBarComponent = ({
  alignItems,
  defaultValue,
  itemsSpaceX,
  itemsSpaceY,
  justifyItems,
  flexGrowItems,
  formElementRefs,
  items,
  name,
  onChange,
  width,
  value,
  debounce,
  flexColumnGap,
  flexWrap,
  ...props
}) => {
  const [initialValue] = useState(defaultValue ?? value ?? [])
  const onDebouncedUpdate = useCallback(
    (checkedChilds, changeEmitter) => {
      isFunction(onChange) && onChange({ id: props.id, name, value: checkedChilds, changeEmitter })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [checkedChilds, setCheckedChilds] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(() => valueToGroupState(checkedChilds), [checkedChilds])

  const internalOnChange = useCallback(
    (evt) => {
      const nextCheckedChilds = groupStateToValue(evt.value)
      setCheckedChilds(nextCheckedChilds, evt.changeEmitter)
    },
    [setCheckedChilds]
  )

  const onReset = useCallback(() => {
    setCheckedChilds(initialValue)
  }, [setCheckedChilds, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = checkedChilds
    }
  }, [checkedChilds, formElementRefs])

  const rows = useMemo(() => (items.length && !Array.isArray(items[0]) ? [items] : items), [items])

  return (
    <FormElement {...props} name={name} value={formState} onChange={internalOnChange}>
      {rows.length > 1 ? (
        <FlexContainer flexDirection="column" flexRowGap={itemsSpaceY} width={width}>
          {rows.map((items, index) => (
            <Row
              key={index}
              name={name}
              alignItems={alignItems}
              justifyContent={justifyItems}
              flexColumnGap={itemsSpaceX}
              flexRowGap={itemsSpaceY}
              flexWrap={flexWrap ?? 'nowrap'}
              flexGrowItems={flexGrowItems}
              width={width}
              items={items}
            />
          ))}
        </FlexContainer>
      ) : (
        <Row
          name={name}
          alignItems={alignItems}
          justifyContent={justifyItems}
          flexColumnGap={itemsSpaceX}
          flexRowGap={itemsSpaceY}
          flexWrap={flexWrap ?? 'nowrap'}
          flexGrowItems={flexGrowItems}
          width={width}
          items={items}
        />
      )}
    </FormElement>
  )
}

CheckboxBarComponent.type = 'CheckboxBar'
CheckboxBarComponent.defaultProps = defaultProps
CheckboxBarComponent.propTypes = {
  alignItems,
  flexGrowItems,
  justifyItems,
  itemsSpaceX,
  itemsSpaceY,
  name,
  width,
}

export const CheckboxBar = withFormElementProps(memo(CheckboxBarComponent))
