import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { allowedProps } from './config'
import { string } from 'prop-types'

const StyledReactRouterDomLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => allowedProps.includes(prop),
})`
  cursor: pointer;
  outline: none;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.disabled && {
      cursor: 'not-allowed',
      '&:hover': { textDecoration: 'none' },
    }}
`

export const ReactRouterDomLink = forwardRef(({ children, ...props }, ref) => {
  const handleClick = props.disabled
    ? (e) => {
        e.preventDefault()
        return false
      }
    : null
  return (
    <StyledReactRouterDomLink className={props.className} ref={ref} onClick={handleClick} {...props}>
      {props.iconAlign === 'left' && children}
      {props.text && props.text}
      {props.iconAlign === 'right' && children}
    </StyledReactRouterDomLink>
  )
})

const withIcon = (Component) => {
  const WithIcon = ({ children, ...props }) => {
    return (
      <Component ref={props.forwardedRef} {...props} text={null} iconAlign="left">
        {children}
      </Component>
    )
  }
  return forwardRef((props, ref) => {
    return <WithIcon {...props} forwardedRef={ref} />
  })
}

const withIconAndText = (Component) => {
  const WithIconAndText = ({ children, ...props }) => {
    return (
      <Component ref={props.forwardedRef} {...props}>
        {children}
      </Component>
    )
  }
  return forwardRef((props, ref) => {
    return (
      <WithIconAndText {...props} forwardedRef={ref}>
        {props.children}
      </WithIconAndText>
    )
  })
}

export const ReactRouterDomLinkWithIcon = withIcon(ReactRouterDomLink)
export const ReactRouterDomLinkWithIconAndText = withIconAndText(ReactRouterDomLink)

ReactRouterDomLink.defaultProps = {
  text: 'Text',
}

ReactRouterDomLink.propTypes = {
  text: string,
}

ReactRouterDomLinkWithIcon.defaultProps = {
  text: 'Text',
}

ReactRouterDomLinkWithIcon.propTypes = {
  text: string,
}
