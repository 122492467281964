import { clamp } from './math'

/**
 * Color conversion functions
 *
 * Goal is to support any color syntax
 * https://drafts.csswg.org/css-color/#typedef-color
 */

export function rgbToHex(rgb) {
  const components = parseRgb(rgb)
  if (components.length === 4) {
    components[3] = Math.round(components[3] * 255)
  }
  const toHex = (cmp) => cmp.toString(16).padStart(2, '0')
  return `#${components.map(toHex).join('')}`
}

export function hexToRgb(hex, alpha) {
  const components = parseHex(hex)
  if (typeof alpha !== 'undefined') {
    components.push(alpha)
  }
  const content = components.join(', ')
  return components.length === 3 ? `rgb(${content})` : `rgba(${content})`
}

/**
 * Takes a valid rgb(a) function string and returns the components as integers
 * https://drafts.csswg.org/css-color/#funcdef-rgb
 */
export function parseRgb(rgb) {
  // support "," and " " as separators
  const separator = rgb.includes(',') ? ',' : ' '
  const match = /rgba?\((.*)\)/.exec(rgb)

  if (match === null) {
    throw Error('Invalid rgb string')
  }

  // parse components as strings
  const components = match[1]
    .split(separator)
    .map((s) => s.trim())
    .filter(Boolean) // case multiple whitespaces as separators

  if (components.length < 3) {
    throw Error('Too few components in this rgb string')
  }
  if (components.length > 4) {
    throw Error('Too many components in this rgb string')
  }

  components[0] = toByteRange(components[0])
  components[1] = toByteRange(components[1])
  components[2] = toByteRange(components[2])

  if (components.length === 4) {
    components[3] = toAlphaRange(components[3])
  }

  return components
}

function toByteRange(str) {
  if (str.includes('%')) {
    const numPart = str.slice(0, str.length - 1)
    str = Math.round((numPart / 100) * 255)
  }
  return clamp(parseInt(str), 0, 255)
}

function toAlphaRange(str) {
  if (str.includes('%')) {
    const numPart = str.slice(0, str.length - 1)
    str = numPart / 100
  }
  return clamp(Number(str), 0, 1)
}

/**
 * Takes a valid hex string and returns the components as integers
 * https://drafts.csswg.org/css-color/#hex-notation
 */
export function parseHex(hex) {
  hex = hex.slice(1)
  if (hex.length === 3 || hex.length === 4) {
    hex = duplicateChars(hex)
  }
  const components = []
  for (let i = 0; i < hex.length; i += 2) {
    let component = hex.slice(i, i + 2)
    component = parseInt(component, 16)
    components.push(component)
  }
  if (components.length === 4) {
    // round to three digits for practicability
    components[3] = Math.round((components[3] * 1000) / 255) / 1000
  }
  return components
}

function duplicateChars(str) {
  return Array.from(str)
    .flatMap((el) => [el, el])
    .join('')
}
