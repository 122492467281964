import { createDataCache, validators } from 'lib/cache'
import api from 'stores/api'

const conditionals = [
  { name: 'objectType' },
  {
    name: 'geometryMeta',
    stringify: (val) => val[0],
    validators: [
      validators.isArray,
      validators.length(2, 2),
      (val) => validators.isType('string')(val[0]),
      (val) => validators.isArray(val),
    ],
  },
  { name: 'countryCodes' },
]

const getter = (objectType, geometryMeta, countryCodes) => {
  const geometry = geometryMeta[1]
  return api.RiwisObject.byType({
    type: objectType,
    params: { geometry, countryCodes },
  }).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    const { dateOfExport, features } = result.data.data
    return { dateOfExport, features }
  })
}

export const [getObjects, bustObjects] = createDataCache(conditionals, getter, { name: 'ObjectsCache' })
