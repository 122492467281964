import { array, oneOfType, number, string } from 'prop-types'

const gridAutoRows = oneOfType([string, array])
const gridColumnGap = oneOfType([string, number, array])
const gridRowGap = oneOfType([string, number, array])
const gridTemplateColumns = oneOfType([number, string, array])
const gridTemplateRows = oneOfType([number, string, array])
const gridTemplateAreas = oneOfType([string, array])
const marginLeft = oneOfType([number, string, array])
const marginRight = oneOfType([number, string, array])

export {
  gridAutoRows,
  gridColumnGap,
  gridRowGap,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  marginLeft,
  marginRight,
}
