import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import appConfig from 'config/appConfig'

export const VersionCheck = ({ children }) => {
  const location = useLocation()
  const [needsRefresh, setNeedsRefresh] = useState(false)

  useEffect(() => {
    let isMounted = true

    fetch('appVersion.json', {
      // cache no-cache: first check version with server before using cached file
      cache: 'no-cache',
    })
      .then((response) => response.json())
      .then((version) => {
        if (isMounted && appConfig.appVersion !== version.current) {
          setNeedsRefresh(true)
        }
      })

    return () => {
      isMounted = false
    }
  }, [location])

  if (needsRefresh) {
    setTimeout(() => {
      window.location.reload()
    }, 0)
    return null
  } else {
    return children
  }
}
