import React, { forwardRef, useCallback, useState } from 'react'
import { Input } from '@forms'

export const TableDataCellInput = forwardRef(
  ({ initValue, applyKey, discardKey, onClose, inputId = 'cellInputChange', onChange, cellData }, ref) => {
    const [value, setValue] = useState(initValue)

    const onInputChange = useCallback((target) => {
      setValue(target.value)
    }, [])

    const applyOrDiscard = useCallback(
      (evt) => {
        if (evt.key === applyKey) {
          onClose()
          onChange({ target: { value, id: inputId, cellData } })
        }
        if (evt.key === discardKey) {
          onClose()
        }
      },
      [applyKey, discardKey, onClose, value, onChange, inputId, cellData]
    )

    return (
      <Input
        id={inputId}
        ref={ref}
        mb={0}
        value={value}
        onChange={onInputChange}
        onKeyDown={applyOrDiscard}
      />
    )
  }
)
