import { useEffect, useState, useCallback } from 'react'

export const useResizeObserver = (ref, callback) => {
  const [contentRect, setContentRect] = useState(
    ref?.current?.getBoundingClientRect?.() ?? { width: null, height: null }
  )

  const handleResize = useCallback(
    (entries) => {
      if (!Array.isArray(entries)) {
        return
      }

      const entry = entries[0]
      setContentRect(entry.contentRect)

      if (callback) {
        callback(entry)
      }
    },
    [callback]
  )

  useEffect(() => {
    if (!ref.current) {
      return
    }

    let resizeObserver = new ResizeObserver((entries) => handleResize(entries))
    resizeObserver.observe(ref.current)

    return () => {
      resizeObserver.disconnect()
      resizeObserver = null
    }
  }, [ref, handleResize])

  return [contentRect.width, contentRect.height]
}
