/** Asserts that conditionals are of required form
 * conditional schema: [{ name?: string, validators?: [], toString?: function}]
 */
export function validateConditionals(conditionals) {
  isArray(conditionals)
  for (let rules of conditionals) {
    rules.validators = rules.validators || []
    rules.validators && rules.validators.forEach(isFunction)
    rules.stringify && isFunction(rules.stringify)
  }
}

/** Apply validators defined in conditionals on arguments
 * Also asserts that the argument length equals the conditionals length
 */
export function validateArgs(args) {
  args.forEach((arg, idx) =>
    arg.validators.forEach((validator) => {
      try {
        validator(arg.value)
      } catch (err) {
        throw Error(`Validation Error on argument "${arg.name || `idx${idx}`}": ${err.message}`)
      }
    })
  )
}

// Validators
// A Validator is a function that takes one argument.
// It throws an Error if a condition is not met.
// Otherwise it returns true

const assert = (bool, msg = '') => {
  if (!bool) throw Error(msg)
  return true
}

const isType = (type) => (val) =>
  assert(typeof val === type, `Value "${val}" is not ${type}. It is ${typeof val}`)
const isFunction = isType('function')
const isArray = (val) => assert(Array.isArray(val), `Value "${val}" is not an array. It is ${typeof val}.`)
const length =
  (min = 0, max = Infinity) =>
  (val) =>
    minLength(min)(val) && maxLenght(max)(val)
const minLength = (min) => (val) =>
  assert(val.length >= min, `Length must be at least ${min}. It is ${val.length}`)
const maxLenght = (max) => (val) =>
  assert(val.length <= max, `Length must be at most ${max}. It is ${val.length}`)
const arrayOf = (type, min, max) => (val) =>
  isArray(val) && length(min, max)(val) && val.forEach(isType(type))

export const validators = { isArray, length, minLength, maxLenght, assert, arrayOf, isType }
