export const getDropdown = ({ radii, colors, themeColors }) => ({
  bg: colors.white,
  header: {
    px: '20px',
    py: '16px',
    mb: '12px',
    bg: themeColors.app.bg,
  },
  footer: {
    px: '20px',
    py: '16px',
    mt: '12px',
    bg: colors.lightgray[230],
  },
  spacer: {
    height: {
      top: '12px',
      bottom: '12px',
    },
    bg: colors.white,
  },
  spaceX: {
    optionList: {
      default: '10px 11px 0 16px',
      hasOverflow: '0 5px 0 16px',
    },
    rsTable: {
      hasOverflow: '0 8px 0 16px',
    },
  },

  option: {
    radius: radii.small,
    py: '9px',
    px: '12px',

    default: {
      bg: colors.white,
      color: colors.gray[900],
      odd: {
        bg: colors.lightgray[50],
        color: colors.gray[900],
      },
      even: {
        bg: colors.lightgray[170],
        color: colors.gray[900],
      },
    },
    hover: {
      bg: colors.lightgray[230],
      color: colors.gray[1000],
    },
    cursor: {
      bg: colors.lightgray[230],
      color: colors.gray[1000],
    },
    selected: {
      bg: colors.gray[600],
      color: colors.lightgray[50],
      fontWeight: 500,
    },
    selectedHasCursor: {
      bg: colors.lightgray[700],
      color: colors.lightgray[50],
      fontWeight: 500,
    },
    disabled: {
      bg: colors.white,
      color: colors.gray[300],
    },
  },
})
