import bgagColors from 'config/colors/bgag-colors.json'
import Color from 'lib/color'

export const colorCalculator = (baseColor, type = 0, colorIndex = 1, amountOfNeededColors = 2) => {
  // type 1 : darken
  // type 2 : lighten
  const newColor = Color(baseColor).hsl()
  const maxChange = 15

  if (type === 1) {
    const changeBy = colorIndex * ((5 + newColor.color[2]) / amountOfNeededColors)
    newColor.color[2] -= Math.min(changeBy, maxChange)
  } else if (type === 2) {
    const changeBy = colorIndex * ((95 - newColor.color[2]) / amountOfNeededColors)
    newColor.color[2] += Math.min(changeBy, maxChange)
  }

  return Color(newColor).hex()
}

export const dataColorAllocator = (index, source) => {
  const colorList = {
    default: [
      '#C84CB3',
      '#9B6EB1',
      '#7CD1C0',
      '#29AC9A',
      '#137764',
      '#B67E3A',
      '#D8811E',
      '#F8A03C',
      '#E2CD31',
      '#FCFE71',
      '#52C4F1',
      '#009CDA',
      '#1C6193',
      '#64417B',
    ],
    defaultReversed: [
      '#4CB3C8',
      '#6EB19B',
      '#D1C07C',
      '#AC9A29',
      '#7371FE',
      '#3146E2',
      '#3C94F8',
      '#1E75D8',
      '#3A72B6',
      '#771326',
      '#AC293B',
      '#D17C8D',
      '#84B16E',
      '#4CC861',
    ],

    bws: ['#C84CB3', '#137764', '#29AC9A', '#7CD1C0', '#B67E3A', '#F8A03C', '#E2CD31', '#FCFE71'],
    sixColors: ['#9B6EB1', '#29AC9A', '#D8811E', '#E2CD31', '#009CDA', '#64417B'],
    takeUpIndustries: [
      '#7CD1C0',
      '#29AC9A',
      '#137764',
      '#FCFE71',
      '#52C4F1',
      '#009CDA',
      '#C84CB3',
      '#64417B',
    ],
    censusPopulationAge: ['#E2CD31', '#F8A03C', '#D8811E', '#B67E3A'],
    censusCivilStatus: ['#C84CB3', '#29AC9A', '#F8A03C', '#009CDA', '#E2CD31]'],
    censusHouseholdForm: ['#C84CB3', '#29AC9A', '#7CD1C0', '#F8A03C', '#E2CD31'],
    censusConstructionYear: ['#29AC9A', '#7CD1C0', '#64417B', '#9B6EB1', '#E2CD31', '#FCFE71'],
    censusRBOwner: ['#C84CB3', '#9c77ad', '#D8811E', '#F8A03C', '#29AC9A', '#137764', '#E2CD31', '#FCFE71'],
    censusRBEnergy: [
      '#B67E3A',
      '#D8811E',
      '#F8A03C',
      '#29AC9A',
      '#52C4F1',
      '#009CDA',
      '#1C6193',
      '#7CD1C0',
      '#FCFE71',
    ],
    censusRBUnitsize: ['#7CD1C0', '#29AC9A', '#F8A03C', '#D8811E', '#B67E3A'],
    censusRBUnitsRent: [
      '#E85B3B',
      '#F99D59',
      '#FEC980',
      '#FFEDAA',
      '#ECF7B9',
      '#C7E8AD',
      '#9DD3A6',
      '#64ABB0',
      '#2B83BA',
      '#64417B',
    ],
    censusUnitsArea: [
      '#1C6193',
      '#2B83BA',
      '#64ABB0',
      '#9DD3A6',
      '#C7E8AD',
      '#ECF7B9',
      '#FFEDAA',
      '#FEC980',
      '#F99D59',
      '#D8811E',
    ],
    censusUnitsVacDur: ['#c994c7', '#9c77ad', '#C84CB3', '#8a337b'],
    censusUnitsVacReason: ['#29AC9A', '#009CDA', '#F8A03C', '#E2CD31', '#137764', '#9B6EB1'],
  }
  return colorList[source][index]
}

export const colorAllocator = (index, type = 0, colorReplacements) => {
  const colorList = [
    bgagColors['bgag-blue'],
    bgagColors['bgag-yellow'],
    bgagColors['bgag-lightgreen'],
    bgagColors['bgag-violet'],
    bgagColors['bgag-darkgreen'],
  ]

  if (colorReplacements && colorReplacements[index % colorList.length]) {
    Object.entries(colorReplacements).forEach(([replaceIndex, colorObject]) => {
      const oldColor = colorList[replaceIndex] || {}
      const mergedColor = { ...oldColor, ...colorObject }
      colorList.splice(replaceIndex, 1, mergedColor)
    })
  }

  const typeKeys = {
    0: 'main',
    1: 'dark',
    2: 'bright',
  }

  return colorList[index % colorList.length][typeKeys[type] || 'main']
}
