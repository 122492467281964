export const getHeader = ({ themeColors, spaces, colors }) => ({
  borderBottom: '0',
  colors: {
    bg: colors.white,
  },
  space: {
    inner: [spaces.rhythm.horizontal.medium, null, null, spaces.rhythm.horizontal.large],
    partner: spaces.rhythm.horizontal.extraLarge,
  },
  tabs: {
    gap: [spaces.rhythm.horizontal.medium, null, null, spaces.rhythm.horizontal.large],
    marginLeft: [spaces.rhythm.horizontal.medium, null, null, spaces.rhythm.horizontal.large],
    colors: {
      default: {
        color: colors.gray[400],
      },
      hover: {
        color: colors.gray[950],
      },
      active: {
        color: colors.brand.primary.default,
      },
    },
  },
})
