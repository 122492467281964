import React, { createContext, useContext, useMemo, useRef, memo } from 'react'
import appConfig from 'config/appConfig'
import { useDevtoolReducer } from './devTool'

// BlockRender is a component wrapped in memo to prevent the whole subtree of a Provider to be rerendered on every state-change
// memo's isEqual returns always true, otherwise usage of "children" will rerender the subtree anyways
// https://gist.github.com/slikts/e224b924612d53c1b61f359cfb962c06
const BlockRender = memo(
  ({ children }) => children,
  () => true
)

function createMultiStore(reducer, actions, initialState, name) {
  const context = createContext([initialState, {}])

  const reducerFn = (state, { type, id, payload }) => {
    logger(type, id, payload || 'no payload')
    if (typeof reducer[type] === 'undefined') return state
    return reducer[type](state, id, payload)
  }

  const StoreProvider = ({ children }) => {
    const store = useDevtoolReducer(reducerFn, initialState, { name })
    const storeRef = useRef(store)
    const thunks = useMemo(() => createThunks(actions, storeRef), [storeRef])
    return (
      <context.Provider value={[store[0], thunks]}>
        <BlockRender>{children}</BlockRender>
      </context.Provider>
    )
  }

  const useStore = (selector, id) => {
    const [state, actions] = useContext(context)

    if (typeof id === 'undefined') {
      console.log('stores/createMultiStore useStore needs second parameter id to work properly!')
      id = 'default'
    }
    const defaultState = typeof state.default === 'undefined' ? {} : state.default

    let retState
    if (typeof state[id] === 'undefined') {
      retState = defaultState
    } else {
      retState = state[id]
    }
    const retActions = useMemo(() => createActionsBySubState(actions, id), [actions, id])
    return typeof selector === 'function' ? selector(retState, retActions) : [retState, retActions]
  }

  return [context, StoreProvider, useStore]
}

export default createMultiStore

function createThunks(actions, ref) {
  const [state, dispatch] = ref.current
  return Object.keys(actions).reduce((res, name) => {
    res[name] = (...args) => {
      return actions[name](...args)(dispatch, state)
    }
    return res
  }, {})
}

function createActionsBySubState(actions, id) {
  return Object.keys(actions).reduce((res, name) => {
    res[name] = (...args) => {
      return actions[name](id, ...args)
    }
    return res
  }, {})
}

function logger(type, id, payload) {
  if (appConfig.quietStoreLogger) {
    return
  }
  // To be extended...
  // Inspiration: https://github.com/LogRocket/redux-logger
  let dataOut = payload
  if (type === 'SET_OBJECTS') {
    dataOut =
      (payload.render ? payload.render.length + ' objects to render ' : '') +
      (payload.geoJSON ? payload.geoJSON.features.length + ' objects loaded' : '')
  }
  const ignoreTypes = ['SET_CENTER', 'SET_BOUNDS']
  if (ignoreTypes.indexOf(type) < 0) {
    console.log('%c%s%c:', 'color:#1b72bf;', type + ' ' + id, 'color:inherit;', dataOut)
  }
}
