import React from 'react'

import { FlexContainer } from '@layout/BuildingBlocks'
import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'

export const SubHeaderControls = ({ children }) => {
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')
  return (
    <FlexContainer
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      height="100%"
      flexColumnGap={[spaces.rhythm.horizontal.large, null, spaces.rhythm.horizontal.extraLarge]}
    >
      {children}
    </FlexContainer>
  )
}
