import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { Markdown } from 'lib/markdown'

import { RegistrationForm } from './SubComponents/RegistrationForm'

import { CopyMedium, Royal } from 'stories/typography'

import { CardPublic } from '@layout/CardPublic'
import { LayoutPublic } from '@layout/LayoutPublic'
import { FlexItem } from '@layout/BuildingBlocks'
import { L3PlusFooter } from '@layout/CardPublic/SubComponents/L3PlusFooter/L3PlusFooter'
import { CardLogoL3Plus } from '@layout/CardPublic/SubComponents/CardLogoL3Plus'
import { L3PlusHeaderBanner } from '@layout/LayoutPublic/SubComponents/L3PlusHeaderBanner'

import l3plusBg from 'assets/images/l3plusBackground.png'

import { withDifferentTheme } from '../../../theming/util/withDifferentTheme'

const markdownComponents = {
  p: ({ node, ...props }) => <CopyMedium tag="p" lineHeight={1.66} {...props} />,
  ul: ({ node, ...props }) => <CopyMedium tag="ul" lineHeight={1.66} {...props} />,
}

export const RegisterTestAccount = ({ type }) => {
  const translationTypes = []
  if (type === 'developer') translationTypes.push('developer')
  const { t, i18n } = useTranslation(translationTypes)
  const [queryParams] = useSearchParams()

  const bgImage = type === 'l3plus' ? l3plusBg : null

  // texts
  const header = type === 'developer' ? t('registration.info.header') : t('registerTestAccount.header')
  const registerText =
    type === 'developer'
      ? t('registration.info.text').replace(
          '<0>{{email}}</0>',
          '<a href="mailto:sales@riwis.de">sales@riwis.de</a>'
        )
      : t('registerTestAccount.info.text')

  useEffect(() => {
    if (queryParams?.get('lang') && queryParams.get('lang').toLowerCase() === 'en') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('de')
    }
  }, [queryParams, i18n])

  return (
    <LayoutPublic bgImage={bgImage}>
      {type === 'l3plus' && (
        <LayoutPublic.HeaderFullscreen>
          <L3PlusHeaderBanner />
        </LayoutPublic.HeaderFullscreen>
      )}
      <LayoutPublic.Main>
        {type === 'l3plus' && <CardLogoL3Plus />}
        <CardPublic>
          {type !== 'l3plus' && <CardPublic.Logo />}
          {type !== 'l3plus' && (
            <CardPublic.Header>
              <div dangerouslySetInnerHTML={{ __html: header }} style={{ textAlign: 'center' }} />
            </CardPublic.Header>
          )}
          <CardPublic.BodyLeft>
            {type === 'developer' && (
              <FlexItem flexDirection="column">
                <Markdown components={markdownComponents}>{t('developer:registration.presents')}</Markdown>
                <Royal>{t('developer:registration.cooperation')}</Royal>
                <Markdown components={markdownComponents}>{t('developer:registration.cooperation')}</Markdown>
              </FlexItem>
            )}
            <Markdown components={markdownComponents}>{registerText}</Markdown>
          </CardPublic.BodyLeft>
          <CardPublic.BodyRight>
            <RegistrationForm type={type} />
          </CardPublic.BodyRight>
          {type === 'l3plus' && (
            <CardPublic.Footer>
              <L3PlusFooter />
            </CardPublic.Footer>
          )}
        </CardPublic>
      </LayoutPublic.Main>
    </LayoutPublic>
  )
}

export const RegisterTestAccountL3Plus = () =>
  withDifferentTheme('L3Plus')(RegisterTestAccount)({ type: 'l3plus' })
export const RegisterTestAccountDeveloper = () => <RegisterTestAccount type="developer" />
