import createStore from 'lib/flux-store'
import api from 'stores/api'
import functionQueue from 'lib/util/functionQueue'

const DEFAULT_AREA = 'residentialArea'
const DEFAULT_TOPIC = 'wohnlagen'

const initialState = {
  initialLoadDone: false,
  area: '',
  topic: '',
  poi: [],
  filters: [],
  displayObjects: false,
  displayArea: false,
  displayThema: false,
  displayPlanetVT: false,
  themaResolution: 'autozoom',
  layersByQuery: null,
}

let lastSavedState = initialState

const ignoreKeysOnUpdate = ['initialLoadDone', 'layersByQuery']

const __NAME__ = 'CityStructureStore'

const actions = {
  loadState: () => (dispatch) => {
    api.AppState.get(__NAME__).then((response) => {
      const state = response.data.data
      if (state.area === 'none') state.area = ''
      if (state.topic === 'none') state.topic = ''
      ignoreKeysOnUpdate.forEach((key) => {
        delete state[key]
      })
      dispatch({ loadedState: state })
    })
  },
  setTopic: (topic) => ({ topic }),
  setArea: (area) => ({ area }),
  setPoi: (poi) => ({ poi }),
  setFilters: (filters) => ({ filters }),
  setDisplayObjects: (displayObjects) => ({ displayObjects }),
  setDisplayArea: (displayArea) => ({ displayArea }),
  setDisplayThema: (displayThema) => ({ displayThema }),
  setLayersByQuery: (layersByQuery) => ({ layersByQuery }),
  setDisplayPlanetVT: (displayPlanetVT) => ({ displayPlanetVT }),
  setThemaResolution: (themaResolution) => ({ themaResolution }),
}

const reducer = {
  loadState: (state, { loadedState }) => {
    const update = {
      ...initialState,
      ...(loadedState || {}),
      ...(state.layersByQuery || {}),
      layersByQuery: null,
      initialLoadDone: true,
    }
    if (state.layersByQuery === null) {
      lastSavedState = { ...update }
    }
    return update
  },
  setTopic: (state, { topic }) => ({ ...state, topic }),
  setArea: (state, { area }) => ({ ...state, area }),
  setPoi: (state, { poi }) => ({ ...state, poi }),
  setFilters: (state, { filters }) => ({ ...state, filters }),
  setDisplayObjects: (state, { displayObjects }) => ({ ...state, displayObjects }),
  setDisplayArea: (state, { displayArea }) => ({
    ...state,
    displayArea,
    area: displayArea ? state.area || DEFAULT_AREA : state.area,
  }),
  setDisplayThema: (state, { displayThema }) => ({
    ...state,
    displayThema,
    topic: displayThema ? state.topic || DEFAULT_TOPIC : state.topic,
  }),
  setLayersByQuery: (state, { layersByQuery }) => {
    return {
      ...state,
      ...(state.initialLoadDone ? layersByQuery : {}),
      layersByQuery: state.initialLoadDone ? null : layersByQuery,
    }
  },
  setDisplayPlanetVT: (state, { displayPlanetVT }) => ({ ...state, displayPlanetVT }),
  setThemaResolution: (state, { themaResolution }) => ({ ...state, themaResolution }),
}

const queuedSave = functionQueue(api.AppState.save)

const onUpdate = (state) => {
  if (state.initialLoadDone) {
    const changed = Object.keys(state).reduce((changed, key) => {
      return changed || (ignoreKeysOnUpdate[key] ? false : !Object.is(lastSavedState[key], state[key]))
    }, false)

    if (changed) {
      lastSavedState = { ...state }
      ignoreKeysOnUpdate.forEach((key) => {
        delete lastSavedState[key]
      })
      return queuedSave(__NAME__, lastSavedState)
    }
  }
  return Promise.resolve()
}

export const [CityStructureContext, CityStructureProvider, useCityStructureStore] = createStore(
  reducer,
  actions,
  initialState,
  onUpdate,
  'CityStructureStore'
)
