import { useState, useEffect, useCallback } from 'react'

import { useNotificationsStore } from 'stores/Notifications'

export const useNotificationsListener = () => {
  const [{ notifications }, { addNotification }] = useNotificationsStore()

  const [notification, setNotification] = useState(null)

  const [notificationId, setNotificationId] = useState(null)

  const addListener = useCallback(
    (notification, meta = {}) => {
      setNotificationId(notification.id)
      addNotification({ ...notification, meta: { ...notification.meta, ...meta, clientIsInitiator: true } })
    },
    [addNotification]
  )

  useEffect(() => {
    if (notifications[notificationId]) {
      setNotification(notifications[notificationId])
    }
  }, [notificationId, notifications])

  return [notification, addListener]
}
