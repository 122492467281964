import { l3plusTypeOfGoods, l3plusTypeOfGoodsMainMapping } from 'config/l3plusTypeOfGoods'

const sortedL3Togs = [...l3plusTypeOfGoods]
sortedL3Togs.sort((a, b) => parseFloat(b) - parseFloat(a))

export const getTogId = (id, drilldown = false) => {
  id = id.toString()

  const tog = sortedL3Togs.find((pid) => id.startsWith(pid))
  if (drilldown !== false && !l3plusTypeOfGoodsMainMapping[drilldown].includes(tog)) {
    return
  }
  return tog
}

export const getUnitSize = (category) => {
  switch (category?.toLowerCase() ?? '') {
    case 'klein':
      return 100
    case 'mittel':
      return 250
    case 'groß':
      return 500
    case 'sehr groß':
      return 1200
    default:
      return 0
  }
}

export const areaSizeMapping = {
  klein: 's',
  mittel: 'm',
  groß: 'l',
  'sehr groß': 'xl',
}
