import React, { memo, useEffect, useMemo, useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { layout, space } from 'styled-system'

import { customScrollbar } from 'theming/styles/CustomScrollbar'
import { getThemeValue } from 'theming'

import { useLayoutContext } from '@layout/Layout'
import { useControlsPanelContext } from '@layout/Layout/context/ControlsPanelContext'
import { LayOver } from './SubComponents/LayOver/LayOver'
import { getScrollParents } from 'lib/util/getScrollParents'

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => ['children'].includes(prop),
})`
  margin-right: ${(props) => (props.fullscreen ? '0px' : props.marginRight)};
  /* breaks fixed overlays e.g. geotools */
  /* position: sticky; */
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable;
  ${customScrollbar};
  ${layout};
`

const Panel = styled.div`
  height: 100%;
  margin-top: 0;
  padding-right: ${(props) => props.spaceToScrollbar};
  ${space}
  ${layout}
`

export const InnerPanelStyledComponent = styled.div`
  border-radius: ${themeGet('controlsPanel.border.radius', '0px')};
  min-height: 100%;
  padding-bottom: ${themeGet('controlsPanel.space.bottom', '0px')};
  padding-top: ${themeGet('controlsPanel.space.top', '0px')};
  ${space}
`

export const ControlsPanel = memo(({ children, fullscreen = false }) => {
  const theme = useTheme()
  const ref = useRef(null)

  const {
    state: { isLayOverOpen, LayOverComponent, title, componentProps, scrollToId },
    dispatch,
    closeLayOver,
  } = useControlsPanelContext()

  const {
    state: { controlsPanelIsOpen },
  } = useLayoutContext()

  const marginRight = getThemeValue(theme, 'controlsPanel.space.marginRight')
  const paddingToScrollbar = getThemeValue(theme, 'controlsPanel.space.paddingToScrollbar')
  const paddingBottom = getThemeValue(theme, 'controlsPanel.space.bottom')
  const width = controlsPanelIsOpen ? getThemeValue(theme, 'controlsPanel.width') : '0px'
  const scrollbarWidth = getThemeValue(theme, 'sizes.scrollbar.default.thumb.width')

  const intRepr = useMemo(() => {
    const convert = (str) => parseInt(str.replace(/px/, ''))

    return {
      marginRight: convert(marginRight),
      paddingToScrollbar: convert(paddingToScrollbar),
      scrollbarWidth: convert(scrollbarWidth),
    }
  }, [marginRight, paddingToScrollbar, scrollbarWidth])

  const marginToMainStage = useMemo(() => {
    return controlsPanelIsOpen ? `${intRepr.marginRight - intRepr.scrollbarWidth / 2}px` : 0
  }, [intRepr, controlsPanelIsOpen])

  const onClose = () => {
    closeLayOver()
  }

  useEffect(() => {
    if (!isLayOverOpen && scrollToId) {
      if (scrollToId) {
        const target = document.getElementById(scrollToId)
        if (!target) {
          return
        }
        const container = getScrollParents(target)[0]
        if (!container) {
          return
        }

        const offsetTarget = target.getBoundingClientRect()
        container.scroll({
          top: offsetTarget.top,
        })
        dispatch({ type: 'resetLayOver' })
      }
    }
  }, [dispatch, isLayOverOpen, scrollToId])

  return (
    <Wrapper
      controlsPanelIsOpen={controlsPanelIsOpen}
      fullscreen={fullscreen}
      marginRight={marginToMainStage}
      minWidth={width}
      ref={ref}
      width={width}
    >
      <Panel pr={paddingToScrollbar}>
        <InnerPanelStyledComponent pb={paddingBottom}>
          {isLayOverOpen && (
            <LayOver
              Component={LayOverComponent}
              onCloseClick={onClose}
              title={title}
              componentProps={componentProps}
            />
          )}
          <div style={{ display: isLayOverOpen ? 'none' : 'block' }}>{children}</div>
        </InnerPanelStyledComponent>
      </Panel>
    </Wrapper>
  )
})
