const min = 1975

export const defaultProps = {
  name: 'SliderYearComponent',
  min,
  max: 2050,
  defaultValue: min,
  debounce: 100,
  appearance: 'allRail',
}
