import React from 'react'

import { DropdownMenu } from '@ui/DropdownMenu/DropdownMenu'
import { BudiconSharedUser } from 'bgag-budicons'

import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'
import { useTranslation } from 'react-i18next'

export const DropdownMenuAdministration = ({ iconColor }) => {
  const { hasRouteAccess } = useAuthzChecks()
  const { t } = useTranslation()

  let entries = []

  const addItems = (items) => {
    items.forEach((item) => {
      entries.push(
        hasRouteAccess(item.route) && {
          label: item.label,
          to: item.route,
        }
      )
    })
  }

  addItems([
    {
      label: t('modules.adminUsers.name'),
      route: '/admin/user',
    },

    {
      label: t('modules.adminGroups.name'),
      route: '/admin/group',
    },
    {
      label: t('modules.adminRoles.name'),
      route: '/admin/role',
    },
  ])

  entries = entries.filter((item) => item !== false)

  return (
    hasRouteAccess('/admin/user') && (
      <DropdownMenu
        icon={{
          icon: {
            Icon: BudiconSharedUser,
            strokeWidth: '0',
            width: ['18px', null, '20px', null, '23px'],
            fill: iconColor,
          },
          iconPosition: { top: ['-11px', null, null, null, '-13px'] },
        }}
        placement="bottomEnd"
        items={entries}
      />
    )
  )
}
