import { getCheckboxBar } from 'theming/themes/default/uiElements/checkboxBar'
import { spaces } from 'theming/themes/default/variables/spaces'

const checkboxBar = getCheckboxBar({ spaces })

export const defaultProps = {
  alignItems: 'flex-start',
  debounce: null,
  flexGrowItems: 1,
  itemsSpaceX: checkboxBar.items.space.compact.x,
  itemsSpaceY: checkboxBar.items.space.compact.y,
  justifyItems: 'flex-start',
  name: 'checkboxBarComponent',
  width: '100%',
}
