import styled from 'styled-components'
import { color, typography, compose } from 'styled-system'

import { typeScale, typeStyle } from '@typography'

const excludeProps = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
  'typeOffset',
  'topSpace',
  'heightCorrection',
  'preventCollapse',
  'meta',
  'text',
]

export const StyledText = styled.span.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  ${compose(color, typography)}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}

  line-height: ${(props) => `${props.lineHeight}px`};
  padding-top: ${(props) => `${props.preventCollapse.toString().replace(/px/, '')}px`};
  transform: translateY(${(props) => props.typeOffset}em);

  :before {
    content: '';
    margin-top: ${(props) => `-${props.heightCorrection + props.preventCollapse}px`};
    display: block;
    height: 0;
  }
`
