import { getThemeValue } from 'theming'

export const oval = (theme) => {
  return {
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.oval'),
  }
}

export const square = (theme) => {
  return {
    borderRadius: getThemeValue(theme, 'uiActionElements.radius.small'),
  }
}

export const shapeless = () => {
  return {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    border: 0,
    ':disabled': {
      border: 0,
    },
  }
}
