import { variant } from 'styled-system'

import {
  locationPinOne,
  locationPinTwo,
  locationPinThree,
  locationPinFour,
  locationPinFive,
} from 'theming/systemHelper/appearance'

export const appearance = (props) =>
  variant({
    prop: 'appearance',
    variants: {
      locationPinOne,
      locationPinTwo,
      locationPinThree,
      locationPinFour,
      locationPinFive,
    },
  })
