import { useRef, useState, useCallback, useEffect } from 'react'

export const useCursor = ({
  options,
  isActive,
  keepCursorValueOnInactive = false,
  refScrollContainer = null,
  refOptions = null,
  setScrollTo = null,
}) => {
  const [cursorValue, setCursorValue] = useState(null)

  /*
   * following reference and effect allow cursors container to maintain its last scrollTop
   * on closing and reopening the select dropdown. Otherwise the scrollTop will jump on reopening,
   * depending on cursors current value
   *
   */

  const refScrollTop = useRef(0)
  useEffect(() => {
    if (refScrollContainer === null) {
      return
    }
    const evtOptions = { passive: true }
    let onScroll
    let scrollContainer = null

    if (isActive) {
      scrollContainer = refScrollContainer.current
      onScroll = (evt) => {
        refScrollTop.current = scrollContainer.scrollTop
      }
      scrollContainer.addEventListener('scroll', onScroll, evtOptions)
      scrollContainer.scrollTo({ top: refScrollTop.current })
    }

    return () => {
      if (typeof onScroll === 'function') {
        scrollContainer.removeEventListener('scroll', onScroll, evtOptions)
      }
    }
  }, [refScrollContainer, isActive])

  /*
   * setScrollTo/internalSetScrollTo will be called inside the cursor-state setting method.
   * If it would be triggered as an effect to changes of cursorValue, you might see some
   * flickering, because first the new option gets the cursors styles, and then scrolling takes place.
   *
   */

  const internalSetScrollTo = useCallback(
    (index) => {
      if (
        refScrollContainer === null ||
        refScrollContainer.current === null ||
        !refOptions.current?.[index]
      ) {
        return
      }
      const scrollContainer = refScrollContainer.current
      const option = refOptions.current[index]

      const { height: containerHeight = null } = scrollContainer.getBoundingClientRect() ?? {}
      const { height: optionHeight = null } = option.getBoundingClientRect() ?? {}
      const scrollTop = scrollContainer.scrollTop
      const scrollBottom = scrollTop + containerHeight
      const optionTop = option.offsetTop
      const optionBottom = optionTop + optionHeight

      if (optionTop < scrollTop) {
        scrollContainer.scrollTo({ top: optionTop })
      } else if (optionBottom > scrollBottom) {
        scrollContainer.scrollTo({ top: optionBottom - containerHeight })
      }
    },
    [refScrollContainer, refOptions]
  )

  const getNextCursorValue = useCallback(
    (direction = false, defaultValue = null) => {
      return (prev) => {
        if (!options.length) {
          return prev
        }
        const getNextIndex = (index) => {
          if (direction === 'down') {
            return index + 1 >= options.length ? 0 : index + 1
          } else {
            return index - 1 < 0 ? options.length - 1 : index - 1
          }
        }
        const curIndex = options.findIndex((option) => option.value === (defaultValue ?? prev))

        let nextIndex = curIndex
        if (direction !== false) {
          let count = 0
          do {
            nextIndex = getNextIndex(nextIndex)
            count++
          } while (options?.[nextIndex]?.disabled && nextIndex !== curIndex && count <= options.length)
        }

        if (nextIndex < 0 || options?.[nextIndex]?.disabled) {
          return prev
        }
        typeof setScrollTo === 'function' ? setScrollTo(nextIndex) : internalSetScrollTo(nextIndex)

        return options[nextIndex].value
      }
    },
    [options, setScrollTo, internalSetScrollTo]
  )

  const setNextCursorValue = useCallback(
    (direction = false, defaultValue = null) => {
      setCursorValue(getNextCursorValue(direction, defaultValue))
    },
    [getNextCursorValue]
  )

  useEffect(() => {
    if (!isActive && keepCursorValueOnInactive === false) {
      setCursorValue(null)
    }
  }, [isActive, keepCursorValueOnInactive])
  return { cursorValue, setNextCursorValue, setCursorValue }
}
