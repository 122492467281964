import React from 'react'
import { defaultProps } from './defaultProps'
import { fill, width, height } from './defaultPropTypes'
import { BudiconChatInformation } from 'bgag-budicons'
import { IconWrapper } from '@utilities/IconWrapper'
import { ButtonIcon } from '@ui/Buttons'

export const InfoBubble = ({
  iconVariantSize = 'large',
  iconVariantSourceName = 'buttonIcons',
  cardHeaderSpaceRight = false,
  cardHeaderSpaceLeft = false,
  cardHeaderSpaceTop = false,
  appearance = 'bare',
  shape = 'shapeless',
  type = 'outline',
  ...rest
}) => {
  return (
    <IconWrapper
      icon={{ Icon: BudiconChatInformation, type }}
      iconVariantSize={iconVariantSize}
      iconVariantSourceName={iconVariantSourceName}
      appearance={appearance}
      shape={shape}
      iconWrapper={{
        marginRight: cardHeaderSpaceRight ? '12px' : null,
        marginTop: cardHeaderSpaceTop ? '6px' : null,
        marginLeft: cardHeaderSpaceLeft ? '12px' : null,
      }}
      {...rest}
    />
  )
}

export const InfoBubbleButton = ({
  iconVariantSize = 'large',
  iconVariantSourceName = 'buttonIcons',
  cardHeaderSpaceRight = false,
  cardHeaderSpaceLeft = false,
  cardHeaderSpaceTop = false,
  appearance = 'bare',
  shape = 'shapeless',
  type = 'outline',
  ...rest
}) => {
  return (
    <ButtonIcon
      icon={{ Icon: BudiconChatInformation, type }}
      iconVariantSize={iconVariantSize}
      iconVariantSourceName={iconVariantSourceName}
      appearance={appearance}
      shape={shape}
      iconWrapper={{
        marginRight: cardHeaderSpaceRight ? '12px' : null,
        marginTop: cardHeaderSpaceTop ? '6px' : null,
        marginLeft: cardHeaderSpaceLeft ? '12px' : null,
      }}
      {...rest}
    />
  )
}

InfoBubble.propTypes = {
  fill,
  width,
  height,
}

InfoBubble.defaultProps = defaultProps
