import { useMemo, useCallback } from 'react'

import { sortKeys } from 'lib/util'

export const useOnChangeWithKeySorter = (onChange, keys) => {
  const sanitizedKeys = useMemo(() => {
    if (Array.isArray(keys)) {
      return keys
    }
    if (typeof keys === 'function') {
      return keys
    }
    if (typeof keys === 'object') {
      return Object.keys(keys)
    }
    return keys
  }, [keys])

  return useCallback(
    (value) => onChange(value ? sortKeys(value, sanitizedKeys) : value),
    [onChange, sanitizedKeys]
  )
}
