// imports moved to import() async import for better code splitting
// import { OktaAuth } from '@okta/okta-auth-js'
// import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'

import Api from 'stores/api'

const authClient = {
  token: null,
  config: null,
  client: null,
}

const _resetAuthClient = () => {
  authClient.token = null
  authClient.config = null
  authClient.client = null
}
const _setAuthClient = async (config) => {
  authClient.token = config.token
  authClient.config = config
  if (config.type === 'okta') {
    const { OktaAuth } = await import('@okta/okta-auth-js')
    authClient.client = new OktaAuth({
      issuer: config.issuer,
      clientId: config.client_id,
      redirectUri: config.redirect_uri + config.token,
      scopes: ['openid', 'email', 'profile'],
    })
  } else if (config.type === 'azure') {
    const { PublicClientApplication } = await import('@azure/msal-browser')
    authClient.client = new PublicClientApplication({
      auth: {
        clientId: config.client_id,
        authority: config.issuer,
        redirectUri: config.redirect_uri + config.token,
        navigateToLoginRequestUrl: false, // navigate will happen by riwis app after auth on services
      },
    })
  } else if (config.type === 'oneloginoidc') {
    const { OneloginOidcAuth } = await import('lib/sso/onelogin-auth')
    authClient.client = new OneloginOidcAuth({
      authority: config.issuer,
      client_id: config.client_id,
      redirect_uri: config.redirect_uri + config.token,
    })
  }
}

export const getAuthClient = (token) => {
  if (authClient.token === token) {
    return Promise.resolve(authClient)
  } else {
    return Api.SingleSignOn.getClientConfig(token).then(async ({ data: { data: config } }) => {
      localStorage.setItem('ssoConfig', JSON.stringify(config))
      await _setAuthClient(config)
      return authClient
    })
  }
}

export const ssoLogin = (idToken, accessToken, href = null) => {
  return Api.SingleSignOn.login({
    idToken,
    accessToken,
    href,
    token: authClient.token,
  }).then((res) => res.data.data.valid)
}

export const ssoLogout = (email) => {
  // if returning true: redirecting/reloading is handled by authClient!
  let config = localStorage.getItem('ssoConfig')
  if (config === null) {
    return false
  }
  config = JSON.parse(config)
  localStorage.removeItem('ssoConfig')

  if (typeof authClient.config.logout_redirect_uri === 'string') {
    window.location.href = authClient.config.logout_redirect_uri
    _resetAuthClient()
    return true
  }
  _resetAuthClient()
  return false
}

export const getSsoStatus = async (user, navigate) => {
  if (!user || typeof user !== 'object') {
    return
  }
  let config = localStorage.getItem('ssoConfig')
  if (config === null) {
    return
  }
  config = JSON.parse(config)
  if (authClient.client === null) {
    await _setAuthClient(config)
  }
  if (authClient.config.type === 'azure') {
    const accounts = authClient.client.getAllAccounts()
    if (!Array.isArray(accounts) || !accounts.length) {
      return
    }
    const account = accounts.find((acc) => acc.username === user.data.email) || null
    if (account === null) {
      return
    }
    authClient.client.acquireTokenSilent({ account }).catch(async (err) => {
      const { InteractionRequiredAuthError } = await import('@azure/msal-browser')
      if (err instanceof InteractionRequiredAuthError) {
        navigate('/logout')
      } else {
        console.error(err)
      }
    })
  } else if (authClient.config.type === 'okta') {
    authClient.client.tokenManager.getTokens().then(({ accessToken, idToken }) => {
      if (!idToken || authClient.client.tokenManager.hasExpired(idToken)) {
        navigate('/logout')
      }
    })
  }
}
