export const getNavigationMeta = ({ colors }) => ({
  icon: {
    color: colors.gray[900],
  },
  flyout: {
    colors: {
      container: {
        bg: colors.lightgray[130],
      },
      item: {
        default: {
          bg: colors.lightgray[50],
          color: colors.gray[700],
        },
        hover: {
          bg: colors.lightgray[300],
          color: colors.gray[900],
        },
        active: {
          bg: colors.lightgray[470],
          color: colors.gray[900],
        },
      },
    },
  },
})
