import { Children, isValidElement } from 'react'

export const useSlots = (names, children) => {
  const slots = []
  const childrenArray = Children.toArray(children)

  names.forEach((displayName) => {
    const slot = childrenArray.find((child) => child?.type?.displayName === displayName)

    if (isValidElement(slot)) {
      slots.push(slot)
    } else {
      slots.push(null)
    }
  })

  const rest = childrenArray.filter((child) => !names.includes(child?.type?.displayName))
  return [...slots, ...rest]
}

export const useSlotsAsArray = (names, children) => {
  const slots = []
  const childrenArray = Children.toArray(children)

  names.forEach((displayName) => {
    slots.push(
      childrenArray.filter((child) => child?.type?.displayName === displayName && isValidElement(child))
    )
  })
  return slots
}
