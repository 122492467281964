import themeGet from '@styled-system/theme-get'
import React, { memo } from 'react'
import styled from 'styled-components'
import { compose, layout, space } from 'styled-system'

const StyledComponent = styled.header.withConfig({
  shouldForwardProp: (prop) => ['children', 'id'].includes(prop),
})`
  width: 100vw;
  background-color: ${themeGet('layout.headerFullscreen.colors.public.bg', 'lightgray')};
  background: ${themeGet('layout.headerFullscreen.background', '')};
  ${compose(layout, space)};
`

export const HeaderFullscreen = memo(({ children, px, py }) => {
  return (
    <StyledComponent px={px} py={py} id="header-fullscreen">
      {children}
    </StyledComponent>
  )
})

HeaderFullscreen.propTypes = {}

HeaderFullscreen.defaultProps = {}
