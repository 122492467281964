import React, { useRef, useCallback, useEffect } from 'react'

export const OnClickOutside = ({ children, handler, stretch }) => {
  const clickedInside = useRef(false)

  const onMouseDown = useCallback((evt) => {
    clickedInside.current = true
  }, [])

  useEffect(() => {
    const listener = (event) => {
      if (!clickedInside.current && typeof handler === 'function') {
        handler(event)
      }
      clickedInside.current = false
    }

    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [handler])

  return (
    <div style={{ display: 'flex', ...(stretch ? { width: '100%' } : {}) }} onMouseDown={onMouseDown}>
      {children}
    </div>
  )
}
