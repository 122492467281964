import { useCallback } from 'react'
import { useModalContext } from '..'

export const useModalCloseCurrent = () => {
  const {
    state: { activeModal },
    dispatch,
  } = useModalContext()

  const closeCurrent = useCallback(() => {
    if (activeModal === null) return

    dispatch({
      type: 'setActiveModal',
      payload: { name: activeModal, isActive: false },
    })
  }, [dispatch, activeModal])

  return closeCurrent
}
