import React, { useState, useEffect } from 'react'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import api from 'stores/api'

import { useAuthStore } from 'stores/AuthStore'

import { LayoutPublic } from '@layout/LayoutPublic'
import { CardPublic } from '@layout/CardPublic'
import { L3PlusFooter } from '@layout/CardPublic/SubComponents/L3PlusFooter/L3PlusFooter'
import { CardLogoL3Plus } from '@layout/CardPublic/SubComponents/CardLogoL3Plus'
import { L3PlusHeaderBanner } from '@layout/LayoutPublic/SubComponents/L3PlusHeaderBanner'

import { LoginForm } from './SubComponents/LoginForm'
import { Footer } from './SubComponents/Footer'

import CTLoginBg from 'assets/images/CTLoginBg.jpg'
import l3plusBg from 'assets/images/l3plusBackground.png'

import { withDifferentTheme } from '../../../theming/util/withDifferentTheme'

export const LogoutModule = () => {
  const [, { resetCurrentUser }] = useAuthStore()

  useEffect(() => {
    api.Auth.logout().then(() => {
      resetCurrentUser()
      sessionStorage.removeItem('l3token')
    })
  }, [resetCurrentUser])

  return null
}

export const LoginModule = ({ type }) => {
  const [queryParams] = useSearchParams()
  const translations = ['translation']

  let translationSpace = 'authentication'
  if (type === 'ct') {
    translations.unshift('highstreetReport')
    translationSpace = 'login'
  } else if (type === 'l3plus') {
    translations.unshift('l3plus')
    translationSpace = 'authentication'
  }

  const { t, i18n } = useTranslation(translations)
  const location = useLocation()
  const locationState = location.state || {}

  let from = locationState.from && locationState.from.pathname ? locationState.from.pathname : '/home'
  if (type === 'ct') {
    from = '/features/highstreet-report/home'
  } else if (from.startsWith('/logout')) {
    from = '/home'
  } else if (locationState.from && typeof locationState.from.search === 'string') {
    from += locationState.from.search
  }
  const showMessage = locationState.showMessage || ''

  const bgImage = type === 'ct' ? CTLoginBg : type === 'l3plus' ? l3plusBg : null

  const [redirectToReferrer, setRedirectToReferrer] = useState(false)

  // remove ssoConfig, to allow normal login after sso logout
  localStorage.removeItem('ssoConfig')

  useEffect(() => {
    if (queryParams?.get('lang') && queryParams.get('lang').toLowerCase() === 'en') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('de')
    }
  }, [queryParams, i18n])

  if (redirectToReferrer === true) {
    return <Navigate to={from} replace="true" />
  }

  return (
    <LayoutPublic bgImage={bgImage}>
      {type === 'l3plus' && (
        <LayoutPublic.HeaderFullscreen>
          <L3PlusHeaderBanner />
        </LayoutPublic.HeaderFullscreen>
      )}
      <LayoutPublic.Main>
        {type === 'l3plus' && <CardLogoL3Plus />}
        <CardPublic>
          {type !== 'l3plus' && <CardPublic.Logo />}
          {type !== 'l3plus' && <CardPublic.Header>{t(translationSpace + '.header')}</CardPublic.Header>}
          {type === 'ct' && <CardPublic.SubHeader>{t('login.subHeader')}</CardPublic.SubHeader>}
          <CardPublic.BodyRight>
            <LoginForm
              setRedirectToReferrer={setRedirectToReferrer}
              showMessage={showMessage}
              type={type}
              forgotPasswordLink={type === 'l3plus' ? '/forgotPasswordL3Plus' : '/forgotPassword'}
            />
          </CardPublic.BodyRight>
          <CardPublic.Footer>{(type === 'l3plus' && <L3PlusFooter />) || <Footer />}</CardPublic.Footer>
        </CardPublic>
      </LayoutPublic.Main>
    </LayoutPublic>
  )
}

export const CTLoginModule = () => <LoginModule type="ct" />
export const L3PlusLoginModule = () => withDifferentTheme('L3Plus')(LoginModule)({ type: 'l3plus' })
