/** Icons generated by create-react-icons. Don't edit this file directly. **/
import React from 'react'
import PropTypes from 'prop-types'

const LogoBulwiengesa = ({ size = '1em', color = undefined, part = '', ...other }) => {
  return (
    <svg
      viewBox={part === 'icon' ? '0 0 27 26' : part === 'text' ? '33 0 115 26' : '0 0 148 26'}
      height={size}
      // width={size}
      {...other}
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.41421"
    >
      {part !== 'text' && (
        <g fillRule="nonzero" fill={color || '#7f9ec1'}>
          <path d="M5.703,2.215L17.19,8.199L20.916,6.045L9.482,0.052L5.703,2.215Z" />
          <path d="M11.461,2.164L22.943,8.154L26.668,6.045L15.121,0.052L11.461,2.164Z" />
          <path d="M0,9.604L11.468,15.581L15.192,13.462L3.686,7.48L0,9.604Z" />
        </g>
      )}
      {part !== 'text' && (
        <g fillRule="nonzero" fill={color || '#00487A'}>
          <path d="M11.459,15.593L11.459,26L15.201,23.846L15.192,13.462L11.459,15.593Z" />
          <path d="M17.165,22.921L20.905,20.797L20.897,6.045L17.165,8.199L17.165,22.921Z" />
          <path d="M22.935,19.51L26.677,17.352L26.668,6.045L22.936,8.165L22.935,19.51Z" />
        </g>
      )}
      {part !== 'icon' && (
        <g fillRule="nonzero" fill={color || '#00487A'}>
          <path d="M78.093,3.06C77.439,3.06 76.904,3.592 76.904,4.249C76.904,4.893 77.439,5.417 78.093,5.417C78.747,5.417 79.261,4.903 79.261,4.249C79.261,3.592 78.736,3.06 78.093,3.06Z" />
          <path d="M77.158 6.298H79.007V17.219H77.158z" />
          <path d="M71.101,14.652L68.366,6.298L66.79,6.298L64.034,14.653L61.492,6.361L61.474,6.298L59.548,6.298L63.031,17.218L64.83,17.218L67.566,8.793L70.283,17.158L70.303,17.218L72.125,17.218L75.568,6.415L75.606,6.298L73.66,6.298L71.101,14.652Z" />
          <path d="M51.777,14.254C51.221,15.01 50.033,15.817 48.709,15.817C47.074,15.817 46.376,15.111 46.376,13.46L46.376,6.298L44.527,6.298L44.527,13.97C44.527,16.303 45.71,17.487 48.042,17.487C49.627,17.487 50.978,16.667 51.777,15.846L51.777,17.219L53.626,17.219L53.626,6.298L51.777,6.298L51.777,14.254Z" />
          <path d="M56.129 2.208H57.977V17.219H56.129z" />
          <path d="M37.969,6.032C36.605,6.032 35.331,6.654 34.434,7.749L34.434,2.207L32.586,2.207L32.586,17.219L34.434,17.219L34.434,15.792C35.314,16.857 36.616,17.486 37.969,17.486C40.882,17.486 42.84,15.19 42.84,11.771C42.84,8.339 40.882,6.032 37.969,6.032ZM37.568,7.702C39.575,7.702 40.925,9.336 40.925,11.771C40.925,14.191 39.575,15.816 37.568,15.816C36.345,15.816 35.031,15.134 34.434,14.19L34.434,9.372C34.962,8.54 36.202,7.702 37.568,7.702Z" />
          <path d="M132.593,10.703C131.233,10.377 130.054,10.096 130.054,9.168C130.054,8.226 130.998,7.568 132.344,7.568C133.62,7.568 134.79,8.063 135.478,8.892L135.555,8.986L136.441,7.668L136.39,7.616C135.685,6.894 134.412,6.032 132.344,6.032C129.533,6.032 128.272,7.629 128.272,9.213C128.272,11.485 130.354,11.99 132.192,12.438C133.656,12.794 134.923,13.101 134.923,14.194C134.923,15.279 133.979,15.95 132.458,15.95C130.957,15.95 129.636,15.195 128.988,14.446L128.91,14.358L127.958,15.715L128.011,15.77C129.106,16.909 130.586,17.487 132.411,17.487C135.021,17.487 136.705,16.176 136.705,14.149C136.705,11.685 134.424,11.14 132.593,10.703Z" />
          <path d="M143.152,6.032C141.393,6.032 139.967,6.624 138.793,7.838L138.745,7.89L139.627,9.202L139.703,9.118C140.617,8.122 141.639,7.657 142.928,7.657C144.523,7.657 145.553,8.503 145.553,9.814L145.553,11.52C144.696,10.652 143.532,10.212 142.083,10.212C140.244,10.212 138.259,11.348 138.259,13.838C138.259,16.207 140.229,17.486 142.083,17.486C143.487,17.486 144.681,17.02 145.553,16.133L145.553,17.219L147.402,17.219L147.402,9.747C147.402,7.385 145.852,6.032 143.152,6.032ZM145.553,12.846L145.553,14.832C144.959,15.63 143.898,16.105 142.708,16.105C141.19,16.105 140.129,15.174 140.129,13.838C140.129,12.517 141.19,11.594 142.708,11.594C143.893,11.594 144.956,12.061 145.553,12.846Z" />
          <path d="M121.538,6.032C118.468,6.032 116.155,8.489 116.155,11.748C116.155,15.128 118.45,17.486 121.739,17.486C123.443,17.486 124.94,16.901 126.069,15.794L126.126,15.738L125.216,14.5L125.143,14.576C124.304,15.435 123.112,15.927 121.873,15.927C119.484,15.927 118.276,14.168 118.121,12.394L126.787,12.394L126.787,11.881C126.787,8.382 124.676,6.032 121.538,6.032ZM121.515,7.591C123.955,7.591 124.899,9.576 124.958,10.968L118.099,10.968C118.231,9.274 119.431,7.591 121.515,7.591Z" />
          <path d="M99.011,6.032C97.475,6.032 96.057,6.846 95.253,7.707L95.253,6.298L93.406,6.298L93.406,17.219L95.253,17.219L95.253,9.309C95.862,8.505 97.046,7.702 98.342,7.702C99.957,7.702 100.676,8.442 100.676,10.103L100.676,17.219L102.504,17.219L102.504,9.591C102.504,7.229 101.331,6.032 99.011,6.032Z" />
          <path d="M112.619,7.749C111.722,6.654 110.447,6.032 109.084,6.032C106.125,6.032 104.213,8.258 104.213,11.704C104.213,15.135 106.125,17.351 109.084,17.351C110.42,17.351 111.722,16.707 112.619,15.613L112.619,16.972C112.619,19.8 110.035,20.017 109.239,20.017C107.682,20.017 106.661,19.597 105.818,18.605L105.743,18.516L104.786,19.875L104.84,19.928C106.236,21.302 107.558,21.579 109.239,21.579C110.811,21.579 114.467,21.129 114.467,16.972L114.467,6.298L112.619,6.298L112.619,7.749ZM109.506,15.684C107.453,15.684 106.126,14.121 106.126,11.704C106.126,9.272 107.453,7.702 109.506,7.702C110.825,7.702 112.064,8.528 112.619,9.352L112.619,14.011C112.072,14.845 110.831,15.684 109.506,15.684Z" />
          <path d="M86.269,6.032C83.199,6.032 80.886,8.489 80.886,11.748C80.886,15.128 83.181,17.486 86.47,17.486C88.172,17.486 89.671,16.901 90.803,15.794L90.857,15.738L89.949,14.5L89.874,14.576C89.035,15.435 87.843,15.927 86.602,15.927C84.213,15.927 83.007,14.168 82.852,12.394L91.518,12.394L91.518,11.881C91.518,8.382 89.407,6.032 86.269,6.032ZM86.246,7.591C88.684,7.591 89.632,9.576 89.689,10.968L82.83,10.968C82.962,9.274 84.164,7.591 86.246,7.591Z" />
        </g>
      )}
    </svg>
  )
}

LogoBulwiengesa.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default LogoBulwiengesa
