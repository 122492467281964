import React from 'react'
import styled from 'styled-components'
import { position, space } from 'styled-system'

import { useMergeDefaultProps } from 'lib/hooks'
import { defaultPropsIconPosition } from '../defaultProps'

const Position = styled.span`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content center;
  height: inherit;
  position: absolute;
  width: inherit;
  
  > svg: {
    fill: currentColor;
  }
  ${position}
  ${space}
`

export const PositionComponent = ({ children, iconPosition }) => {
  const { top, right, bottom, left, marginTop, marginRight, marginBottom, marginLeft } = useMergeDefaultProps(
    { iconPosition },
    defaultPropsIconPosition
  )

  return (
    <Position
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
    >
      {children}
    </Position>
  )
}
