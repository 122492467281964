import createStore from 'lib/flux-store'
import api from 'stores/api'

// import themeRiwis from 'theming/themes/classic'
import { getTheme } from 'theming'

export const createOptionsObject_cityType = (type) => ({
  value: type,
  label: `Ø ${type}-Städte`,
})

export const findSmallestAreaType = (f, availableTypes = ['10', '20', '30']) => {
  // let z = Object.keys(f.areas).find(a => a.type === userType)
  return availableTypes.find((type) => Object.keys(f.areas).includes(type))
  // return f.areas[z].gac
}

const initialState = {
  isLoading: true,
  adminUserFilter: {
    groups: [],
  },
  adminUserSort: { key: null, dir: null },
  adminGroupFilter: {},
  adminGroupSort: { key: null, dir: null },
  adminRoleFilter: {},
  adminRoleSort: { key: null, dir: null },
  adminWebtrackingSort: { key: null, dir: null },
  currentTheme: getTheme('Riwis'),
  lastRoute: null,
  retailMarketsActiveProject: null,
  colorFavorites: [],
  colorHistory: [],
}

const ignoreKeysOnUpdate = {
  isLoading: true,
  currentTheme: true,
  setBy: true,
}

const setLoadingSrc = {
  adminUserFilter: true,
  adminGroupFilter: true,
  adminRoleFilter: true,
}
const addDefaults = {
  adminUserFilter: true,
  adminUserSort: true,
  adminGroupFilter: true,
  adminGroupSort: true,
  adminRoleFilter: true,
  adminRoleSort: true,
  themeOne: true,
}

let lastSavedState = initialState
let isSaving = false
let saveStateQueued = null

const callbacks = {
  onUpdate: (state) => {
    // delete temporary projects when they are removed from the store
    if (state.dontPreserveState) {
      return Promise.resolve()
    }

    let changed = Object.keys(state).reduce((changed, key) => {
      return changed || (ignoreKeysOnUpdate[key] ? false : !Object.is(lastSavedState[key], state[key]))
    }, false)

    if (changed) {
      if (isSaving) {
        saveStateQueued = state
        return
      } else {
        saveStateQueued = null
      }
      isSaving = true

      lastSavedState = { ...state }

      const saveState = {}
      Object.keys(lastSavedState).forEach((key) => {
        if (!ignoreKeysOnUpdate[key]) {
          if (lastSavedState[key] instanceof Map) {
            saveState[key] = []
            lastSavedState[key].forEach((v, k) => saveState[key].push([k, v]))
          } else {
            saveState[key] = lastSavedState[key]
          }
        }
      })
      return api.AppState.save('default', saveState)
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          isSaving = false
          if (saveStateQueued !== null) {
            callbacks.onUpdate(saveStateQueued)
          }
        })
    } else {
      return Promise.resolve()
    }
  },
}

const actions = {
  dontPreserveState: () => ({}),
  loadState: () => (dispatch) => {
    return api.AppState.get('default').then((appState) => {
      if (appState?.data?.data) {
        dispatch({ type: 'setLoadedState', payload: appState.data.data })
      }
    })
  },
  addLocation: (feature, setAsMain = false) => ({ feature, setAsMain }),
  setAdminUserFilter: (filter) => ({ filter }),
  resetAdminUserSort: () => ({}),
  setAdminUserSort: (adminUserSort) => ({ adminUserSort }),
  setAdminGroupFilter: (filter) => ({ filter }),
  resetAdminGroupSort: () => ({}),
  setAdminGroupSort: (adminGroupSort) => ({ adminGroupSort }),
  setAdminRoleFilter: (filter) => ({ filter }),
  resetAdminRoleSort: () => ({}),
  setAdminRoleSort: (adminRoleSort) => ({ adminRoleSort }),
  switchCurrentTheme: (currentTheme) => ({ currentTheme }),
  switchCurrentThemeByName: (currentThemeName) => ({ currentThemeName }),
  setLastRoute: (lastRoute) => ({ lastRoute }),
  setRetailMarketsActiveProject: (projectId) => ({ projectId }),
  setColorFavorites: (colorFavorites) => ({ colorFavorites }),
  setColorHistory: (colorHistory) => ({ colorHistory }),
  setAdminWebtrackingSort: (adminWebtrackingSort) => ({ adminWebtrackingSort }),
}

const reducer = {
  dontPreserveState: (state, { payload }) => ({ ...state, dontPreserveState: true }),
  reset: (state) => {
    const keep = Object.keys(ignoreKeysOnUpdate).reduce((keep, key) => {
      keep[key] = state[key]
      return keep
    }, {})
    const set = Object.keys(initialState).reduce((set, key) => {
      if (typeof keep[key] === 'undefined') {
        set[key] = initialState[key]
        if (addDefaults[key]) {
          if (typeof set[key] === 'object') {
            set[key] = { ...initialState[key], ...set[key] }
          }
        }
      }
      return set
    }, {})
    lastSavedState = { ...set, ...keep }
    return lastSavedState
  },
  setLoadedState: (state, { payload }) => {
    const keep = Object.keys(ignoreKeysOnUpdate).reduce((keep, key) => {
      keep[key] = state[key]
      delete payload[key]
      return keep
    }, {})
    const set = Object.keys(initialState).reduce((set, key) => {
      if (typeof payload[key] !== 'undefined') {
        if (state[key] instanceof Map) {
          set[key] = new Map(payload[key])
        } else if (setLoadingSrc[key]) {
          set[key] = { ...payload[key], setBy: 'loader' }
        } else {
          set[key] = payload[key]
        }
      }
      if (addDefaults[key]) {
        if (typeof set[key] === 'object' && typeof initialState[key] === 'object') {
          set[key] = { ...initialState[key], ...set[key] }
        } else if (typeof set[key] === 'undefined') {
          if (typeof initialState[key] === 'object') {
            set[key] = { ...initialState[key] }
          } else {
            set[key] = initialState[key]
          }
        }
      }
      return set
    }, {})

    lastSavedState = { ...initialState, ...keep, ...set, isLoading: false }
    return lastSavedState
  },
  setAdminUserFilter: (state, { filter }) => {
    // filter needs to be of format { key: value }
    return {
      ...state,
      adminUserFilter: { ...state.adminUserFilter, ...filter },
    }
  },
  resetAdminUserSort: (state) => ({ ...state, adminUserSort: initialState.adminUserSort }),
  setAdminUserSort: (state, { adminUserSort }) => ({ ...state, adminUserSort }),
  setAdminGroupFilter: (state, { filter }) => {
    // filter needs to be of format { key: value }
    return {
      ...state,
      adminGroupFilter: { ...state.adminGroupFilter, ...filter },
    }
  },
  resetAdminGroupSort: (state) => ({ ...state, adminGroupSort: initialState.adminGroupSort }),
  setAdminGroupSort: (state, { adminGroupSort }) => ({ ...state, adminGroupSort }),
  setAdminRoleFilter: (state, { filter }) => {
    // filter needs to be of format { key: value }
    return {
      ...state,
      adminRoleFilter: { ...state.adminRoleFilter, ...filter },
    }
  },
  resetAdminRoleSort: (state) => ({ ...state, adminRoleSort: initialState.adminRoleSort }),
  setAdminRoleSort: (state, { adminRoleSort }) => ({ ...state, adminRoleSort }),
  setVariable: (state, { payload }) => ({ ...state, variable: payload }),
  switchCurrentTheme: (state, currentTheme) => ({ ...state, currentTheme }),
  switchCurrentThemeByName: (state, { currentThemeName }) => {
    return { ...state, currentTheme: getTheme(currentThemeName) }
  },
  setLastRoute: (state, { lastRoute }) => ({ ...state, lastRoute }),
  setRetailMarketsActiveProject: (state, { projectId }) => ({
    ...state,
    retailMarketsActiveProject: projectId,
  }),
  setColorFavorites: (state, { colorFavorites }) => ({ ...state, colorFavorites }),
  setColorHistory: (state, { colorHistory }) => ({ ...state, colorHistory }),
  setAdminWebtrackingSort: (state, { adminWebtrackingSort }) => ({ ...state, adminWebtrackingSort }),
}

export const [AppStoreContext, AppStoreProvider, useAppStore] = createStore(
  reducer,
  actions,
  initialState,
  callbacks,
  'AppStore'
)
