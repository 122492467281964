import React, { memo, useCallback } from 'react'
import styled, { useTheme } from 'styled-components'
import { border, compose, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { HeaderCell } from 'rsuite-table'

import { getThemeValue } from 'theming'

import { BudiconArrowTop, BudiconArrowBottom, BudiconArrowUpDown } from 'bgag-budicons'

import { typeScale, typeStyle, defaultProps as textProps } from '@typography'
import { excludeProps, getStyleHeader, getFlexStyle, typeMapHeader } from '@ui/Table'
import { IconWrapper } from '@utilities/IconWrapper'

import { defaultProps } from './defaultProps'
import { size } from './defaultPropTypes'

const StyledHeaderCell = styled(HeaderCell).withConfig({
  shouldForwardProp: (prop) => !excludeProps.includes(prop),
})`
  // color: ${(props) => themeGet('colors.theme.table.header.color', '#000')(props)};
  ${compose(typography, border)}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
`

export const TableHeaderCell = memo((props) => {
  const theme = useTheme()

  const iconSpaceX = getThemeValue(theme, 'sizes.icons.button.space.x')

  const { children, size, ...rest } = props
  const { typeStyle, typeScale } = typeMapHeader[size]
  const styleAlign = rest.align ? getFlexStyle(rest.align) : getFlexStyle('left')

  const styleHeader = {
    ...getStyleHeader(theme),
    ...styleAlign,
  }

  const renderSortIcon = useCallback(
    (sortType) => {
      const icon = {
        strokeWidth: typeof sortType !== 'undefined' ? '2px' : '0px',
        Icon:
          sortType === 'asc'
            ? BudiconArrowTop
            : sortType === 'desc'
            ? BudiconArrowBottom
            : BudiconArrowUpDown,
      }
      const iconPosition = {}
      const iconWrapper = {}

      return (
        <IconWrapper
          icon={icon}
          iconPosition={iconPosition}
          iconWrapper={iconWrapper}
          iconVariantSourceName="buttonIcons"
          iconVariantSize="tiny"
          ml={iconSpaceX}
        />
      )
    },
    [iconSpaceX]
  )

  return (
    <StyledHeaderCell
      renderSortIcon={renderSortIcon}
      typeScale={typeScale}
      typeStyle={typeStyle}
      style={styleHeader}
      {...rest}
    >
      {children}
    </StyledHeaderCell>
  )
})

TableHeaderCell.defaultProps = {
  ...defaultProps,
  ...textProps,
}

TableHeaderCell.propTypes = {
  size,
}
