import React, { useRef, useLayoutEffect, useState, useCallback, useEffect, useMemo } from 'react'
import { debounce } from 'debounce'

import { useScrollListener } from 'lib/hooks'

const defaultAlignments = { parentH: 'left', parentV: 'top', childH: 'left', childV: 'top' }
const defaultContainments = { invertH: false, invertV: false, forceVisible: true }

/**
 * Will align its children relative to parent while keeping it in container
 */
export const AlignmentBox = ({ children, parent, container, alignments, containments, zIndex }) => {
  const _alignments = useMemo(() => ({ ...defaultAlignments, ...alignments }), [alignments])
  const _containments = useMemo(() => ({ ...defaultContainments, ...containments }), [containments])
  const childRef = useRef(null)
  const [rects, setRects] = useState(null)
  const [position, setPosition] = useState(null)

  const updateClientRects = useCallback(() => {
    if (childRef.current === null) return
    const childRect = childRef.current.getBoundingClientRect()
    const parentRect = parent
      ? parent.getBoundingClientRect()
      : childRef.current.parentNode.getBoundingClientRect()
    const containerRect = container ? container.getBoundingClientRect() : getWindowClientRect()
    setRects({ childRect, parentRect, containerRect })
  }, [parent, container])
  const debouncedUpdate = useMemo(() => debounce(updateClientRects, 100), [updateClientRects])

  useLayoutEffect(() => {
    updateClientRects()
  }, [updateClientRects])

  useEffect(() => {
    if (rects === null) return
    setPosition(alignChild(rects, _alignments, _containments))
  }, [rects, _alignments, _containments])

  const onScroll = useCallback(() => {
    // debounced for performance
    // https://developer.mozilla.org/en-US/docs/Mozilla/Performance/Scroll-linked_effects
    debouncedUpdate()
  }, [debouncedUpdate])
  useScrollListener(childRef.current, onScroll)

  useLayoutEffect(() => {
    if (!childRef.current) return
    const parentNode = parent || childRef.current.parentNode
    const observer = new ResizeObserver(() => debouncedUpdate())
    observer.observe(childRef.current)
    observer.observe(parentNode)
    return () => {
      observer.disconnect()
    }
  }, [parent, debouncedUpdate])

  const style = useMemo(() => {
    const { top, left } = position || {}
    return {
      // prevent flashing while not correctly aligned
      visibility: position === null ? 'hidden' : 'visible',
      position: 'fixed',
      top: `${top}px`,
      left: `${left}px`,
      zIndex: zIndex ?? 1,
    }
  }, [position, zIndex])

  return (
    <div ref={childRef} style={style}>
      {children}
    </div>
  )
}

function getWindowClientRect() {
  return {
    top: 0,
    left: 0,
    bottom: window.innerHeight,
    right: window.innerWidth,
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

export function alignChild(rects, alignments, containment) {
  let { childRect, parentRect, containerRect } = rects
  const { invertH, invertV, forceVisible } = containment

  // First position calculation
  childRect = calculateAlignmentPosition(parentRect, childRect, alignments)

  // invert H and V if necessary and enabled
  const overflows = getOverflows(containerRect, childRect)
  if (invertH && overflows.h) {
    alignments = invertHAlignments(alignments)
  }
  if (invertV && overflows.v) {
    alignments = invertVAlignments(alignments)
  }
  childRect = calculateAlignmentPosition(parentRect, childRect, alignments)

  // Keep child visible if enabled
  if (forceVisible) {
    childRect = containInto(containerRect, childRect)
  }

  return { top: childRect.top, left: childRect.left }
}

export function calculateAlignmentPosition(parentRect, childRect, alignments) {
  const { parentH, parentV, childH, childV } = alignments
  const baseLeft = parentRect[parentH]
  const baseTop = parentRect[parentV]
  const left = childH === 'left' ? baseLeft : baseLeft - childRect.width
  const top = childV === 'top' ? baseTop : baseTop - childRect.height

  return updateRect(childRect, { left, top })
}

const alignMapping = {
  top: 'bottom',
  bottom: 'top',
  left: 'right',
  right: 'left',
}
function invertHAlignments(alignments) {
  const parentH = alignMapping[alignments.parentH]
  const childH = alignMapping[alignments.childH]
  return { ...alignments, parentH, childH }
}

function invertVAlignments(alignments) {
  const parentV = alignMapping[alignments.parentV]
  const childV = alignMapping[alignments.childV]
  return { ...alignments, parentV, childV }
}

/**
 * Sets new top and left property of a rectangle
 * @param {Object} rect
 * @param {Object} updates
 */
export function updateRect(rect, { left, top }) {
  const { width, height } = rect
  top = top || rect.top
  left = left || rect.left
  const right = left + width
  const bottom = top + height
  return { top, bottom, left, right, width, height }
}

export function getOverflows(parentRect, childRect) {
  const top = childRect.top < parentRect.top
  const bottom = childRect.bottom > parentRect.bottom
  const left = childRect.left < parentRect.left
  const right = childRect.right > parentRect.right
  const h = left || right
  const v = top || bottom
  const any = h || v
  return { top, bottom, left, right, h, v, any }
}

export function containInto(parent, child) {
  let top = child.top
  top = Math.min(top, parent.bottom - child.height)
  top = Math.max(top, parent.top)

  let left = child.left
  left = Math.min(left, parent.right - child.width)
  left = Math.max(left, parent.left)

  return updateRect(child, { top, left })
}
