import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { background, border, color, compose, layout, position, space } from 'styled-system'

import { allowedProps } from '../allowedProps'
import { defaultProps } from './defaultProps'
import { tag } from './defaultPropTypes'

const StyledComponent = styled.div.withConfig({
  shouldForwardProp: (prop) => allowedProps.includes(prop),
})`
  ${compose(background, border, color, layout, position, space)}
`

export const PosAbsolute = ({ children, tag, ...rest }) => {
  return (
    <StyledComponent as={tag} position="absolute" {...rest}>
      {children}
    </StyledComponent>
  )
}

export const PosAbsoluteForwardedRef = forwardRef(({ children, tag, ...rest }, ref) => {
  return (
    <StyledComponent as={tag} ref={ref} position="absolute" {...rest}>
      {children}
    </StyledComponent>
  )
})

export const PosRelative = ({ children, tag, ...rest }) => {
  return (
    <StyledComponent as={tag} position="relative" {...rest}>
      {children}
    </StyledComponent>
  )
}

export const PosStatic = ({ children, tag, ...rest }) => {
  return (
    <StyledComponent as={tag} position="static" {...rest}>
      {children}
    </StyledComponent>
  )
}

export const PosRelativeForwardedRef = forwardRef(({ children, tag, ...rest }, ref) => {
  return (
    <StyledComponent as={tag} ref={ref} position="relative" {...rest}>
      {children}
    </StyledComponent>
  )
})

export const PosFixed = ({ children, tag, ...rest }) => {
  return (
    <StyledComponent as={tag} position="fixed" {...rest}>
      {children}
    </StyledComponent>
  )
}

export const PosSticky = ({ children, tag, ...rest }) => {
  return (
    <StyledComponent as={tag} position="sticky" {...rest}>
      {children}
    </StyledComponent>
  )
}

PosAbsolute.propTypes = {
  tag,
}

PosRelative.propTypes = {
  tag,
}

PosFixed.propTypes = {
  tag,
}

PosSticky.propTypes = {
  tag,
}

PosAbsolute.defaultProps = defaultProps
PosRelative.defaultProps = defaultProps
PosFixed.defaultProps = defaultProps
PosSticky.defaultProps = defaultProps
