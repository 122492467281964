import React from 'react'
import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { layout } from 'styled-system'

import { MinionMedium as Text } from '@typography'
import { IconWrapper } from '@utilities/IconWrapper'
import { FlexContainer } from '@layout/BuildingBlocks'

import { appearance, shape, size } from './variants'
import { checkboxAppearance } from 'theming/systemHelper/appearance-checkbox'

import { bool, string, func, oneOfType } from 'prop-types'

const labelStyles = css`
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  position: relative;
  color: inherit;
  width: fit-content;
  white-space: pre-line;
  hyphens: auto;
  text-align: center;
`
export const Label = styled(Text)`
  ${labelStyles}
  ${layout}
`

export const Checkbox = styled.div`
  display: flex;
`

const Container = styled(FlexContainer)`
  cursor: pointer;
  justify-content: center;
  padding: ${(props) => themeGet('switches.padding.container', '0px')(props)};

  ${(props) => props.disabled && { cursor: 'not-allowed' }}
  ${(props) => props.disabled && { '> div': { pointerEvents: 'none' } }}
  ${(props) => props.aspectRatio && { aspectRatio: props.aspectRatio }};

  ${appearance}
  ${shape}
  ${size}
  ${checkboxAppearance}
`

export const CheckboxIcon = ({
  aspectRatio,
  appearance,
  badgeWidth,
  checked,
  direction,
  disabled,
  handleMouseMove,
  handleMouseLeave,
  handleMouseOver,
  icon,
  iconPosition,
  iconWrapper,
  iconVariantSourceName,
  iconVariantSize,
  id,
  label,
  labelGap,
  labelMaxWidth,
  name,
  shape,
  size,
}) => {
  return (
    <Container
      aspectRatio={aspectRatio}
      appearance={appearance}
      alignItems="center"
      badgeWidth={badgeWidth}
      checked={checked}
      disabled={disabled}
      direction={direction}
      onMouseMove={handleMouseMove}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      shape={shape}
      size={size}
    >
      <Checkbox name={name} id={id} />
      <FlexContainer
        flexDirection={direction}
        alignItems="center"
        flexRowGap={labelGap}
        flexColumnGap={labelGap}
      >
        <IconWrapper
          icon={icon}
          iconPosition={iconPosition}
          iconWrapper={iconWrapper}
          iconVariantSourceName={iconVariantSourceName}
          iconVariantSize={iconVariantSize}
        />

        <Label maxWidth={labelMaxWidth} tag="span">
          {label}
        </Label>
      </FlexContainer>
    </Container>
  )
}

CheckboxIcon.defaultProps = {
  appearance: 'lightgray',
  aspectRatio: null,
  badgeWidth: null,
  checked: false,
  direction: 'column',
  disabled: false,
  handleMouseMove: () => {},
  handleMouseLeave: () => {},
  handleMouseOver: () => {},
  id: 'checkbox-id',
  label: 'checkbox label',
  labelGap: null,
  labelMaxWidth: null,
  name: 'checkbox-name',
  shape: 'square',
  size: 'small',
}
CheckboxIcon.propTypes = {
  appearance: string,
  aspectRatio: string,
  badgeWidth: string,
  checked: bool,
  direction: string,
  disabled: bool,
  handleMouseMove: func,
  handleMouseLeave: func,
  handleMouseOver: func,
  id: string,
  label: oneOfType([bool, string]),
  labelGap: string,
  labelMaxWidth: string,
  name: string,
  shape: string,
  size: string,
}
