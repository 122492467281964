import React from 'react'
import styled, { useTheme } from 'styled-components'
import { layout, space } from 'styled-system'

import { getThemeValue } from 'theming'

const StyledComponent = styled.div`
  ${layout}
  ${space}
  display: flex;
  flex-direction: column;
  flex: auto;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  ${space}
`

export const ModalSection = ({ children, scrollable, expand, ...props }) => {
  const theme = useTheme()
  const spaceLeft = expand ? '0px' : getThemeValue(theme, 'modal.body.space.x')
  const spaceScrollbar = getThemeValue(theme, 'modal.body.space.scrollbar')
  const spaceRight = scrollable
    ? `${+spaceLeft.replace(/px/, '') - +spaceScrollbar.replace(/px/, '')}px`
    : expand
    ? null
    : getThemeValue(theme, 'modal.body.space.x')

  return (
    <StyledComponent pl={spaceLeft} pr={spaceRight}>
      <Content {...props}>{children}</Content>
    </StyledComponent>
  )
}

ModalSection.displayName = 'modalSection'
ModalSection.propTypes = {}
ModalSection.defaultProps = {}
