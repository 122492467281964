import { variant } from 'styled-system'
import { getThemeValue } from 'theming'

import {
  bare,
  dark,
  ghost,
  gray,
  light,
  lightgray,
  primary,
  primaryGhost,
  primaryShallow,
  success,
  error,
  white,
} from 'theming/systemHelper/appearance'
import { oval, square, shapeless } from 'theming/systemHelper/shape'
import {
  nano,
  nanoOval,
  tiny,
  tinyOval,
  small,
  smallOval,
  medium,
  mediumOval,
  large,
  largeOval,
} from 'theming/systemHelper/size'

export const appearance = (props) =>
  variant({
    prop: 'appearance',
    variants: {
      bare,

      dark: (theme) => {
        return {
          ...dark(theme),
          ...(() => {
            return (
              props.disabled && {
                '&:link, &:hover, &:visited, &:active': {
                  border: '1px solid',
                  backgroundColor: getThemeValue(theme, 'uiActionElements.dark.disabled.bg'),
                  borderColor: getThemeValue(theme, 'uiActionElements.dark.disabled.bg'),
                  color: getThemeValue(theme, 'uiActionElements.dark.disabled.color'),
                },
              }
            )
          })(),
        }
      },

      ghost: (theme) => {
        return {
          ...ghost(theme),
          ...(() => {
            return (
              props.disabled && {
                '&:link, &:hover, &:visited, &:active': {
                  border: '1px solid',
                  backgroundColor: getThemeValue(theme, 'uiActionElements.ghost.disabled.bg'),
                  borderColor: getThemeValue(theme, 'uiActionElements.ghost.disabled.color'),
                  color: getThemeValue(theme, 'uiActionElements.ghost.disabled.color'),
                },
              }
            )
          })(),
        }
      },

      gray: (theme) => {
        return {
          ...gray(theme),
          ...(() => {
            return (
              props.disabled && {
                '&:link, &:hover, &:visited, &:active': {
                  border: '1px solid',
                  backgroundColor: getThemeValue(theme, 'uiActionElements.gray.disabled.bg'),
                  borderColor: getThemeValue(theme, 'uiActionElements.gray.disabled.bg'),
                  color: getThemeValue(theme, 'uiActionElements.gray.disabled.color'),
                },
              }
            )
          })(),
        }
      },

      light: (theme) => {
        return {
          ...light(theme),
          ...(() => {
            return (
              props.disabled && {
                '&:link, &:hover, &:visited, &:active': {
                  border: '1px solid',
                  backgroundColor: getThemeValue(theme, 'uiActionElements.light.disabled.bg'),
                  borderColor: getThemeValue(theme, 'uiActionElements.light.disabled.bg'),
                  color: getThemeValue(theme, 'uiActionElements.light.disabled.color'),
                },
              }
            )
          })(),
        }
      },

      lightgray: (theme) => {
        return {
          ...lightgray(theme),
          ...(() => {
            return (
              props.disabled && {
                '&:link, &:hover, &:visited, &:active': {
                  border: '1px solid',
                  backgroundColor: getThemeValue(theme, 'uiActionElements.lightgray.disabled.bg'),
                  borderColor: getThemeValue(theme, 'uiActionElements.lightgray.disabled.bg'),
                  color: getThemeValue(theme, 'uiActionElements.lightgray.disabled.color'),
                },
              }
            )
          })(),
        }
      },

      primary: (theme) => {
        return {
          ...primary(theme),
          ...(() => {
            return (
              props.disabled && {
                '&:link, &:hover, &:visited, &:active': {
                  border: '1px solid',
                  backgroundColor: getThemeValue(theme, 'uiActionElements.primary.disabled.bg'),
                  borderColor: getThemeValue(theme, 'uiActionElements.primary.disabled.bg'),
                  color: getThemeValue(theme, 'uiActionElements.primary.disabled.color'),
                },
              }
            )
          })(),
        }
      },

      primaryGhost: (theme) => {
        return {
          ...primaryGhost(theme),
          ...(() => {
            return (
              props.disabled && {
                '&:link, &:hover, &:visited, &:active': {
                  border: '1px solid',
                  backgroundColor: getThemeValue(theme, 'uiActionElements.primaryGhost.disabled.bg'),
                  borderColor: getThemeValue(theme, 'uiActionElements.primaryGhost.disabled.color'),
                  color: getThemeValue(theme, 'uiActionElements.primaryGhost.disabled.color'),
                },
              }
            )
          })(),
        }
      },
      primaryShallow: (theme) => {
        return {
          ...primaryShallow(theme),
          ...(() => {
            return (
              props.disabled && {
                '&:link, &:hover, &:visited, &:active': {
                  border: '1px solid',
                  backgroundColor: getThemeValue(theme, 'uiActionElements.primaryShallow.disabled.bg'),
                  borderColor: getThemeValue(theme, 'uiActionElements.primaryShallow.disabled.color'),
                  color: getThemeValue(theme, 'uiActionElements.primaryShallow.disabled.color'),
                },
              }
            )
          })(),
        }
      },

      success: (theme) => {
        return {
          ...success(theme),
          ...(() => {
            return (
              props.disabled && {
                '&:link, &:hover, &:visited, &:active': {
                  border: '1px solid',
                  backgroundColor: getThemeValue(theme, 'uiActionElements.success.disabled.bg'),
                  borderColor: getThemeValue(theme, 'uiActionElements.success.disabled.bg'),
                  color: getThemeValue(theme, 'uiActionElements.success.disabled.color'),
                },
              }
            )
          })(),
        }
      },

      error: (theme) => {
        return {
          ...error(theme),
          ...(() => {
            return (
              props.disabled && {
                '&:link, &:hover, &:visited, &:active': {
                  border: '1px solid',
                  backgroundColor: getThemeValue(theme, 'uiActionElements.error.disabled.bg'),
                  borderColor: getThemeValue(theme, 'uiActionElements.error.disabled.bg'),
                  color: getThemeValue(theme, 'uiActionElements.error.disabled.color'),
                },
              }
            )
          })(),
        }
      },

      white,
    },
  })

export const size = (props) => {
  return props.shape === 'oval'
    ? variant({
        prop: 'size',
        variants: {
          nano: nanoOval,
          tiny: tinyOval,
          small: smallOval,
          medium: mediumOval,
          large: largeOval,
        },
      })
    : variant({
        prop: 'size',
        variants: {
          nano,
          tiny,
          small,
          medium,
          large,
        },
      })
}

export const shape = (props) =>
  variant({
    prop: 'shape',
    variants: {
      oval,
      square,
      shapeless,
    },
  })
