import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const CalendarIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#62A9D1',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16.5" cy="16.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M25.12 5.75H20.72V4.4375C20.72 4.32147 20.6736 4.21019 20.5911 4.12814C20.5086 4.04609 20.3967 4 20.28 4C20.1633 4 20.0514 4.04609 19.9689 4.12814C19.8864 4.21019 19.84 4.32147 19.84 4.4375V5.75H10.16V4.4375C10.16 4.32147 10.1136 4.21019 10.0311 4.12814C9.94861 4.04609 9.8367 4 9.72 4C9.6033 4 9.49139 4.04609 9.40887 4.12814C9.32636 4.21019 9.28 4.32147 9.28 4.4375V5.75H4.88C4.64661 5.75 4.42278 5.84219 4.25775 6.00628C4.09271 6.17038 4 6.39294 4 6.625V24.125C4 24.3571 4.09271 24.5796 4.25775 24.7437C4.42278 24.9078 4.64661 25 4.88 25H25.12C25.3534 25 25.5772 24.9078 25.7423 24.7437C25.9073 24.5796 26 24.3571 26 24.125V6.625C26 6.39294 25.9073 6.17038 25.7423 6.00628C25.5772 5.84219 25.3534 5.75 25.12 5.75V5.75ZM25.12 24.125H4.88V11H25.12V24.125ZM25.12 10.125H4.88V6.625H25.12V10.125ZM17.2 15.375H15.44V13.625H17.2V15.375ZM21.6 15.375H19.84V13.625H21.6V15.375ZM10.16 19.75H8.4V18H10.16V19.75ZM14.56 19.75H12.8V18H14.56V19.75Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
