import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'

import { themeGet } from '@styled-system/theme-get'
import { StyledLink } from '../ParentLinkItem'
import { FlexRow, FlexItem, PosAbsolute, PosRelative } from '@layout/BuildingBlocks'

import { defaultProps } from './defaultProps'
import { itemProps } from './defaultPropTypes'

const activeStyle = css`
  > div > a {
    color: ${themeGet('navigationMain.flyout.colors.parent.active.color', 'lightgray')};

    text-decoration: underline;
  }
  ul {
    display: block;
  }
`

const ListItem = styled.li`
  margin: 0;

  :hover,
  &.active {
    ${activeStyle}
  }
`

const LinkItem = forwardRef(({ itemProps: { label, link, icon } }, ref) => {
  return (
    <StyledLink
      ref={ref}
      rel="noopener noreferrer"
      target="_blank"
      href={link.path}
      typeScale="copy"
      typeStyle="copy"
    >
      <FlexRow alignItems="center">
        <PosRelative width="28px">
          <PosAbsolute marginTop={'-7px'}>{React.cloneElement(icon.component)}</PosAbsolute>
        </PosRelative>
        <FlexItem>{label}</FlexItem>
      </FlexRow>
    </StyledLink>
  )
})

export const ParentExtraLinkItem = forwardRef(({ itemProps, children, onMouseOver }, ref) => {
  return (
    <ListItem ref={ref} onMouseOver={onMouseOver}>
      <FlexRow>
        <LinkItem itemProps={itemProps} />
        {children}
      </FlexRow>
    </ListItem>
  )
})

ParentExtraLinkItem.defaultProps = defaultProps
ParentExtraLinkItem.propTypes = {
  itemProps,
}
