import createStore from 'lib/flux-store'
import api from 'stores/api'
import functionQueue from 'lib/util/functionQueue'

const queuedSave = functionQueue(api.AppState.save)
const __NAME__ = 'LayoutStore'

const initialState = {
  isLoading: true,
  isFailure: false,
  themeConstants: null,
  messages: [],
}

const ignoreKeysOnUpdate = {
  isLoading: true,
  isFailure: true,
  messages: true,
  themeConstants: true,
}

let lastSavedState = initialState

const callbacks = {
  onUpdate: (state) => {
    const changed = Object.keys(state).reduce((changed, key) => {
      return changed || (ignoreKeysOnUpdate[key] ? false : !Object.is(lastSavedState[key], state[key]))
    }, false)

    if (changed) {
      lastSavedState = { ...state }

      const saveState = {}
      Object.keys(lastSavedState).forEach((key) => {
        if (!ignoreKeysOnUpdate[key]) {
          saveState[key] = lastSavedState[key]
        }
      })

      return queuedSave(__NAME__, saveState)
    } else {
      return Promise.resolve()
    }
  },
}

const actions = {
  loadState: () => (dispatch) => {
    api.AppState.get(__NAME__)
      .then((response) => {
        const state = response.data.data
        dispatch({ type: 'setLoadedState', payload: state })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },

  setThemeConstants: (themeConstants) => ({ themeConstants }),
}

const reducer = {
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),

  setLoadedState: (state, { payload }) => {
    const set = Object.keys(initialState).reduce((set, key) => {
      if (ignoreKeysOnUpdate[key]) return set
      if (typeof payload[key] !== 'undefined') {
        set[key] = payload[key]
      }
      if (Array.isArray(initialState[key])) {
        if (!Array.isArray(set[key])) {
          set[key] = [...initialState[key]]
        }
      } else if (typeof set[key] === 'object' && typeof initialState[key] === 'object') {
        set[key] = { ...initialState[key], ...set[key] }
      } else if (typeof set[key] === 'undefined') {
        if (Array.isArray(initialState[key])) {
          set[key] = [...initialState[key]]
        } else if (typeof initialState[key] === 'object') {
          set[key] = { ...initialState[key] }
        } else {
          set[key] = initialState[key]
        }
      }
      return set
    }, {})

    lastSavedState = { ...state, ...set, isLoading: false }
    return lastSavedState
  },

  setThemeConstants: (prev, { themeConstants }) => {
    if (Object.is(themeConstants, prev.themeConstants)) {
      return prev
    }
    return { ...prev, themeConstants }
  },
}

export const [LayoutContext, LayoutProvider, useLayoutStore] = createStore(
  reducer,
  actions,
  initialState,
  callbacks,
  __NAME__
)
