import React, { memo, useEffect, useCallback, useMemo, useState } from 'react'

import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { isFunction } from 'lib/util'

import { FormElement, Radio, withFormElementProps } from '@forms'
import { FlexContainer, FlexItem } from '@layout/BuildingBlocks'

import { array, oneOfType, string, number } from 'prop-types'
import { getCheckboxBar } from 'theming/themes/default/uiElements/checkboxBar'
import { spaces } from 'theming/themes/default/variables/spaces'

const checkboxBar = getCheckboxBar({ spaces })

const groupStateToValue = (state, name) => {
  return state[name]
}

const valueToGroupState = (value, name) => {
  return { [name]: value }
}

const Row = ({
  name,
  alignItems,
  justifyContent,
  flexColumnGap,
  flexRowGap,
  flexWrap,
  flexGrowItems,
  flexWidthItems,
  width,
  items,
  groupedView,
}) => {
  return (
    <FlexContainer
      alignItems={alignItems}
      justifyContent={justifyContent}
      flexColumnGap={flexColumnGap}
      flexRowGap={flexRowGap}
      flexWrap={flexWrap}
      width={width}
    >
      {items.map((item, index) => (
        <FlexItem flexGrow={flexGrowItems} key={index} width={flexWidthItems}>
          <Radio
            value={item.value}
            disabled={item.disabled}
            forElementName={name}
            labelStyle={{ width: '100%' }}
            groupedView={groupedView}
            firstItem={index === 0}
            lastItem={index === items.length - 1}
          >
            {item.renderChild}
          </Radio>
        </FlexItem>
      ))}
    </FlexContainer>
  )
}

export const RadioBarComponent = ({
  alignItems,
  debounce,
  defaultValue,
  itemsSpaceX,
  itemsSpaceY,
  justifyItems,
  flexColumnGap,
  flexGrowItems,
  flexWidthItems,
  flexWrap,
  formElementRefs,
  items,
  name,
  onChange,
  width,
  value,
  groupedView,
  ...props
}) => {
  const [initialValue] = useState(defaultValue ?? value ?? '')
  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(() => valueToGroupState(internalValue, name), [internalValue, name])

  const updateControllableState = useCallback(
    (evt) => {
      setInternalValue(groupStateToValue(evt.value, name))
    },
    [setInternalValue, name]
  )

  const onReset = useCallback(() => {
    setInternalValue(initialValue)
  }, [setInternalValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  const rows = useMemo(() => (items.length && !Array.isArray(items[0]) ? [items] : items), [items])

  return (
    <FormElement {...props} name={name} value={formState} onChange={updateControllableState}>
      {rows.length > 1 ? (
        <FlexContainer flexDirection="column" flexRowGap={itemsSpaceY} width={width}>
          {rows.map((items, index) => (
            <Row
              key={index}
              name={name}
              alignItems={alignItems}
              justifyContent={justifyItems}
              flexColumnGap={itemsSpaceX}
              flexRowGap={itemsSpaceY}
              flexWrap={flexWrap ?? 'nowrap'}
              flexGrowItems={flexGrowItems}
              flexWidthItems={flexWidthItems}
              groupedView={groupedView}
              width={width}
              items={items}
            />
          ))}
        </FlexContainer>
      ) : (
        <Row
          name={name}
          alignItems={alignItems}
          justifyContent={justifyItems}
          flexColumnGap={itemsSpaceX}
          flexRowGap={itemsSpaceY}
          flexWrap={flexWrap ?? 'nowrap'}
          flexGrowItems={flexGrowItems}
          flexWidthItems={flexWidthItems}
          groupedView={groupedView}
          width={width}
          items={items}
        />
      )}
    </FormElement>
  )
}

RadioBarComponent.type = 'RadioBar'
RadioBarComponent.defaultProps = {
  alignItems: 'flex-start',
  debounce: null,
  flexGrowItems: 1,
  flexWidthItems: null,
  flexWrap: null,
  itemsSpaceX: checkboxBar.items.space.compact.x,
  itemsSpaceY: checkboxBar.items.space.compact.y,
  justifyItems: 'flex-start',
  name: 'radioBarComponent',
  width: '100%',
}
RadioBarComponent.propTypes = {
  alignItems: string,
  debounce: number,
  flexGrowItems: oneOfType([number, string]),
  flexWidthItems: oneOfType([number, string]),
  flexWrap: oneOfType([number, string]),
  itemsSpaceX: oneOfType([array, string]),
  itemsSpaceY: oneOfType([array, string]),
  justifyItems: string,
  name: string,
  width: string,
}

export const RadioBar = withFormElementProps(memo(RadioBarComponent))
