import { checkPropTypes, array, string, oneOfType } from 'prop-types'

export const propsCheck = (props, propName, componentName) => {
  if (props.gridColumn) {
    checkPropTypes(
      {
        gridColumn: oneOfType([array, string]),
      },
      {
        gridColumn: props.gridColumn,
      },
      'prop',
      'GridItem'
    )
  }
  if (props.gridArea) {
    checkPropTypes(
      {
        gridArea: oneOfType([array, string]),
      },
      {
        gridArea: props.gridArea,
      },
      'prop',
      'GridItem'
    )
  }
  if (props.variant) {
    checkPropTypes(
      {
        variant: string,
      },
      {
        variant: props.variant,
      },
      'prop',
      'GridItem'
    )
  }
}

export const defaultPropTypes = {
  gridArea: propsCheck,
  gridColumn: propsCheck,
  variant: propsCheck,
}
