const getNewPos = ({ side, align, alignOffset, distance, targetRect, flyoutWidth, flyoutHeight }) => {
  let top
  let left

  if (side === 'top' || side === 'bottom') {
    top = side === 'top' ? `${targetRect.top - flyoutHeight}px` : `${targetRect.bottom}px`
    left =
      align === 'start'
        ? `${targetRect.left + alignOffset}px`
        : `${targetRect.right - flyoutWidth - alignOffset}px`
  }

  if (side === 'left' || side === 'right') {
    top =
      align === 'start'
        ? `${targetRect.top + alignOffset}px`
        : `${targetRect.bottom - flyoutHeight - alignOffset}px`
    left = side === 'left' ? `${targetRect.left - flyoutWidth}px` : `${targetRect.right}px`
  }

  return { top, left }
}

const getPlacementCorrection = ({ flyoutTop, flyoutLeft, side, align, flyoutWidth, flyoutHeight }) => {
  const flyoutBottom = flyoutTop + flyoutHeight
  const flyoutRight = flyoutLeft + flyoutWidth

  const viewHeight = document.documentElement.clientHeight
  const viewWidth = document.documentElement.clientWidth

  const collision = {
    top: flyoutTop < 0,
    right: flyoutRight > viewWidth,
    bottom: flyoutBottom > viewHeight,
    left: flyoutLeft < 0,
  }
  let nextSide = side
  let nextAlign = align
  if (side === 'left' && collision.left) {
    nextSide = 'right'
  } else if (side === 'right' && collision.right) {
    nextSide = 'left'
  } else if (side === 'top' && collision.top) {
    nextSide = 'bottom'
  } else if (side === 'bottom' && collision.bottom) {
    nextSide = 'top'
  }
  if (side === 'left' || side === 'right') {
    if (align === 'start' && collision.bottom) {
      nextAlign = 'end'
    } else if (align === 'end' && collision.top) {
      nextAlign = 'start'
    }
  } else if (side === 'top' || side === 'bottom') {
    if (align === 'start' && collision.right) {
      nextAlign = 'end'
    } else if (align === 'end' && collision.left) {
      nextAlign = 'start'
    }
  }
  return { align: nextAlign, side: nextSide }
}

export const reposFlyout = ({
  targetRect = {},
  flyout,
  inView,
  side,
  align,
  centerInOpener,
  alignOffset,
  distance,
  propWidth,
  propMinWidth,
  propMaxWidth,
}) => {
  if (!flyout || (!centerInOpener && (!targetRect.height || !targetRect.width))) {
    return
  }

  if (!inView) {
    if (flyout?.parentNode) {
      flyout.parentNode.style.display = 'none'
    }
    return
  }
  if (flyout?.parentNode) {
    flyout.parentNode.style.display = 'block'
  }

  // set margins according to side and distance
  if (side === 'top' || side === 'bottom') {
    flyout.style.margin = `${distance}px 0px`
  } else {
    flyout.style.margin = `0px ${distance}px`
  }

  // recalc minWidth/width
  let widthCalc = null
  let minWidthCalc = null
  let maxWidthCalc = null
  if (propWidth) {
    if (propWidth.includes('%')) {
      widthCalc = (targetRect.width * parseInt(propWidth)) / 100 + 'px'
    } else {
      widthCalc = propWidth
    }
  }
  if (propMinWidth) {
    if (propMinWidth.includes('%')) {
      minWidthCalc = (targetRect.width * parseInt(propMinWidth)) / 100 + 'px'
    } else {
      minWidthCalc = propMinWidth
    }
  }
  if (propMaxWidth) {
    if (propMaxWidth.includes('%')) {
      maxWidthCalc = (targetRect.width * parseInt(propMaxWidth)) / 100 + 'px'
    } else {
      maxWidthCalc = propMaxWidth
    }
  }
  flyout.style.width = widthCalc === null ? 'initial' : widthCalc
  flyout.style.minWidth = minWidthCalc === null ? 'initial' : minWidthCalc
  flyout.style.maxWidth = maxWidthCalc === null ? 'initial' : maxWidthCalc

  // first set new positioning:
  let { height: flyoutHeight, width: flyoutWidth } = flyout.getBoundingClientRect()
  const { marginTop, marginBottom, marginLeft, marginRight } = getComputedStyle(flyout)

  flyoutHeight = flyoutHeight + parseInt(marginTop) + parseInt(marginBottom)
  flyoutWidth = flyoutWidth + parseInt(marginLeft) + parseInt(marginRight)

  let { top, left } = getNewPos({
    side,
    align,
    centerInOpener,
    alignOffset,
    targetRect,
    flyoutWidth,
    flyoutHeight,
  })

  // now test for viewContainer, and fix if needed
  const nextPlacement = getPlacementCorrection({
    flyoutTop: parseFloat(top),
    flyoutLeft: parseFloat(left),
    side,
    align,
    flyoutWidth,
    flyoutHeight,
  })

  if (nextPlacement.side !== side || nextPlacement.align !== align) {
    const newPos = getNewPos({
      side: nextPlacement.side,
      align: nextPlacement.align,
      centerInOpener,
      alignOffset,
      targetRect,
      flyoutWidth,
      flyoutHeight,
    })
    top = newPos.top
    left = newPos.left
  }
  flyout.style.top = top
  flyout.style.left = left

  flyout.classList.remove('triangle-top-start')
  flyout.classList.remove('triangle-top-end')
  flyout.classList.remove('triangle-right-start')
  flyout.classList.remove('triangle-right-end')
  flyout.classList.remove('triangle-bottom-start')
  flyout.classList.remove('triangle-bottom-end')
  flyout.classList.remove('triangle-left-start')
  flyout.classList.remove('triangle-left-end')
  flyout.classList.add(`triangle-${nextPlacement.side}-${nextPlacement.align}`)
}
