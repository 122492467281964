import { array, bool, oneOfType, string } from 'prop-types'

const appearance = string
const checkmarkWidth = oneOfType([string, array])
const checkmarkHeight = oneOfType([string, array])
const checkmarkFill = string
const checkmarkStroke = string
const checkmarkStrokeWidth = string
const height = oneOfType([string, array])
const width = oneOfType([string, array])
const checked = bool
const disabled = bool

export {
  appearance,
  checkmarkFill,
  checkmarkStroke,
  checkmarkStrokeWidth,
  checkmarkHeight,
  checkmarkWidth,
  height,
  width,
  checked,
  disabled,
}
