import React from 'react'

import { ThemeProvider } from 'styled-components'
import { getTheme } from '../index'

export const withDifferentTheme =
  (themeName) =>
  (Component) =>
  ({ ...props }) => {
    const theme = getTheme(themeName)

    return (
      <ThemeProvider theme={theme}>
        <Component {...props} />
      </ThemeProvider>
    )
  }
