import styled from 'styled-components'
import { typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { FlexRow } from '@layout/BuildingBlocks'
import { typeScale, typeStyle } from '@typography'

const excludeProps = [
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'typeScale',
  'typeStyle',
  'scale',
]

export const Container = styled(FlexRow)
  .withConfig({
    shouldForwardProp: (props) => !excludeProps.includes(props),
  })
  .attrs({
    tag: 'span',
  })`
  margin-bottom: ${themeGet('spaces.rhythm.vertical.small', '8px')}

  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
`
