export const getControlsPanel = ({ radii, spaces, colors }) => ({
  border: {
    radius: radii.medium,
  },
  space: {
    top: spaces.rhythm.horizontal.medium,
    bottom: spaces.rhythm.horizontal.large,
    marginRight: spaces.gaps.controlsPanel,
    paddingToScrollbar: '8px',
    innerPaddingTop: spaces.rhythm.vertical.large,
  },
  bg: colors.lightgray[270],
  width: ['284px', null, '294px', '300px', '336px'],
})
