export const defaultProps = {
  appearance: null,
  direction: 'left',
  icon: null,
  iconPosition: null,
  onClick: null,
  onMouseLeave: null,
  shape: 'shapeless',
  size: 'tiny',
}
