import React from 'react'

import { ColorCell, Cell } from '.'

import { string, node, oneOfType, object } from 'prop-types'
import { useNumbersFormats } from 'config/numbersFormats'

export const LegendEntry = ({ color, content, span, isPatchedLegend }) => {
  const numbersFormats = useNumbersFormats()
  const renderCells = Array.isArray(content) ? (
    content.map((item, index) => {
      // eslint-disable-next-line no-useless-escape
      const regex = new RegExp(/[\<\>,-\.]+/g)
      // console.log(item.match(regex))
      item =
        item.length === 0 || item.match(regex) || isPatchedLegend
          ? item
          : `${numbersFormats.roundedFraction1.format(Number(item).toFixed(1))}`
      return <Cell key={index} text={item} span={span} align="right" />
    })
  ) : (
    <Cell text={content} span={span} />
  )

  return (
    <tr>
      <ColorCell color={color} />
      {renderCells}
    </tr>
  )
}

LegendEntry.propTypes = {
  color: string,
  content: oneOfType([string, node, object]),
}

LegendEntry.defaultProps = {
  color: 'red',
  content: 'Lorem Ipsum',
}
