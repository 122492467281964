import { useState, useCallback, useEffect } from 'react'

export const useCursor = ({ options, isActive, onPrevious, onNext, colsPerRow = 10 }) => {
  const [cursorIndex, setCursorIndex] = useState(null)

  const getNextCursorIndex = useCallback(
    (direction = false, defaultIndex = null) => {
      return (prev) => {
        if (!options.length) {
          return prev
        }

        let switchMatrix = false

        const getNextIndex = (index) => {
          switch (direction) {
            case 'up':
              if (index - colsPerRow < 0) {
                switchMatrix = 'prev'
              }
              return (index + options.length - colsPerRow) % options.length
            case 'down':
              if (index + colsPerRow > options.length) {
                switchMatrix = 'next'
              }
              return (index + colsPerRow) % options.length
            case 'left':
              if ((index % colsPerRow) - 1 < 0) {
                switchMatrix = 'prev'
                return index
              } else {
                return index - 1
              }
            case 'right':
              if ((index % colsPerRow) + 1 >= colsPerRow) {
                switchMatrix = 'next'
                return index
              } else {
                return index + 1
              }
            default:
              return index
          }
        }

        const curIndex = defaultIndex ?? prev ?? 0

        let nextIndex = curIndex
        if (direction !== false) {
          let count = 0
          do {
            nextIndex = getNextIndex(nextIndex)
            count++
          } while (options?.[nextIndex]?.disabled && nextIndex !== curIndex && count <= options.length)
        }

        if (nextIndex < 0 || options?.[nextIndex]?.disabled) {
          return prev
        }
        switchMatrix === 'prev' && onPrevious()
        switchMatrix === 'next' && onNext()
        return nextIndex
      }
    },
    [options, colsPerRow, onNext, onPrevious]
  )

  const setNextCursorIndex = useCallback(
    (direction = false, defaultIndex = null) => {
      setCursorIndex(getNextCursorIndex(direction, defaultIndex))
    },
    [getNextCursorIndex]
  )

  useEffect(() => {
    if (!isActive) {
      setCursorIndex(null)
    }
  }, [isActive])

  return { cursorIndex, setNextCursorIndex }
}
