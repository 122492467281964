import React from 'react'

import { PosRelative } from '@layout/BuildingBlocks'
import { FlexContainer } from '@layout/BuildingBlocks'

import { defaultProps } from './defaultProps'
import { children } from './defaultPropTypes'

export const HeaderGlobalControls = ({ children }) => (
  <FlexContainer alignItems="center" justifyContent="flex-end" marginRight="36px">
    <PosRelative>{children}</PosRelative>
  </FlexContainer>
)

HeaderGlobalControls.propTypes = {
  children,
}

HeaderGlobalControls.defaultProps = defaultProps
