import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { compose, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { FlexItem, FlexColumn, FlexRow } from '@layout/BuildingBlocks'

import { getThemeValue } from 'theming'

import { defaultProps } from './defaultProps'
import { itemProps } from './defaultPropTypes'

import {
  Pica,
  typeScale,
  typeStyle,
  defaultProps as textProps,
  defaultPropTypes as textPropTypes,
} from '@typography'

const StyledSubListItem = styled.li`
  margin: 0 0 8px;
  width: 100%;

  .active {
    background: ${themeGet('navigationMain.flyout.colors.sub.active.bg', 'lightgray')};
    color: ${themeGet('navigationMain.flyout.colors.sub.active.color', 'lightgray')};
    font-weight: 600;
  }

  :hover {
    color: ${themeGet('navigationMain.flyout.colors.sub.hover.color', 'lightgray')};
    background: ${themeGet('navigationMain.flyout.colors.sub.hover.bg', 'lightgray')};
  }

  .active,
  :hover {
    border-radius: ${themeGet('navigationMain.flyout.radius', '0')};
    a {
      div div:first-of-type {
        text-decoration: underline;
      }
    }
  }
`

const excludeProps = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
]

const StyledLink = styled.a.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  color: ${themeGet('navigationMain.flyout.colors.sub.default.color', 'lightgray')};
  display: block;
  height: 100%;
  padding: 10px 13px;
  position: relative;
  text-decoration: none;
  width: 100%;

  ${compose(typography)}

  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
`

export const SubLinkItem = ({ itemProps: { label, link, description, icon, image } }) => {
  const theme = useTheme()
  const descriptionColor = getThemeValue(theme, 'navigationMain.flyout.colors.sub.description.color')

  const props = useMemo(() => {
    const props =
      link.href !== null
        ? {
            as: 'a',
            href: link.href,
            target: '_blank',
            rel: 'noopener noreferrer',
            typeScale: 'copy',
            typeStyle: 'copy',
          }
        : {
            as: NavLink,
            to: link.path,
            typeScale: 'copy',
            typeStyle: 'copy',
          }
    return props
  }, [link])

  return (
    <StyledSubListItem>
      <StyledLink {...props}>
        <FlexRow flexColumnGap={'1rem'} alignItems="start">
          <FlexItem>{icon}</FlexItem>
          <FlexColumn>
            {image && (
              <FlexItem>
                <img width="120px" src={image.src} alt={image.alt} />
              </FlexItem>
            )}
            {label && <FlexItem>{label}</FlexItem>}
            <FlexItem>
              <Pica marginTop="4px" color={descriptionColor} marginBottom="0px" tag="p">
                {description}
              </Pica>
            </FlexItem>
          </FlexColumn>
        </FlexRow>
      </StyledLink>
    </StyledSubListItem>
  )
}

SubLinkItem.defaultProps = defaultProps
SubLinkItem.propTypes = {
  itemProps,
}

StyledLink.defaultProps = {
  ...textProps,
}

StyledLink.propTypes = {
  ...textPropTypes,
}
