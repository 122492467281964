const heights = [36, 36, 32]

export const defaultProps = {
  bordered: false,
  headerHeight: heights,
  isTree: false,
  minHeight: 0,
  rowHeight: heights,
  rowKey: null,
  scrollTo: null,
  scrollToPx: null,
}
