import React, { useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme, css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import appConfig from 'config/appConfig'
import { zIndices } from 'config/zIndices'

import { useNotificationsStore } from 'stores/Notifications'
import { useWebsocketQueueUpdates } from 'lib/hooks'

import {
  notification as notificationAnimation,
  notificationReverse as notificationAnimationReverse,
} from '@utilities/Animation'

import { ButtonLinkWithIcon } from '@ui/Buttons'
import { Box, FlexRow } from '@layout/BuildingBlocks'
import { Progress } from '@utilities/Progress'
import { Copy } from '@typography'

import { BudiconFileDownload, BudiconCrossUi } from 'bgag-budicons'

const bgagServicesUrl = appConfig.bgagServicesUrl ?? 'localhost:9500'
const downloadUrl = `${bgagServicesUrl.replace(/\/$/, '')}/queue/jobDownload/`

const containerStyle = {
  position: 'absolute',
  right: 4,
  top: 8,
  pointerEvents: 'none',
  zIndex: zIndices.notifications,
}

const NotificationContainer = styled.div`
  pointer-events: auto;
  min-width: 380px;

  background-color: ${themeGet('colors.base.gray.100', 'lightgray')};
  border-radius: 7px;
  border: 1px solid ${themeGet('colors.base.gray.400', 'lightgray')};
  box-shadow: ${themeGet('shadow.dropdown', '0 5px 10px #00000040')};
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;

  ${(props) => {
    if (props.isClosing) {
      return css`
        opacity: 1;
        max-height: 200px;

        animation-name: ${notificationAnimationReverse};
      `
    } else {
      return css`
        opacity: 0;
        max-height: 0px;

        animation-name: ${notificationAnimation};
      `
    }
  }}
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.1s;
`

const DownloadButton = ({ notification, closeNotificationInit }) => {
  const href = downloadUrl + notification.id

  return (
    <ButtonLinkWithIcon
      size="small"
      stretch={true}
      href={href}
      onClick={() => closeNotificationInit(notification.id)}
      icon={{
        Icon: BudiconFileDownload,
        type: 'shady',
      }}
    >
      Download
    </ButtonLinkWithIcon>
  )
}

const Notification = ({ notification, closeNotificationInit }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [, { closeNotification }] = useNotificationsStore()

  const notificationContainer = useRef(null)

  const onAnimEnd = useCallback(
    (evt) => {
      if (evt.animationName === notificationAnimationReverse.name) {
        closeNotification(notification.id)
      }
    },
    [closeNotification, notification.id]
  )

  useEffect(() => {
    const container = notificationContainer.current
    container.addEventListener('animationend', onAnimEnd)
    return () => {
      container.removeEventListener('animationend', onAnimEnd)
    }
  }, [onAnimEnd])

  const progress = notification.progress > 0 ? notification.progress : null

  return (
    <NotificationContainer isClosing={notification.meta.isClosing} ref={notificationContainer}>
      <FlexRow>
        <Box flex="1 0 auto">
          {typeof notification.message !== 'undefined' && (
            <Box>
              <Copy>{notification.message}</Copy>
            </Box>
          )}
          {typeof notification.queue_name !== 'undefined' && (
            <Box>
              <Copy>{t('export.inProgress.' + notification.queue_name)}</Copy>
            </Box>
          )}
          {typeof notification.progress !== 'undefined' && notification.status !== 'completed' && (
            <Progress value={progress} max="100" mt="0px" mb="10px" />
          )}
          {notification.status === 'failed' && (
            <Box>
              <Copy tag="span" color={theme.colors.base.error.text} mt={2}>
                {t('export.failed')}
              </Copy>
            </Box>
          )}
          {notification.status === 'completed' &&
            notification.flags.downloadAvailable === true &&
            notification.meta.isClosing !== true && (
              <Box mt={1} mb="6px">
                <DownloadButton notification={notification} closeNotificationInit={closeNotificationInit} />
              </Box>
            )}
        </Box>
        {(notification.flags.showClose === true || notification.status === 'failed') && (
          <Box flex="0 0 auto" onClick={() => closeNotificationInit(notification.id)} cursor="pointer">
            <BudiconCrossUi />
          </Box>
        )}
      </FlexRow>
    </NotificationContainer>
  )
}

export const Notifications = () => {
  useWebsocketQueueUpdates()

  const [{ notifications }, { closeNotificationInit }] = useNotificationsStore()

  const closeNotificationWrapper = useCallback(
    (id) => {
      const notification = notifications[id]
      closeNotificationInit(id)
      typeof notification.onClose === 'function' && notification.onClose()
    },
    [notifications, closeNotificationInit]
  )

  const displayNotifications = Object.values(notifications).filter(
    (notification) => !notification.meta.closed
  )

  useEffect(() => {
    // auto download, if notification reciever initiated the queue job
    Object.values(notifications).forEach((notification) => {
      if (
        notification.flags.downloadAvailable &&
        notification.meta.clientIsInitiator &&
        !notification.meta.isClosing &&
        !notification.meta.closed
      ) {
        closeNotificationWrapper(notification.id)
        window.location.href = downloadUrl + notification.id
      } else if (
        notification.queue_name === 'bulk' &&
        (notification.status === 'completed' || notification.status === 'failed') &&
        !notification.meta.closed
      ) {
        closeNotificationWrapper(notification.id)
      }
    })
  }, [notifications, closeNotificationWrapper])

  return (
    <div style={containerStyle}>
      {displayNotifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          closeNotificationInit={closeNotificationWrapper}
        ></Notification>
      ))}
    </div>
  )
}
