import React from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { typography } from 'styled-system'

import { FlexCenter } from '@layout/BuildingBlocks'

import { appearance, shape, size } from './variants'
import { typeMap, typeScale, typeStyle, defaultProps as textProps } from '@typography'

import { bool, string } from 'prop-types'

const Badge = styled(FlexCenter)`
  cursor: pointer;

  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
  ${(props) => props.stretch && { width: '100%' }}
  ${(props) => props.leftAlign && { justifyContent: 'flex-start' }}


  ${(props) => props.disabled && { cursor: 'not-allowed' }}
  ${(props) => props.disabled && { '> div': { pointerEvents: 'none' } }}

  background: ${(props) =>
    props.disabled
      ? themeGet(`uiActionElements.${props.appearance}.disabled.bg`, 'gray')(props)
      : props.checked
      ? themeGet(`uiActionElements.${props.appearance}.checked.bg`, 'gray')(props)
      : themeGet(`uiActionElements.${props.appearance}.default.bg`, 'gray')(props)};

  border-color: ${(props) =>
    props.disabled
      ? themeGet(`uiActionElements.${props.appearance}.disabled.bg`, 'gray')(props)
      : props.checked
      ? themeGet(`uiActionElements.${props.appearance}.checked.bg`, 'gray')(props)
      : themeGet(`uiActionElements.${props.appearance}.default.borderColor`, 'gray')(props)};

  color: ${(props) =>
    props.disabled
      ? themeGet(`uiActionElements.${props.appearance}.disabled.color`, '#000')(props)
      : props.checked
      ? themeGet(`uiActionElements.${props.appearance}.checked.color`, '#000')(props)
      : themeGet(`uiActionElements.${props.appearance}.default.color`, '#000')(props)};

  &:hover {
    background: ${(props) =>
      props.disabled
        ? themeGet(`uiActionElements.${props.appearance}.disabled.bg`, 'gray')(props)
        : props.checked
        ? themeGet(`uiActionElements.${props.appearance}.checked.bg`, 'gray')(props)
        : themeGet(`uiActionElements.${props.appearance}.hover.bg`, 'gray')(props)};
    
    border-color: ${(props) =>
      props.disabled
        ? themeGet(`uiActionElements.${props.appearance}.disabled.bg`, 'gray')(props)
        : props.checked
        ? themeGet(`uiActionElements.${props.appearance}.checked.borderColor`, 'gray')(props)
        : themeGet(`uiActionElements.${props.appearance}.default.borderColor`, 'gray')(props)};

    color: ${(props) =>
      props.disabled
        ? themeGet(`uiActionElements.${props.appearance}.disabled.color`, '#000')(props)
        : props.checked
        ? themeGet(`uiActionElements.${props.appearance}.checked.color`, '#000')(props)
        : themeGet(`uiActionElements.${props.appearance}.hover.color`, '#000')(props)};
`

export const CheckboxText = ({
  checked,
  disabled,
  handleMouseMove,
  handleMouseLeave,
  handleMouseOver,
  id,
  maxwidth,
  name,
  label,
  ...props
}) => {
  const { typeStyle, typeScale } = typeMap[props.size]
  return (
    <Badge
      checked={checked}
      disabled={disabled}
      onMouseMove={handleMouseMove}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      typeScale={typeScale}
      typeStyle={typeStyle}
      {...props}
    >
      {label}
    </Badge>
  )
}

CheckboxText.defaultProps = {
  ...textProps,
  ...{
    appearance: 'lightgray',
    checked: false,
    label: 'label',
    leftAlign: null,
    maxwidth: null,
    name: 'checkbox-text-name',
    shape: 'square',
    size: 'medium',
    stretch: null,
    tag: 'span',
  },
}
CheckboxText.propTypes = {
  appearance: string,
  checked: bool,
  label: string.isRequired,
  leftAlign: bool,
  maxwidth: string,
  name: string,
  shape: string,
  size: string,
  stretch: bool,
}
