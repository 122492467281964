import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const DeveloperIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#FAC390',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="19.5" cy="13.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M6.83221 19.8286H5.95883C5.65713 19.8286 5.41228 20.0731 5.41228 20.3744V24.9085H4.54655C4.24485 24.9085 4 25.153 4 25.4542C4 25.7555 4.24485 26 4.54655 26H27.4535C27.7551 26 28 25.7555 28 25.4542C28 25.153 27.7551 24.9085 27.4535 24.9085H25.7307V18.4773C25.7307 18.2798 25.6236 18.0975 25.4509 18.0015L21.1157 15.5783C20.9495 15.4855 20.7462 15.4855 20.5801 15.5794L16.3017 18.0025C16.1301 18.0997 16.024 18.2809 16.024 18.4773V24.9085H13.5482V20.3744C13.5482 20.0731 13.3033 19.8286 13.0016 19.8286H10.7859V9.5805H20.3035C20.3035 9.5936 20.3024 9.60669 20.3024 9.61979V11.7482L14.8686 14.7444C14.6041 14.8896 14.509 15.2214 14.6544 15.4855C14.7998 15.7497 15.1332 15.8446 15.3966 15.6995L20.849 12.6935L26.3013 15.6995C26.5659 15.8446 26.8982 15.7497 27.0435 15.4855C27.19 15.2214 27.0938 14.8896 26.8293 14.7444L21.3955 11.7482V9.61979C21.3955 9.60669 21.3955 9.5936 21.3944 9.5805H25.6389C25.9198 9.5805 26.0685 9.43969 26.1549 9.29016C26.2193 9.17882 26.2696 9.01619 26.2029 8.80335C26.1669 8.68765 26.0302 8.46171 25.7985 8.20084C25.1656 7.48372 23.7599 6.21539 23.6976 6.15863C23.3281 5.81917 22.9477 5.63144 22.3432 5.63144H10.7859V3.35019C10.7859 2.60469 10.1804 2 9.43378 2H8.18437C7.43778 2 6.83221 2.60469 6.83221 3.35019V5.63144H5.34451C4.6023 5.63144 4 6.23285 4 6.97399V8.23795C4 8.97908 4.6023 9.5805 5.34451 9.5805H6.83221V19.8286ZM19.8422 24.9085H17.1171V18.795L20.8512 16.6807L24.6376 18.7972V24.9085H19.8422ZM12.4551 20.9201V24.9085H6.50537V20.9201H12.4551ZM9.69284 19.8286H7.9253V9.5805H9.69284V19.8286ZM24.5731 8.48899H5.34451C5.20568 8.48899 5.0931 8.37657 5.0931 8.23795V6.97399C5.0931 6.83536 5.20568 6.72294 5.34451 6.72294H22.3432C22.6187 6.72294 22.7914 6.80917 22.9586 6.96307C22.9608 6.96525 22.9619 6.96635 22.9641 6.96744C22.9641 6.96744 23.8998 7.80571 24.5731 8.48899ZM9.69284 3.35019V5.63144H7.9253V3.35019C7.9253 3.2072 8.04117 3.0915 8.18437 3.0915H9.43378C9.57697 3.0915 9.69284 3.2072 9.69284 3.35019Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
