import { useTheme } from 'styled-components'

export const useBreakpointAliases = () => {
  const theme = useTheme()
  const aliases = []

  for (let i in theme.breakpoints) {
    if (Number.isNaN(+i)) {
      aliases.push(i)
    }
  }
  aliases.reverse()
  aliases.push('default')
  aliases.reverse()

  return aliases
}
