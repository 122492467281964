import React, { useRef, useState, useEffect } from 'react'

import { Badge } from '@utilities/Badge'
import { BudiconChevronBottomA } from 'bgag-budicons'

const BadgeComponent = ({ placeholder = null, refBadge, ...props }) => {
  const [text, setText] = useState(placeholder)

  useEffect(() => {
    setText(props.text !== null ? props.text : placeholder)
  }, [props.text, placeholder])

  return (
    <Badge
      appearance={props.appearance}
      iconRight={{ Icon: BudiconChevronBottomA }}
      isActive={props.isActive}
      onBlur={props.onBlur}
      onClick={props.onClick}
      onFocus={props.onFocus}
      ref={refBadge}
      shape={props.shape}
      size={props.size}
      stretch={props.stretch}
      disabled={props.disabled}
      tabIndex="0"
      text={text}
      tag={props.tag}
    />
  )
}

export const withBadge =
  (DropdownSelectComponent) =>
  ({ ...props }) => {
    const refBadge = useRef()

    return (
      <DropdownSelectComponent refBadge={refBadge} {...props}>
        <BadgeComponent />
      </DropdownSelectComponent>
    )
  }
