import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const InformationIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#58C1E8',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="13.5" cy="14.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M26.62 7H6.38C6.01412 7.00039 5.66334 7.14481 5.40462 7.40158C5.14591 7.65835 5.00039 8.0065 5 8.36963V22.979C5.00039 23.3421 5.14591 23.6902 5.40462 23.947C5.66334 24.2038 6.01412 24.3482 6.38 24.3486H18.0301C18.1317 24.349 18.2305 24.3824 18.3113 24.4436L22.843 27.8137C22.9794 27.9166 23.1421 27.9797 23.3127 27.9959C23.4833 28.012 23.6551 27.9805 23.8086 27.905C23.9622 27.8294 24.0914 27.7128 24.1818 27.5683C24.2721 27.4237 24.32 27.257 24.32 27.0869V24.3486H26.62C26.9859 24.3482 27.3367 24.2038 27.5954 23.947C27.8541 23.6902 27.9996 23.3421 28 22.979V8.36963C27.9996 8.0065 27.8541 7.65835 27.5954 7.40158C27.3367 7.14481 26.9859 7.00039 26.62 7V7ZM27.08 22.979C27.0798 23.1 27.0313 23.216 26.9451 23.3016C26.8589 23.3872 26.7419 23.4353 26.62 23.4355H24.044C23.8733 23.4358 23.7096 23.5032 23.5889 23.623C23.4682 23.7428 23.4003 23.9052 23.4 24.0747L23.3973 27.085L18.8656 23.7149C18.6251 23.5341 18.3318 23.436 18.0301 23.4353H6.38C6.25805 23.4352 6.14115 23.387 6.05492 23.3014C5.96869 23.2158 5.92017 23.0998 5.92 22.9788V8.36944C5.92017 8.24841 5.96869 8.13239 6.05492 8.04681C6.14115 7.96122 6.25805 7.91307 6.38 7.9129H26.62C26.7419 7.91307 26.8589 7.96122 26.9451 8.04681C27.0313 8.13239 27.0798 8.24841 27.08 8.36944V22.979ZM16.04 14.3047H16.96V19.7832H16.04V14.3047ZM16.04 11.5654H16.96V13.3916H16.04V11.5654Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
