import { useCallback } from 'react'
import { useModalContext } from '..'

export const useModalSwitchView = () => {
  const {
    state: { activeView },
    dispatch,
  } = useModalContext()

  const toggleView = useCallback(
    (targetView) => {
      if (targetView === activeView) return

      dispatch({
        type: 'setActiveView',
        payload: targetView,
      })
    },
    [dispatch, activeView]
  )

  return {
    toggleView,
  }
}
