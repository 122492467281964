import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { layout } from 'styled-system'

import { customScrollbarNotHiddenSmall } from 'theming/styles/CustomScrollbar'
import { useOverflowObserver } from 'lib/hooks'

import { oneOfType, string, array } from 'prop-types'

export const StyledOptionsList = styled.ul`
  list-style-type: none;
  margin: ${(props) =>
    props.hasOverflow
      ? themeGet('dropdown.spaceX.optionList.hasOverflow', 0)(props)
      : themeGet('dropdown.spaceX.optionList.default', 0)(props)};
  padding: 0;
  ${(props) => props.maxHeight !== null && `overflow-y: auto;`}
  ${customScrollbarNotHiddenSmall} 
  ${layout}
`

export const OptionsList = forwardRef(({ children, maxHeight }, ref) => {
  const [hasOverflow] = useOverflowObserver(ref)

  return (
    <StyledOptionsList hasOverflow={hasOverflow} maxHeight={maxHeight} ref={ref}>
      {children}
    </StyledOptionsList>
  )
})

OptionsList.defaultProps = {
  maxHeight: null,
}

OptionsList.propTypes = {
  maxHeight: oneOfType([string, array]),
}
