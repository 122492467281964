import React from 'react'
import styled from 'styled-components'
import { space, flexbox } from 'styled-system'

import { node, string } from 'prop-types'

const StyledComponent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
StyledComponent.Section = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${flexbox}
  ${space};
`

export const CardBody = ({ children }) => {
  return <StyledComponent className="card-body">{children}</StyledComponent>
}

CardBody.Section = ({ children, pb, px, pt, flex }) => {
  return (
    <StyledComponent.Section pb={pb} px={px} pt={pt} flex={flex}>
      {children}
    </StyledComponent.Section>
  )
}

CardBody.Section.propTypes = {
  children: node,
  pt: string,
  pb: string,
  px: string,
  flex: string,
}

CardBody.Section.defaultProps = {
  children: null,
  pt: null,
  pb: null,
  px: null,
  flex: 'auto',
}
