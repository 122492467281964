import React from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { FlexRow } from '@layout/BuildingBlocks'

const Container = styled(FlexRow)`
  background-color: ${themeGet('card.colors.footer.public.bg', 'lightgray')};
`

export const CardFooter = ({ children, px, pb }) => {
  return (
    <Container px={px} pb={pb} justifyContent="flex-end" width="100%" height="100%">
      {children}
    </Container>
  )
}
