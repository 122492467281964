import { useEffect, useState } from 'react'

export const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false)

  useEffect(() => {
    const downHandler = ({ key, code }) => {
      if (code === targetKey || key === targetKey) {
        setKeyPressed(true)
      }
    }
    const upHandler = ({ key, code }) => {
      if (code === targetKey || key === targetKey) {
        setKeyPressed(false)
      }
    }

    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)

    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [targetKey])

  return keyPressed
}
