import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const RetailIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#82CBC0',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="15.5" cy="16.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M24.0937 4.9524C24.0815 4.69568 23.9709 4.45352 23.7849 4.27618C23.5988 4.09884 23.3517 3.99994 23.0946 4H20.0482C20.0482 2.93913 19.6268 1.92172 18.8767 1.17157C18.1265 0.421427 17.1091 0 16.0482 0C14.9874 0 13.97 0.421427 13.2198 1.17157C12.4697 1.92172 12.0482 2.93913 12.0482 4H8.90493C8.64793 3.99997 8.40079 4.09888 8.21478 4.27621C8.02876 4.45355 7.91817 4.69569 7.90593 4.9524L7.00113 23.9524C6.99469 24.0875 7.01574 24.2226 7.06301 24.3494C7.11028 24.4761 7.18279 24.592 7.27613 24.6899C7.36948 24.7879 7.48173 24.8659 7.60609 24.9191C7.73045 24.9724 7.86433 25 7.99963 25H23.9996C24.135 25 24.2689 24.9726 24.3933 24.9193C24.5177 24.866 24.6301 24.7881 24.7235 24.6901C24.8169 24.5922 24.8894 24.4763 24.9367 24.3495C24.984 24.2227 25.0051 24.0876 24.9986 23.9524L24.0937 4.9524ZM16.0482 1C16.8436 1.0009 17.6061 1.31726 18.1686 1.87967C18.731 2.44209 19.0473 3.20463 19.0482 4H13.0482C13.0491 3.20463 13.3655 2.44209 13.9279 1.87967C14.4903 1.31726 15.2529 1.0009 16.0482 1ZM8.90493 5H12.0482V8.5C12.0482 8.63261 12.1009 8.75979 12.1947 8.85355C12.2884 8.94732 12.4156 9 12.5482 9C12.6808 9 12.808 8.94732 12.9018 8.85355C12.9956 8.75979 13.0482 8.63261 13.0482 8.5V5H19.0482V8.5C19.0482 8.63261 19.1009 8.75979 19.1947 8.85355C19.2884 8.94732 19.4156 9 19.5482 9C19.6808 9 19.808 8.94732 19.9018 8.85355C19.9956 8.75979 20.0482 8.63261 20.0482 8.5V5H23.0831L23.8065 20H8.19063L8.90493 5ZM7.99963 24L8.14253 21H23.8547L23.9996 24H7.99963Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
