import React, { useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { color } from 'styled-system'

import { getThemeValue } from 'theming'

import { FlexColumn, PosRelative, PosAbsolute } from '@layout/BuildingBlocks'
import { Minion, Royal as DigitText } from '@typography'
import { DigitContainer } from '@typography/ContentElements'

import { elementType, number, string, oneOfType } from 'prop-types'

const Label = styled(Minion)`
  margin: 2px 0;
`

const Digit = styled(DigitText)`
  font-size: clamp(1rem, 1.3vw, 2rem);

  @media (max-width: 1440px) {
    font-size: clamp(1.8rem, 2.5vw, 2.8rem);
  }

  @media (max-width: 1280px) {
    font-size: clamp(1.2rem, 2.2vw, 2.8rem);
  }

  @media (max-width: 992px) {
    font-size: 1rem;
  }
`

const DigitContainerTop = styled(DigitContainer)`
  background: ${themeGet('colors.base.brand.primary.default', 'lightgray')};
  border-radius: ${themeGet('radii.large', '3px')} ${themeGet('radii.large', '3px')} 0 0;
  padding: 5px 0;
  ${color}
`

const DigitContainerBottom = styled(DigitContainerTop)`
  background: ${themeGet('colors.base.gray.100', 'lightgray')};
  border: 1px solid ${(props) => props.bgColCompare};
  border-radius: 0 0 ${themeGet('radii.large', '3px')} ${themeGet('radii.large', '3px')};
  padding: 5px 0;
  ${color}
`

export const BadgeDigitsCompareWithIcon = ({
  Icon,
  bgColor,
  bgColorCompare,
  iconColor,
  label,
  textColorDigit,
  textColorDigitCompare,
  textColorMeta,
  value,
  valueCompare,
}) => {
  const theme = useTheme()

  const iconCol = getThemeValue(theme, iconColor)
  const textColMeta = getThemeValue(theme, textColorMeta)
  const textColDigit = getThemeValue(theme, textColorDigit)
  const textColDigitCompare = getThemeValue(theme, textColorDigitCompare)
  const bgCol = getThemeValue(theme, bgColor)
  const bgColCompare = getThemeValue(theme, bgColorCompare)

  const displayValue = useMemo(() => (value && value !== '0%' ? value : '-'), [value])
  const displayValueCompare = useMemo(
    () => (valueCompare && valueCompare !== '0%' ? valueCompare : '-'),
    [valueCompare]
  )

  return (
    <FlexColumn alignItems="center" justifyContent="center" flex="1">
      <PosRelative>
        <FlexColumn alignItems="center" flex="1">
          <PosRelative marginBottom="18px">
            <Icon size="18px" type="solid" color={iconCol} />
          </PosRelative>
          <PosAbsolute bottom="5px">
            <Label tag="span" color={textColMeta}>
              {label}
            </Label>
          </PosAbsolute>
        </FlexColumn>
      </PosRelative>

      <DigitContainerTop alignItems="center" justifyContent="center" backgroundColor={bgCol}>
        <Digit tag="span" color={textColDigit}>
          {displayValue}
        </Digit>
      </DigitContainerTop>

      <DigitContainerBottom
        alignItems="center"
        justifyContent="center"
        backgroundColor={bgColCompare}
        bgColCompare={bgColCompare}
      >
        <Digit tag="span" color={textColDigitCompare}>
          {displayValueCompare}
        </Digit>
      </DigitContainerBottom>
    </FlexColumn>
  )
}

BadgeDigitsCompareWithIcon.propTypes = {
  Icon: elementType,
  bgColor: string,
  bgColorCompare: string,
  label: string,
  iconColor: string,
  textColorDigit: string,
  textColorDigitCompare: string,
  textColorMeta: string,
  value: oneOfType([string, number]),
  valueCompare: oneOfType([string, number]),
}

BadgeDigitsCompareWithIcon.defaultProps = {
  bgColor: 'colors.base.lightgray.270',
  bgColorCompare: 'colors.base.gray.700',
  iconColor: 'colors.base.gray.700',
  label: 'Shops',
  textColorDigit: 'colors.base.gray.700',
  textColorDigitCompare: 'colors.base.lightgray.270',
  textColorMeta: 'colors.base.gray.700',
  value: '100',
  valueCompare: '9999',
}
