export const getModal = ({ radii, themeColors, sizes, spaces, colors }) => ({
  colors: {
    dialog: {
      bg: colors.white,
    },
    body: {
      bg: colors.white,
    },
    subtitle: {
      color: colors.gray[270],
    },
  },
  dialog: {
    space: {
      maxHeight: 'calc(100vh - 120px)',
      maxWidth: 'calc(100vw - 80px)',
      x: '4px',
    },
  },
  body: {
    space: {
      x: '16px',
      bottom: '20px',
    },
  },

  header: {
    space: {
      x: '16px',
      top: '8px',
      bottom: '7px',
    },
  },
  footer: {
    space: {
      x: '16px',
      y: '20px',
    },
  },
  subHeadline: {
    space: {
      bottom: '48x',
    },
  },
  headline: {
    space: {
      top: '16px',
      bottom: '4px',
    },
  },
})
