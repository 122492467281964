import React from 'react'
import styled, { useTheme } from 'styled-components'
import { space, layout } from 'styled-system'

import { FlexContainer, PosRelative } from '@layout/BuildingBlocks'
import { ButtonIcon as ButtonClose } from '@ui/Buttons/ButtonIcon'
import { CopyMedium as Title } from '@typography'
import { BudiconCrossUi } from 'bgag-budicons'

import { getThemeValue } from 'theming'

const StyledComponent = styled(FlexContainer)`
  ${space}
  ${layout}
`

export const ModalHeader = ({ title, onClickClose }) => {
  const theme = useTheme()
  const spaceX = getThemeValue(theme, 'modal.header.space.x')
  const height = getThemeValue(theme, 'modal.header.height')
  const spaceBottom = getThemeValue(theme, 'modal.header.space.bottom')

  return (
    <StyledComponent
      alignItems="center"
      justifyContent="space-between"
      px={spaceX}
      height={height}
      mb={spaceBottom}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        {title ? (
          <Title width="100%" tag="h4" m="0">
            {title}
          </Title>
        ) : (
          <span></span>
        )}

        <PosRelative right={['-14px', null, null, null, '-16px']} top="-1px">
          <ButtonClose
            appearance="bare"
            icon={{ Icon: BudiconCrossUi, type: 'shady', strokeWidth: '1.2' }}
            onClick={onClickClose}
            shape="shapeless"
            size="large"
          />
        </PosRelative>
      </div>
    </StyledComponent>
  )
}

ModalHeader.displayName = 'modalHeader'
