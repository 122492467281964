import api from 'stores/api'

/**
 * Fix up ols schemas of "creationParams" in zones
 *
 * Some formats of "creationParams" changed:
 * - circle: center from [lat, lng] to GeoJSON Point geometry
 * - fzr: center from [lat, lng] to GeoJSON Point geometry
 *
 */
export async function fixOldState(collection) {
  const collectionId = collection.id

  for (const zone of collection.features) {
    const zoneId = zone.id
    const { creationMethod, creationParams } = zone.properties
    const polygon = zone.geometry

    if (creationMethod === 'circle') {
      const { center } = creationParams
      if (Array.isArray(center)) {
        const [lat, lng] = center
        const newCenter = { type: 'Point', coordinates: [lng, lat] }
        creationParams.center = newCenter

        await api.Geotools.updateFeature(collectionId, zoneId, { creationMethod, creationParams, polygon })
      }
    }

    if (creationMethod === 'fzr') {
      const { center } = creationParams
      if (Array.isArray(center)) {
        const [lat, lng] = center
        const newCenter = { type: 'Point', coordinates: [lng, lat] }
        creationParams.center = newCenter

        await api.Geotools.updateFeature(collectionId, zoneId, { creationMethod, creationParams, polygon })
      }
    }
  }
}
