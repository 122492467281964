export const typeMap = {
  nano: {
    typeStyle: 'minion',
    typeScale: 'minion',
  },
  tiny: {
    typeStyle: 'minion',
    typeScale: 'minion',
  },
  small: {
    typeStyle: 'pica',
    typeScale: 'pica',
  },
  medium: {
    typeStyle: 'copy',
    typeScale: 'copy',
  },
  large: {
    typeStyle: 'greatPrimer',
    typeScale: 'greatPrimer',
  },
}
