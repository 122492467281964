export const wohn_lagen = {
  101: '#FF8C85',
  101.5: '#FFD1AD',
  102: '#FFFAC2',
  102.5: '#E6F5FE',
  103: '#B9E6F9',
  103.5: '#94D9F8',
  104: '#4E91C6',
}

export const buero_lagen = {
  10: '#FB3232',
  20: '#FFD0A1',
  30: '#FFCF40',
  40: '#FFEDA7',
  45: '#FFFFFF',
}

export const eh_lagen = {
  10: '#6699CC',
  20: '#FF3333',
  30: '#FF9933',
  40: '#660066',
  50: '#807DBA',
  90: '#FFD0A1',
}

export const logistik_lagen = {
  10: '#6699CC',
}

export const exurbs = {
  1: '#FF6600',
  2: '#E1CC3',
}

export const lightblue_green = {
  j51: '#A6BDDB',
  j52: '#67A9CF',
  j53: '#3690C0',
  j54: '#02818A',
  j55: '#016C59',
  j71: '#D0D1E6',
  j72: '#A6BDDB',
  j73: '#67A9CF',
  j74: '#3690C0',
  j75: '#02818A',
  j76: '#016C59',
  j77: '#014636',
}

export const orange = {
  j00: '#DDDDDD',
  j71: '#FFF7BC',
  j72: '#FEE391',
  j73: '#FEC44F',
  j74: '#FE9929',
  j75: '#EC7014',
  j76: '#CC4C02',
  j77: '#993404',
}

export const yellow_red = {
  j71: '#FFEDA0',
  j72: '#FED976',
  j73: '#FEB24C',
  j74: '#FD8D3C',
  j75: '#FC4E2A',
  j76: '#E31A1C',
  j77: '#BD0026',
}

export const yellow_green = {
  j91: '#ffffe5',
  j92: '#f7fcb9',
  j93: '#d9f0a3',
  j94: '#addd8e',
  j95: '#78c679',
  j96: '#41ab5d',
  j97: '#238443',
  j98: '#006837',
  j99: '#004529',
  j71: '#f7fcb9',
  j72: '#d9f0a3',
  j73: '#addd8e',
  j74: '#78c679',
  j75: '#41ab5d',
  j76: '#238443',
  j77: '#006837',
}

export const lightblue = {
  j51: '#eff3ff',
  j52: '#c6dbef',
  j53: '#6baed6',
  j54: '#2171b5',
  j55: '#084594',
}

export const yellow_brown9 = {
  j91: '#ffffe5',
  j92: '#fff7bc',
  j93: '#fee391',
  j94: '#fec44f',
  j95: '#fe9929',
  j96: '#ec7014',
  j97: '#cc4c02',
  j98: '#993404',
  j99: '#662506',
}

export const gebDom = {
  p00: '#fff7ec',
  p01: '#fee8c8',
  p02: '#fdd49e',
  p03: '#fdbb84',
  p04: '#fc8d59',
  p05: '#ef6548',
  p06: '#d7301f',
  p07: '#b30000',
  p08: '#7f0000',
  p09: '#7f0000',
  p19: '#7f0000',
  p21: '#7f0000',
  p22: '#7f0000',
  p23: '#7f0000',
}

export const school = {
  j51: '#A6BDDB',
  j52: '#67A9CF',
  j53: '#3690C0',
  j54: '#02818A',
  j55: '#016C59',
}

export const gastro = {
  j50: '#FF8C85',
  j51: '#FFD1AD',
  j52: '#FFFAC2',
  j53: '#E6F5FE',
  j54: '#B9E6F9',
  j55: '#94D9F8',
}

export const popDensity = {
  j71: '#f7fcb9',
  j72: '#d9f0a3',
  j73: '#addd8e',
  j74: '#78c679',
  j75: '#41ab5d',
  j76: '#238443',
  j77: '#006837',
}

export const typeOfGoods = {
  0: '#660066',
  0.1: '#660066',
  0.2: '#660066',
  1: '#EF3B2C',
  1.1: '#EF3B2C',
  1.11: '#EF3B2C',
  1.12: '#FF8A33',
  1.13: '#A50F15',
  1.131: '#A50F15',
  1.132: '#A50F15',
  1.133: '#A50F15',
  1.14: '#EF3B2C',
  1.141: '#EF3B2C',
  1.15: '#EF3B2C',
  1.16: '#FB6A4A',
  1.17: '#FB6A4A',
  1.2: '#DF65B0',
  1.21: '#DF65B0',
  1.22: '#DF65B0',
  1.221: '#DF65B0',
  1.222: '#DF65B0',
  1.3: '#E7298A',
  1.31: '#E7298A',
  1.32: '#E7298A',
  1.4: '#49E5C0',
  1.41: '#49E5C0',
  1.42: '#90E9D4',
  2: '#154066',
  2.1: '#154066',
  2.11: '#154066',
  2.111: '#2171B5',
  2.112: '#2171B5',
  2.113: '#2171B5',
  2.114: '#2171B5',
  2.115: '#2171B5',
  2.12: '#4292C6',
  2.2: '#00441B',
  2.21: '#00441B',
  2.211: '#00441B',
  2.212: '#00441B',
  2.22: '#006D2C',
  2.23: '#238B45',
  2.24: '#41AB5D',
  2.25: '#74C476',
  2.3: '#723110',
  2.31: '#723110',
  2.312: '#723110',
  2.32: '#723110',
  2.33: '#934B26',
  2.34: '#934B26',
  2.35: '#B36943',
  2.36: '#B36943',
  2.37: '#F5CB94',
  2.38: '#F5CB94',
  2.4: '#8F8675',
  2.41: '#8F8675',
  2.42: '#8F8675',
  2.43: '#8F8675',
  2.44: '#8F8675',
  2.5: '#807DBA',
  2.51: '#807DBA',
  2.52: '#807DBA',
  2.53: '#807DBA',
  2.54: '#807DBA',
  2.55: '#807DBA',
  2.56: '#9E9AE5',
  2.57: '#AFABFF',
  2.58: '#807DBA',
  2.59: '#49E5C0',
  2.6: '#BFBBFF',
  3: '#FFB580',
  3.1: '#FFB580',
  3.2: '#FFB580',
  3.3: '#FFB580',
  3.4: '#FFB580',
  3.5: '#FFB580',
  3.6: '#FFB580',
  3.7: '#FFB580',
  3.8: '#FFB580',
  3.9: '#FFB580',
  4: '#F6BC1C',
  4.1: '#F6BC1C',
  4.2: '#F6BC1C',
  4.3: '#F6BC1C',
  4.4: '#F6BC1C',
  4.5: '#F6BC1C',
  4.6: '#F6BC1C',
  4.7: '#F6BC1C',
  5: '#35978F',
  5.1: '#35978F',
  5.2: '#35978F',
  5.3: '#35978F',
  5.4: '#35978F',
  5.5: '#35978F',
  5.6: '#35978F',
  5.7: '#35978F',
  6: '#FFFFFF',
  7: '#D5A669',
  7.1: '#D5A669',
  7.2: '#D5A669',
  7.3: '#D5A669',
  8: '#FFFFFF',
  9: '#969696',
  9.1: '#969696',
  9.2: '#969696',
  9.3: '#969696',
  9.4: '#555555',
  9.5: '#555555',
  9.6: '#A50F15',
}
