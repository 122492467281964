import React, { memo } from 'react'

import { FlexContainer, FlexItem } from '@layout/BuildingBlocks'

import { useSlots } from 'lib/hooks/useSlots'
import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'

export const SubHeaderMain = memo(({ children }) => {
  const [left, right] = useSlots(['left', 'right'], children)
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  return (
    <FlexContainer alignItems="center" justifyContent="space-between" width="100%" height="100%">
      {left && (
        <FlexItem
          flexColumnGap={[spaces.rhythm.horizontal.large, null, spaces.rhythm.horizontal.extraLarge]}
          {...left.props}
        >
          {left}
        </FlexItem>
      )}
      {right && (
        <FlexItem
          flexColumnGap={[spaces.rhythm.horizontal.large, null, spaces.rhythm.horizontal.extraLarge]}
          {...right.props}
        >
          {right}
        </FlexItem>
      )}
    </FlexContainer>
  )
})

SubHeaderMain.Left = ({ children }) => children
SubHeaderMain.Left.displayName = 'left'

SubHeaderMain.Right = ({ children }) => children
SubHeaderMain.Right.displayName = 'right'
