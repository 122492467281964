import React from 'react'
import styled, { useTheme } from 'styled-components'
import { layout, space } from 'styled-system'

import { getThemeValue } from 'theming'
import { defaultProps } from './defaultProps'
import { fill, height, stroke, strokeWidth, width } from './defaultPropTypes'

const CheckmarkSizer = styled.span.withConfig({
  shouldForwardProp: (props) => ['children'].includes(props),
})`
  display: block;
  ${layout}
  ${space}
  transform: rotate(-3deg)
`

const CheckmarkSVG = ({ fill, stroke, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
  >
    <path d="M9 22l-10-10.598 2.798-2.859 7.149 7.473 13.144-14.016 2.909 2.806z" />
  </svg>
)

export const Checkmark = ({ fill, height, stroke, strokeWidth, width, ...rest }) => {
  const theme = useTheme()
  const svgFill = getThemeValue(theme, fill)
  const svgStroke = getThemeValue(theme, stroke)

  return (
    <CheckmarkSizer width={width} height={height} {...rest}>
      <CheckmarkSVG fill={svgFill} stroke={svgStroke} strokeWidth={strokeWidth} />
    </CheckmarkSizer>
  )
}

Checkmark.propTypes = {
  fill,
  height,
  stroke,
  strokeWidth,
  width,
}

Checkmark.defaultProps = defaultProps
