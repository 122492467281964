import { variant } from 'styled-system'

import {
  bare,
  dark,
  ghost,
  gray,
  light,
  lightgray,
  l3plusLocationCurrent,
  l3plusLocationCompare,
  primary,
  primaryGhost,
  primaryShallow,
  white,
  error,
  errorGhost,
  errorShallow,
} from 'theming/systemHelper/appearance'

import { oval, shapeless, square } from 'theming/systemHelper/shape'

import {
  tiny,
  tinyOval,
  small,
  smallOval,
  medium,
  mediumOval,
  large,
  largeOval,
} from 'theming/systemHelper/size'

export const appearance = (props) =>
  variant({
    prop: 'appearance',
    variants: {
      bare,
      dark,
      ghost,
      gray,
      light,
      lightgray,
      l3plusLocationCurrent,
      l3plusLocationCompare,
      primary,
      primaryGhost,
      primaryShallow,
      white,
      error,
      errorGhost,
      errorShallow,
      custom: props?.customAppearance,
    },
  })

export const alignment = (props) =>
  variant({
    prop: 'alignment',
    variants: {
      default: {},
      noAlignment: {
        padding: '0',
      },
    },
  })

export const size = (props) => {
  return props.shape === 'oval'
    ? variant({
        prop: 'size',
        variants: {
          tiny: tinyOval,
          small: smallOval,
          medium: mediumOval,
          large: largeOval,
        },
      })
    : variant({
        prop: 'size',
        variants: {
          tiny,
          small,
          medium,
          large,
        },
      })
}

export const shape = (props) =>
  variant({
    prop: 'shape',
    variants: {
      oval,
      shapeless,
      square,
    },
  })
