import { useState, useMemo, useEffect } from 'react'
import { useTheme } from 'styled-components'

export const useMediaQuery = (values) => {
  const theme = useTheme()

  const queries = useMemo(() => {
    const queries = theme.breakpoints.map((breakpoint) => window.matchMedia(`(min-width:${breakpoint})`))
    queries.numMatches = function () {
      return this.filter((q) => q.matches).length
    }
    return queries
  }, [theme])

  const [queriesMatching, setQueriesMatching] = useState(() => queries.numMatches())

  useEffect(() => {
    const queryEventListener = (evt) => {
      setQueriesMatching(queries.numMatches())
    }

    queries.forEach((query) =>
      typeof query.addListener === 'function' && typeof query.addEventListener !== 'function'
        ? query.addListener(queryEventListener)
        : query.addEventListener('change', queryEventListener)
    )

    setQueriesMatching(queries.numMatches())

    return () => {
      queries.forEach((query) =>
        typeof query.removeListener === 'function' && typeof query.removeEventListener !== 'function'
          ? query.removeListener(queryEventListener)
          : query.removeEventListener('change', queryEventListener)
      )
    }
  }, [queries])

  const value = useMemo(() => {
    let index = Array.isArray(values) ? Math.min(queriesMatching, values.length - 1) : -1
    let value = !Array.isArray(values) ? values : values[index]

    while (value === null && index > 0) {
      index--
      value = values[index]
    }
    return value
  }, [values, queriesMatching])

  return value
}
