import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const OfficeIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#6C91AC',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.7" cx="12.5" cy="13.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M26.5 4H4.5C4.10231 4.00042 3.72102 4.15859 3.43981 4.43981C3.15859 4.72102 3.00042 5.10231 3 5.5V22.5C3.00042 22.8977 3.15859 23.279 3.43981 23.5602C3.72102 23.8414 4.10231 23.9996 4.5 24H12.8L12.2 27H10.5C10.3674 27 10.2402 27.0527 10.1464 27.1464C10.0527 27.2402 10 27.3674 10 27.5C10 27.6326 10.0527 27.7598 10.1464 27.8536C10.2402 27.9473 10.3674 28 10.5 28H20.5C20.6326 28 20.7598 27.9473 20.8536 27.8536C20.9473 27.7598 21 27.6326 21 27.5C21 27.3674 20.9473 27.2402 20.8536 27.1464C20.7598 27.0527 20.6326 27 20.5 27H18.8L18.2 24H26.5C26.8977 23.9996 27.279 23.8414 27.5602 23.5602C27.8414 23.279 27.9996 22.8977 28 22.5V5.5C27.9996 5.10231 27.8414 4.72102 27.5602 4.43981C27.279 4.15859 26.8977 4.00042 26.5 4ZM13.22 27L13.82 24H17.18L17.78 27H13.22ZM27 22.5C27 22.6326 26.9473 22.7598 26.8536 22.8536C26.7598 22.9473 26.6326 23 26.5 23H4.5C4.36739 23 4.24021 22.9473 4.14645 22.8536C4.05268 22.7598 4 22.6326 4 22.5V20H27V22.5ZM27 19H4V5.5C4 5.36739 4.05268 5.24021 4.14645 5.14645C4.24021 5.05268 4.36739 5 4.5 5H26.5C26.6326 5 26.7598 5.05268 26.8536 5.14645C26.9473 5.24021 27 5.36739 27 5.5V19Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
