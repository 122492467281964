import React from 'react'

import { useTranslation } from 'react-i18next'

import { CardPublic } from '@layout/CardPublic'
import { LayoutPublic } from '@layout/LayoutPublic'
import { CardLogoL3Plus } from '@layout/CardPublic/SubComponents/CardLogoL3Plus'
import { L3PlusFooter } from '@layout/CardPublic/SubComponents/L3PlusFooter/L3PlusFooter'
import { L3PlusHeaderBanner } from '@layout/LayoutPublic/SubComponents/L3PlusHeaderBanner'

import { ForgotPasswordForm } from './SubComponents/ForgotPasswordForm'

import l3plusBg from 'assets/images/l3plusBackground.png'

import { withDifferentTheme } from '../../../theming/util/withDifferentTheme'

export const ForgotPassword = ({ type }) => {
  const { t } = useTranslation()

  const bgImage = type === 'l3plus' ? l3plusBg : null

  return (
    <LayoutPublic bgImage={bgImage}>
      {type === 'l3plus' && (
        <LayoutPublic.HeaderFullscreen>
          <L3PlusHeaderBanner />
        </LayoutPublic.HeaderFullscreen>
      )}
      <LayoutPublic.Main>
        {type === 'l3plus' && <CardLogoL3Plus />}
        <CardPublic>
          {type !== 'l3plus' && <CardPublic.Logo />}
          {type !== 'l3plus' && <CardPublic.Header>{t('forgotPassword.header')}</CardPublic.Header>}
          <CardPublic.BodyRight>
            <ForgotPasswordForm />
          </CardPublic.BodyRight>
          {type === 'l3plus' && (
            <CardPublic.Footer>
              <L3PlusFooter />
            </CardPublic.Footer>
          )}
        </CardPublic>
      </LayoutPublic.Main>
    </LayoutPublic>
  )
}

export const ForgotPasswordL3Plus = () => withDifferentTheme('L3Plus')(ForgotPassword)({ type: 'l3plus' })
