import { useMemo } from 'react'

export const useCountryByLocation = (location) => {
  const country = useMemo(() => {
    if (location) {
      return location.properties.countryCode
    }
    return null
  }, [location])

  return country
}
