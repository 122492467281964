import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const IndustryIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#FAC390',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="15.5" cy="15.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M26.4375 8.62857L22.0869 5.31429C21.8208 5.11137 21.4903 5.00055 21.1494 5H9.8506C9.51016 5.00058 9.18 5.11115 8.9141 5.31362L4.5625 8.62952C4.38657 8.76302 4.2446 8.93266 4.1472 9.12576C4.04979 9.31886 3.99947 9.53044 4 9.74467V23.5714C3.99962 23.7588 4.03803 23.9444 4.11304 24.1177C4.18804 24.2909 4.29816 24.4484 4.43711 24.5811C4.57606 24.7138 4.74111 24.819 4.92282 24.8909C5.10453 24.9628 5.29934 24.9999 5.4961 25H5.499C9.159 24.9953 21.839 24.981 25.499 24.9926C25.895 24.9888 26.2744 24.8405 26.559 24.5782C26.6995 24.4455 26.8109 24.2875 26.8866 24.1133C26.9623 23.9392 27.0009 23.7525 27 23.564V9.74467C27.0005 9.53031 26.9501 9.31861 26.8528 9.12536C26.7554 8.9321 26.6134 8.76229 26.4375 8.62857V8.62857ZM16 5.95238H21.1494C21.2628 5.9525 21.3728 5.98968 21.4609 6.05771L25.0732 8.80952H16V5.95238ZM9.54 6.05714C9.62796 5.98951 9.73755 5.95254 9.8506 5.95238H15V8.80952H5.9268L9.54 6.05714ZM26 23.564C26.0004 23.6276 25.9876 23.6906 25.9622 23.7495C25.9369 23.8083 25.8996 23.8618 25.8525 23.9069C25.8072 23.951 25.7531 23.9862 25.6935 24.0103C25.6338 24.0345 25.5697 24.0472 25.5049 24.0476H25.5029C21.8379 24.0476 9.1591 24.0467 5.497 24.0513L5.4961 24.0476C5.43111 24.0478 5.36673 24.0358 5.30666 24.0121C5.24659 23.9885 5.19202 23.9538 5.1461 23.91C5.0994 23.8656 5.06243 23.8128 5.03734 23.7547C5.01226 23.6966 4.99957 23.6343 5 23.5714V9.7619H15V13.0952C15 13.2215 15.0527 13.3427 15.1464 13.432C15.2402 13.5213 15.3674 13.5714 15.5 13.5714C15.6326 13.5714 15.7598 13.5213 15.8535 13.432C15.9473 13.3427 16 13.2215 16 13.0952V9.7619H26V23.564Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
