import React from 'react'
import styled from 'styled-components'
import { color } from 'styled-system'

import { RoyalSemiBold as Text } from '@typography'

const StyledComponent = styled(Text)`
  margin: 0;
  line-height: 1.15;
  ${color};
`

export const CardTitleLarge = ({ color, children }) => (
  <StyledComponent color={color} tag="h3" lineHeight="1">
    {children}
  </StyledComponent>
)
