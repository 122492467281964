import React from 'react'

export const ModalTrigger = ({ children, ...props }) => {
  if (props.onClick) {
    return React.cloneElement(children, {
      onClick: (event) => {
        props.onClick.call()
        props.onOpenModal(event, { for: props.for })
      },
    })
  } else {
    return React.cloneElement(children, {
      onClick: (event) => {
        props.onOpenModal(event, { ...props, for: props.for })
      },
    })
  }
}

ModalTrigger.diplayName = 'modalTrigger'
