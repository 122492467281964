import { createDataCache } from 'lib/cache'

import api from 'stores/api'

const conditionalsMaxYears = []

const getterMaxYears = () => {
  return api.Config.dataSourcesYears('district').then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }

    return result.data.data
  })
}

const conditionalsZonelist = []

const getterZonelist = () => {
  return api.L3Plus.zonelist().then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }

    return result.data.data
  })
}

const conditionalsMunicipalityInfo = [{ name: 'gac' }, { name: 'year' }]

const getterMunicipalityInfo = (gac, year) => {
  return api.Data.getData({
    collections: ['l3plus'],
    format: 'tree',
    classifyValues: false,
    failOnAuthz: false,
    time: {
      from: year,
      to: year,
    },
    dataSources: [
      'data_de.st_purchasing_power.purchasing_power_index',
      'data_de.st_purchasing_power.purchasing_power_total',
      'data_de.st_purchasing_power.centrality',
    ],
    gacs: [gac],
  }).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    const info = result.data.data?.[gac]?.[year]
    return {
      centrality: info?.['data_de.st_purchasing_power.centrality'],
      purchasingPowerTotal: info?.['data_de.st_purchasing_power.purchasing_power_total'],
      purchasingPowerIndex: info?.['data_de.st_purchasing_power.purchasing_power_index'],
    }
  })
}

const conditionalsSubmarkets = [{ name: 'city' }, { name: 'zone' }]

const getterSubmarkets = (city, zone) => {
  return api.L3Plus.submarkets({ city, zone }).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }

    return result.data.data
  })
}

const conditionalsRetailUnits = [{ name: 'zoneId' }]

const getterRetailUnits = (zoneId) => {
  return api.L3Plus.retailUnits(zoneId).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }

    return result.data.data
  })
}

export const [getMaxYears, bustMaxYears] = createDataCache(conditionalsMaxYears, getterMaxYears, {
  name: 'L3PlusCacheMaxYears',
})
export const [getZonelist, bustZonelist] = createDataCache(conditionalsZonelist, getterZonelist, {
  name: 'L3PlusCacheZonelist',
})
export const [getMunicipalityInfo, bustMunicipalityInfo] = createDataCache(
  conditionalsMunicipalityInfo,
  getterMunicipalityInfo,
  {
    name: 'L3PlusCacheMunicipalityInfo',
  }
)
export const [getSubmarkets, bustSubmarkets] = createDataCache(conditionalsSubmarkets, getterSubmarkets, {
  name: 'L3PlusCacheSubmarkets',
})
export const [getRetailUnits, bustRetailUnits] = createDataCache(conditionalsRetailUnits, getterRetailUnits, {
  name: 'L3PlusCacheRetailUnits',
})
