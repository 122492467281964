import React, { useCallback } from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'

import { isFunction } from 'lib/util'

import { ButtonIcon } from '@ui/Buttons/ButtonIcon'
import { BudiconChevronBottom } from 'bgag-budicons'

import { useControlsSegmentContext } from '@ui/ControlsSegment'

const ControlsFoldButtonStyledComponent = styled(ButtonIcon)`
  transform: ${(props) => (props.folded ? 'rotate(0)' : 'rotate(180deg)')};
  transition: transform 0.2s ease-out;
`

export const ControlsFoldButton = ({ onClick, folded, ...props }) => {
  return (
    <ControlsFoldButtonStyledComponent
      appearance="bare"
      shape="shapeless"
      size="small"
      folded={folded}
      icon={{ Icon: BudiconChevronBottom, width: '18px', strokeWidth: '0.5' }}
      onClick={onClick}
      {...props}
    />
  )
}

export const ControlsFold = ({ onClick }) => {
  const { toggle, folded } = useControlsSegmentContext()
  const onLocalClick = useCallback(() => {
    toggle((prev) => !prev)
    if (isFunction(onClick)) {
      onClick()
    }
  }, [toggle, onClick])

  return <ControlsFoldButton onClick={onLocalClick} folded={folded} />
}

ControlsFold.defaultProps = {
  onClick: () => {},
}
ControlsFold.propTypes = {
  onClick: func,
}
