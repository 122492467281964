import React from 'react'
import styled, { useTheme } from 'styled-components'

import { PosAbsolute } from '@layout/BuildingBlocks'

import { getThemeValue } from 'theming'

const Container = styled.div`
  position: relative;
`

export const CardHeaderAddOn = ({ children }) => {
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  return (
    <Container>
      <PosAbsolute top={`-${spaces[6]}`} right={`-${spaces[10]}`}>
        {children}
      </PosAbsolute>
    </Container>
  )
}
