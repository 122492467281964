import { array, number, oneOfType, string } from 'prop-types'

const fill = string
const stroke = string
const strokeWidth = oneOfType([string, number])
const innerFill = string
const innerStroke = string
const innerStrokeWidth = oneOfType([string, number])
const width = oneOfType([string, array])
const height = oneOfType([string, array])

export { fill, stroke, strokeWidth, innerFill, innerStroke, innerStrokeWidth, width, height }
