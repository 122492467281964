import React from 'react'

import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { getThemeValue } from 'theming'
import { BudiconCheckUi } from 'bgag-budicons'

import { FlexColumn, FlexRow, FlexItem, PosRelative } from '@layout/BuildingBlocks'

import { Flyout } from '@utilities/Flyout'
import { IconWrapper } from '@utilities/IconWrapper'

import { Pica, PicaMedium } from 'stories/typography'

const PasswordCriteriumMessage = ({ color, showIcon, text }) => {
  return (
    <FlexRow flexColumnGap="5px" lineHeight="1.66">
      {showIcon && (
        <FlexItem flex="0 0 15px">
          <IconWrapper icon={{ Icon: BudiconCheckUi, type: 'outline', fill: color }} size="15px" />
        </FlexItem>
      )}
      <Pica color={color}>{text}</Pica>
    </FlexRow>
  )
}

export const PasswordCriteriumFlyout = ({
  requiredCriteria,
  optionalCriteria,
  refOpener,
  placement,
  optionalRemaining,
  config,
}) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const colors = getThemeValue(theme, 'colors')
  const colorPassed = colors.base.success.default
  const colorError = colors.base.error.default

  return (
    <Flyout minWidth="100%" refOpener={refOpener} placement={placement} renderTriangle={true}>
      <PosRelative>
        <FlexColumn px="20px" py="20px" alignItems="flex-start">
          <PicaMedium style={{ textDecoration: 'underline' }}>
            {t('setPassword.strength.preRequired')}
          </PicaMedium>
          {requiredCriteria &&
            requiredCriteria.map((criterium) => (
              <PasswordCriteriumMessage
                key={criterium.key}
                color={criterium.passed ? colorPassed : colorError}
                showIcon={criterium.passed}
                text={t(
                  'setPassword.strength.' + criterium.key,
                  criterium.key === 'minLength' || criterium.key === 'maxLength'
                    ? { [criterium.key]: config[criterium.key] }
                    : {}
                )}
              />
            ))}
          {optionalRemaining > 0 && (
            <>
              <PicaMedium style={{ textDecoration: 'underline' }}>
                {t('setPassword.strength.preOptional', {
                  optionalRemaining,
                })}
              </PicaMedium>
              {optionalCriteria &&
                optionalCriteria.map((criterium) => (
                  <PasswordCriteriumMessage
                    key={criterium.key}
                    color={criterium.passed ? colorPassed : null}
                    showIcon={criterium.passed}
                    text={t(
                      'setPassword.strength.' + criterium.key,
                      criterium.key === 'minLength' || criterium.key === 'maxLength'
                        ? { [criterium.key]: config[criterium.key] }
                        : {}
                    )}
                  />
                ))}
            </>
          )}
        </FlexColumn>
      </PosRelative>
    </Flyout>
  )
}
