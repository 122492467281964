import React from 'react'
import styled, { useTheme } from 'styled-components'
import { layout, space } from 'styled-system'

import { getThemeValue } from 'theming'
import { defaultProps } from './defaultProps'
import { fill, height, stroke, strokeWidth, width } from './defaultPropTypes'

const LightBulbSizer = styled.span.withConfig({
  shouldForwardProp: (props) => ['children'].includes(props),
})`
  display: block;
  ${layout}
  ${space}
`

const LightBulbSVG = ({ fill, stroke, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
  >
    <path d="M19 6.734c0 4.164-3.75 6.98-3.75 10.266h-1.992c.001-2.079.996-3.826 1.968-5.513.913-1.585 1.774-3.083 1.774-4.753 0-3.108-2.518-4.734-5.004-4.734-2.482 0-4.996 1.626-4.996 4.734 0 1.67.861 3.168 1.774 4.753.972 1.687 1.966 3.434 1.967 5.513h-1.991c0-3.286-3.75-6.103-3.75-10.266 0-4.343 3.498-6.734 6.996-6.734 3.502 0 7.004 2.394 7.004 6.734zm-4 11.766c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5zm0 2c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5zm-1.701 3.159c-.19.216-.465.341-.752.341h-1.094c-.287 0-.562-.125-.752-.341l-1.451-1.659h5.5l-1.451 1.659zm-3.629-16.347l-1.188-.153c.259-1.995 1.5-3.473 3.518-3.847l.219 1.177c-1.947.361-2.433 1.924-2.549 2.823z" />
  </svg>
)

export const LightBulb = ({ fill, height, stroke, strokeWidth, width, ...rest }) => {
  const theme = useTheme()
  const svgFill = getThemeValue(theme, fill)
  const svgStroke = getThemeValue(theme, stroke)

  return (
    <LightBulbSizer width={width} height={height} {...rest}>
      <LightBulbSVG fill={svgFill} stroke={svgStroke} strokeWidth={strokeWidth} />
    </LightBulbSizer>
  )
}

LightBulb.propTypes = {
  fill,
  height,
  stroke,
  strokeWidth,
  width,
}

LightBulb.defaultProps = defaultProps
