import { useCallback, useEffect, useState } from 'react'

const simulateMaxContent = '100000px'

export const useMaxHeightOnTransition = ({ refTarget, folded }) => {
  const [maxHeight, setMaxHeight] = useState(window.visualViewport.height)

  useEffect(() => {
    let timeout
    const onResize = () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setMaxHeight(window.visualViewport.height)
      }, 100)
    }

    window.addEventListener('resize', onResize)

    return () => {
      clearTimeout(timeout)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const handleTransitionStart = useCallback(
    (evt) => {
      if (evt.target === refTarget.current && evt.propertyName === 'opacity' && folded) {
        refTarget.current.style.maxHeight = ''
      }
    },
    [refTarget, folded]
  )

  const handleTransitionEnd = useCallback(
    (evt) => {
      if (evt.target === refTarget.current && evt.propertyName === 'opacity' && !folded) {
        refTarget.current.style.maxHeight = simulateMaxContent
      }
    },
    [refTarget, folded]
  )

  useEffect(() => {
    if (!folded) {
      const timeout = setTimeout(() => {
        if (refTarget.current && refTarget.current.style?.maxHeight !== simulateMaxContent) {
          refTarget.current.style.maxHeight = simulateMaxContent
        }
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [refTarget, folded])

  useEffect(() => {
    const domElement = refTarget.current
    if (domElement) {
      domElement.addEventListener('transitionstart', handleTransitionStart)
      domElement.addEventListener('transitionend', handleTransitionEnd)
      return () => {
        domElement.removeEventListener('transitionstart', handleTransitionStart)
        domElement.removeEventListener('transitionend', handleTransitionEnd)
      }
    }
  }, [handleTransitionStart, handleTransitionEnd, refTarget])

  return maxHeight
}
