import { useEffect, useState, useMemo } from 'react'

export const useOverflowObserver = (element) => {
  const [isOverflow, setIsOverflow] = useState({ height: false, width: false })

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const target = entries[0].target
        setIsOverflow(({ height: prevHeight, width: prevWidth }) => {
          const nextHeight = target.scrollHeight > target.clientHeight
          const nextWidth = target.scrollWidth > target.clientWidth
          // temporarily unobserve to prevent error loop limit exceeded
          //  - solution from https://blog.elantha.com/resizeobserver-loop-limit-exceeded/
          //  - happened on storybook forms datepicker e.g. when selecting a month due
          //    to optionlist changes size if isOverflow changes
          if ((prevHeight !== nextHeight || prevWidth !== nextWidth) && element?.current) {
            observer.unobserve(element.current)
            window.requestAnimationFrame(() => {
              if (element?.current) {
                observer.observe(element.current)
              }
            })
          }
          return { height: nextHeight, width: nextWidth }
        })
      }),
    [element]
  )

  useEffect(() => {
    if (element?.current) {
      observer.observe(element.current)
      return () => {
        observer.disconnect()
      }
    }
  }, [observer, element])

  return [isOverflow.height, isOverflow.width]
}
