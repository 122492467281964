import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const RetailMarketsIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#82CBC0',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="17.5" cy="17.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M14.9565 11.7779V21.0958C14.9565 21.2404 15.0138 21.379 15.1157 21.4812C15.2176 21.5833 15.3559 21.6408 15.5 21.6408C15.6441 21.6408 15.7824 21.5833 15.8843 21.4812C15.9862 21.379 16.0435 21.2404 16.0435 21.0958V11.7779C17.2859 11.6387 18.4279 11.0277 19.2351 10.0706C20.0423 9.11345 20.4533 7.88276 20.3837 6.63128C20.3142 5.3798 19.7693 4.20252 18.8611 3.34122C17.9528 2.47993 16.7501 2 15.5 2C14.2499 2 13.0472 2.47993 12.1389 3.34122C11.2307 4.20252 10.6858 5.3798 10.6163 6.63128C10.5467 7.88276 10.9577 9.11345 11.7649 10.0706C12.5721 11.0277 13.7141 11.6387 14.9565 11.7779V11.7779ZM15.5 3.11394C16.2524 3.11394 16.988 3.33764 17.6136 3.75677C18.2392 4.17589 18.7268 4.77161 19.0148 5.46859C19.3027 6.16557 19.378 6.93251 19.2312 7.67242C19.0845 8.41233 18.7221 9.09198 18.1901 9.62543C17.658 10.1589 16.9802 10.5222 16.2422 10.6693C15.5042 10.8165 14.7393 10.741 14.0441 10.4523C13.349 10.1636 12.7548 9.67468 12.3368 9.04742C11.9188 8.42015 11.6957 7.68269 11.6957 6.92828C11.6968 5.917 12.0979 4.94746 12.8112 4.23237C13.5244 3.51729 14.4914 3.11506 15.5 3.11394V3.11394ZM28 21.6408C28 24.4722 21.56 26 15.5 26C9.44 26 3 24.4722 3 21.6408C3 19.1466 7.95076 17.8142 12.1903 17.431C12.2614 17.4246 12.3331 17.4322 12.4012 17.4536C12.4694 17.4749 12.5326 17.5095 12.5875 17.5553C12.6423 17.6012 12.6875 17.6574 12.7207 17.7208C12.7538 17.7842 12.7742 17.8535 12.7806 17.9248C12.787 17.996 12.7794 18.0679 12.7581 18.1362C12.7368 18.2045 12.7024 18.268 12.6566 18.3229C12.6109 18.3779 12.5548 18.4233 12.4916 18.4565C12.4284 18.4897 12.3592 18.5101 12.2882 18.5166C7.15446 18.9806 4.08696 20.4695 4.08696 21.6408C4.08696 23.0062 8.42837 24.9102 15.5 24.9102C22.5716 24.9102 26.913 23.0062 26.913 21.6408C26.913 20.4695 23.8453 18.9806 18.7121 18.5166C18.5685 18.5036 18.4359 18.4339 18.3436 18.3229C18.2512 18.2119 18.2066 18.0687 18.2196 17.9248C18.2326 17.7808 18.3021 17.6479 18.4128 17.5553C18.5235 17.4627 18.6663 17.418 18.8099 17.431C23.049 17.8142 28 19.1466 28 21.6408Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
