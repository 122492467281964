import React from 'react'
import styled from 'styled-components'
import { system, grid, layout, space, flexbox, background, compose, border, color } from 'styled-system'

import { propsCheck } from './defaultPropTypes'
import { defaultProps } from './defaultProps'

const StyledGridItem = styled.div.withConfig({
  shouldForwardProp: (prop) => ['children'].includes(prop),
})`
  ${compose(background, border, color, flexbox, grid, layout, space)};
  ${system({
    gridColumn: {
      property: 'gridColumn',
      scale: 'gridColumns',
    },
    gridRow: {
      property: 'gridRow',
      scale: 'gridRows',
    },
  })}
`

export const GridItem = ({ children, ...props }) => {
  return <StyledGridItem {...props}>{children}</StyledGridItem>
}

GridItem.propTypes = {
  gridArea: propsCheck,
  gridColumn: propsCheck,
  gridRow: propsCheck,
}
GridItem.defaultProps = defaultProps
