/*
  basefontsize for calculating responive sizes from https://type-scale.com/ 1.125 Major Second Scale
  default >=  14px
  md      >=  14px
  lg      >=  15px
  xl      >=  16px
  xxl     >=  18px
*/

const osFontsSansSerif =
  '-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'

// font families
const fonts = {
  whitneyBook: [`Whitney A, Whitney B`],
  whitneyBookSSm: [`Whitney SSm A, Whitney SSm B, ${osFontsSansSerif}`],
}

// fontSizes
const minion = ['.614rem', '.614rem', '.658rem', '.703rem', '.79rem']
const pica = ['.691rem', '.691rem', '.741rem', '.79rem', '.829rem'] // value for xxl adjusted
const copy = ['.778rem', '.778rem', '.833rem', '.833rem', '.9rem'] // value for xxl adjusted
const greatPrimer = ['.984rem', '.984rem', '1.055rem', '1.125rem', '1.266rem']
const canon = ['1.108rem', '1.108rem', '1.186rem', '1.266rem', '1.424rem']
const royal = ['1.577rem', '1.577rem', '1.689rem', '1.802rem', '2.027rem']
const imperial = ['2.526rem', '2.526rem', '2.706rem', '2.886rem', '3.248rem']

export { fonts, minion, pica, copy, greatPrimer, canon, royal, imperial }
