import { variant } from 'styled-system'

export const appearance = (props) =>
  variant({
    prop: 'appearance',
    variants: {
      default: {
        '& .value': { fontSize: '14.22px', fontWeight: '500', lineHeight: '16.66px' },
      },
      focused: {
        '& .value': { fontSize: '28px', fontWeight: '500', lineHeight: '32.81px' },
      },
    },
  })
