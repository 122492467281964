import React from 'react'

import { BudiconChevronLeft, BudiconChevronRight } from 'bgag-budicons'

import { FlexContainer, Box } from '@layout/BuildingBlocks'

import { ButtonIcon } from '@ui/Buttons'

import { DropdownSelect } from '@forms/DropdownSelectComponents'

const months = []
const tmpDate = new Date()
tmpDate.setDate(1)
for (let i = 0; i < 12; i++) {
  tmpDate.setMonth(i)
  months.push({ value: i, label: tmpDate.toLocaleString(undefined, { month: 'long' }) })
}

const years = []
for (let i = tmpDate.getFullYear() - 50; i < tmpDate.getFullYear() + 50; i++) {
  years.push({ value: i, label: i.toString() })
}

export const CalendarHead = ({ onFocus, onBlur, onPreviousMonth, onNextMonth, forElementName }) => {
  return (
    <FlexContainer flexColumnGap="3px">
      <Box flex="0 0 auto">
        <ButtonIcon
          shape="square"
          icon={{
            Icon: BudiconChevronLeft,
          }}
          onClick={onPreviousMonth}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Box>
      <Box flex="1 0 auto">
        <DropdownSelect
          forElementName={forElementName}
          onFocus={onFocus}
          onBlur={onBlur}
          name="displayMonth"
          appearance="lightgray"
          items={months}
          sizeVariant="small"
          optionsListMaxHeight="240px"
        />
      </Box>
      <Box flex="0 0 30%">
        <DropdownSelect
          forElementName={forElementName}
          onFocus={onFocus}
          onBlur={onBlur}
          name="displayYear"
          appearance="lightgray"
          items={years}
          sizeVariant="small"
          optionsListMaxHeight="240px"
        />
      </Box>
      <Box flex="0 0 auto">
        <ButtonIcon
          shape="square"
          icon={{
            Icon: BudiconChevronRight,
          }}
          onClick={onNextMonth}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Box>
    </FlexContainer>
  )
}
