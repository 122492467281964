export const sizes = {
  icons: {
    default: {
      strokeWidth: {
        tiny: {},
        small: {},
        medium: {},
        large: {},
      },

      tiny: {
        width: ['20px', '25px', '30px'],
        height: ['20px', '25px', '30px'],
      },
      small: {
        width: ['28px', '33px', '38px'],
        height: ['28px', '33px', '38px'],
      },
      medium: {
        width: ['36px', '41px', '46px'],
        height: ['36px', '41px', '46px'],
      },
      large: {
        width: ['41px', '46px', '51px'],
        height: ['41px', '46px', '51px'],
      },
    },
    button: {
      space: {
        x: ['3px', '3px', '5px', '5px'],
      },

      tiny: {
        width: ['10px', null, '13px'],
        height: ['10px', null, '13px'],
      },
      small: {
        width: ['12px', null, '14px'],
        height: ['12px', null, '14px'],
      },
      medium: {
        width: ['14px', null, '16px'],
        height: ['14px', null, '16px'],
      },
      large: {
        width: ['18px', null, '20px'],
        height: ['18px', null, '20px'],
      },
    },
    switches: {
      tiny: {
        width: ['18px', null, '20px'],
        height: ['18px', null, '20px'],
      },
      small: {
        width: ['20px', null, '22px'],
        height: ['20px', null, '22px'],
      },
      medium: {
        width: ['22px', null, '24px'],
        height: ['22px', null, '24px'],
      },
      large: {
        width: ['24px', null, '28px'],
        height: ['24px', null, '28px'],
      },
    },
    navigation: {
      tiny: {
        width: ['24px', null, '26px'],
        height: ['24px', null, '26px'],
      },
      small: {
        width: ['26px', null, '28px'],
        height: ['26px', null, '28px'],
      },
      medium: {
        width: ['32px'],
        height: ['32px'],
      },
      large: {
        width: ['32px', null, '40px'],
        height: ['32px', null, '40px'],
      },
    },
  },

  scrollbar: {
    default: {
      thumb: {
        width: '8px',
        radius: '6px',
      },
      track: {
        width: '8px',
        radius: '6px',
      },
    },
    small: {
      thumb: {
        width: '6px',
        radius: '4px',
      },
      track: {
        width: '6px',
        radius: '4px',
      },
    },
  },
}
