export const getTaglist = ({ colors, spaces }) => ({
  flexRowGap: spaces.rhythm.horizontal.medium,
  searchbox: {
    bg: colors.lightgray[270],
    padding: '8px 13px',
  },
  selectedTagsBox: {
    height: ['104px', null, null, '136px'],
    rowGap: spaces.rhythm.horizontal.tiny,
    columnGap: spaces.rhythm.horizontal.tiny,
  },
  suggestionsBox: {
    height: '220px',
    padding: '12px 0 0 0',
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '8px',
    borderColor: colors.grayScale[300],
    overflow: 'hidden',
  },
})
