import { useRef, useEffect, useCallback } from 'react'

/** Exposes methods to register a Promise and cancel all registered promises
 * Will cancel all registered promises on teardown
 */
export const usePromiseHandle = () => {
  const promises = useRef([])

  // Removes a single promise from reflist.
  const unregister = useCallback((promise) => {
    promises.current = promises.current.filter((p) => p !== promise)
  }, [])

  // Cancels a single promise and removes it from reflist.
  const cancel = useCallback(
    (promise) => {
      promise.cancel()
      unregister(promise)
    },
    [unregister]
  )

  // Registers a cancelable promise and returns the original promise.
  const register = useCallback(
    (promise) => {
      const p = makeCancelable(promise)
      promises.current.push(p)
      // Unregister on resolve. Leave error handling to caller.
      p.promise.then(() => unregister(p)).catch(() => {})
      return p.promise
    },
    [unregister]
  )

  // Cancels all registered promises.
  const cancelAll = useCallback(() => {
    promises.current.forEach(cancel)
  }, [cancel])

  // Cleanup all promises on teardown.
  useEffect(() => {
    return () => {
      cancelAll()
    }
  }, [cancelAll])
  return [register, cancelAll]
}

class PromiseCanceled extends Error {
  constructor(message = '', ...args) {
    super(message, ...args)
    this.message = `A promise was canceled. Handle a canceled Promise in a catch block or ignore it by using ".catch(ignoreCanceled)". ${
      message ? `Message: ${message}` : ''
    }`
  }
}
/** Wraps a promise in an object that provides a cancel method.
 * From https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 */
const makeCancelable = (promise) => {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject(new PromiseCanceled()) : resolve(val)),
      (error) => (hasCanceled_ ? reject(new PromiseCanceled()) : reject(error))
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    },
  }
}

export const ignoreCanceled = (err) => {
  if (err instanceof PromiseCanceled) return
  throw err
}

/** Simplifies a common Use-Case of auto registering/canceling promises */
export const useAutoCancel = (promise) => {
  const [register, cancelAll] = usePromiseHandle()
  const wrappedPromise = useCallback(
    async (...args) => {
      cancelAll()
      return register(promise(...args))
    },
    [promise, register, cancelAll]
  )

  return wrappedPromise
}
