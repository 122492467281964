import createStore from 'lib/flux-store'

import { getTexts } from './Cache'

const initialState = {
  isLoading: false,
  isFailure: false,
  messages: null,
  texts: [],
}

const actions = {
  fetchTexts: (gacs, textTags, language) => (dispatch) => {
    dispatch({ type: 'setLoading', payload: true })
    getTexts({ gacs, textTags }, language)
      .then((texts) => {
        dispatch({
          type: 'setTexts',
          payload: {
            texts,
          },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
}
const reducer = {
  setLoading: (state, { payload }) => ({ ...state, isLoading: payload, isFailure: false }),
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setTexts: (state, { payload }) => {
    const newTexts = [...state.texts]

    Object.entries(payload.texts).forEach(([gac, tags]) => {
      const index = newTexts.findIndex((entry) => entry.gac === gac)
      if (index < 0) {
        newTexts.push({ gac, tags })
      } else {
        newTexts[index].tags = { ...state.texts[index].tags, ...tags }
      }
    })

    return {
      ...state,
      isLoading: false,
      texts: newTexts,
    }
  },
}

export const [MarketTextDataContext, MarketTextDataProvider, useMarketTextDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'MarketTextDataStore'
)
