import React from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

import { useSlots } from 'lib/hooks/useSlots'

import { BadgeWithSlotsLeftAndRight } from '@utilities/BadgeWithSlotsLeftAndRight'
import { LocationPin } from '@utilities/LocationPin'

const BadgeLabelComponent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  user-select: none;
  ${space}
`

const BadgeLabel = ({ children }) => {
  return (
    <BadgeLabelComponent ml={['10px', null, '8px']} mr={['8px', null, '4px']}>
      {children}
    </BadgeLabelComponent>
  )
}

export const LocationBadge = ({
  locationId = 0,
  locationName,
  checked = true,
  appearance,
  onClick = null,
  children,
  ...props
}) => {
  const [slotRight] = useSlots(['slotRight'], children)
  return (
    <BadgeWithSlotsLeftAndRight
      appearance={appearance}
      size="small"
      alignSelf="stretch"
      alignItems="center"
      onClick={onClick}
      {...props}
    >
      <BadgeWithSlotsLeftAndRight.SlotLeft>
        <LocationPin
          size="small"
          iconWidth={['19px', null, '20px', '21px', '22px']}
          iconPosition={{
            marginTop: ['-2px'],
          }}
          locationId={locationId}
          checked={checked}
        />
      </BadgeWithSlotsLeftAndRight.SlotLeft>
      <BadgeWithSlotsLeftAndRight.Label>
        <BadgeLabel>{locationName}</BadgeLabel>
      </BadgeWithSlotsLeftAndRight.Label>
      {slotRight && <BadgeWithSlotsLeftAndRight.SlotRight>{slotRight}</BadgeWithSlotsLeftAndRight.SlotRight>}
    </BadgeWithSlotsLeftAndRight>
  )
}

LocationBadge.SlotRight = ({ children }) => children
LocationBadge.SlotRight.displayName = 'slotRight'
