import numbersFormats from 'config/numbersFormats'
import { getShortSurfaceFormat, getSurfaceFormat } from 'lib/util/NumbersUtils'

export const getQuarterByMonth = (month) => {
  return Math.ceil(month / 3)
}

export const getQuarter = (date) => {
  date = new Date(date)
  return getQuarterByMonth(date.getMonth() + 1)
}

export const getQuarterDisplay = (date) => {
  date = new Date(date)
  return 'Q' + getQuarterByMonth(date.getMonth() + 1) + '/' + date.getFullYear()
}

export const GetFormattedValue = ({
  value,
  format = '',
  fraction = null,
  allowMio = null,
  isNullable = false,
}) => {
  if (!value) return { val: isNullable ? null : '-' }
  switch (format) {
    case 'curr': {
      let amount = Number(value)
      let suffix = '€'
      if (Math.round(amount / 1000000) < 1 || !allowMio) {
        amount = numbersFormats.roundedFraction1Chart.format(Math.round(amount / 1000))
        suffix = 'Tsd. ' + suffix
      } else {
        amount = numbersFormats.roundedFraction1Chart.format(Math.round(amount / 1000000))
        suffix = 'Mio. ' + suffix
      }
      return { val: amount, suffix }
    }
    case 'space': {
      const { surface, compact } = getShortSurfaceFormat({ surface: value, fraction, allowMio })
      return { val: surface, suffix: `${compact} m²` }
    }
    case 'space-m2': {
      const { surface } = getSurfaceFormat({ surface: value, fraction })
      return { val: surface, suffix: ` m²` }
    }
    case 'date': {
      return { val: getQuarterDisplay(value) }
    }
    case 'number':
      return { val: numbersFormats.number.format(value) }
    case 'evaluation':
      if (value && !isNaN(value)) {
        return { val: Number(value) }
      }
      if (value && isNaN(value)) {
        const regEx = new RegExp(/\*{1,5}/)
        return { val: regEx.test(value) ? value.trim().length : 0 }
      }
      return 0
    case 'link':
      return { val: value, href: value.startsWith('http') ? value : '//' + value }
    default:
      value = String(value).replace('\u001d', '').trim()
      return { val: value }
  }
}
