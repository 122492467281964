import { ancestors } from 'lib/util'
import { useEffect } from 'react'

const isScrollable = (node) => node.scrollHeight > node.clientHeight

export const useScrollListener = (node, callback) => {
  useEffect(() => {
    if (node === null || node === '_noScroll') return
    const scrollContainers = Array.from(ancestors(node)).filter(isScrollable)
    for (const container of scrollContainers) {
      container.addEventListener('scroll', callback)
      container.addEventListener('wheel', callback, { passive: true })
    }
    return () => {
      for (const container of scrollContainers) {
        container.removeEventListener('scroll', callback)
        container.removeEventListener('wheel', callback, { passive: true })
      }
    }
  }, [node, callback])
}
