import { variant } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const size = (props) => {
  const iconVariantSourceName = props.iconVariantSourceName || 'icons'
  const iconVariantSources = {
    buttonIcons: {
      tiny: {
        width: themeGet('sizes.icons.button.tiny.width', null)(props),
        height: themeGet('sizes.icons.button.tiny.height', null)(props),
      },
      small: {
        width: themeGet('sizes.icons.button.small.width', null)(props),
        height: themeGet('sizes.icons.button.small.height', null)(props),
      },
      medium: {
        width: themeGet('sizes.icons.button.medium.width', null)(props),
        height: themeGet('sizes.icons.button.medium.height', null)(props),
      },
      large: {
        width: themeGet('sizes.icons.button.large.width', null)(props),
        height: themeGet('sizes.icons.button.large.height', null)(props),
      },
    },
    badgeIcons: {
      tiny: {
        width: themeGet('sizes.icons.button.tiny.width', null)(props),
        height: themeGet('sizes.icons.button.tiny.height', null)(props),
      },
      small: {
        width: themeGet('sizes.icons.button.small.width', null)(props),
        height: themeGet('sizes.icons.button.small.height', null)(props),
      },
      medium: {
        width: themeGet('sizes.icons.button.medium.width', null)(props),
        height: themeGet('sizes.icons.button.medium.height', null)(props),
      },
      large: {
        width: themeGet('sizes.icons.button.large.width', null)(props),
        height: themeGet('sizes.icons.button.large.height', null)(props),
      },
    },
    switchIcons: {
      tiny: {
        width: themeGet('sizes.icons.switches.tiny.width', null)(props),
        height: themeGet('sizes.icons.switches.tiny.height', null)(props),
      },
      small: {
        width: themeGet('sizes.icons.switches.small.width', null)(props),
        height: themeGet('sizes.icons.switches.small.height', null)(props),
      },
      medium: {
        width: themeGet('sizes.icons.switches.medium.width', null)(props),
        height: themeGet('sizes.icons.switches.medium.height', null)(props),
      },
      large: {
        width: themeGet('sizes.icons.switches.large.width', null)(props),
        height: themeGet('sizes.icons.switches.large.height', null)(props),
      },
    },
    icons: {
      tiny: {
        width: themeGet('sizes.icons.default.tiny.width', null)(props),
        height: themeGet('sizes.icons.default.tiny.height', null)(props),
      },
      small: {
        width: themeGet('sizes.icons.default.small.width', null)(props),
        height: themeGet('sizes.icons.default.small.height', null)(props),
      },
      medium: {
        width: themeGet('sizes.icons.default.medium.width', null)(props),
        height: themeGet('sizes.icons.default.medium.height', null)(props),
      },
      large: {
        width: themeGet('sizes.icons.default.large.width', null)(props),
        height: themeGet('sizes.icons.default.large.height', null)(props),
      },
    },
    navigationIcons: {
      tiny: {
        width: themeGet('sizes.icons.navigation.tiny.width', null)(props),
        height: themeGet('sizes.icons.navigation.tiny.height', null)(props),
      },
      small: {
        width: themeGet('sizes.icons.navigation.small.width', null)(props),
        height: themeGet('sizes.icons.navigation.small.height', null)(props),
      },
      medium: {
        width: themeGet('sizes.icons.navigation.medium.width', null)(props),
        height: themeGet('sizes.icons.navigation.medium.height', null)(props),
      },
      large: {
        width: themeGet('sizes.icons.navigation.large.width', null)(props),
        height: themeGet('sizes.icons.navigation.large.height', null)(props),
      },
    },
  }

  return variant({
    prop: 'size',
    variants: iconVariantSources[iconVariantSourceName],
  })
}
