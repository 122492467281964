import { useControllableState } from './useControllableState'
import { isUndefined } from 'lib/util'

/**
 * Provides a toggle function state that is handled as a list
 *
 * Use this hook to simple include or exclude values from a list of values.
 */
export const useListState = (defaultValue, value, onChange, equals = defaultEquality) => {
  const [localValue, setValue] = useControllableState(defaultValue, value, onChange)

  const toggle = (value) => {
    setValue((prevList) => {
      const isValueInPrev = !isUndefined(prevList.find((elem) => equals(elem, value)))
      if (isValueInPrev) {
        return prevList.filter((elem) => !equals(elem, value))
      } else {
        return [...prevList, value]
      }
    })
  }

  return [localValue, toggle]
}

/**
 * Like useListState but puts elements in front of the list
 *
 * Removes items after maxSize
 */
export const usePriorityListState = (defaultValue, value, onChange, maxSize, equals = defaultEquality) => {
  const [localValue, setValue] = useControllableState(defaultValue, value, onChange)

  const priorize = (value) => {
    setValue((prevList) => {
      const listWithoutNewValue = prevList.filter((elem) => !equals(elem, value))
      return [value, ...listWithoutNewValue].slice(0, maxSize)
    })
  }

  return [localValue, priorize]
}

const defaultEquality = (a, b) => a === b
