import React, { useCallback } from 'react'
import styled from 'styled-components'
import { variant } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { FlexContainer } from '@layout/BuildingBlocks'

import { Button } from '@ui/Buttons'

const Container = styled(FlexContainer)`
  &:focus,
  &:focus-visible {
    border: none;
    outline: none;
  }
`
const Day = styled(Button)`
  flex: 0 0 14.28%;
  display: block;
  text-align: right;

  opacity: ${(props) =>
    props.isDisplayMonth ? '1' : themeGet('datepicker.day.otherMonth.opacity', '0.5')(props)};

  ${(props) =>
    variant({
      prop: 'typeVariant',
      variants: {
        default: {
          color: `${themeGet(`datepicker.day.default.color`, 'gray')(props)}`,
          bg: `${themeGet(`datepicker.day.default.bg`, 'gray')(props)}`,
        },
        today: {
          color: `${themeGet(`datepicker.day.today.color`, 'gray')(props)}`,
          bg: `${themeGet(`datepicker.day.today.bg`, 'gray')(props)}`,
        },
        weekend: {
          color: `${themeGet(`datepicker.day.weekend.color`, 'gray')(props)}`,
          bg: `${themeGet(`datepicker.day.weekend.bg`, 'gray')(props)}`,
        },
        selected: {
          color: `${themeGet('datepicker.day.selected.color', 'gray')(props)}`,
          bg: `${themeGet('datepicker.day.selected.bg', 'gray')(props)}`,
          fontWeight: `${themeGet('datepicker.day.selected.fontWeight', '600')(props)}`,
        },
      },
    })}

  ${(props) =>
    props.hasCursor &&
    `color: ${themeGet(`datepicker.day.${props.isToday ? 'today' : 'cursor'}.color`, 'gray')(props)}; 
     background: ${themeGet('datepicker.day.cursor.bg', 'gray')(props)};`}

  &:hover {
    color: ${(props) => themeGet(`datepicker.day.${props.isToday ? 'today' : 'hover'}.color`, 'gray')(props)};
    background: ${(props) => themeGet('datepicker.day.hover.bg', 'gray')(props)};
  }
`

export const Calendar = ({ options = [], onClickDate = () => {}, cursorIndex = null }) => {
  const today = new Date()
  const todayKey = today.toDateString()

  const setDate = useCallback(
    (evt) => {
      onClickDate(new Date(evt.target.value))
    },
    [onClickDate]
  )

  return (
    <Container
      width={['352px', null, '360px', '390px', '420px']}
      height="auto"
      flexWrap="wrap"
      padding="4px 8px"
      tabIndex="0"
      flexColumnGap="3px"
      flexRowGap="3px"
    >
      {options.map((day, index) => (
        <Day
          appearance="bare"
          size="small"
          shape="square"
          key={day.key}
          value={day.value}
          typeVariant={todayKey === day.key && day.type !== 'selected' ? 'today' : day.type}
          isDisplayMonth={day.monthOffset === 0}
          hasCursor={cursorIndex === index}
          onClick={setDate}
          text={day.label}
          tabIndex="-1"
        />
      ))}
    </Container>
  )
}
