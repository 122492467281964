import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  createContext,
  useContext,
  forwardRef,
} from 'react'
import propTypes from 'prop-types'

import { useControllableState } from 'lib/hooks'
import { isNullOrUndefined, isUndefined, isFunction, warnOnce } from 'lib/util'
import { uniqueName } from 'lib/unique-name'

import { useForm } from '@forms'

export const formElementContext = createContext(null)

export const useFormElement = (elementName = null) => {
  let context = useContext(formElementContext)
  if (elementName !== null) {
    while (context.elementName !== elementName && context.parentElement) {
      context = context.parentElement
    }
    return context
  }
  return null
}
export const useIsFormElementControlled = (elementName) => !isUndefined(useFormElement(elementName))

/**
 * The FormElement Component
 * Encapsulates input components and manages their state for them.
 */
export const FormElement = React.memo(
  ({ name, id, forElementName, value, onChange, defaultValue, children }) => {
    const onControllableStateChange = useCallback(
      (state, changeEmitter) => {
        isFunction(onChange) && onChange({ id, name, value: state, changeEmitter })
      },
      [onChange, id, name]
    )

    const [initialState] = useState(defaultValue ?? value ?? {})

    const [state, setState] = useControllableState(initialState, value, onControllableStateChange)

    const [ownGroupName] = useState(() => name || uniqueName('FormElement'))

    const parentElement = useContext(formElementContext)

    const setStateByKey = useCallback(
      (key, value) => {
        setState(
          (prevState) => ({
            ...prevState,
            [key]: value,
          }),
          // changeEmitter
          { name: key, value }
        )
      },
      [setState]
    )

    return (
      <formElementContext.Provider
        value={{
          parentElement,
          groupState: state,
          setGroupState: setStateByKey,
          elementName: name,
          groupId: id ?? ownGroupName,
        }}
      >
        {children}
      </formElementContext.Provider>
    )
  }
)

FormElement.propTypes = {
  onChange: propTypes.func,
}

/**
 * A hook to connect an input to its group.
 *
 * - Returns the "value" and "onChange" props to use in the input
 * - Sets the id property
 */
export const useFormElementProps = ({ forElementName = null, ...props }, componentType) => {
  const { name } = props

  const formContext = useForm()
  const groupContext = useFormElement(forElementName)

  const isGroupControlled = groupContext !== null

  const { groupState, setGroupState } = isGroupControlled ? groupContext : {}

  const stateKey = getStateKey(componentType)
  const stateId = stateKey === 'forElementName' ? forElementName : props[stateKey]

  if (isGroupControlled && isNullOrUndefined(stateId)) {
    const msg = `A group-controlled input of type "${componentType}" needs "${stateKey}" property set. It is used to determine where to put the value of this element, so it has to be a unique value in this Element.`
    warnOnce(`FORM_INPUT_${componentType}_IDENTIFIER_${stateKey}_MISSING`, msg)
  }
  if (isGroupControlled && !isNullOrUndefined(props.onChange)) {
    const msg = `A group-controlled input of type "${componentType}" has its "onChange" property set. This value will be ignored. Remove the property to resolve.`
    warnOnce(`FORM_INPUT_${componentType}_CONTROLLED_PROPERTY_ONCHANGE_SET`, msg)
  }

  const value = useRef(null)
  const reset = useRef(null)
  const formElementRefs = useMemo(() => ({ value, reset }), [])

  const [controlledProperties] = useState(isGroupControlled ? getControlledPropertyNames(componentType) : [])
  controlledProperties.forEach((property) => {
    if (!isNullOrUndefined(props[property])) {
      const msg = `A group-controlled input of type "${componentType}" has its "${property}" property set. This value will be ignored. Remove the property to resolve.`
      warnOnce(`FORM_INPUT_${componentType}_CONTROLLED_PROPERTY_${property}_SET`, msg)
    }
  })

  useEffect(() => {
    if (!isGroupControlled && name && formContext) {
      formContext.addElement(name, formElementRefs)
    }
  }, [componentType, isGroupControlled, name, formContext, formElementRefs])

  const onChange = useCallback(
    (evt) => {
      if (isGroupControlled && (componentType !== 'Radio' || evt?.checked)) {
        setGroupState(stateId, evtToStateHandler({ controlledProperties, values: evt, componentType }))
      }
    },
    [isGroupControlled, setGroupState, stateId, controlledProperties, componentType]
  )

  const memGroupState = useMemo(() => {
    return groupState?.[stateId]
  }, [groupState, stateId])

  const childPropsControlled = useMemo(
    () => ({
      ...props,
      ...{
        onChange,
        ...stateToPropsHandler({ controlledProperties, props, values: memGroupState, componentType }),
      },
    }),
    [props, onChange, controlledProperties, memGroupState, componentType]
  )

  const childPropsUncontrolled = useMemo(
    () => ({
      ...props,
      ...{ formElementRefs },
    }),
    [props, formElementRefs]
  )

  return isGroupControlled ? childPropsControlled : childPropsUncontrolled
}

/**
 * A HOC to connect an arbitrary component to its group.
 */
export const withFormElementProps = (WrappedInput) =>
  forwardRef((props, ref) => (
    <WrappedInput ref={ref} {...useFormElementProps(props, WrappedInput.type?.type || WrappedInput.type)} />
  ))

function getStateKey(type) {
  switch (type) {
    case 'Checkbox':
      return 'value'
    case 'Radio':
      return 'forElementName'
    default:
      return 'name'
  }
}
function getControlledPropertyNames(type) {
  switch (type) {
    case 'Checkbox':
    case 'Radio':
      return ['checked']
    default:
      return ['value', 'defaultValue']
  }
}
function evtToStateHandler({ controlledProperties, values = {}, componentType }) {
  if (componentType === 'Checkbox') {
    return { checked: values?.checked ?? false }
  } else if (componentType === 'Radio') {
    return values.value
  } else if (['RadioGroup', 'DropdownSelect', 'DropdownMultiSelect'].includes(componentType)) {
    return { value: typeof values.value !== 'undefined' ? values.value : null }
  } else {
    return controlledProperties.reduce((properties, key) => {
      if (key === 'defaultValue') {
        return properties
      }
      properties[key] = values?.[key] || ''
      return properties
    }, {})
  }
}
function stateToPropsHandler({ controlledProperties, props, values = {}, componentType }) {
  if (componentType === 'Checkbox') {
    return { checked: values?.checked ?? false }
  } else if (componentType === 'Radio') {
    return { checked: values === props?.value }
  } else if (['RadioGroup', 'DropdownSelect', 'DropdownMultiSelect'].includes(componentType)) {
    return { value: typeof values.value !== 'undefined' ? values.value : null }
  } else {
    return controlledProperties.reduce((properties, key) => {
      if (key === 'defaultValue') {
        return properties
      }
      properties[key] = values?.[key] || ''
      return properties
    }, {})
  }
}
