import React from 'react'

import { StyledText } from './SubComponents'

import { defaultProps as textProps, defaultPropTypes as textPropTypes } from '@typography'

export const DefinitionText = ({ children, ...props }) => {
  return <StyledText {...props}>{children}</StyledText>
}

DefinitionText.propTypes = {
  ...textPropTypes,
}

DefinitionText.defaultProps = {
  ...textProps,
}
