import { useBreakpointAliases } from './useBreakpointAliases'
import { useMediaQuery } from 'lib/hooks'

export const useCurrentBreakpoint = () => {
  const breakpointAliases = useBreakpointAliases()
  const currentBreakpoint = useMediaQuery(breakpointAliases)
  const currentBreakpointIndex = breakpointAliases.indexOf(currentBreakpoint)

  return {
    currentBreakpoint,
    currentBreakpointIndex,
  }
}
