import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { difference, isDefined } from 'lib/util'
import { DropdownSelect, InputNumber } from '@forms'
import { FlexContainer, FlexItem } from '@layout/BuildingBlocks'

// example config
const defaults = {
  min: 0,
  max: null,
  step: 0.05,
  units: ['mm', 'cm'],
  unitsConfig: {
    mm: { min: 0, step: 1 },
    cm: { min: 0, step: 0.1 },
  },
  conversions: {
    mm: {
      default: 15,
      cm: 10,
    },
    cm: {
      default: 1.5,
      mm: 0.1,
    },
  },
}

export const InputUnitValue = ({
  value,
  onChange,
  min = defaults.min,
  max = defaults.max,
  step = defaults.step,
  units = defaults.units,
  allowedUnits,
  unitsConfig = defaults.unitsConfig,
  conversions = defaults.conversions,
  translationValuesPrefix,
}) => {
  const { t } = useTranslation()

  allowedUnits ??= units
  unitsConfig = { ...defaults.unitsConfig, ...unitsConfig }
  const config = { min, max, step, ...unitsConfig[value.unit] }
  const unknownUnits = difference(allowedUnits, units)
  if (unknownUnits.length !== 0) {
    throw Error(`allowedUnits contain unknown units: ${JSON.stringify(allowedUnits)}`)
  }
  if (!allowedUnits.includes(value.unit)) {
    throw Error(`Unit ${value.unit} not allowed (${JSON.stringify(allowedUnits)}).`)
  }

  const convert = useMemo(() => createConvertFunction(conversions), [conversions])
  return (
    <FlexContainer>
      <FlexItem flex="1">
        <InputNumber
          min={config.min}
          step={config.step}
          max={config.max}
          value={value.value}
          onChange={(target) => onChange({ ...value, value: target.value })}
        />
      </FlexItem>
      <FlexItem>
        <DropdownSelect
          name="css-unit"
          appearance="white"
          items={allowedUnits.map((s) => ({
            label: translationValuesPrefix ? t(`${translationValuesPrefix}.${s}`) : s,
            value: s,
          }))}
          value={value.unit}
          onChange={(target) =>
            onChange({ value: convert(value.value, value.unit, target.value), unit: target.value })
          }
        />
      </FlexItem>
    </FlexContainer>
  )
}

function fillMissingConnections(conversions) {
  let connectionsAdded

  while (connectionsAdded !== 0) {
    connectionsAdded = 0

    for (const toUnit in conversions) {
      for (const fromUnit in conversions) {
        const isSameUnit = toUnit === fromUnit
        const multiplier = conversions[toUnit][fromUnit]
        const hasMultiplier = typeof multiplier !== 'undefined'
        const reverseMultiplier = conversions[fromUnit][toUnit]
        const hasReverseMultiplier = typeof reverseMultiplier !== 'undefined'

        if (isSameUnit) continue
        if (hasMultiplier) continue

        if (hasReverseMultiplier) {
          conversions[toUnit][fromUnit] = 1 / reverseMultiplier
          connectionsAdded++
        }

        for (const intermediateUnit in conversions[toUnit]) {
          const intermediateMultiplier = conversions[toUnit][intermediateUnit]

          const transitionalMultiplier = conversions[intermediateUnit]?.[fromUnit]
          const hasTransitionalMultiplier = typeof transitionalMultiplier !== 'undefined'

          if (hasTransitionalMultiplier) {
            conversions[toUnit][fromUnit] = intermediateMultiplier * transitionalMultiplier
            connectionsAdded++
          }
        }
      }
    }
  }
}

export const createConvertFunction = (conversions) => {
  fillMissingConnections(conversions)
  return (value, fromUnit, toUnit) => {
    if (isDefined(value.value) && isDefined(value.unit)) {
      toUnit = fromUnit
      fromUnit = value.unit
      value = value.value
    }
    if (fromUnit === toUnit) {
      return value
    }
    if (conversions[toUnit][fromUnit]) {
      return value * conversions[toUnit][fromUnit]
    }
    return conversions[toUnit].default
  }
}

// A HOC to define own inputs based on unit config
export const createInputUnitValue = (config) => {
  const Component = (props) => {
    props = { ...config, ...props }
    return <InputUnitValue {...props} />
  }
  const convert = createConvertFunction(config.conversions)

  return { Component, convert }
}
