import React from 'react'
import LogoBulwiengesa from 'components/icons/LogoBulwiengesa'

export const defaultProps = {
  itemProps: {
    icon: {
      component: <LogoBulwiengesa part="icon" size="18px" />,
    },

    label: 'bulwiengesa',
    link: {
      path: 'https://bulwiengesa.de',
      rel: 'noopener noreferrer',
      target: '_blank',
    },
  },
}
