import React from 'react'

import { useTranslation } from 'react-i18next'

import { ButtonLink } from '@ui/Buttons'

import { CopyMedium } from 'stories/typography'

const emailSubject = `Account-Anfrage Highstreet-App`

const emailBody = `Sehr geehrte Damen und Herren,
%0D%0A
%0D%0A
bitte richten Sie mir einen Account für die Highstreet-App von ein.
%0D%0A
%0D%0A
Meine Kontaktdaten lauten:
%0D%0A
%0D%0A
Mit besten Grüßen,
%0D%0A
`

export const CTInquiryLink = () => {
  const { t } = useTranslation(['highstreetReport', 'translation'])

  return (
    <CopyMedium>
      {t('login.accountRequest.text')} <br />
      <ButtonLink
        appearance="bare"
        stretch={true}
        href={`mailto:jacqueline.baer@columbiathreadneedle.com ?subject=${emailSubject}&body=${emailBody}`}
        style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        text={t('login.accountRequest.button')}
      />
    </CopyMedium>
  )
}
