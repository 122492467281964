import React, { useMemo } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { layout, space } from 'styled-system'

import { size } from './variants'

import { array, string, number, oneOfType } from 'prop-types'
import { getThemeValue } from 'theming'

const styles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  pointer-events: none;
`

const Position = styled.span`
  ${space}
`
const StyledComponent = styled.span.withConfig({
  shouldForwardProp: (prop) => ['children'].includes(prop),
})`
  ${styles}
  ${layout}
  ${space}
  ${size}
`

export const LoadingIndicatorWrapper = ({ children, ...props }) => {
  const theme = useTheme()

  const shouldUseColor = useMemo(
    () =>
      props.appearance
        ? getThemeValue(theme, `uiActionElements.${props.appearance}.default.color`)
        : props.color,
    [props, theme]
  )
  const shouldUseCustomSize = useMemo(() => props.customSize !== null && props.customSize, [props.customSize])
  const shouldUseSize = useMemo(
    () => (shouldUseCustomSize === false ? props.size : null),
    [props.size, shouldUseCustomSize]
  )

  return (
    <Position {...props.position}>
      <StyledComponent
        iconVariantSourceName={props.iconVariantSourceName}
        height={shouldUseCustomSize}
        width={shouldUseCustomSize}
        ml={props.ml}
        mr={props.mr}
        size={shouldUseSize}
      >
        {React.cloneElement(children, { color: shouldUseColor })}
      </StyledComponent>
    </Position>
  )
}

LoadingIndicatorWrapper.defaultProps = {
  appearance: null,
  iconVariantSourceName: 'buttonIcons',
  ml: 0,
  mr: 0,
  size: 'medium',
  customSize: null,
}

LoadingIndicatorWrapper.propTypes = {
  appearance: string,
  iconVariantSourceName: string,
  customSize: string,
  size: string,
  ml: oneOfType([string, number, array]),
  mr: oneOfType([string, number, array]),
}
