import createStore from 'lib/flux-store'
import api from 'stores/api'

const computePrice = (hedonic) => {
  if (!hedonic) return null
  const price = hedonic.industry
  //const price = hedonic ? hedonic.riwis + hedonic.location + hedonic.plot + hedonic.house : 0

  return price
}

const initialState = {
  isLoading: true,
  isFailure: false,
  messages: [],
  hedonic: null,
}

const __NAME__ = 'HedonicIndustryDataStore'

const actions = {
  fetchHedonic: (params, location) => (dispatch) => {
    const [long, lat] = location.geometry.coordinates

    api.Hedonic.getIndustryProspectAtLocation({ lat, long, params })
      .then((result) => {
        const hedonic = result.data.data
        hedonic.price = computePrice(hedonic)
        dispatch({ type: 'setHedonic', payload: hedonic })
      })
      .catch((error) => {
        throw error
      })
  },
  setHedonic: (hedonic) => ({ hedonic }),
}

const reducer = {
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setHedonic: (state, { payload }) => {
    return { ...state, hedonic: payload }
  },
}

export const [HedonicIndustryDataContext, HedonicIndustryDataProvider, useHedonicIndustryDataStore] =
  createStore(reducer, actions, initialState, undefined, __NAME__)
