import React from 'react'
import { useTheme } from 'styled-components'

import { GreatPrimerSemiBold as Title } from '@typography'
import { PosRelative, FlexContainer } from '@layout/BuildingBlocks'
import { getThemeValue } from 'theming'
import { defaultProps } from './defaultProps'
import { children } from './defaultPropTypes'

export const HeaderTitle = ({ children }) => {
  const theme = useTheme()
  const color = getThemeValue(theme, 'colors.base.brand.primary.default')

  return (
    <FlexContainer alignItems="center" justifyContent="flex-end">
      <PosRelative marginLeft="20px" marginTop="2px">
        <Title color={color}>{children}</Title>
      </PosRelative>
    </FlexContainer>
  )
}

HeaderTitle.propTypes = {
  children,
}

HeaderTitle.defaultProps = defaultProps
