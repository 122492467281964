export const getBadge = ({ themeColors, radii, sizes }) => ({
  radius: {
    default: radii.small,
  },

  icon: {
    space: sizes.icons.button.space,
    sizes: sizes.icons.button,
  },

  margins: {
    inner: '8px',
    outer: '13px',
  },

  dark: {
    default: {
      bg: themeColors.dark.default.bg,
      color: themeColors.dark.default.color,
    },
    focus: {
      bg: themeColors.dark.focus.bg,
      color: themeColors.dark.focus.color,
    },
  },
  gray: {
    default: {
      bg: themeColors.gray.default.bg,
      color: themeColors.gray.default.color,
    },
    focus: {
      bg: themeColors.gray.focus.bg,
      color: themeColors.gray.focus.color,
    },
  },
  lightgray: {
    default: {
      bg: themeColors.lightgray.default.bg,
      color: themeColors.lightgray.default.color,
    },
    focus: {
      bg: themeColors.lightgray.focus.bg,
      color: themeColors.lightgray.focus.color,
    },
  },
  ghost: {
    default: {
      bg: themeColors.ghost.default.bg,
      color: themeColors.ghost.default.color,
    },
    focus: {
      bg: themeColors.ghost.focus.bg,
      color: themeColors.ghost.focus.color,
    },
  },
})
