import React, { memo } from 'react'
import { useTheme } from 'styled-components'

import { FlexContainer, FlexRow } from '@layout/BuildingBlocks'

import { getThemeValue } from 'theming'

import { useSlots } from 'lib/hooks/useSlots'

export const SubHeaderFullwidth = memo(({ children }) => {
  const [left, right] = useSlots(['left', 'right'], children)
  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  return (
    <FlexContainer
      alignItems="center"
      width="100%"
      height="100%"
      flexColumnGap={[spaces.rhythm.horizontal.large, null, spaces.rhythm.horizontal.extraLarge]}
    >
      {left && (
        <FlexRow
          flexColumnGap={[spaces.rhythm.horizontal.large, null, spaces.rhythm.horizontal.extraLarge]}
          flex="1 1 0px"
          minWidth="0px"
        >
          {left}
        </FlexRow>
      )}
      {right && (
        <FlexRow
          flexColumnGap={[spaces.rhythm.horizontal.large, null, spaces.rhythm.horizontal.extraLarge]}
          flex="0 1 0px"
          justifyContent="flex-end"
          minWidth="fit-content"
        >
          {right}
        </FlexRow>
      )}
    </FlexContainer>
  )
})

SubHeaderFullwidth.Left = ({ children }) => children
SubHeaderFullwidth.Left.displayName = 'left'

SubHeaderFullwidth.Right = ({ children }) => children
SubHeaderFullwidth.Right.displayName = 'right'
