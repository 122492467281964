const osFontsSansSerif =
  '-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"'
// const osFontsMonospace = 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace'

const typography = {
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fonts: {
    heading: `whitney-semibold-2, whitney-semibold-1, ${osFontsSansSerif}`,
    headingBold: `whitney-bold-1, ${osFontsSansSerif}`,
    headingNew: `Maven Pro, ${osFontsSansSerif}`,
    body: `whitney-book-2, whitney-book-1, ${osFontsSansSerif}`,
    sansSerif: `whitney-book-2, whitney-book-1, ${osFontsSansSerif}`,
    serif: `Maven Pro, ${osFontsSansSerif}`,
    mono: `${osFontsSansSerif}`,
  },
  fontSizes: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    '2xl': '1.25rem',
    '3xl': '1.5rem',
    '4xl': '1.875rem',
    '5xl': '2.25rem',
    '6xl': '3rem',
  },
}

export default typography
