import React from 'react'

import { StyledText } from '../StyledText'
import { typeStyles } from '../typeStyles'

import { defaultProps } from '../defaultProps'
import { defaultPropTypes } from '../defaultPropTypes'

export const Royal = ({ children, tag, ...props }) => (
  <StyledText scale="royal" {...props} {...typeStyles.royal} as={tag}>
    {children}
  </StyledText>
)

export const RoyalMedium = ({ children, tag, ...props }) => (
  <StyledText scale="royal" {...props} {...typeStyles.royalMedium} as={tag}>
    {children}
  </StyledText>
)

export const RoyalSemiBold = ({ children, tag, ...props }) => (
  <StyledText scale="royal" {...props} {...typeStyles.royalSemiBold} as={tag}>
    {children}
  </StyledText>
)

export const RoyalBold = ({ children, tag, ...props }) => (
  <StyledText scale="royal" {...props} {...typeStyles.royalBold} as={tag}>
    {children}
  </StyledText>
)

Royal.propTypes = defaultPropTypes
RoyalMedium.propTypes = defaultPropTypes
RoyalSemiBold.propTypes = defaultPropTypes
RoyalBold.propTypes = defaultPropTypes

Royal.defaultProps = defaultProps
RoyalMedium.defaultProps = defaultProps
RoyalSemiBold.defaultProps = defaultProps
RoyalBold.defaultProps = defaultProps
