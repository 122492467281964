import React from 'react'

import { PosRelative } from '@layout/BuildingBlocks'
import { FlexContainer, FlexCenter } from '@layout/BuildingBlocks'

import { defaultProps } from './defaultProps'
import { children } from './defaultPropTypes'

export const HeaderTitleLogo = ({ children }) => (
  <FlexContainer alignItems="center">
    <PosRelative marginLeft="36px" position="relative">
      <FlexCenter>{children}</FlexCenter>
    </PosRelative>
  </FlexContainer>
)

HeaderTitleLogo.propTypes = {
  children,
}

HeaderTitleLogo.defaultProps = defaultProps
