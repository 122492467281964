import { variant } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const appearanceVariants = (props) =>
  variant({
    prop: 'appearance',
    variants: {
      default: {
        background: `${themeGet('colors.base.white', 'gray')(props)}`,
        width: '100%',
      },
      light: {
        background: `${themeGet('colors.base.white', 'gray')(props)}`,
        borderColor: `${themeGet('colors.base.gray.100', 'gray')(props)}`,
        width: '100%',
      },
      dark: {
        backgroundColor: themeGet('badge.dark.default.bg', 'gray')(props),
        color: themeGet('badge.dark.default.color', 'gray')(props),
        width: '100%',
      },
    },
  })

export const borderVariants = (props) =>
  variant({
    prop: 'border',
    variants: {
      default: {
        borderRadius: '4px',
        borderColor: `${themeGet('colors.base.gray.400', 'gray')(props)}`,
        borderStyle: 'solid',
        borderWidth: '1px',
      },
      noBorder: {
        border: 'none',
      },
    },
  })
