import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'

import { Box, GridContainer } from '@layout/BuildingBlocks'
import SelectedTags from '@forms/TagList/SelectedTags'
import { InputSearch } from '@forms'
import { Suggestions } from '@utilities/Suggestions'
import { ScrollContainer } from '@layout/Layout/SubComponents/ScrollContainer'

import { Copy } from '@typography'
import { useTranslation } from 'react-i18next'
import { GridItem } from '@layout/Grid'

const ROW_HEIGHT = 44
const TagListSimpleViewComponent = ({
  entries = [],
  onChange,
  onRemove,
  onReset,
  value = [],
  appearance,
  suggestionsProps,
  tagsProps,
  noModal,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [searchVal, setSearchVal] = useState('')

  const inputFilter = useRef()
  const tagsListRef = useRef()

  const searchBoxBg = useMemo(() => getThemeValue(theme, 'taglist.searchbox.bg'), [theme])
  const searchBoxPadding = useMemo(() => getThemeValue(theme, 'taglist.searchbox.padding'), [theme])
  const suggestionsBoxPadding = useMemo(() => getThemeValue(theme, 'taglist.suggestionsBox.padding'), [theme])

  const availableEntries = useMemo(
    () => (value?.length ? entries.filter((item) => !value.includes(item.value)) : entries),
    [entries, value]
  )
  const filteredSuggestionOpts = useMemo(
    () =>
      !searchVal
        ? availableEntries
        : availableEntries.filter(
            (item) => String(item.label).toLowerCase().search(searchVal.toLowerCase()) >= 0
          ),
    [availableEntries, searchVal]
  )

  const onFilterChange = useCallback(
    (target) => {
      setSearchVal(target.value)
    },
    [setSearchVal]
  )
  const onAdd = useCallback(
    (newValue) => {
      if (!value.includes(newValue)) onChange([...value, newValue])
    },
    [value, onChange]
  )

  return (
    <GridContainer
      gridTemplateColumns={1}
      gridRowGap={'.75rem'}
      gridTemplateRows={['80px auto', '120px auto']}
      height="100%"
    >
      <GridItem gridColumn="_1_1">
        <ScrollContainer disabled={!value.length}>
          <SelectedTags ref={tagsListRef} value={value} entries={entries} onRemove={onRemove} />
        </ScrollContainer>
      </GridItem>
      <GridItem gridColumn="_1_1">
        <GridContainer
          gridTemplateColumns={1}
          gridTemplateRows={'auto 1fr'}
          height="100%"
          overflow={!noModal && getThemeValue(theme, 'taglist.suggestionsBox.overflow')}
          borderStyle={!noModal && getThemeValue(theme, 'taglist.suggestionsBox.borderStyle')}
          borderWidth={getThemeValue(theme, 'taglist.suggestionsBox.borderWidth')}
          borderRadius={getThemeValue(theme, 'taglist.suggestionsBox.borderRadius')}
          borderColor={getThemeValue(theme, 'taglist.suggestionsBox.borderColor')}
          backgroundColor={getThemeValue(theme, 'taglist.suggestionsBox.backgroundColor')}
        >
          <GridItem gridColumn="_1_1">
            <Box
              style={{
                backgroundColor: searchBoxBg,
                padding: searchBoxPadding,
              }}
            >
              <InputSearch
                ref={inputFilter}
                width="100%"
                size="small"
                placeholder={t('actions.actionSearch')}
                name="search"
                entries={entries}
                onChange={onFilterChange}
                appearance="white"
              />
            </Box>
          </GridItem>
          <GridItem gridColumn="_1_1">
            <Suggestions
              className="rs-table-taglist"
              options={filteredSuggestionOpts}
              calculateHeight={true}
              rowHeight={ROW_HEIGHT}
              renderEmpty={() => (
                <Copy p={suggestionsBoxPadding} textAlign="center">
                  {t('table.noResult')}
                </Copy>
              )}
              onRowClick={(rowData) => {
                if (!rowData.disabled) {
                  onAdd(rowData.value)
                }
              }}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}
export const TagListSimpleView = memo(TagListSimpleViewComponent)
