import { useCallback } from 'react'

export const useMergeDefaultProps = (propsToMerge, defaultProps) => {
  const result = useCallback(() => {
    const key = Object.keys(propsToMerge)[0]
    const hasKey = Object.prototype.hasOwnProperty.call(defaultProps, Object.keys(propsToMerge)[0])

    if (hasKey) {
      const newObj = { ...defaultProps[key], ...propsToMerge[key] }

      return newObj
    } else {
      console.warn('@useMergeDefaultProps: unable to merge - returned defaultProps instead')
      return defaultProps
    }
  }, [propsToMerge, defaultProps])

  return result()
}
