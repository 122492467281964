import React from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const StyledComponent = styled.div`
  margin-left: 13px;
  margin-right: 13px;
  padding: 0px;
  border-radius: ${themeGet('card.border.radius', '0px')};
  box-shadow: 2px 6px 27px 4px rgba(0, 0, 0, 0.43);
`

export const CardShadow = ({ id, children }) => <StyledComponent id={id}>{children}</StyledComponent>
