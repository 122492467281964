import React from 'react'
import styled, { useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { string, object, oneOfType } from 'prop-types'

import { CopyMedium as SubHeadline } from '@typography'
import { getThemeValue } from 'theming'

const SubHeadlineWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  margin-bottom: ${(props) => (!props.folded ? themeGet('controlsSegment.subHeadline.spaceBottom', 0) : 0)};
  text-transform: ${themeGet('controlsSegment.subHeadline.textTransform')};
`
export const ControlsSegmentSubHeadline = ({ children, folded, ...props }) => {
  const theme = useTheme()
  const color = getThemeValue(theme, 'controlsSegment.subHeadline.color')

  return (
    <SubHeadlineWrapper folded={folded}>
      <SubHeadline color={color} padding="0" margin="0" tag="h1" {...props}>
        {children}
      </SubHeadline>
    </SubHeadlineWrapper>
  )
}

ControlsSegmentSubHeadline.defaultProps = {
  children: 'Filter Subheadline',
}

ControlsSegmentSubHeadline.propTypes = {
  children: oneOfType([object, string]),
}
