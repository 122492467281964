import styled from 'styled-components'
import { typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { typeScale, typeStyle } from '@typography'

const excludeProps = [
  'hasCursor',
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
  'withCheckbox',
]

export const StyledText = styled.span.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  ${typography}
  ${typeScale('copy')}
  ${typeStyle('copy')}

    h3, h4 {
    ${typography}
    ${typeScale('canon')}
    ${typeStyle('canonMedium')}
    color: ${themeGet('colors.base.gray.900', '#fff')};
    display: block;
    margin-bottom: 16px;
    margin-top: 24px;
  }

  ul li {
    ${typography}
    ${typeScale('copy')}
    ${typeStyle('copy')}
    color: ${themeGet('colors.base.gray.900', '#fff')};
    line-height: 1.425;
    margin-bottom: 0.5rem;
  }

  p {
    ${typography}
    ${typeScale('copy')}
    ${typeStyle('copy')}
    color: ${themeGet('colors.base.gray.900', '#fff')};
    line-height: 1.425;
    margin-bottom: 1.25rem;
  }

  b,
  p b,
  ul li b {
    ${typography}
    ${typeScale('copy')}
    ${typeStyle('copySemiBold')}
    color: ${themeGet('colors.base.gray.900', '#fff')};
  }

  math {
    ${typography}
    ${typeScale('copy')}
    ${typeStyle('copyMediumItalic')}
    color: ${themeGet('colors.base.gray.900', '#fff')};
  }
  margin-top: 30px;
`
