import { useEffect } from 'react'

import { useKeyPressEvent } from 'lib/hooks'

export const useKeyboardNavigation = ({
  name = 'notSet',
  focused = false,
  active = false,
  spacePreventDefault,
  closeOnTab = true,
  closeDropdown,
  openDropdown,
  selectCursor,
  moveCursor,
}) => {
  const escapePressed = useKeyPressEvent('Escape', {
    active: active && typeof closeDropdown === 'function',
    once: true,
    stopPropagation: true,
    preventDefault: true,
  })
  const tabPressed = useKeyPressEvent('Tab', { active: focused || active, once: true })
  const enterPressed = useKeyPressEvent('Enter', {
    active: focused || active,
    once: true,
    stopPropagation: true,
    preventDefault: true,
  })
  const spacePressed = useKeyPressEvent('Space', {
    active: focused || active,
    once: true,
    preventDefault: spacePreventDefault ?? focused,
  })
  const arrowUpPressed = useKeyPressEvent('ArrowUp', {
    active: focused || active,
    once: true,
    stopPropagation: true,
    preventDefault: true,
  })
  const arrowDownPressed = useKeyPressEvent('ArrowDown', {
    active: focused || active,
    once: true,
    stopPropagation: true,
    preventDefault: true,
  })

  useEffect(() => {
    if ((escapePressed || (closeOnTab && tabPressed)) && typeof closeDropdown === 'function') {
      closeDropdown(!!escapePressed)
    }
  }, [escapePressed, tabPressed, closeOnTab, closeDropdown])

  useEffect(() => {
    if (enterPressed || spacePressed) {
      if (active) {
        selectCursor(enterPressed ? 'enter' : 'space')
      } else if (focused) {
        openDropdown(false)
      }
    }
  }, [enterPressed, spacePressed, active, focused, selectCursor, openDropdown])

  useEffect(() => {
    if (focused && (arrowDownPressed || arrowUpPressed)) {
      openDropdown(arrowUpPressed ? 'up' : 'down')
    }
  }, [arrowDownPressed, arrowUpPressed, focused, openDropdown])

  useEffect(() => {
    if (active && (arrowDownPressed || arrowUpPressed)) {
      moveCursor(arrowDownPressed ? 'down' : 'up')
    }
  }, [arrowDownPressed, arrowUpPressed, active, moveCursor])
}
