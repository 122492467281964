export const getLayout = ({ spaces, sizes }) => {
  const largIntRepr = spaces.rhythm.horizontal.large.replace(/px/, '')
  const thumbWidthIntRepr = sizes.scrollbar.default.thumb.width.replace(/px/, '')
  const positionSubHeaderMainIntRepr = largIntRepr - thumbWidthIntRepr / 2
  return {
    header: {
      height: ['36px', null, null, '40px', '48px'],
      mx: [spaces.rhythm.horizontal.medium],
    },
    headerFullscreen: {
      height: ['36px', null, null, '40px', '48px'],
      py: spaces.rhythm.vertical.medium,
      px: spaces.rhythm.horizontal.medium,
      background: null,
      backgroundBreakWidth: null,
    },
    subheader: {
      height: ['44px', null, null, '48px', '56px'],
      mt: [spaces.rhythm.vertical.small, null, spaces.rhythm.vertical.medium],
      mb: [spaces.rhythm.vertical.medium, null, spaces.rhythm.vertical.large],
      positionMain: `${positionSubHeaderMainIntRepr}px`,
    },
    page: {
      mx: [spaces.rhythm.horizontal.medium, null, spaces.rhythm.horizontal.extraLarge],
      minWidth: 760,
      minHeight: 520,
    },
    areaMain: {
      pt: spaces.rhythm.vertical.large,
      pb: spaces.rhythm.vertical.large,
      pr: [spaces.rhythm.horizontal.medium, null, spaces.rhythm.horizontal.large],
    },
    mainStage: {
      ml: spaces.rhythm.horizontal.extraLarge, // used if no ControlsPanel available on page
    },
    controlsPanel: {
      mr: spaces.gaps.controlsPanel, // used for gap between ControlsPanel and MainStage
    },
    footer: {
      height: [spaces.rhythm.vertical.medium, null, null, spaces.rhythm.vertical.large, null],
    },
  }
}
