import { bool, element, func, number, object, string } from 'prop-types'

const buttonDown = element.isRequired
const buttonUp = element.isRequired
const debounce = number
const defaultValue = object
const disabled = bool
const formElementRefs = object
const gap = string.isRequired
const id = string
const maxFrom = number
const maxTo = number
const minFrom = number
const minTo = number
const name = string.isRequired
const nameFrom = string.isRequired
const nameTo = string.isRequired
const placeholderFrom = string
const placeholderTo = string
const onChange = func
const stepFrom = number
const stepTo = number
const value = object
const size = string

export {
  buttonDown,
  buttonUp,
  debounce,
  defaultValue,
  disabled,
  formElementRefs,
  gap,
  id,
  maxFrom,
  maxTo,
  minFrom,
  minTo,
  name,
  nameFrom,
  nameTo,
  placeholderFrom,
  placeholderTo,
  onChange,
  stepFrom,
  stepTo,
  value,
  size,
}
