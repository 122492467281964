import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { typography } from 'styled-system'

import { bool, string, oneOf } from 'prop-types'

import { HTMLAnchorElement, ReactRouterDomLink } from '../SubComponents'

import { appearance, shape, size } from './variants'
import { typeMap, typeScale, typeStyle, defaultProps as textProps } from '@typography'

const StyledComponent = styled(HTMLAnchorElement)`
  align-self: center;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  ${shape}
  ${(props) => props.shape !== 'shapeless' && size}
  ${(props) => props.minWidth && { minWidth: props.minWidth }}
  ${(props) => props.width && { width: props.width }}
  ${(props) => props.height && { height: props.height }}
  ${(props) => props.stretch && { width: '100%' }}
  ${(props) => props.leftAlign && { justifyContent: 'flex-start' }}
`

export const ButtonLink = forwardRef(({ children, ...props }, ref) => {
  const { typeStyle, typeScale } = typeMap[props.size]

  return props.linkType === 'htmlAnchorElement' ? (
    <StyledComponent typeScale={typeScale} typeStyle={typeStyle} ref={ref} {...props} />
  ) : (
    <StyledComponent
      as={ReactRouterDomLink}
      typeScale={typeScale}
      typeStyle={typeStyle}
      ref={ref}
      {...props}
    />
  )
})

ButtonLink.defaultProps = {
  ...textProps,
  appearance: 'lightgray',
  href: null,
  leftAlign: null,
  linkType: 'htmlAnchorElement',
  size: 'medium',
  shape: 'oval',
  stretch: null,
  to: '/',
}

ButtonLink.propTypes = {
  appearance: string,
  leftAlign: bool,
  linkType: oneOf(['htmlAnchorElement', 'reactRouterDomLink']),
  shape: string,
  size: string,
  text: string.isRequired,
  href: string,
  stretch: bool,
}
