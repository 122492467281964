import { useModalContext } from '..'
import { useSlotsAsArray } from 'lib/hooks/useSlots'

export const useModalViewProperties = (children) => {
  const {
    state: { activeView },
  } = useModalContext()

  const [views] = useSlotsAsArray(['views'], children)
  const currentViewProps = { size: 'default', ...views[activeView].props }

  return {
    currentViewProps,
  }
}
