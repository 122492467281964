import React, { useCallback, useRef } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { space } from 'styled-system'
import { math } from 'polished'

import { InputNumber } from '@forms'
import { FlexContainer } from '@layout/BuildingBlocks'

import { defaultProps } from './defaultProps'
import { appearance, borderRadius, direction, forElementName, name } from './defaultPropTypes'
import { appearance as appearanceVariants, size } from '@forms/Input/variants'
import { getThemeValue } from 'theming'

const inputStyles = css`
  appearance: textfield;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  width: 100%;
  ${space};
  ${appearanceVariants};
  ${size};

  border: 0;
  border-color: unset;
`

const styleLeft = css`
  border-radius: ${(props) => `${props.borderRadius} 0 0 ${props.borderRadius}`};
  border-right-width: 0;
`

const styleRight = css`
  border-radius: ${(props) => `0 ${props.borderRadius}  ${props.borderRadius} 0`};
  border-left-width: 0;
`

const borderStyle = css`
  ${appearanceVariants};
`

const InputWrapper = styled(FlexContainer)`
  ${(props) => (props.direction === 'right' ? styleRight : styleLeft)}
  background: ${(props) => themeGet('colors.base.white', '#fff')(props)};
  ${borderStyle};
  overflow: hidden;
  position: relative;
  width: 100%;
`

const StyledInputNumber = styled(InputNumber).withConfig({
  shouldForwardProp: (props) => !['direction'].includes(props),
})`
  ${inputStyles}
`

const StyledInputNumberWrapper = styled.div`
  width: 100%;
  ${space}
`

const ButtonWrapper = styled(FlexContainer)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => (props.direction === 'left' ? 'unset' : props.arrowOffset)};
  z-index: 2;
  right: ${(props) => (props.direction === 'right' ? 'unset' : props.arrowOffset)};
`

export const InputNumberCustom = ({
  appearance,
  borderRadius,
  buttonUp,
  buttonDown,
  direction,
  disabled,
  name,
  max,
  min,
  forElementName,
  placeholder,
  typeScale,
  typeStyle,
  step,
  size,
}) => {
  const ref = useRef()
  const theme = useTheme()
  const arrowButtonSize = 'tiny'
  const arrowOffset = '2px'
  const inputWrapperPadding = getThemeValue(theme, `sizes.icons.button.${arrowButtonSize}.width`).map(
    (size) => (size === null ? null : math(`${arrowOffset} + ${size}`))
  )

  const onClickStepUp = useCallback(() => {
    if (ref.current.disabled !== true) {
      ref.current.stepUp()
      ref.current.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }, [ref])

  const onClickStepDown = useCallback(() => {
    if (ref.current.disabled !== true) {
      ref.current.stepDown()
      ref.current.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }, [ref])

  return (
    <InputWrapper direction={direction} borderRadius={borderRadius} appearance={appearance}>
      {direction === 'left' && (
        <StyledInputNumberWrapper paddingRight={inputWrapperPadding}>
          <StyledInputNumber
            direction={direction}
            disabled={disabled}
            forElementName={forElementName}
            max={max}
            min={min}
            name={name}
            placeholder={placeholder}
            ref={ref}
            step={step}
            typeScale={typeScale}
            typeStyle={typeStyle}
            appearance={appearance}
            shape="shapeless"
            size={size}
            arrowButtonSize={arrowButtonSize}
          />
        </StyledInputNumberWrapper>
      )}
      <ButtonWrapper
        direction={direction}
        flexDirection="column"
        justifyContent="center"
        arrowOffset={arrowOffset}
      >
        {React.cloneElement(buttonUp, {
          onClick: onClickStepUp,
          size: arrowButtonSize,
          shape: 'shapeless',
          appearance,
          direction,
        })}
        {React.cloneElement(buttonDown, {
          onClick: onClickStepDown,
          direction,
          size: arrowButtonSize,
          shape: 'shapeless',
          appearance,
        })}
      </ButtonWrapper>
      {direction === 'right' && (
        <StyledInputNumberWrapper paddingLeft={inputWrapperPadding}>
          <StyledInputNumber
            direction={direction}
            disabled={disabled}
            forElementName={forElementName}
            max={max}
            min={min}
            name={name}
            placeholder={placeholder}
            ref={ref}
            step={step}
            typeScale={typeScale}
            typeStyle={typeStyle}
            appearance={appearance}
            shape="shapeless"
            size={size}
            arrowButtonSize={arrowButtonSize}
          />
        </StyledInputNumberWrapper>
      )}
    </InputWrapper>
  )
}

InputNumberCustom.defaultProps = {
  ...defaultProps,
}

InputNumberCustom.propTypes = {
  appearance,
  borderRadius,
  direction,
  forElementName,
  name,
}
