import React, { useMemo } from 'react'

import { merge } from 'lodash'

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize'

const defaultRehypePlugins = [[rehypeKatex, { strict: false }], rehypeRaw]
const defaultRemakrPlugins = [remarkGfm, remarkMath]

/**
 *
 * @param components a object to transform incoming HTML tags
 * @param children
 * @param enableSanitizer sanitize HTML in Markdown texts - should be enabled for user generated content
 * @param sanitizerSchema a schema to allow more HTML tagNames, attributes etc. when sanitizing HTML in Markdown texts - schema blueprint can be found at node_modules/hast-utils-sanitize/lib/index.d.ts
 *
 */

export const Markdown = ({ components, children, enableSanitizer, sanitizerSchema = {}, ...props }) => {
  const rehypePlugins = useMemo(() => {
    const plugins = [...defaultRehypePlugins]
    if (enableSanitizer) {
      plugins.push([rehypeSanitize, merge(defaultSchema, sanitizerSchema)])
    }
    return plugins
  }, [enableSanitizer, sanitizerSchema])

  return (
    <ReactMarkdown
      components={components}
      rehypePlugins={rehypePlugins}
      remarkPlugins={defaultRemakrPlugins}
      {...props}
    >
      {children}
    </ReactMarkdown>
  )
}
