import createStore from 'lib/flux-store'
import api from 'stores/api'

const computeRentAndPrice = (hedonic, residentialArea) => {
  if (!hedonic) return null

  const rent = hedonic.rent
    ? hedonic.rent.riwis + hedonic.rent.location + hedonic.rent.building + hedonic.rent.flat
    : 0
  const price = hedonic.buy
    ? hedonic.buy.riwis + hedonic.buy.location + hedonic.buy.building + hedonic.buy.flat
    : 0

  const rentAndPrice = {
    rent: rent,
    rentForFlat: residentialArea * rent,
    price: price,
    priceForFlat: residentialArea * price,
  }

  return rentAndPrice
}

const initialState = {
  isLoading: true,
  isFailure: false,
  messages: [],

  hedonic: null,
  demandValues: null,
}

const __NAME__ = 'HedonicRentsDataStore'

const actions = {
  fetchHedonic: (currentConfig, location, curGac) => (dispatch) => {
    const [long, lat] = location.geometry.coordinates
    const params = { ...currentConfig, gac: curGac }

    api.Hedonic.getResidentialApartmentProspectAtLocation({ lat, long, params })
      .then((result) => {
        const hedonic = result.data.data
        hedonic.rentAndPrice = computeRentAndPrice(hedonic, currentConfig.residentialArea)
        dispatch({ type: 'setHedonic', payload: hedonic })
      })
      .catch((error) => {
        throw error
      })
  },

  fetchDemandValues: (location, curGac) => (dispatch) => {
    const params = {
      gac: curGac,
      demandBy: ['buildingPeriod', 'residentialArea', 'costs'],
      hedonicType: ['rents', 'buy'],
    }

    api.Hedonic.getResidentialDemandValues(params)
      .then((data) => data.data.data)
      .then((demandValues) => dispatch({ type: 'setDemandValues', payload: demandValues }))
      .catch((error) => {
        throw error
      })
  },
  setHedonic: (hedonic) => ({ hedonic }),
  setDemandValues: (demandValues) => ({ demandValues }),
}

const reducer = {
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setHedonic: (state, { payload }) => {
    return { ...state, hedonic: payload }
  },
  setDemandValues: (state, { payload }) => {
    return { ...state, demandValues: payload }
  },
}

export const [HedonicRentsDataContext, HedonicRentsDataProvider, useHedonicRentsDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  __NAME__
)
