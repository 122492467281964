const generateGridColumns = () => {
  let items = []
  for (let i = 1; i <= 24; i++) {
    for (let k = 1; k <= 24; k++) {
      items.push(`${i} / span ${k}`)
    }
  }

  for (let i = 1; i <= 24; i++) {
    items.push(`span ${i}`)
  }

  return items
}

const generateAliases = () => {
  let index = 0

  for (let i = 1; i <= 24; i++) {
    for (let k = 1; k <= 24; k++) {
      let key = `_${i}_${k}`
      Object.assign(gridColumns, {
        [key]: gridColumns[index],
      })
      index++
    }
  }

  for (let i = 1; i <= 24; i++) {
    let key = `_${i}`
    Object.assign(gridColumns, {
      [key]: gridColumns[index],
    })
    index++
  }
}

const genrateGridTemplateColumns = () => {
  let items = [null]
  for (let i = 1; i <= 24; i++) {
    let item = `repeat(${i}, 1fr)`
    items.push(item)
  }
  return items
}

const genrateGridTemplateRows = () => {
  let items = [null]
  for (let i = 1; i <= 24; i++) {
    let item = `repeat(${i}, 1fr)`
    items.push(item)
  }
  return items
}

const gridTemplateColumns = genrateGridTemplateColumns()
const gridTemplateRows = genrateGridTemplateRows()
const gridColumns = generateGridColumns()
const gridRows = generateGridColumns()

generateAliases()

export const getGrid = ({ spaces }) => {
  const gridColumnGaps = spaces.gaps.column
  const gridRowGaps = spaces.gaps.row
  return { gridColumnGaps, gridRowGaps, gridTemplateColumns, gridTemplateRows, gridRows, gridColumns }
}
