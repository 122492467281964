export const getModal = ({ radii, themeColors, sizes, spaces, colors }) => ({
  border: {
    radius: radii.extrahuge,
  },
  colors: {
    dialog: {
      bg: themeColors.app.bg,
    },
    body: {
      bg: themeColors.app.bg,
      color: themeColors.app.color,
    },
    subtitle: {
      color: colors.gray[400],
    },
  },

  dialog: {
    space: {
      maxHeight: `calc(100vh - ${spaces[11]})`,
      maxWidth: `calc(100vw - ${spaces[12]})`,
      x: spaces[1],
    },
  },
  body: {
    space: {
      x: spaces[9],
      scrollbar: sizes.scrollbar.default.track.width,
      bottom: spaces[9],
    },
  },
  header: {
    height: spaces[12],
    space: {
      x: spaces[9],
      bottom: '0px',
    },
  },
  footer: {
    space: {
      x: spaces[9],
      y: spaces[9],
    },
  },
  subHeadline: {
    space: {
      bottom: spaces[11],
    },
  },
  headline: {
    space: {
      top: spaces[9],
      bottom: spaces[1],
    },
  },
})
