import React from 'react'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { bool } from 'prop-types'

import {
  ControlsSegmentContent,
  ControlsSegmentSubHeadline,
  ControlsSegmentSectionContextProvider,
} from '@ui/ControlsSegment/SubComponents'
import { FlexContainer } from '@layout/BuildingBlocks'

import { useSlots } from 'lib/hooks/useSlots'
import { LimitedFeatureMask } from 'pages/Developer/Utils/LimitedFeatureMask'

export const ControlsSegmentStyledComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  margin-bottom: ${themeGet('controlsSegment.section.spaceBottom', 0)};

  :last-of-type {
    margin-bottom: 0;
  }
`

export const ControlsSegmentSection = ({
  children,
  folded,
  foldDisabled,
  isDemoVersion = false,
  ...props
}) => {
  const [content, subHeadline, subControls] = useSlots(['content', 'subHeadline', 'subControls'], children)

  return (
    <ControlsSegmentSectionContextProvider initialFolded={folded} foldDisabled={foldDisabled}>
      <ControlsSegmentStyledComponent {...props}>
        {(subHeadline || subControls) && (
          <FlexContainer justifyContent={subHeadline ? 'space-between' : 'flex-end'}>
            {subHeadline && (
              <ControlsSegmentSubHeadline folded={folded} {...subHeadline.props}>
                {subHeadline}
              </ControlsSegmentSubHeadline>
            )}
            {subControls && subControls}
          </FlexContainer>
        )}
        {content && <ControlsSegmentContent>{content}</ControlsSegmentContent>}
        {isDemoVersion && <LimitedFeatureMask />}
      </ControlsSegmentStyledComponent>
    </ControlsSegmentSectionContextProvider>
  )
}

ControlsSegmentSection.Content = ({ children }) => children
ControlsSegmentSection.Content.displayName = 'content'

ControlsSegmentSection.SubHeadline = ({ children }) => children
ControlsSegmentSection.SubHeadline.displayName = 'subHeadline'

ControlsSegmentSection.SubControls = ({ children }) => children
ControlsSegmentSection.SubControls.displayName = 'subControls'
ControlsSegmentSection.prototype = {
  folded: bool,
  foldDisabled: bool,
}
ControlsSegmentSection.defaultProps = {
  folded: false,
  foldDisabled: false,
}
