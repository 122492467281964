import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { system, background, border, compose, color, flexbox, layout, space } from 'styled-system'

import { allowedProps } from '../allowedProps'
import { defaultProps } from './defaultProps'
import { tag, flexRowGap, flexColumnGap } from './defaultPropTypes'

const StyledComponent = styled.div.withConfig({
  shouldForwardProp: (prop) => allowedProps.includes(prop),
})`
  display: flex;
  ${compose(background, border, color, flexbox, layout, space)};
  ${system({
    flexColumnGap: {
      property: 'columnGap',
      scale: 'flexColumnGaps',
    },
    flexRowGap: {
      property: 'rowGap',
      scale: 'flexRowGaps',
    },
    cursor: true,
  })}
`

export const FlexCenter = ({ children, tag, ...props }) => {
  return (
    <StyledComponent as={tag} alignItems="center" justifyContent="center" {...props}>
      {children}
    </StyledComponent>
  )
}

export const FlexCenterForwardedRef = forwardRef(({ children, tag, ...props }, ref) => {
  return (
    <StyledComponent ref={ref} as={tag} alignItems="center" justifyContent="center" {...props}>
      {children}
    </StyledComponent>
  )
})

export const FlexColumn = ({ children, tag, ...props }) => {
  return (
    <StyledComponent as={tag} flexDirection="column" {...props}>
      {children}
    </StyledComponent>
  )
}

export const FlexColumnForwardedRef = forwardRef(({ children, tag, ...props }, ref) => {
  return (
    <StyledComponent ref={ref} as={tag} flexDirection="column" {...props}>
      {children}
    </StyledComponent>
  )
})

export const FlexContainer = ({ children, tag, ...props }) => {
  return (
    <StyledComponent as={tag} {...props}>
      {children}
    </StyledComponent>
  )
}

export const FlexContainerForwardedRef = forwardRef(({ children, tag, ...props }, ref) => {
  return (
    <StyledComponent ref={ref} as={tag} {...props}>
      {children}
    </StyledComponent>
  )
})

export const FlexItem = ({ children, tag, ...props }) => {
  return (
    <StyledComponent as={tag} {...props}>
      {children}
    </StyledComponent>
  )
}
export const FlexItemForwardedRef = forwardRef(({ children, tag, ...props }, ref) => {
  return (
    <StyledComponent ref={ref} as={tag} {...props}>
      {children}
    </StyledComponent>
  )
})

export const FlexRow = ({ children, tag, ...props }) => {
  return (
    <StyledComponent as={tag} flexDirection="row" {...props}>
      {children}
    </StyledComponent>
  )
}

export const FlexRowForwardedRef = forwardRef(({ children, tag, ...props }, ref) => {
  return (
    <StyledComponent ref={ref} as={tag} flexDirection="row" {...props}>
      {children}
    </StyledComponent>
  )
})

FlexCenter.propTypes = {
  tag,
  flexRowGap,
  flexColumnGap,
}

FlexCenterForwardedRef.propTypes = {
  tag,
}

FlexColumn.propTypes = {
  tag,
  flexRowGap,
  flexColumnGap,
}

FlexColumnForwardedRef.propTypes = {
  tag,
}

FlexContainer.propTypes = {
  tag,
  flexRowGap,
  flexColumnGap,
}

FlexContainerForwardedRef.propTypes = {
  tag,
  flexRowGap,
  flexColumnGap,
}

FlexItem.propTypes = {
  tag,
  flexRowGap,
  flexColumnGap,
}

FlexItemForwardedRef.propTypes = {
  tag,
}

FlexRow.propTypes = {
  tag,
  flexRowGap,
  flexColumnGap,
}

FlexRowForwardedRef.propTypes = {
  tag,
}

FlexCenter.defaultProps = defaultProps

FlexCenterForwardedRef.defaultProps = defaultProps

FlexColumn.defaultProps = defaultProps

FlexColumnForwardedRef.defaultProps = defaultProps

FlexContainer.defaultProps = defaultProps

FlexContainerForwardedRef.defaultProps = defaultProps

FlexItem.defaultProps = defaultProps

FlexItemForwardedRef.defaultProps = defaultProps

FlexRow.defaultProps = defaultProps

FlexRowForwardedRef.defaultProps = defaultProps
