import React, { createRef, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { BudiconPasswordLock } from 'bgag-budicons'
import { getThemeValue } from 'theming'

import { useDebounce } from 'lib/hooks'

import api from 'stores/api'
import useApi from 'stores/useApi'
import { CopyMedium } from 'stories/typography'

import { FlexColumn, FlexItem } from '@layout/BuildingBlocks'
import { Form, Input } from '@forms/index'
import { Button } from '@ui/Buttons'

import { SetPasswordInvalidToken } from '../SetPasswordInvalidToken'
import { PasswordCriteriumFlyout } from '../PasswordCriteriumFlyout'

export const SetPasswordForm = ({ token, userId, type, onSetPassword }) => {
  const { t } = useTranslation()

  const [validateToken, tokenIsValid, , failedValidatingToken] = useApi(api.Auth.validateToken, null)
  const [testPasswordStrength, passwordStrength] = useApi(api.Auth.testPasswordStrength, null)
  const [setPassword, passwordSaved, isSettingPassword, failedSettingPassword] = useApi(
    api.Auth.setPassword,
    null
  )

  const theme = useTheme()
  const spaces = getThemeValue(theme, 'spaces')

  const refOpenerFlyout = createRef()

  const [passwords, setPasswords] = useState({
    new: '',
    repeat: '',
  })
  const debouncedPassword = useDebounce(passwords.new, 200)

  const newEqualsRepeat = passwords.new === passwords.repeat

  const submitIsDisabled =
    !tokenIsValid || !passwordStrength || !passwordStrength?.strong || isSettingPassword || !newEqualsRepeat

  const showCriteriaFlyout = passwordStrength && !passwordStrength?.strong

  const onSubmit = () => {
    setPassword({ password: passwords.new, userId, type, token })
  }

  useEffect(() => {
    validateToken({ userId, type, token })
  }, [validateToken, userId, type, token])

  useEffect(() => {
    if (
      failedSettingPassword &&
      Array.isArray(passwordSaved?.messages) &&
      passwordSaved.messages.find((message) => message.msg === 'invalid token')
    ) {
      validateToken({ userId, type, token })
    }
  }, [failedSettingPassword, passwordSaved, validateToken, userId, type, token])

  useEffect(() => {
    if (passwordSaved === true && typeof onSetPassword === 'function') {
      onSetPassword()
    }
  }, [passwordSaved, onSetPassword])

  useEffect(() => {
    if (debouncedPassword.length > 0) {
      testPasswordStrength(debouncedPassword)
    }
  }, [testPasswordStrength, debouncedPassword])

  // Invalid Token
  if (!tokenIsValid)
    return <SetPasswordInvalidToken type={type} failedValidatingToken={failedValidatingToken} />

  return (
    <>
      <FlexColumn mb={spaces.rhythm.vertical.medium}>
        <CopyMedium>{t('setPassword.' + type + '.intro')}</CopyMedium>
      </FlexColumn>
      <Form onSubmit={onSubmit}>
        <FlexColumn flexRowGap={spaces.rhythm.vertical.medium}>
          <FlexItem flexDirection="column">
            <Input
              appearance="white"
              type="password"
              value={passwords.new}
              placeholder={t('setPassword.newPassword')}
              iconLeft={{ Icon: BudiconPasswordLock }}
              onChange={(target) => {
                setPasswords({ ...passwords, new: target.value })
              }}
              resetable={true}
              showPassword={true}
              autoComplete="off"
              disabled={isSettingPassword}
              ref={refOpenerFlyout}
            />
            {showCriteriaFlyout && (
              <PasswordCriteriumFlyout
                refOpener={refOpenerFlyout}
                requiredCriteria={passwordStrength?.required}
                optionalCriteria={passwordStrength?.optional}
                optionalRemaining={passwordStrength?.optionalRemaining}
                config={passwordStrength?.config}
                placement="bottomStart"
              />
            )}
          </FlexItem>
          <FlexItem>
            <Input
              appearance="white"
              type="password"
              value={passwords.repeat}
              placeholder={t('setPassword.repeatPassword')}
              iconLeft={{ Icon: BudiconPasswordLock }}
              onChange={(target) => {
                setPasswords({ ...passwords, repeat: target.value })
              }}
              resetable={true}
              showPassword={true}
              autoComplete="off"
              disabled={isSettingPassword}
            />
          </FlexItem>
          <FlexItem>
            <Button
              type="submit"
              appearance="primaryShallow"
              shape="square"
              isLoading={isSettingPassword}
              disabled={submitIsDisabled}
              isDisabled={submitIsDisabled}
              stretch={true}
              text={t('menu.login')}
            />
          </FlexItem>
        </FlexColumn>
      </Form>
    </>
  )
}
