import React, { forwardRef, memo } from 'react'
import { useTheme } from 'styled-components'

import { Box, FlexRowForwardedRef } from '@layout/BuildingBlocks'
import { ButtonWithIcon } from '@ui/Buttons'
import { BudiconCrossUi } from 'bgag-budicons'

import { getThemeValue } from 'theming'

const SelectedTags = forwardRef(
  ({ entries = [], value = [], onRemove, disabled = false, appearance = 'gray' }, ref) => {
    const theme = useTheme()
    const selectedTagsRowGap = getThemeValue(theme, 'taglist.selectedTagsBox.rowGap')
    const selectedTagsColumnGap = getThemeValue(theme, 'taglist.selectedTagsBox.columnGap')

    return (
      <FlexRowForwardedRef
        ref={ref}
        flexWrap="wrap"
        flexRowGap={selectedTagsRowGap}
        flexColumnGap={selectedTagsColumnGap}
      >
        {entries
          .filter((item) => value.includes(item.value))
          .map((item, idx) => {
            return (
              <Box key={`selected-item-${item}-${idx}`}>
                <ButtonWithIcon
                  disabled={disabled}
                  appearance={appearance}
                  icon={{
                    Icon: BudiconCrossUi,
                    type: 'outline',
                  }}
                  iconAlign="right"
                  onClick={() => onRemove(item.value)}
                  size="tiny"
                  text={`${item.label}${typeof item.amount !== 'undefined' ? ` (${item.amount})` : ''}`}
                  type="button"
                />
              </Box>
            )
          })}
      </FlexRowForwardedRef>
    )
  }
)

SelectedTags.type = 'SelectedTags'
export default memo(SelectedTags)
