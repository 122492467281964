import { array, oneOfType, string, number } from 'prop-types'

const alignItems = string
const flexGrowItems = oneOfType([number, string])
const itemsSpaceX = oneOfType([array, string])
const itemsSpaceY = oneOfType([array, string])
const justifyItems = string
const name = string
const width = string

export { alignItems, flexGrowItems, itemsSpaceX, itemsSpaceY, justifyItems, name, width }
