import React, { useMemo } from 'react'

import { LogoRiwisText } from '@utilities/SVG/Logos/'
import { PosAbsolute } from '@layout/BuildingBlocks'

import { useMediaQuery } from 'lib/hooks/useMediaQuery'

export const CardLogo = ({ px, pt }) => {
  const shouldUseLargeSize = useMediaQuery([false, false, false, false, true])
  const shouldUseMediumSize = useMediaQuery([false, false, true, true, false])

  const size = useMemo(() => {
    return shouldUseMediumSize ? '.925rem' : shouldUseLargeSize ? '1.125rem' : '1rem'
  }, [shouldUseLargeSize, shouldUseMediumSize])

  return (
    <PosAbsolute top={pt} left={px}>
      <LogoRiwisText size={size} />
    </PosAbsolute>
  )
}
