import React from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CardPublic } from '@layout/CardPublic'
import { LayoutPublic } from '@layout/LayoutPublic'
import { CardLogoL3Plus } from '@layout/CardPublic/SubComponents/CardLogoL3Plus'
import { L3PlusFooter } from '@layout/CardPublic/SubComponents/L3PlusFooter/L3PlusFooter'
import { L3PlusHeaderBanner } from '@layout/LayoutPublic/SubComponents/L3PlusHeaderBanner'

import { SetPasswordForm } from './SubComponents/SetPasswordForm'

import l3plusBg from 'assets/images/l3plusBackground.png'

import { withDifferentTheme } from '../../../theming/util/withDifferentTheme'

export const SetPassword = ({ appType }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const params = useParams()
  const { token, type, userId } = params

  const bgImage = type === 'l3plus' ? l3plusBg : null

  const onSetPassword = () => {
    navigate('/', { replace: true, showMessage: 'passwordSet' })
  }

  return (
    <LayoutPublic bgImage={bgImage}>
      {appType === 'l3plus' && (
        <LayoutPublic.HeaderFullscreen>
          <L3PlusHeaderBanner />
        </LayoutPublic.HeaderFullscreen>
      )}
      <LayoutPublic.Main>
        {appType === 'l3plus' && <CardLogoL3Plus />}
        <CardPublic>
          {appType !== 'l3plus' && <CardPublic.Logo />}
          {appType !== 'l3plus' && (
            <CardPublic.Header>{t('setPassword.' + type + '.header')}</CardPublic.Header>
          )}
          <CardPublic.BodyRight>
            <SetPasswordForm token={token} userId={userId} type={type} onSetPassword={onSetPassword} />
          </CardPublic.BodyRight>
          {appType === 'l3plus' && (
            <CardPublic.Footer>
              <L3PlusFooter />
            </CardPublic.Footer>
          )}
        </CardPublic>
      </LayoutPublic.Main>
    </LayoutPublic>
  )
}

export const SetPasswordL3Plus = () => withDifferentTheme('L3Plus')(SetPassword)({ appType: 'l3plus' })
