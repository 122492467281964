import React, { useEffect, useState, useCallback, memo, useMemo } from 'react'
import styled from 'styled-components'

import { FlexContainer } from '@layout/BuildingBlocks'
import { InputNumberCustom } from './SubComponents'

import { FormElement, withFormElementProps } from '@forms'

import { isFunction } from 'lib/util'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'

import { defaultProps } from './defaultProps'
import {
  buttonDown,
  buttonUp,
  debounce,
  defaultValue,
  formElementRefs,
  gap,
  disabled,
  id,
  maxFrom,
  maxTo,
  minFrom,
  minTo,
  name,
  nameFrom,
  nameTo,
  placeholderFrom,
  placeholderTo,
  onChange,
  stepFrom,
  stepTo,
  value,
  size,
} from './defaultPropTypes'

const Container = styled(FlexContainer)`
  gap: ${(props) => props.gap};
`
const groupStateToValue = (state) => {
  const { from, to, value } = state
  const nextValue = {
    from:
      parseInt(from ? from.value : typeof value?.from === 'object' ? value?.from?.value : value?.from) ||
      undefined,
    to: parseInt(to ? to.value : typeof value?.to === 'object' ? value?.to?.value : value?.to) || undefined,
  }
  return nextValue
}

const valueToGroupState = (value) => {
  const { from, to } = value
  const newState = {
    from: { value: parseInt(typeof from === 'object' ? from?.value : from) || null },
    to: { value: parseInt(typeof to === 'object' ? to?.value : to) || null },
  }
  return newState
}

export const InputRangeNumbersComponent = ({
  buttonDown,
  buttonUp,
  debounce,
  defaultValue,
  formElementRefs,
  gap,
  disabled,
  id,
  maxFrom,
  maxTo,
  minFrom,
  minTo,
  name,
  nameFrom,
  nameTo,
  placeholderFrom,
  placeholderTo,
  onChange,
  stepFrom,
  stepTo,
  value,
  size,
}) => {
  const [initialValue] = useState(defaultValue ?? value ?? {})

  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id, name, value })
    },
    [onChange, id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [localValue, setValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(() => valueToGroupState(localValue), [localValue])

  const internalOnChange = useCallback(
    (target) => {
      groupStateToValue(target.value)

      setValue(groupStateToValue(target.value))
    },
    [setValue]
  )

  const onReset = useCallback(() => {
    setValue(initialValue || '')
  }, [setValue, initialValue])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.reset.current = onReset
    }
  }, [onReset, formElementRefs])

  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = localValue
    }
  }, [localValue, formElementRefs])

  return (
    <FormElement value={formState} name={name} onChange={internalOnChange}>
      <Container gap={gap}>
        <InputNumberCustom
          buttonDown={React.cloneElement(buttonDown)}
          buttonUp={React.cloneElement(buttonUp)}
          direction="left"
          disabled={disabled}
          forElementName={name}
          max={maxFrom}
          min={minFrom}
          name={nameFrom}
          placeholder={placeholderFrom}
          step={stepFrom}
          size={size}
        />
        <InputNumberCustom
          buttonDown={React.cloneElement(buttonDown)}
          buttonUp={React.cloneElement(buttonUp)}
          direction="right"
          disabled={disabled}
          forElementName={name}
          max={maxTo}
          min={minTo}
          name={nameTo}
          placeholder={placeholderTo}
          step={stepTo}
          size={size}
        />
      </Container>
    </FormElement>
  )
}

InputRangeNumbersComponent.type = 'InputRangeNumbers'
InputRangeNumbersComponent.defaultProps = defaultProps
InputRangeNumbersComponent.propTypes = {
  buttonDown,
  buttonUp,
  debounce,
  defaultValue,
  formElementRefs,
  gap,
  disabled,
  id,
  maxFrom,
  maxTo,
  minFrom,
  minTo,
  name,
  nameFrom,
  nameTo,
  placeholderFrom,
  placeholderTo,
  onChange,
  stepFrom,
  stepTo,
  value,
  size,
}

export const InputRangeNumbers = withFormElementProps(memo(InputRangeNumbersComponent))
