export const getCard = ({ colors, spaces }) => ({
  space: {
    top: spaces.rhythm.vertical.medium,
  },
  colors: {
    subtitle: {
      color: colors.gray[270],
    },
  },
})
