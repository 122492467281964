import React, { useEffect, useState, useCallback, memo, forwardRef } from 'react'
import styled from 'styled-components'
import { border, color, compose, layout, space, typography } from 'styled-system'

import { isFunction } from 'lib/util'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'

import { withFormElementProps } from '@forms'

import {
  typeScale,
  typeStyle,
  defaultProps as textProps,
  defaultPropTypes as textPropTypes,
} from '@typography'

const StyledComponent = styled.textarea.withConfig({
  shouldForwardProp: (props) =>
    ![
      'color',
      'fontFamily',
      'fontSize',
      'fontStyle',
      'fontWeight',
      'lineHeight',
      'scale',
      'tag',
      'typeScale',
      'typeStyle',
    ].includes(props),
})`
  ${compose(border, color, space, layout, typography)}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  resize: none;
  padding: 8px;
`

export const TextareaComponent = forwardRef(
  ({ value, onChange, formElementRefs, debounce, ...props }, ref) => {
    const [defaultValue] = useState(props.defaultValue || value || '')

    const onDebouncedUpdate = useCallback(
      (value) => {
        isFunction(onChange) && onChange({ id: props.id, name: props.name, value })
      },
      [onChange, props.id, props.name]
    )

    const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
      value,
      onDebouncedUpdate,
      debounce
    )

    const [localValue, setValue] = useControllableState(
      defaultValue,
      internalValueDebouncer,
      onControllableStateChange
    )

    const updateControllableState = useCallback(
      (evt) => {
        setValue(evt.target.value)
      },
      [setValue]
    )

    const onReset = useCallback(() => {
      setValue(defaultValue || '')
    }, [setValue, defaultValue])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.reset.current = onReset
      }
    }, [onReset, formElementRefs])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.value.current = localValue
      }
    }, [localValue, formElementRefs])

    return <StyledComponent ref={ref} {...props} value={localValue} onChange={updateControllableState} />
  }
)
TextareaComponent.type = 'Textarea'
TextareaComponent.defaultProps = {
  debounce: null,
  typeScale: 'copy',
  typeStyle: 'copyMedium',
  ...textProps,
}
TextareaComponent.propTypes = textPropTypes

export const Textarea = withFormElementProps(memo(TextareaComponent))
