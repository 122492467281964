import React, { useEffect, useState, useCallback, memo, forwardRef } from 'react'
import styled from 'styled-components'

import { isFunction } from 'lib/util'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'

import { withFormElementProps, Input } from '@forms'

import { bool, number, string } from 'prop-types'

import { defaultProps as textProps } from '@typography'

const StyledComponent = styled(Input)`
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`

export const InputNumberComponent = forwardRef(
  ({ borderAppearance, value, onChange, formElementRefs, debounce, ...props }, ref) => {
    const [defaultValue] = useState(props.defaultValue ?? value ?? '')

    const onDebouncedUpdate = useCallback(
      (value) => {
        isFunction(onChange) && onChange({ id: props.id, name: props.name, value })
      },
      [onChange, props.id, props.name]
    )

    const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
      value,
      onDebouncedUpdate,
      debounce
    )

    const [localValue, setValue] = useControllableState(
      defaultValue,
      internalValueDebouncer,
      onControllableStateChange
    )

    const internalOnChange = useCallback(
      (target) => {
        setValue(target.value)
      },
      [setValue]
    )

    const onReset = useCallback(() => {
      setValue(defaultValue || '')
    }, [setValue, defaultValue])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.reset.current = onReset
      }
    }, [onReset, formElementRefs])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.value.current = localValue
      }
    }, [localValue, formElementRefs])

    return (
      <StyledComponent type="number" ref={ref} value={localValue} onChange={internalOnChange} {...props} />
    )
  }
)

InputNumberComponent.type = 'InputNumber'
InputNumberComponent.defaultProps = {
  ...textProps,
  appearance: 'white',
  size: 'medium',
  shape: 'square',
  debounce: null,
  disabled: false,
  border: 'default',
}

InputNumberComponent.propTypes = {
  appearance: string,
  border: string,
  debounce: number,
  disabled: bool,
  shape: string,
  size: string,
}

export const InputNumber = withFormElementProps(memo(InputNumberComponent))
