import React from 'react'
import styled, { keyframes } from 'styled-components'

import { zIndices } from 'config/zIndices'
import { FlexCenter } from '@layout/BuildingBlocks'

const overlayAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: .25;
  }
  100% {
    opacity: 1;
  }
`

const StyledComponent = styled(FlexCenter)`
  animation-name: ${overlayAnimation};
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.25s;

  background: rgba(0, 0, 0, 0.5);
  display: flex;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  z-index: ${zIndices.modalOverlay};
`

export const ModalOverlay = ({ children, active = false, handleClick, ...props }) => {
  if (!active) {
    return children
  }
  return (
    <StyledComponent onClick={handleClick} {...props}>
      {children}
    </StyledComponent>
  )
}

ModalOverlay.displayName = 'modalOverlay'
