import React, { createContext, useContext, useState } from 'react'

const ControlsSegmentSectionContext = createContext()

const ControlsSegmentSectionContextProvider = ({ children, initialFolded, foldDisabled }) => {
  const [folded, setFolded] = useState(initialFolded)

  const toggle = (bool) => {
    setFolded(bool)
  }

  const context = {
    folded,
    setFolded,
    toggle,
    foldDisabled,
  }

  return (
    <ControlsSegmentSectionContext.Provider value={context}>
      {children}
    </ControlsSegmentSectionContext.Provider>
  )
}

const useControlsSegmentSectionContext = () => {
  const context = useContext(ControlsSegmentSectionContext)

  if (!context) {
    throw new Error('Error in creating the context')
  }
  return context
}

export { useControlsSegmentSectionContext, ControlsSegmentSectionContextProvider }
