import React, { useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { color } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { CardTitleButtonContainer } from '../../SubComponents'
import { FlexRow } from '@layout/BuildingBlocks'
import { GreatPrimerMedium as Text } from '@typography'

import { useSlots } from 'lib/hooks/useSlots'
import { getThemeValue } from 'theming'

const tabActive = css`
  background-color: ${themeGet('card.colors.tab.active.bg', 'lightgray')};
`

const tabInactive = css`
  background-color: ${themeGet('card.colors.tab.inActive.bg', 'gray')};
  cursor: pointer;
`

const TabContainer = styled(FlexRow)`
  align-items: flex-start;
  border-radius: ${themeGet('card.tab.border.radius', '0px')} ${themeGet('card.tab.border.radius', '0px')} 0px
    0px;
  height: 100%;
  padding-top: ${themeGet('card.header.space.top', '0')};
  padding-bottom: ${themeGet('card.header.space.bottom', '0')};

  ${(props) => (props.active ? tabActive : tabInactive)};
`

const TabText = styled(Text)`
  margin: 0;
  line-height: normal;
  ${color}
`

export const CardHeaderTabs = ({ active, disabled, setIndex, index, px, children }) => {
  const theme = useTheme()
  const [title, titleButton] = useSlots(['title', 'titleButton', 'buttonBar'], children)

  const tabColor = active
    ? getThemeValue(theme, 'card.colors.tab.active.color')
    : getThemeValue(theme, 'card.colors.tab.inActive.color')

  const onClick = useCallback(() => {
    if (!disabled && !active) {
      setIndex(index)
    }
  }, [disabled, active, index, setIndex])

  const tabPx = parseInt(px) * 1 + px.replace(/[0-9]*/g, '')

  return (
    <TabContainer active={active} onClick={onClick} px={tabPx} marginRight="2px">
      {title && (
        <TabText color={tabColor} tag="h3" lineHeight="1">
          {title}
        </TabText>
      )}
      {titleButton && <CardTitleButtonContainer>{titleButton}</CardTitleButtonContainer>}
    </TabContainer>
  )
}
