export const getNavigationMain = ({ colors, radii }) => ({
  badge: {
    radius: radii.small,
    height: ['26px'],
    colors: {
      default: {
        color: colors.gray[800],
        bg: colors.lightgray[570],
      },
      hover: {
        color: colors.gray[900],
        bg: colors.gray[100],
      },
    },
  },
  flyout: {
    radius: radii.small,
    colors: {
      border: {
        default: colors.lightgray[230],
        active: colors.lightgray[700],
      },
      icon: colors.gray[900],
      container: {
        bg: colors.lightgray[230],
      },
      sublist: {
        bg: colors.lightgray[50],
      },
      parent: {
        default: {
          bg: colors.lightgray[230],
          color: colors.gray[700],
        },
        active: {
          bg: colors.lightgray[50],
          color: colors.gray[900],
          indicator: colors.brand.primary.lighten,
        },
      },
      sub: {
        description: {
          color: colors.gray[500],
        },
        default: {
          bg: colors.lightgray[50],
          color: colors.gray[900],
        },
        active: {
          bg: colors.brand.primary.shallow,
          color: colors.brand.primary.default,
        },
        hover: {
          bg: colors.lightgray[230],
          color: colors.gray[900],
        },
      },
    },
  },
})
