/*

5: Stadtteile
10: kreisangehörige Stadt
20: kreisfreie Stadt
30: Landkreis
40: Regierungsbezirk
45: Regionen (ROR)
50: Bundeslandte
60: Staat
100: EU-Regionen

###############  TEILRÄUME  ###############
107: Bürolagen
108: Wohnlagen
109: Stadtbezirke


*/

const areaTypeEnum = {
  office: 107,
  residential: 108,
  logisticsRegion: 200,
}

export const getAreaTypeNumber = (areaTypeString) => {
  if (!areaTypeString || typeof areaTypeString !== 'string') {
    throw new Error('Argument must be a string')
  }
  return areaTypeEnum[areaTypeString]
}

export const getAreaTypeString = (areaTypeNumber) => {
  if (isNaN(Number(areaTypeNumber))) {
    throw new Error('Argument can not be casted to number')
  }
  const found = Object.entries(areaTypeEnum).find(([_, value]) => value === Number(areaTypeNumber))

  if (!found) {
    console.warn('No area type string found for argument', areaTypeNumber)
    return null
  }

  return found[0]
}

export const addressLevelToZoom = (addressLevel) => {
  if (addressLevel === '1') return 17
  if (addressLevel === '2') return 15
  if (addressLevel === '3') return 13
  if (addressLevel === '4') return 13
  if (addressLevel === '5') return 12
  if (addressLevel === '10') return 13
  if (addressLevel === '15') return 11
  if (addressLevel === '20') return 11
  if (addressLevel === '30') return 10
  if (addressLevel === '50') return 7
  if (addressLevel === '200') return 9
  return 11
}

const _arrayIntersect = (a1, a2) => {
  return Array.from(new Set(a1.filter((e) => a2.includes(e))))
}

export const validateFeature = (f) => {
  if (
    !f.areas ||
    f.properties.name.indexOf('Gemfr') !== -1 ||
    f.properties.matchLevel === 'country' ||
    (f.properties.matchLevel === 'county' && !f.areas['30']) ||
    (f.properties.matchLevel === 'district' && f.properties.name.startsWith(f.properties.city)) ||
    (f.properties.matchLevel === 'city' && !f.areas['10'] && !f.areas['20']) ||
    (!['country', 'county', 'city'].includes(f.properties.matchLevel) &&
      !_arrayIntersect(Object.keys(f.areas), ['10', '20', '30']).length)
  ) {
    return false
  }
  // if (Object.values(f.areas).some(a => a.inactive===true)) return true
  return true
}

const matchLevelOrder = ['state', 'county', 'city', 'district', 'street', 'houseNumber']

export const sortFeatures = (a, b) => {
  const ao = matchLevelOrder.indexOf(a.properties.matchLevel)
  const bo = matchLevelOrder.indexOf(b.properties.matchLevel)
  return ao - bo
}

export const reduceFeatures = (features, feature) => {
  if (feature.properties.matchLevel === null) {
    if (feature.properties.streetNumber.length) {
      feature.properties.matchLevel = 'houseNumber'
    } else if (feature.properties.streetName.length) {
      feature.properties.matchLevel = 'street'
    } else {
      feature.properties.matchLevel = 'city'
    }
  }
  if (!feature.areas || !feature.areas.length) return features
  feature.areas = normalizeAreas(feature.areas)
  if (!validateFeature(feature)) return features
  const identifiedFeature = identifyFeature(feature)
  const unique = !features.find((feature) => {
    const featureArea =
      feature.areas[feature.identifier] ?? feature.areas[10] ?? feature.areas[20] ?? feature.areas[30]
    const identifiedFeatureArea =
      identifiedFeature.areas[identifiedFeature.identifier] ??
      identifiedFeature.areas[10] ??
      identifiedFeature.areas[20] ??
      identifiedFeature.areas[30]

    return (
      feature.addressLevel === identifiedFeature.addressLevel &&
      feature.identifier === identifiedFeature.identifier &&
      featureArea.gac === identifiedFeatureArea.gac
    )
  })
  if (unique) {
    features.push(identifiedFeature)
  }
  return features
}

export const identifyFeature = (f) => {
  // addressLevel '5' not set in identify-feature, but after user interaction in LocationSearch

  // if(f.areas['40']) delete f.areas['40']
  // if(f.areas['45']) delete f.areas['45']
  let displayedName = ''
  let identifier = ''
  let addressLevel = ''
  let districtRelatedRiwisCity = false

  if (f.properties.matchLevel === 'state') {
    // Bundesland
    displayedName = f.areas['50'].name || f.properties.name.split(',')[0]
    identifier = '50'
    addressLevel = '50'
  } else if (f.properties.matchLevel === 'county' && f.areas['30']) {
    // Landkreis
    const preFix = f.properties.countryCode !== 'AT' ? 'Lkr. ' : 'Bezirk '
    if (f.areas['5']) delete f.areas['5']
    if (f.areas['10']) delete f.areas['10']
    if (f.areas['20']) delete f.areas['20']
    displayedName = preFix + f.areas['30'].name
    identifier = '30'
    addressLevel = '30'
  } else {
    let ortsArea = f.areas['10'] ? f.areas['10'] : f.areas['20'] ? f.areas['20'] : f.areas['30']
    if (f.properties.matchLevel === 'houseNumber' || f.properties.matchLevel === 'street') {
      // Straße/Haus
      displayedName =
        f.properties.streetName +
        (f.properties.streetNumber ? ` ${f.properties.streetNumber}, ` : ', ') +
        `${f.properties.zipcode} ` +
        ortsArea.name
      addressLevel = f.properties.streetNumber ? '1' : '2'
    } else if (f.properties.matchLevel === 'district' && (f.areas['5'] || f.areas['109'])) {
      // Stadtteil in RIWIS vorhanden
      displayedName =
        f.areas['5']?.name === f.properties.name.split(',')[0]
          ? `${f.areas['5'].name}, `
          : `${f.areas['109'].name}, `
      displayedName += ortsArea.name
      addressLevel = '3'
    } else if (
      f.properties.matchLevel === 'district' &&
      ortsArea &&
      f.properties.name.indexOf(ortsArea.name) !== -1
    ) {
      // Stadt-/Gemeindeteil in RIWIS nicht vorhanden
      displayedName = f.properties.name.split(ortsArea.name)[0] + ortsArea.name
      addressLevel = '4'
    }

    if (f.areas['10'] || f.areas['20'] || f.areas['30']) {
      // Stadt/Gemeinde
      if (f.areas['10']) {
        if (isRIWISCity(f.areas['10'].market_category)) {
          // addressLevel 15 für RIWIS-Städte mit Typ 10 (Hannover, Hanau,...)
          //  ==> kreisangehörige RIWIS Städte
          addressLevel = '15'
          districtRelatedRiwisCity = true
        }
        identifier = '10'
      } else if (f.areas['20']) {
        identifier = '20'
      } else {
        identifier = '30'
      }
      if (addressLevel === '') {
        addressLevel = identifier
      }
      if (displayedName === '') {
        displayedName = ortsArea.name
      }
    }
  }

  f.displayedName = displayedName
  f.identifier = identifier
  f.addressLevel = addressLevel
  f.districtRelatedRiwisCity = districtRelatedRiwisCity
  return f
}

export const setLocationSubmarket = (location, area, type) => {
  const preserveCoordinates = type === 'logisticsRegion'
  const newLocation = { ...location }
  const geometry = preserveCoordinates
    ? location.geometry
    : { coordinates: [area.centroid_x, area.centroid_y], type: 'Point' }

  newLocation.areas = Object.values(newLocation.areas).reduce((areas, area) => {
    if (parseInt(area.type) <= 100) {
      areas[area.type] = { ...area }
    }
    return areas
  }, {})

  newLocation.areas[area.type] = area
  newLocation.addressLevel = type === 'logisticsRegion' ? '200' : '5'
  newLocation.geometry = geometry
  newLocation.displayedName = area.name
  newLocation.submarketType = type

  return newLocation
}

export const isRIWISCity = (marketCategory) =>
  marketCategory === 'A' ||
  marketCategory === 'B' ||
  marketCategory === 'C' ||
  marketCategory === 'D' ||
  marketCategory === 'AT'

export const hasMarketCategory = (area) =>
  isRIWISCity(area.market_category) || area.market_data_regional === true

export const getMarketCategory = (area) =>
  isRIWISCity(area?.market_category) ? area?.market_category : area?.market_data_regional ? 'R' : '-'

export const findAreaWithMarketCategory = (feature) => {
  return Object.values(feature.areas).find((area) => hasMarketCategory(area))
}

export const findAreaByType = (feature, areaType) =>
  Object.values(feature.areas).find((area) => area.type === areaType)

export const findAreaByMarketCategory = (feature, marketCategory) => {
  if (isRIWISCity(marketCategory)) {
    return Object.values(feature.areas).find((area) => area.market_category === marketCategory)
  } else if (marketCategory === 'R') {
    return Object.values(feature.areas).find(
      (area) =>
        (area.market_data_regional === true || area.type === '30') && !isRIWISCity(area.market_category)
    )
  } else {
    new Error('function needs marketCategory A B C D or R as parameter')
  }
}

/** Used to clear the last usage of normalizr */
function normalizeAreas(areas) {
  const normalized = {}
  for (const area of areas) {
    normalized[area.type] = area
  }
  return normalized
}
