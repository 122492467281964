let memo = new Map()

const names = new Set()
const defaultOptions = {
  prefix: '',
  maxSize: Infinity,
  log: false,
}

/** Store the data in memory */
export const MemoryStorage = class MemoryStorage {
  constructor(options) {
    this.options = { ...defaultOptions, ...options }

    if (typeof this.options.prefix === 'undefined') {
      console.warn('A MemoryStorage needs a prefix. None was given')
    }

    if (names.has(this.options.prefix)) {
      console.warn(`A MemoryStorage with the prefix ${this.options.prefix} has already been created`)
    }
    names.add(this.options.prefix)

    this.lru = new Set()

    this.map = new Map()
    memo.set(this.options.prefix, this.map)

    this.meta = new Map()
  }

  get length() {
    return this.map.size
  }

  hasKey(key) {
    return this.map.has(key)
  }

  setItem(key, val) {
    this.lru.delete(key)
    this.lru.add(key)
    this.makeSpace()
    this.map.set(key, val)
    this.log('SET', key, val)
  }

  setItems(items) {
    items.forEach(({ key, val }) => {
      this.lru.delete(key)
      this.lru.add(key)
      this.map.set(key, val)
    })
    this.cleanup(items.length)
  }

  getItem(key) {
    const val = this.map.get(key)
    this.lru.delete(key)
    this.lru.add(key)
    this.log('GET', key, val)
    return typeof val !== 'undefined' ? val : null
  }

  removeItem(key) {
    this.lru.delete(key)
    this.map.delete(key)
    this.meta.delete(key)
    this.log('REMOVE', key)
  }

  removeItems(keys) {
    keys.forEach((key) => {
      this.removeItem()
    })
  }

  keys(asArray = false) {
    return asArray ? Array.from(this.map.keys()) : this.map.keys()
  }

  setMeta(metakey, key, val) {
    if (!this.meta.has(key)) {
      this.meta.set(key, new Map())
    }
    return this.meta.get(key).set(metakey, val)
  }

  getMeta(metakey, key = null) {
    if (key !== null) {
      return this.meta.get(key)?.get(metakey)
    } else {
      const result = new Map()
      for (let [key, val] of this.meta) {
        if (val.has(metakey)) {
          result.set(key, val.get(metakey))
        }
      }
      return result
    }
  }

  makeSpace() {
    if (this.options.maxSize === Infinity) return
    const amount = this.length + 1 - this.options.maxSize
    this.removeLRU(amount)
  }

  cleanup(minSize = 0) {
    if (this.options.maxSize === Infinity) return
    const amount = minSize > this.options.maxSize ? this.length - minSize : this.length - this.options.maxSize
    this.removeLRU(amount)
  }

  removeLRU(amount = 0) {
    if (amount > 0) {
      const iterator = this.lru.values()
      for (let i = 0; i < amount; i++) {
        const key = iterator.next().value
        this.removeItem(key)
      }
    }
  }

  clear() {
    this.lru = new Set()
    this.map = new Map()
    this.meta = new Map()
  }

  allData() {
    const data = {}
    for (let [key, value] of this.map.entries()) {
      data[key] = value
    }
    return data
  }

  log(action, key, value) {
    if (!this.options.log) return
    console.groupCollapsed(`Storage (${this.length}):`, action, key)
    console.log('value:   ', value)
    console.log('storage: ', this)
    console.log('keys:    ', Array.from(this.keys()))
    console.log('data:    ', this.allData())
    console.groupEnd()
  }
}
