import React from 'react'
import PropTypes from 'prop-types'

export const LogoRiwis = ({ size = '10em', color = undefined, ...other }) => {
  other.fill = color || '#00487A'
  return (
    <svg
      viewBox="0 0 250 261"
      height={size}
      // width={size}
      {...other}
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <g transform="matrix(1,0,0,1,0,-2.02084)">
        <g transform="matrix(4.16667,0,0,4.16667,0,0)">
          <path
            fill="rgb(5,107,124)"
            fillRule="non-zero"
            d="M51.521,21.962C51.521,12.374 44.361,0.485 26.974,0.485L0,0.485L13.92,15.059L25.696,15.059C31.833,15.059 34.901,18.511 34.901,23.113C34.901,26.565 33.111,28.61 29.915,31.807L19.122,43.061L38.225,63L59.831,63L41.166,43.568C48.581,35.769 51.521,31.295 51.521,21.962Z"
          />
          <path fill="rgb(196,218,88)" fillRule="non-zero" d="M0,0.485L29.915,31.807L0,63L0,0.485Z" />
        </g>
      </g>
    </svg>
  )
}

LogoRiwis.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
