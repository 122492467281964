export const getCheckboxBar = ({ spaces }) => ({
  items: {
    space: {
      compact: {
        x: spaces.rhythm.horizontal.tiny,
        y: spaces.rhythm.horizontal.tiny,
      },
      spread: {
        x: '18px',
        y: '9px',
      },
    },
  },
})
