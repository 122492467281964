import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { FormElement, withFormElementProps } from '@forms'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'
import { isFunction } from 'lib/util'
import { defaultProps } from '@forms/ComposedElement/defaultProps'
import { defaultPropTypes } from '@forms/ComposedElement/defaultPropsTypes'

const groupStateToValue = (state) => {
  const stateValue = state.value || {}
  const newValue = Object.keys(stateValue).reduce((data, key) => {
    let value = stateValue[key].value
    if (!value && 'checked' in stateValue[key]) {
      value = { checked: stateValue[key].checked }
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      value = Object.keys(value).reduce((attrs, key) => {
        attrs[key] = typeof value[key] === 'object' && 'value' in value[key] ? value[key].value : value[key]
        return attrs
      }, {})
    }
    data[key] = value
    return data
  }, {})

  return newValue
}

const valueToGroupState = (value) => {
  const newState = Object.keys(value).reduce((acc, key) => {
    let attribute = value[key]
    if ('checked' in attribute) {
      acc[key] = attribute
      return acc
    }
    if (typeof attribute === 'object' && !Array.isArray(attribute) && !('checked' in attribute)) {
      attribute = Object.keys(attribute).reduce((attrs, key) => {
        attrs[key] = { value: attribute[key] }
        return attrs
      }, {})
    }
    acc[key] = { value: attribute }
    return acc
  }, {})

  return newState
}

const ComposedElementComponent = ({
  defaultValue,
  formElementRefs,
  name,
  onChange,
  value,
  children,
  debounce, // default 100, set property to null to turn debounce off
  ...props
}) => {
  const initialValue = useMemo(() => defaultValue ?? value ?? {}, [value, defaultValue])
  const onDebouncedUpdate = useCallback(
    (value) => {
      isFunction(onChange) && onChange({ id: props.id, name, value })
    },
    [onChange, props.id, name]
  )

  const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
    value,
    onDebouncedUpdate,
    debounce
  )

  const [internalValue, setInternalValue] = useControllableState(
    initialValue,
    internalValueDebouncer,
    onControllableStateChange
  )

  const formState = useMemo(() => valueToGroupState(internalValue), [internalValue])

  const internalOnChange = useCallback(
    (target) => {
      setInternalValue(groupStateToValue(target))
    },
    [setInternalValue]
  )
  useEffect(() => {
    if (formElementRefs) {
      formElementRefs.value.current = internalValue
    }
  }, [internalValue, formElementRefs])

  return (
    <FormElement {...props} name={name} value={formState} onChange={internalOnChange}>
      {children}
    </FormElement>
  )
}

ComposedElementComponent.type = 'ComposedElement'
ComposedElementComponent.defaultProps = defaultProps
ComposedElementComponent.propTypes = defaultPropTypes

export const ComposedElement = withFormElementProps(memo(ComposedElementComponent))
