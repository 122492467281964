import { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { getThemeValue } from 'theming'

export const useButtonProgressbar = ({ progress, appearance }) => {
  const theme = useTheme()

  const style = useMemo(() => {
    if (typeof progress !== 'number') return null

    const borderColor = getThemeValue(theme, `uiActionElements.${appearance}.progress.borderColor`)
    const color = getThemeValue(theme, `uiActionElements.${appearance}.progress.color`)
    const defaultBg = getThemeValue(theme, `uiActionElements.${appearance}.default.bg`)
    const progressBg = getThemeValue(theme, `uiActionElements.${appearance}.progress.bg`)

    return {
      background: `linear-gradient(90deg, ${defaultBg} ${progress}%, ${progressBg} ${progress}% )`,
      borderColor,
      color,
    }
  }, [appearance, progress, theme])

  return style
}
