/**
 * Stores state about "add zone"-functionality
 */

import createStore from 'lib/flux-store'

const initialState = {
  isLoading: false,
  activeMethod: '',
  opts: {
    circle: { type: 'circle', center: null, radius: 750, feature: null },
    fzr: {
      type: 'fzr',
      center: null,
      range: [300],
      rangetype: 'time',
      mode: 'car',
      localFeatureCollection: null,
    },
    select: { type: 'select', layer: '_init', ids: [], names: [] },
    draw: { type: 'draw' },
    import: { json: {}, isValidGeoJSON: true },
    traveltime: {
      dayOfWeek: 1, // 0: sunday, 6: saturday
      hourOfDay: 7,
      travelTime: 1800,
      center: null,
      mode: 'departure',
      walkingTime: 900,
      range: 0,
      localFeatureCollection: null,
    },
  },
}

const actions = {
  setOpts: (method, opts) => {
    return { method, opts }
  },
  setActiveMethod: (activeMethod) => ({ activeMethod }),
  toggleSelectedId: (gid, name) => ({ gid, name }),
  setLoading: (isLoading) => ({ isLoading }),
}

const reducer = {
  setOpts: (state, { method, opts }) => {
    return {
      ...state,
      opts: { ...state.opts, [method]: { ...state.opts[method], ...opts } },
    }
  },
  toggleSelectedId: (state, { gid, name }) => {
    const oldIds = state.opts.select.ids
    const oldNames = state.opts.select.names

    const isActive = oldIds.includes(gid)

    let newIds
    let newNames
    if (!isActive) {
      newIds = [...oldIds, gid]
      newNames = [...oldNames, name]
    } else {
      const idx = oldIds.findIndex((el) => el === gid)
      newIds = [...oldIds.slice(0, idx), ...oldIds.slice(idx + 1)]
      newNames = [...oldNames.slice(0, idx), ...oldNames.slice(idx + 1)]
    }
    return {
      ...state,
      opts: {
        ...state.opts,
        select: {
          ...state.opts.select,
          ids: newIds,
          names: newNames,
        },
      },
    }
  },
  setActiveMethod: (state, { activeMethod }) => ({
    ...state,
    activeMethod: activeMethod,
  }),
  setLoading: (state, { isLoading }) => ({
    ...state,
    isLoading: isLoading,
  }),
}

const [GeotoolsDraftsContext, GeotoolsDraftsProvider, useGeotoolsDraftsStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'GeotoolsDraftsStore'
)

export { GeotoolsDraftsContext, GeotoolsDraftsProvider, useGeotoolsDraftsStore }
