/**
 * Returns a function that wraps the passed thenable function into a queue.
 * Calls to the function will be delayed while the thenable is not resolved.
 * Only the last one of the consecutive calls will be triggered afterwards.
 */

export function functionQueue(func) {
  let queuedArgs = null
  let isRunning = false

  const queued = (...args) => {
    if (isRunning) {
      queuedArgs = args
      return Promise.resolve()
    }
    isRunning = true
    return func(...args).finally(() => {
      isRunning = false
      if (queuedArgs) {
        args = [...queuedArgs]
        queuedArgs = null
        return queued(...args)
      }
    })
  }
  return queued
}
export default functionQueue
