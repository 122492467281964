import { useEffect, useState, useCallback } from 'react'

export const useKeyPressEvent = (
  targetKey,
  { active = true, once = false, stopPropagation = false, preventDefault = false } = {}
) => {
  const [keyPressEvent, setKeyPressEvent] = useState(false)

  const downHandler = useCallback(
    (event) => {
      const { code, key } = event
      if (code === targetKey || key === targetKey) {
        if (stopPropagation) {
          event.stopPropagation()
        }
        if (preventDefault) {
          event.preventDefault()
        }
        setKeyPressEvent(event)
      }
    },
    [targetKey, stopPropagation, preventDefault]
  )

  const upHandler = useCallback(
    (event) => {
      const { code, key } = event
      if (code === targetKey || key === targetKey) {
        if (stopPropagation) {
          event.stopPropagation()
        }
        if (preventDefault) {
          event.preventDefault()
        }
        setKeyPressEvent(false)
      }
    },
    [targetKey, stopPropagation, preventDefault]
  )

  useEffect(() => {
    if (active) {
      // third parameter on addEventListener is true to useCapture instead of bubbling,
      // so last added listener will be handled first.
      // that allows usage of stopPropagation to prevent "parent" elements from closing
      // on escape (e.g. subpermission modal on users licence edit closes UserEdit component
      // as well, if useCapture not true)
      window.addEventListener('keydown', downHandler, true)
      window.addEventListener('keyup', upHandler, true)
    } else {
      window.removeEventListener('keydown', downHandler, true)
      window.removeEventListener('keyup', upHandler, true)
    }

    return () => {
      window.removeEventListener('keydown', downHandler, true)
      window.removeEventListener('keyup', upHandler, true)
    }
  }, [active, downHandler, upHandler])

  useEffect(() => {
    if (once && keyPressEvent !== false) {
      setKeyPressEvent(false)
    }
  }, [once, keyPressEvent])

  return keyPressEvent
}
