import React from 'react'
import { FlexContainer, FlexItem } from '@layout/BuildingBlocks'
import { typeScale, typeStyle } from '@typography'
import styled from 'styled-components'
import { typography } from 'styled-system'
import { appearance } from 'stories/typography/ContentElements/TitleValue/variants'
import { themeGet } from '@styled-system/theme-get'
import { string } from 'prop-types'

const StyledContainer = styled(FlexContainer)`
  ${typography}
  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
  ${appearance}
  & .title {
    font-size: 12.83px !important;
    line-height: 15.04px;
    font-weight: 300;
    color: ${themeGet('colors.base.gray.600', 'gray')};
  }
  & .value {
    & a {
      text-decoration: none;
      color: ${themeGet('colors.base.brand.primary.lighten', 'blue')};
      cursor: pointer;
    }
  }
`

export const TitleValue = ({
  children,
  appearance,
  title,
  value,
  direction = 'column',
  href = null,
  ...rest
}) => {
  return (
    <StyledContainer
      flexDirection={direction}
      flexRowGap="2px"
      flexColumnGap="5px"
      alignItems="baseline"
      appearance={appearance}
      {...rest}
    >
      <FlexItem className="title">{title}</FlexItem>
      <FlexItem className="value">
        {href ? (
          <a href={href} target="_blank" rel="noreferrer">
            {value}
          </a>
        ) : (
          value
        )}
      </FlexItem>
    </StyledContainer>
  )
}

TitleValue.propTypes = {
  title: string,
  value: string,
  direction: string,
  href: string,
  appearance: string,
}

TitleValue.defaultProps = {
  appearance: 'default',
  direction: 'column',
  href: null,
}
