import React from 'react'

import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { getThemeValue } from 'theming'

import { ButtonLink } from '@ui/Buttons'

import { CopyMedium } from 'stories/typography'

export const SetPasswordInvalidToken = ({ type, failedValidatingToken }) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const colors = getThemeValue(theme, 'colors')

  if (failedValidatingToken) {
    return (
      <CopyMedium tag="p" color={colors.base.warning.default}>
        {t('setPassword.failedValidatingToken')}
      </CopyMedium>
    )
  }

  return (
    <div>
      <CopyMedium tag="p" color={colors.base.warning.default}>
        {t('setPassword.' + type + '.tokenInvalid')}
      </CopyMedium>
      {type === 'invite' && (
        <>
          <CopyMedium tag="p">{t('setPassword.invite.gotoLogin')}</CopyMedium>
          <ButtonLink
            linkType="reactRouterDomLink"
            appearance="bare"
            to="/"
            text={t('setPassword.invite.btnLogin')}
            stretch={true}
          />
        </>
      )}
      {type === 'reset' && (
        <ButtonLink
          linkType="reactRouterDomLink"
          appearance="bare"
          to="/forgotPassword"
          text={t('setPassword.reset.linkToForgotPassword')}
          stretch={true}
        />
      )}
    </div>
  )
}
