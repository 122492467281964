import React, {
  memo,
  forwardRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
  Fragment,
  isValidElement,
  cloneElement,
} from 'react'
import styled from 'styled-components'
import { hideVisually } from 'polished'

import { isUndefined, isArray, isFunction } from 'lib/util'
import { useControllableState, useDebouncedUpdates } from 'lib/hooks'

import { withFormElementProps } from '@forms'

import { Default } from './SubComponents/Default'
import { defaultProps } from './defaultProps'

const RadioHtml = styled.input.attrs({ type: 'radio' })(() => ({
  ...hideVisually(),
}))

const RadioComponent = forwardRef(
  (
    {
      checked,
      defaultChecked,
      disabled,
      value,
      onChange,
      children,
      title,
      label,
      formElementRefs,
      name,
      debounce,
      labelStyle,
      groupedView,
      firstItem,
      lastItem,
      ...props
    },
    ref
  ) => {
    const [initialChecked] = useState(defaultChecked ?? checked ?? false)

    const onDebouncedUpdate = useCallback(
      (checked) => {
        isFunction(onChange) && onChange({ id: props.id, name, value, checked })
      },
      [onChange, props.id, name, value]
    )

    const [internalValueDebouncer, onControllableStateChange] = useDebouncedUpdates(
      checked,
      onDebouncedUpdate,
      debounce
    )

    const [localChecked, setChecked] = useControllableState(
      initialChecked,
      internalValueDebouncer,
      onControllableStateChange
    )

    const internalOnChange = useCallback(
      (evt) => {
        setChecked(evt.target.checked)
      },
      [setChecked]
    )

    const onReset = useCallback(() => {
      setChecked(false)
    }, [setChecked])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.reset.current = onReset
      }
    }, [onReset, formElementRefs])

    useEffect(() => {
      if (formElementRefs) {
        formElementRefs.value.current = localChecked ? value : null
      }
    }, [localChecked, formElementRefs, value])

    const localLabelStyle = useMemo(() => {
      return {
        cursor: disabled ? 'auto' : 'pointer',
        marginBottom: 0,
        display: 'inline-block',
        position: 'relative',
        ...labelStyle,
      }
    }, [disabled, labelStyle])

    const childProps = useMemo(() => {
      if (groupedView) {
        return { ...props, checked: localChecked, disabled: disabled, firstItem, lastItem, groupedView }
      }
      return { ...props, checked: localChecked, disabled: disabled }
    }, [props, localChecked, disabled, firstItem, lastItem, groupedView])

    const childrenArray = isUndefined(children) ? [] : !isArray(children) ? [children] : children
    if (!childrenArray.length) {
      childrenArray.push(<Default key="0" {...childProps} label={label ?? ''} />)
    }

    return (
      <label style={localLabelStyle} title={title}>
        <RadioHtml
          name={name}
          checked={localChecked}
          value={value}
          disabled={disabled}
          onChange={internalOnChange}
          ref={ref}
          tabIndex="-1"
        />
        {childrenArray.map((child, idx) => {
          return (
            <Fragment key={idx}>
              {isFunction(child)
                ? child(childProps)
                : isValidElement(child)
                ? cloneElement(child, childProps)
                : child}
            </Fragment>
          )
        })}
      </label>
    )
  }
)
RadioComponent.type = 'Radio'
RadioComponent.defaultProps = defaultProps

export const Radio = withFormElementProps(memo(RadioComponent))
