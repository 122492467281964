const spaces = [
  '3px',
  '5px',
  '6px',
  '8px',
  '10px',
  '12px',
  '13px',
  '15px',
  '18px',
  '20px',
  '21px',
  '34px',
  '40px',
]

const verticalRhythm = {
  nano: spaces[0], // 3px
  tiny: spaces[1], // 5px
  small: spaces[3], // 8px
  medium: spaces[6], // 13px
  large: spaces[10], // 21px
  extraLarge: spaces[11], // 34px
}

const horizontalRhythm = {
  nano: spaces[0], // 3px
  tiny: spaces[1], // 5px
  small: spaces[3], // 8px
  medium: spaces[6], // 13px
  large: spaces[10], // 21px
  extraLarge: spaces[11], // 34px
}

const paddings = {
  nanoPaddingTop: ['1px', null, null, null, '3px'],
  nanoPaddingBottom: ['1px', null, null, null, '3px'],
  nanoPaddingX: ['4px', null, null, null, '7px'],
  nanoPaddingXOval: ['6px', null, null, null, '11px'],
  tinyPaddingTop: ['3px', null, null, null, '5px'],
  tinyPaddingBottom: ['3px', null, null, null, '5px'],
  tinyPaddingX: ['6px', null, null, null, '9px'],
  tinyPaddingXOval: ['8px', null, null, null, '13px'],
  smallPaddingTop: ['5px', null, null, null, '7px'],
  smallPaddingBottom: ['5px', null, null, null, '7px'],
  smallPaddingX: ['6px', null, null, null, '9px'],
  smallPaddingXOval: ['11px', null, null, null, '13px'],
  mediumPaddingTop: ['8px', null, null, null, '9px'],
  mediumPaddingBottom: ['8px', null, null, null, '9px'],
  mediumPaddingX: ['6px', null, null, null, '9px'],
  mediumPaddingXOval: ['15px', null, null, null, '17px'],
  largePaddingTop: ['10px', null, null, null, '12px'],
  largePaddingBottom: ['10px', null, null, null, '12px'],
  largePaddingX: ['6px', null, null, null, '9px'],
  largePaddingXOval: ['15px', null, null, null, '17px'],
}

const gaps = {
  column: [null, null, horizontalRhythm.tiny, horizontalRhythm.large, horizontalRhythm.extraLarge],
  row: [null, null, verticalRhythm.tiny, verticalRhythm.large, verticalRhythm.extraLarge],
  controlsPanel: horizontalRhythm.large,
}

// default gaps for grid component
const gridGaps = {
  column: [horizontalRhythm.tiny, horizontalRhythm.small],
  row: [verticalRhythm.tiny, verticalRhythm.small],
}

spaces.badgeX = spaces[4]
spaces.badgeY = spaces[3]

spaces.switchIcon = spaces[1]

spaces.labelIconGap = spaces[2]

spaces.controlsGroupX = spaces[2]

spaces.paddings = paddings

spaces.gaps = gaps
spaces.gridGaps = gridGaps
//  rhythm - uses of fibonacci series (3, 5, 8, 13, 21, 34 [... 55, 89, 144 ...])
spaces.rhythm = {}
spaces.rhythm.vertical = { ...verticalRhythm }
spaces.rhythm.horizontal = { ...horizontalRhythm }

export { spaces }
