import React from 'react'
import styled, { useTheme } from 'styled-components'
import { layout, space } from 'styled-system'

import { getThemeValue } from 'theming'
import { defaultProps } from './defaultProps'
import {
  fill,
  stroke,
  strokeWidth,
  innerFill,
  innerStroke,
  innerStrokeWidth,
  width,
  height,
} from './defaultPropTypes'

const DiscSizer = styled.span.withConfig({
  shouldForwardProp: (props) => ['children'].includes(props),
})`
  display: block;
  ${layout}
  ${space}
`

const DiscSVG = ({ fill, stroke, strokeWidth, innerFill, innerStroke, innerStrokeWidth }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100%" height="100%">
    <circle
      shapeRendering="geometricPrecision"
      cx="12"
      cy="12"
      r="11"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
    {innerFill !== null && (
      <circle
        shapeRendering="geometricPrecision"
        cx="12"
        cy="12"
        r="6"
        fill={innerFill}
        stroke={innerStroke}
        strokeWidth={innerStrokeWidth}
      />
    )}
  </svg>
)

export const Disc = ({
  fill,
  stroke,
  strokeWidth,
  innerFill,
  innerStroke,
  innerStrokeWidth,
  height,
  width,
  ...rest
}) => {
  const theme = useTheme()

  const isColorValue = (str) => {
    const patterns = ['rgb', 'rgba', 'hsl', '#']

    return patterns.some((el) => {
      return str.match(new RegExp(el, 'g'))
    })
  }

  const svgFill = !isColorValue(fill) ? getThemeValue(theme, fill) : fill
  const svgStroke = !isColorValue(stroke) ? getThemeValue(theme, stroke) : stroke
  const svgInnerFill =
    innerFill !== null ? (!isColorValue(innerFill) ? getThemeValue(theme, innerFill) : innerFill) : null
  const svgInnerStroke =
    innerStroke !== null
      ? !isColorValue(innerStroke)
        ? getThemeValue(theme, innerStroke)
        : innerStroke
      : null

  return (
    <DiscSizer width={width} height={height} {...rest}>
      <DiscSVG
        fill={svgFill}
        stroke={svgStroke}
        strokeWidth={strokeWidth}
        innerFill={svgInnerFill}
        innerStroke={svgInnerStroke}
        innerStrokeWidth={innerStrokeWidth}
      />
    </DiscSizer>
  )
}

Disc.propTypes = {
  fill,
  stroke,
  strokeWidth,
  innerFill,
  innerStroke,
  innerStrokeWidth,
  width,
  height,
}

Disc.defaultProps = defaultProps
