import React from 'react'
import { IconWrapper } from '@utilities/IconWrapper'
import { MarketAnalysisIcon } from './SubComponents/MarketAnalysisIcon'
import { MakroIcon } from './SubComponents/MakroIcon'
import { MarketsIcon } from './SubComponents/MarketsIcon'
import { RealEstateObjectsIcon } from './SubComponents/RealEstateObjectsIcon'
import { AppsIcon } from './SubComponents/AppsIcon'
import { DownloadIcon } from './SubComponents/DownloadIcon'
import { DiscoIcon } from './SubComponents/DiscoIcon'
import { RetailMarketsIcon } from './SubComponents/RetailMarketsIcon'
import { L3PlusIcon } from './SubComponents/L3PlusIcon'
import { ProspectIcon } from './SubComponents/ProspectIcon'
import { DeveloperIcon } from './SubComponents/DeveloperIcon'
import { HighstreetReportIcon } from './SubComponents/HighstreetReportIcon'
import { DragnetInvestigationIcon } from './SubComponents/DragnetInvestigation'
import { ESGIcon } from './SubComponents/ESGIcon'
import { OfficeIcon } from './SubComponents/OfficeIcon'
import { ResidentialIcon } from './SubComponents/ResidentialIcon'
import { RetailIcon } from './SubComponents/RetailIcon'
import { IndustryIcon } from './SubComponents/IndustryIcon'
import { HotelIcon } from './SubComponents/HotelIcon'
import { SeniorLivingIcon } from './SubComponents/SeniorLivingIcon'
import { MarketTextsIcon } from './SubComponents/MarketTextsIcon'
import { PortfolioIcon } from './SubComponents/PortfolioIcon'
import { HeadlineIcon } from './SubComponents/HeadlineIcon'
import { BookletIcon } from './SubComponents/BookletIcon'
import { CalendarIcon } from './SubComponents/CalendarIcon'
import { InformationIcon } from './SubComponents/InformationIcon'
import { HandshakeIcon } from './SubComponents/HandshakeIcon'
import { TelescopeIcon } from './SubComponents/TelescopeIcon'

export const BudiconsWithBackgroundCircle = ({
  name,
  iconSettings = {},
  iconWrapper,
  backgroundColor,
  shadow,
  iconVariantSourceName,
  iconVariantSize,
}) => {
  let Icon = null
  if (name === 'MarketAnalysis') {
    Icon = MarketAnalysisIcon
  } else if (name === 'Markets') {
    Icon = MarketsIcon
  } else if (name === 'Objects') {
    Icon = RealEstateObjectsIcon
  } else if (name === 'Makro') {
    Icon = MakroIcon
  } else if (name === 'Apps') {
    Icon = AppsIcon
  } else if (name === 'Download') {
    Icon = DownloadIcon
  } else if (name === 'Disco') {
    Icon = DiscoIcon
  } else if (name === 'RetailMarkets') {
    Icon = RetailMarketsIcon
  } else if (name === 'L3Plus') {
    Icon = L3PlusIcon
  } else if (name === 'Prospect') {
    Icon = ProspectIcon
  } else if (name === 'Developer') {
    Icon = DeveloperIcon
  } else if (name === 'HighstreetReport') {
    Icon = HighstreetReportIcon
  } else if (name === 'DragnetInvestigation') {
    return (Icon = DragnetInvestigationIcon)
  } else if (name === 'ESG') {
    Icon = ESGIcon
  } else if (name === 'Office') {
    Icon = OfficeIcon
  } else if (name === 'Residential') {
    Icon = ResidentialIcon
  } else if (name === 'Retail') {
    Icon = RetailIcon
  } else if (name === 'Industry') {
    Icon = IndustryIcon
  } else if (name === 'Hotel') {
    Icon = HotelIcon
  } else if (name === 'SeniorLiving') {
    Icon = SeniorLivingIcon
  } else if (name === 'MarketTexts') {
    Icon = MarketTextsIcon
  } else if (name === 'Portfolio') {
    Icon = PortfolioIcon
  } else if (name === 'Headline') {
    Icon = HeadlineIcon
  } else if (name === 'Booklet') {
    Icon = BookletIcon
  } else if (name === 'Calendar') {
    Icon = CalendarIcon
  } else if (name === 'Information') {
    Icon = InformationIcon
  } else if (name === 'Handshake') {
    Icon = HandshakeIcon
  } else if (name === 'Telescope') {
    Icon = TelescopeIcon
  } else {
    Icon = () => <span>404</span>
  }

  return (
    <IconWrapper
      iconVariantSourceName={iconVariantSourceName}
      iconVariantSize={iconVariantSize}
      icon={{
        Icon: ({ ...props }) => <Icon {...props} backgroundColor={backgroundColor} shadow={shadow} />,
        ...iconSettings,
      }}
      iconWrapper={iconWrapper}
    />
  )
}
