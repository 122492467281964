import React from 'react'
import styled, { useTheme } from 'styled-components'
import { space } from 'styled-system'

import { RoyalMedium } from '@typography'
import { getThemeValue } from 'theming'

const StyledText = styled(RoyalMedium)`
  ${space}
`

const StyledComponent = styled.div`
  ${space}
`

export const ModalHeadlineTextComponent = ({ children, ...props }) => {
  const theme = useTheme()
  const spaceX = getThemeValue(theme, 'modal.body.space.x')
  const spaceBottom = getThemeValue(theme, 'modal.headline.space.bottom')
  const spaceTop = getThemeValue(theme, 'modal.headline.space.top')

  return (
    <StyledText tag="h3" {...props} px={spaceX} ml="-2px" mt={spaceTop} mb={spaceBottom}>
      {children}
    </StyledText>
  )
}

export const ModalHeadline = ({ children, ...props }) => {
  const theme = useTheme()
  const spaceX = getThemeValue(theme, 'modal.body.space.x')
  const spaceBottom = getThemeValue(theme, 'modal.headline.space.bottom')
  const spaceTop = getThemeValue(theme, 'modal.headline.space.top')

  return typeof children === 'string' ? (
    <StyledText tag="h3" {...props} px={spaceX} ml="-2px" my="0" pt={spaceTop} pb={spaceBottom}>
      {children}
    </StyledText>
  ) : (
    <StyledComponent {...props} px={spaceX} ml="-2px" my="0" pt={spaceTop} pb={spaceBottom}>
      {children}
    </StyledComponent>
  )
}

ModalHeadline.displayName = 'modalHeader'
