import { string, bool, element } from 'prop-types'

const appearance = string.isRequired
const borderRadius = string.isRequired
const direction = string.isRequired
const disabled = bool
const forElementName = string.isRequired
const name = string.isRequired
const buttonDown = element.isRequired
const buttonUp = element.isRequired

export { appearance, borderRadius, direction, disabled, forElementName, name, buttonDown, buttonUp }
