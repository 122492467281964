import React from 'react'
import styled from 'styled-components'
import { color, typography, space, compose } from 'styled-system'

import {
  defaultProps as textProps,
  defaultPropTypes as textPropTypes,
  typeScale,
  typeStyle,
} from '@typography'

import { string } from 'prop-types'

const excludeProps = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'tag',
  'scale',
  'typeScale',
  'typeStyle',
  'marginBottom',
]

const StyledCell = styled.td
  .attrs((props) => ({
    colSpan: props.span,
  }))
  .withConfig({
    shouldForwardProp: (props) => !excludeProps.includes(props),
  })`
  ${compose(color, typography, space)};
  ${(props) => typeScale(props.typeScale)};
  ${(props) => typeStyle(props.typeStyle)};
  span {
    padding: 0 2px 0;
  }
`

export const Cell = ({ align, span, text, typeScale, typeStyle }) => (
  <StyledCell align={align} typeScale={typeScale} typeStyle={typeStyle} span={span}>
    <span>{text}</span>
  </StyledCell>
)

Cell.propTypes = {
  typeScale: string,
  typeStyle: string,
}

Cell.defaultProps = {
  typeScale: 'copy',
  typeStyle: 'copy',
}

StyledCell.propTypes = {
  ...textPropTypes,
}

StyledCell.defaultProps = {
  ...textProps,
}
