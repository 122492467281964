import api from 'stores/api'
import { createDataCache } from 'lib/cache'

const getterMarketDataTopics = () => {
  return api.Config.topics(true).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsTopicDefinitionList = [{ name: 'language' }, { name: 'countryCodes' }]

const getterTopicDefinitionList = (language, countryCodes) => {
  return api.TopicDefinitions.getTopicDefinitionList(language, countryCodes).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsTopicDefinition = [
  { name: 'id' },
  { name: 'topicId' },
  { name: 'language' },
  { name: 'countryCodes' },
  { name: 'topicHeading' },
  { name: 'ignoreStandalone' },
]

const getterTopicDefinitions = (id = null, topicId, language, countryCodes, ignoreStandalone = false) => {
  return api.TopicDefinitions.getTopicDefinitions(id, topicId, language, countryCodes, ignoreStandalone).then(
    (result) => {
      if (!(result.data.status && result.data.status === 'success')) {
        throw new Error(result.data.messages)
      }
      return result.data.data
    }
  )
}

export const [getMarketDataTopics, bustMarketDataTopics] = createDataCache([], getterMarketDataTopics, {
  name: 'GlossarMarketDataTopics',
})

export const [getTopicDefinitionList, bustTopicDefinitionList] = createDataCache(
  conditionalsTopicDefinitionList,
  getterTopicDefinitionList,
  {
    name: 'TopicDefinitionList',
  }
)

export const [getTopicDefinitions, bustTopicDefinitions] = createDataCache(
  conditionalsTopicDefinition,
  getterTopicDefinitions,
  {
    name: 'TopicDefinitions',
  }
)
