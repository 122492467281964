import React, { memo } from 'react'
import styled from 'styled-components'
import { layout } from 'styled-system'

const StyledComponent = styled.main.withConfig({
  shouldForwardProp: (prop) => ['children', 'id'].includes(prop),
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${(props) => (props.height && { height: props.height }) || { height: '100vh' }};
  width: 100vw;
  padding: 0px;
  ${layout}
`

export const MainStage = memo(({ children, height }) => {
  return (
    <StyledComponent height={height} id="main-stage" overflowY="hidden" maxHeight="inherit">
      {children}
    </StyledComponent>
  )
})

MainStage.propTypes = {}

MainStage.defaultProps = {}
