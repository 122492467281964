import styled from 'styled-components'
import { space, layout } from 'styled-system'

export const Progress = styled.progress`
  width: 100%;
  vertical-align: bottom;
  height: 24px;

  accent-color: ${(props) => props.theme.colors.theme.dark.default.bg};
  ${space}
  ${layout}
`
