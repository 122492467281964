import React, { useCallback } from 'react'
import { ButtonIcon } from '@ui/Buttons'
import { isFunction } from 'lib/util'
import { BudiconCrossUi } from 'bgag-budicons'

export const DeleteButton = ({ locationId, onClick, ...props }) => {
  const handleOnClick = useCallback(() => {
    isFunction(onClick) && onClick(locationId)
  }, [locationId, onClick])

  return (
    <ButtonIcon
      icon={{ Icon: BudiconCrossUi, type: 'shady', width: props.iconWidth }}
      onClick={handleOnClick}
      {...props}
    />
  )
}
