import React, { Suspense, useEffect, useRef, useState } from 'react'

import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { useLoadAllTranslations } from 'i18n'

import { useAuthzChecks } from 'lib/hooks'
import { useWebtrackingParams } from 'lib/hooks/useWebtrackingParams'
import { getSsoStatus, ssoLogout } from 'lib/sso'

import { useAppStore } from 'stores/AppStore'
import { useAuthStore } from 'stores/AuthStore'
import api from 'stores/api'

export const PrivateAccessGuard = ({ Component }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { hasRouteAccess, getDefaultRoute } = useAuthzChecks()
  useWebtrackingParams()

  const [isLoggedIn, setIsLoggedIn] = useState(null)

  const [currentUser, pullCurrentUser] = useAuthStore((state, actions) => [
    state.currentUser,
    actions.pullCurrentUser,
  ])
  const [searchString, setSearchString] = useState(location.search)
  const [loadAppState, setLastRoute, isLoadingAppState] = useAppStore((state, actions) => [
    actions.loadState,
    actions.setLastRoute,
    state.isLoading,
  ])
  const userEmail = useRef(null)

  useEffect(() => {
    if (currentUser && typeof currentUser === 'object') {
      userEmail.current = currentUser.data.email
    }
  }, [currentUser])

  useEffect(() => {
    if (!location.pathname.startsWith('/logout')) {
      getSsoStatus(currentUser, navigate)
    }
  }, [currentUser, navigate, location.pathname])

  useEffect(() => {
    let detectedToken = searchString.match(/(?:&|\?)(token=[^&]*)/)
    let detectedL3Token = searchString.match(/(?:&|\?)(l3token=[^&]*)/)
    if (detectedToken !== null) {
      detectedToken = detectedToken[1].replace(/^token=/, '')
      api.Auth.loginWithToken(detectedToken).finally(() => {
        setSearchString((s) => s.replace(/(&|\?)(token=[^&]*&?)/, '$1'))
      })
    } else if (detectedL3Token !== null) {
      detectedToken = detectedL3Token[1].replace(/^l3token=/, '')
      api.Auth.loginWithL3Token(detectedToken).finally(() => {
        setSearchString((s) => s.replace(/(&|\?)(l3token=[^&]*&?)/, '$1'))
      })
    } else {
      if (currentUser === 'init' && isLoggedIn === null) {
        api.Auth.isLoggedIn().then((isLoggedIn) => {
          setIsLoggedIn(isLoggedIn.data.data)
        })
      }
      if (currentUser === 'init' && isLoggedIn) {
        pullCurrentUser()
        loadAppState()
      }
    }
  }, [isLoggedIn, searchString, currentUser, pullCurrentUser, loadAppState])

  useEffect(() => {
    if (currentUser !== 'init' && currentUser && !isLoadingAppState) {
      const doRefresh = !location.pathname.startsWith('/logout')
      const doSetLastRoute =
        !location.pathname.startsWith('/home') &&
        !location.pathname.startsWith('/logout') &&
        !location.pathname.startsWith('/admin')

      if (doSetLastRoute) {
        setLastRoute(location.pathname)
      }
      if (doRefresh) {
        const now = Date.now()
        // localStorage lastRefreshToken gets set in src/stores/api axios-interceptors
        const lastRefreshToken = parseInt(localStorage.getItem('lastRefreshToken') || 0)
        if (now - lastRefreshToken > 20 * 60 * 1000 /* refresh every 20 minutes */) {
          api.Auth.refresh()
        }
      }
    }
  }, [location.pathname, setLastRoute, currentUser, isLoadingAppState])

  const waitingForSsoLogout = useRef(false)
  const [logoutBySso, setLogoutBySso] = useState(null)

  if (currentUser !== 'init' && !currentUser) {
    if (!waitingForSsoLogout.current) {
      waitingForSsoLogout.current = true
      const isRedirecting = ssoLogout(userEmail.current)
      setLogoutBySso(isRedirecting)
    }
  }

  if (logoutBySso !== null) {
    waitingForSsoLogout.current = false
  }

  const waitingForLoginChecks =
    waitingForSsoLogout.current || (currentUser === 'init' && isLoggedIn !== false) || logoutBySso
      ? true
      : false

  const loginSuccessfull = currentUser !== 'init' && currentUser ? true : false

  useLoadAllTranslations({ start: !waitingForLoginChecks && loginSuccessfull })

  if (waitingForLoginChecks) {
    return null
  } else if (loginSuccessfull) {
    return hasRouteAccess(location.pathname) ? (
      <Component />
    ) : (
      <Navigate replace to={getDefaultRoute()} state={{ error: 'noRouteAccess' }} />
    )
  } else {
    return <Navigate replace to="/" state={{ from: location }} />
  }
}

export const PrivateAccessSuspenseGuard = ({ Component, FallBackComponent }) => {
  return (
    <Suspense fallback={<FallBackComponent />}>
      <PrivateAccessGuard Component={Component}></PrivateAccessGuard>
    </Suspense>
  )
}
