import React, { useCallback, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { layout, space } from 'styled-system'

import { BudiconChevronLeft, BudiconChevronRight } from 'bgag-budicons'

import { useOverflowObserver } from 'lib/hooks'
import { useSlots } from 'lib/hooks/useSlots'

import { FlexRow } from '@layout/BuildingBlocks'
import { ButtonIcon } from '@ui/Buttons'

const CarouselWrapper = styled.div`
  overflow: hidden;
  min-width: ${({ minWidth }) => minWidth};
  display: flex;
  align-self: ${(props) => props.alignSelf};
  flex-direction: row;
`

const CarouselContent = styled.div`
  position: relative;
  will-change: transform;
  display: flex;
  flex: 1;
  column-gap: ${(props) => props.columnGap};
  width: 100%;
  transition-property: transform;
  transition-timing-property: ease-out;
`

const LeftSlot = styled.div`
  display: flex;
  ${space};
  ${layout};
  position: ${({ hasOverflow }) => (hasOverflow ? 'relative' : 'absolute')};
  top: ${({ hasOverflow }) => (hasOverflow ? 'unset' : '-10000px')};
`
const RightSlot = styled.div`
  display: flex;
  ${space};
  ${layout};
  position: ${({ hasOverflow }) => (hasOverflow ? 'relative' : 'absolute')};
  top: ${({ hasOverflow }) => (hasOverflow ? 'unset' : '-10000px')};
`
const columnGap = '6px'

export const Carousel = ({ minWidth, children, ...props }) => {
  const carouselWrapperRef = useRef()
  const carouselContentRef = useRef()
  const leftSlotRef = useRef()
  const rightSlotRef = useRef()
  const [isMouseDown, setIsMouseDown] = useState(null)
  const [mouseMovement, setMouseMovement] = useState(0)
  const [mouseDownStart, setMouseDownStart] = useState(null)
  const [touchStartPoint, setTouchStartPoint] = useState(0)
  const [interpretAsGesture, setInterpretAsGesture] = useState(false)
  const [mouseDistanceTraveled, setMouseDistanceTraveled] = useState(0)
  const isTransforming = useRef()
  const [hasOverflow, setHasOverflow] = useState(false)
  const [, hasOverFlowFromObserver] = useOverflowObserver(carouselContentRef)
  const [leftSlot, rightSlot, slides] = useSlots(['leftSlot', 'rightSlot', 'slides'], children)
  const [, setSlidesCount] = useState(slides?.props?.children?.length || 0)

  const transformTo = useCallback((position) => {
    if (carouselContentRef.current) {
      carouselContentRef.current.style.transform = `translate(${position}px, 0px)`
    }
  }, [])

  const animateTransform = useCallback((duration = 300) => {
    if (carouselContentRef.current) {
      carouselContentRef.current.style.transitionDuration = `${duration}ms`
      // setIsTransforming(true)
      isTransforming.current = true
      // onTransitionEnd will be triggered afterwards to set transitionDuration = 0ms
      // and isTransforming.current = false
    }
  }, [])

  const handleClick = useCallback(
    (event) => {
      if (interpretAsGesture) {
        event.preventDefault()
        setInterpretAsGesture(false)
      }
    },
    [interpretAsGesture]
  )

  const handleMouseMove = useCallback(
    (e) => {
      let mouseMovement
      setMouseMovement((prevState) => {
        mouseMovement = e.movementX + prevState
        return mouseMovement
      })
      transformTo(mouseMovement)
      setMouseDistanceTraveled((prevState) => Math.abs(e.movementX) + prevState)
    },
    [transformTo]
  )

  const handleMouseUp = useCallback((e) => setIsMouseDown(false), [])

  const handleMouseDown = useCallback(() => {
    isTransforming.current = false
    setIsMouseDown(true)
    setMouseDownStart(Date.now())
    if (hasOverflow) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseMove, handleMouseUp, hasOverflow])

  const handleTouchStart = useCallback(
    (e) => {
      isTransforming.current = false
      setTouchStartPoint(e.changedTouches[0].pageX - mouseMovement)
    },
    [mouseMovement]
  )

  const handleTouchMove = useCallback(
    (e) => {
      if (hasOverflow) {
        const newMouseMovement = Math.ceil(e.changedTouches[0].pageX - touchStartPoint)
        setMouseMovement(newMouseMovement)

        setMouseDistanceTraveled(mouseDistanceTraveled + Math.abs(newMouseMovement))
        transformTo(newMouseMovement)
      }
    },
    [hasOverflow, touchStartPoint, mouseDistanceTraveled, transformTo]
  )

  const handleMouseLeave = useCallback(() => {
    setMouseDistanceTraveled(0)
    setIsMouseDown(false)
    if (mouseMovement > 0 && !isTransforming.current) {
      setMouseMovement(0)
      transformTo(0)
      animateTransform(300)
    }

    if (
      carouselWrapperRef.current &&
      carouselContentRef.current &&
      carouselWrapperRef.current.scrollWidth - carouselContentRef.current.scrollWidth > mouseMovement &&
      !isTransforming.current
    ) {
      const position = carouselWrapperRef.current.scrollWidth - carouselContentRef.current.scrollWidth
      setMouseMovement(position)
      transformTo(position)
      animateTransform(300)
    }
  }, [mouseMovement, animateTransform, transformTo])

  const handleLeftSlotClick = useCallback(() => {
    const scrollToElement =
      Array.from(carouselContentRef.current.children)
        .reverse()
        .find((child) => {
          return child.offsetLeft < Math.abs(mouseMovement)
        }) || carouselContentRef.current.firstChild

    const position = -Math.round(scrollToElement.offsetLeft)

    if (mouseMovement < 0) {
      setMouseMovement(position)
      transformTo(position)
      animateTransform(300)
    }
  }, [animateTransform, mouseMovement, transformTo])

  const handleRightSlotClick = useCallback(() => {
    const { width } = carouselContentRef.current.getBoundingClientRect()
    const scrollToElement =
      Array.from(carouselContentRef.current.children).find(
        (child) =>
          Math.round(child.offsetLeft - Math.abs(mouseMovement) + child.getBoundingClientRect().width) >
          Math.round(width)
      ) || carouselContentRef.current.lastChild

    const scrollToElementOffsetRight =
      scrollToElement.offsetLeft + scrollToElement.getBoundingClientRect().width

    const position = Math.round(width - scrollToElementOffsetRight)

    if (position < mouseMovement) {
      setMouseMovement(position)
      transformTo(position)
      animateTransform(300)
    }
  }, [animateTransform, transformTo, mouseMovement])

  useEffect(() => {
    if (hasOverflow === false) {
      setMouseMovement(0)
      transformTo(0)
      animateTransform(1000)
    }
  }, [hasOverflow, animateTransform, transformTo])

  const onTransitionEnd = useCallback(() => {
    carouselContentRef.current.style.transitionDuration = `0ms`
    // setIsTransforming(false)
    isTransforming.current = false
  }, [])

  useEffect(() => {
    const carouselContent = carouselContentRef?.current || null
    carouselContent && carouselContent.addEventListener('transitionend', onTransitionEnd)
    return () => {
      carouselContent && carouselContent.removeEventListener('transitionend', onTransitionEnd)
    }
  }, [onTransitionEnd])

  // onmouseup
  useEffect(() => {
    if (isMouseDown === false) {
      if (Date.now() - mouseDownStart > 100 && mouseDistanceTraveled > 15) {
        setInterpretAsGesture(true)
      }
      setMouseDistanceTraveled(0)
      setIsMouseDown(null)

      if (mouseMovement > 0 && !isTransforming.current) {
        setMouseMovement(0)
        transformTo(0)
        animateTransform(300)
      }
      if (
        carouselWrapperRef.current &&
        carouselContentRef.current &&
        carouselWrapperRef.current.scrollWidth - carouselContentRef.current.scrollWidth > mouseMovement &&
        !isTransforming.current
      ) {
        const position = carouselWrapperRef.current.scrollWidth - carouselContentRef.current.scrollWidth
        setMouseMovement(position)
        transformTo(position)
        animateTransform(300)
      }
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [
    animateTransform,
    handleMouseUp,
    handleMouseMove,
    isMouseDown,
    mouseDistanceTraveled,
    mouseDownStart,
    mouseMovement,
    transformTo,
  ])

  useEffect(() => {
    if (slides && carouselContentRef.current) {
      const { width } = carouselWrapperRef.current.getBoundingClientRect()
      let slidesSum = 0
      carouselContentRef.current?.childNodes.forEach((child, index) => {
        const gap = index === 0 ? 0 : Number(columnGap.match(/\d*/)[0])
        const rect = child.getBoundingClientRect()
        slidesSum += rect.width + gap
      })
      const hasOverflow = hasOverFlowFromObserver || Math.round(slidesSum) > Math.round(width)

      if (hasOverflow) {
        const leftAndRightButtonPx =
          getComputedStyle(leftSlotRef.current).position === 'absolute'
            ? leftSlotRef.current.getBoundingClientRect().width +
              rightSlotRef.current.getBoundingClientRect().width
            : 0
        setSlidesCount((prevState) => {
          // slide added
          if (prevState < slides?.props?.children.length) {
            const scrollToElement = carouselContentRef.current.lastChild

            const scrollToElementOffsetRight =
              scrollToElement.offsetLeft + scrollToElement.getBoundingClientRect().width
            const position = Math.round(width - scrollToElementOffsetRight - leftAndRightButtonPx)

            setMouseMovement(position)
            transformTo(position)
            animateTransform(100)
            // slide removed
          } else if (prevState > slides?.props?.children.length) {
            const lastChild = carouselContentRef.current.lastChild
            setMouseMovement((prevMouseMovement) => {
              const lastChildPositionRight =
                lastChild.offsetLeft + lastChild.getBoundingClientRect().width - Math.abs(prevMouseMovement)
              const gapRight = Math.abs(width) - Math.abs(lastChildPositionRight)
              if (gapRight > 0) {
                const position = Math.round(prevMouseMovement + gapRight)
                transformTo(position)
                animateTransform(300)
                return position
              }
            })
          }

          return slides?.props?.children.length
        })
      }
      setHasOverflow(hasOverflow)
    }
  }, [slides, hasOverFlowFromObserver, transformTo, animateTransform])

  return (
    <FlexRow alignItems="stretch" minWidth="0">
      <LeftSlot
        onClick={handleLeftSlotClick}
        {...leftSlot?.props}
        hasOverflow={hasOverflow}
        ref={leftSlotRef}
      >
        {leftSlot ? (
          leftSlot
        ) : (
          <ButtonIcon
            icon={{ Icon: BudiconChevronLeft, width: '20px' }}
            appearance="bare"
            size="small"
            disabled={!hasOverflow}
          />
        )}
      </LeftSlot>
      <CarouselWrapper ref={carouselWrapperRef} minWidth={minWidth} {...props}>
        {
          <CarouselContent
            ref={carouselContentRef}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleMouseLeave}
            onClick={handleClick}
            columnGap={columnGap}
          >
            {slides && slides}
          </CarouselContent>
        }
      </CarouselWrapper>
      <RightSlot
        style={{ opacity: hasOverflow ? 1 : 0 }}
        onClick={handleRightSlotClick}
        {...rightSlot?.props}
        hasOverflow={hasOverflow}
        ref={rightSlotRef}
      >
        {rightSlot ? (
          rightSlot
        ) : (
          <ButtonIcon
            onClick={handleRightSlotClick}
            disabled={!hasOverflow}
            icon={{ Icon: BudiconChevronRight, width: '20px' }}
            appearance="bare"
            size="small"
          />
        )}
      </RightSlot>
    </FlexRow>
  )
}

Carousel.LeftSlot = ({ children }) => children
Carousel.LeftSlot.displayName = 'leftSlot'
Carousel.RightSlot = ({ children }) => children
Carousel.RightSlot.displayName = 'rightSlot'
Carousel.Slides = ({ children }) => children
Carousel.Slides.displayName = 'slides'

Carousel.defaultProps = {
  minWidth: '400px',
  alignSelf: 'stretch',
}

Carousel.propTypes = {
  minWidth: string,
  alignSelf: string,
}
