import React from 'react'
import { useTheme } from 'styled-components'

import { FlexColumn } from '@layout/BuildingBlocks'
import { Text } from '@typography/ContentElements'

import { getThemeValue } from 'theming'
import { oneOfType, string, number, element, elementType } from 'prop-types'

export const MetalineWithText = ({
  align,
  metaline,
  metalineColor,
  text,
  textColor,
  typeScaleMeta,
  typeScaleText,
  typeStyleMeta,
  typeStyleText,
}) => {
  const theme = useTheme()
  const space = getThemeValue(theme, 'spaces.rhythm.vertical.nano')

  return (
    <FlexColumn alignItems="flex-start" flexRowGap={space}>
      <Text
        align={align}
        text={metaline}
        color={metalineColor}
        typeStyle={typeStyleMeta}
        typeScale={typeScaleMeta}
      />
      <Text
        align={align}
        breakWord={true}
        text={text}
        color={textColor}
        typeStyle={typeStyleText}
        typeScale={typeScaleText}
      />
    </FlexColumn>
  )
}

MetalineWithText.propTypes = {
  align: string,
  metaline: string,
  metalineColor: string,
  text: oneOfType([string, number, element, elementType]),
  textColor: string,
  typeScaleMeta: string,
  typeStyleMeta: string,
  typeScaleText: string,
  typeStyleText: string,
}

MetalineWithText.defaultProps = {
  align: 'left',
  metaline: 'Metaline',
  metalineColor: null,
  text: '-',
  textColor: null,
  typeScaleMeta: 'minion',
  typeStyleMeta: 'minionLight',
  typeScaleText: 'copy',
  typeStyleText: 'copyMedium',
}
