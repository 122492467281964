export const styleEllipsis = {
  overflowX: 'hidden',
  lineHeight: 'normal',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

export const excludeProps = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
]

export const typeMapHeader = {
  tiny: {
    typeStyle: 'minionSemiBold',
    typeScale: 'minion',
  },
  small: {
    typeStyle: 'picaSemiBold',
    typeScale: 'pica',
  },
  medium: {
    typeStyle: 'copySemiBold',
    typeScale: 'copy',
  },
  large: {
    typeStyle: 'greatPrimerSemiBold',
    typeScale: 'greatPrimer',
  },
}

export const typeMap = {
  tiny: {
    typeStyle: 'minion',
    typeScale: 'minion',
  },
  small: {
    typeStyle: 'pica',
    typeScale: 'pica',
  },
  medium: {
    typeStyle: 'copy',
    typeScale: 'copy',
  },
  large: {
    typeStyle: 'greatPrimer',
    typeScale: 'greatPrimer',
  },
}
