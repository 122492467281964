import React, { forwardRef, useMemo } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { compose, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { FlexRow, FlexItem, PosAbsolute, PosRelative } from '@layout/BuildingBlocks'

import { getThemeValue } from 'theming'
import { useArrayValueByBreakpoint } from 'lib/hooks'
import { defaultProps } from './defaultProps'
import { itemProps } from './defaultPropTypes'

import {
  typeScale,
  typeStyle,
  defaultProps as textProps,
  defaultPropTypes as textPropTypes,
} from '@typography'
import { NavLink } from 'react-router-dom'

const excludeProps = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'lineHeight',
  'scale',
  'tag',
  'typeScale',
  'typeStyle',
]

export const StyledLink = styled.a.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  color: ${themeGet('navigationMain.flyout.colors.parent.default.color', 'gray')};
  background: ${themeGet('navigationMain.flyout.colors.parent.default.bg', 'lightgray')};
  cursor: pointer;
  display: block;
  height: 100%;
  padding: 19px 0 16px 13px;
  position: relative;
  text-decoration: none;
  width: 100%;

  ${compose(typography)}

  ${(props) => typeScale(props.typeScale)}
  ${(props) => typeStyle(props.typeStyle)}
`

const activeStyle = css`
  border: 1px solid ${themeGet('navigationMain.flyout.colors.border.active', 'lightgray')};
  border-width: 1px 0;

  + li {
    border-top: 0;
  }

  > div > a {
    color: ${themeGet('navigationMain.flyout.colors.parent.active.color', 'lightgray')};
    background-color: ${themeGet('navigationMain.flyout.colors.parent.active.bg', 'lightgray')};
    font-weight: 500;

    :before {
      background: ${themeGet('navigationMain.flyout.colors.parent.active.indicator', 'lightgray')};
      content: ' ';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 3px;
    }

    :after {
      background: ${themeGet('navigationMain.flyout.colors.parent.active.bg', 'lightgray')};
      content: ' ';
      height: 100%;
      position: absolute;
      right: -1px;
      top: 0;
      width: 2px;
      z-index: 9;
    }
  }
  ul {
    display: block;
  }
`

const ListItem = styled.li`
  border: 1px solid ${themeGet('navigationMain.flyout.colors.border.default', 'lightgray')};
  border-width: 1px 0 0;
  margin: 0;

  &:last-of-type {
    border: 1px solid ${themeGet('navigationMain.flyout.colors.border.default', 'lightgray')};
    border-width: 1px 0 1px 0;
  }

  :hover,
  &.active {
    ${activeStyle}
  }
`

const LinkItem = forwardRef(({ itemProps: { label, icon, route } }, ref) => {
  const theme = useTheme()
  const iconColor = getThemeValue(theme, 'navigationMain.flyout.colors.icon')
  const iconLeft = icon.iconPosition?.left || null
  const iconTop = icon.iconPosition?.top || null
  const iconSize = useArrayValueByBreakpoint(icon.size)

  const linkProps = useMemo(() => {
    const linkProps = route
      ? {
          as: NavLink,
          to: route,
          typeScale: 'copy',
          typeStyle: 'copy',
        }
      : {
          typeScale: 'copy',
          typeStyle: 'copy',
        }
    return linkProps
  }, [route])

  return (
    <StyledLink ref={ref} {...linkProps}>
      <FlexRow alignItems="center">
        <PosRelative width="28px">
          <PosAbsolute marginTop={['-10px', null, null, '-12px']} top={iconTop} left={iconLeft}>
            {React.cloneElement(icon.component, { color: iconColor, size: iconSize, strokeWidth: '0.3' })}
          </PosAbsolute>
        </PosRelative>
        <FlexItem>{label}</FlexItem>
      </FlexRow>
    </StyledLink>
  )
})

export const ParentLinkItem = forwardRef(({ itemProps, children, onClick, onMouseOver }, ref) => {
  return (
    <ListItem ref={ref} onMouseOver={onMouseOver} onClick={onClick}>
      <FlexRow>
        <LinkItem itemProps={itemProps} />
        {children}
      </FlexRow>
    </ListItem>
  )
})

ParentLinkItem.defaultProps = defaultProps
ParentLinkItem.propTypes = {
  itemProps,
}

StyledLink.defaultProps = {
  ...textProps,
}

StyledLink.propTypes = {
  ...textPropTypes,
}
