export const getTable = ({ colors }) => ({
  header: {
    bg: colors.grayScale[200],
    color: colors.gray[900],
  },
  data: {
    even: {
      bg: colors.lightgray[130],
      color: colors.gray[900],
    },
    odd: {
      bg: colors.lightgray[270],
      color: colors.gray[900],
    },
    hover: {
      bg: colors.lightgray[300],
      color: colors.gray[1000],
    },
    evenGroup: {
      evenRow: {
        bg: colors.lightgray[50],
        color: colors.gray[900],
      },
      oddRow: {
        bg: colors.lightgray[200],
        color: colors.gray[900],
      },
    },
    oddGroup: {
      evenRow: {
        bg: colors.lightgray[530],
        color: colors.gray[900],
      },
      oddRow: {
        bg: colors.lightgray[700],
        color: colors.gray[900],
      },
    },
    evenParent: {
      bg: colors.white,
      color: colors.gray[900],
    },
    oddParent: {
      bg: colors.white,
      color: colors.gray[900],
    },
  },
})
