import React from 'react'
import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { layout, variant } from 'styled-system'

import { Checkmark } from '@utilities/SVG/Checkmark'
import { PosAbsolute } from '@layout/BuildingBlocks'

import {
  appearance,
  checkmarkHeight,
  checkmarkWidth,
  checkmarkFill,
  checkmarkStroke,
  checkmarkStrokeWidth,
  height,
  width,
  checked,
  disabled,
} from './defaultPropTypes'
import { defaultProps } from './defaultProps'

const checkboxCheckedSquare = (props) => css`
  ${variant({
    prop: 'appearance',
    variants: {
      default: {
        background: themeGet('colors.base.lightgray.100', 'white')(props),
        border: `1px solid ${themeGet('colors.base.gray.300', 'black')(props)}`,
      },
      primary: {
        background: themeGet('colors.base.brand.primary.lighten', 'white')(props),
        border: `1px solid ${themeGet('colors.base.brand.primary.default', 'black')(props)}`,
      },
    },
  })}
`

const checkboxDefaultSquare = (props) => css`
  ${variant({
    prop: 'appearance',
    variants: {
      default: {
        background: themeGet('colors.base.lightgray.100', 'white')(props),
        border: `1px solid ${themeGet('colors.base.gray.300', 'black')(props)}`,
      },
      primary: {
        background: themeGet('colors.base.brand.primary.lighten', 'white')(props),
        border: `1px solid ${themeGet('colors.base.brand.primary.default', 'black')(props)}`,
      },
    },
  })}
`

const CheckboxSquare = styled.span.withConfig({
  shouldForwardProp: (props) => ['children'].includes(props),
})`
  position: relative;
  top: 0px;
  border-radius: 3px;
  box-sizing: border-box;
  display: inline-block;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  ${(props) => (props.checked ? checkboxCheckedSquare : checkboxDefaultSquare)} ${layout};
`

export const CheckboxCheckmark = ({
  appearance,
  checkmarkHeight,
  checkmarkWidth,
  checkmarkFill,
  checkmarkStroke,
  checkmarkStrokeWidth,
  height,
  width,
  checked,
  disabled,
  ...props
}) => {
  return (
    <CheckboxSquare
      appearance={appearance}
      checked={checked}
      disabled={disabled}
      height={height}
      width={width}
      {...props}
    >
      {checked && (
        <PosAbsolute tag="span" ml="2px" mt="0px">
          <Checkmark
            fill={checkmarkFill}
            height={checkmarkHeight}
            stroke={checkmarkStroke}
            strokeWidth={checkmarkStrokeWidth}
            width={checkmarkWidth}
          />
        </PosAbsolute>
      )}
      &nbsp;
    </CheckboxSquare>
  )
}

CheckboxCheckmark.defaultProps = defaultProps
CheckboxCheckmark.propTypes = {
  appearance,
  checkmarkHeight,
  checkmarkWidth,
  checkmarkFill,
  checkmarkStroke,
  checkmarkStrokeWidth,
  height,
  width,
  checked,
  disabled,
}
