import React from 'react'

import Layout from './Layout'
import { FlexContainer } from '@layout/BuildingBlocks'
import { TextHighlight, TextSubHeader } from 'components/atoms/Text'
import ErrorGraphic from './ErrorGraphic'
import api from 'stores/api'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null, crashReport: false, forcedNoReport: props.noReport ?? false }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState((prev) => ({
      error: error,
      errorInfo: errorInfo,
      crashReport: errorInfo && !prev.forcedNoReport,
    }))
    const report = {
      message: error.message,
      stack: error.stack,
      componentStack: errorInfo.componentStack,
      location: window.location.pathname,
    }

    api.ErrorReport.log(report)
      .then(() => {
        console.log('Error has been reported')
      })
      .catch((err) => {
        console.warn('Could not report error.')
        throw err
      })
  }

  render() {
    if (this.state.crashReport) {
      return (
        <Layout showMainMenu={false}>
          <FlexContainer width="50%" justifyContent="center" alignItems="center" style={{ padding: '5%' }}>
            <ErrorGraphic />
          </FlexContainer>
          <FlexContainer width="50%" flexDirection="column" justifyContent="flex-start">
            <TextHighlight mb={3}>Absturzbericht.</TextHighlight>
            {/* <TextSubHeader mb={2}>Der Fehler wurde übermittelt. Bitte laden Sie die Seite neu.</TextSubHeader> */}
            <TextSubHeader mb={2}>
              Hier ist leider etwas schief gegangen. Bitte laden Sie die Seite neu.
            </TextSubHeader>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </div>
          </FlexContainer>
        </Layout>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundary

/**
 * Handle errors by onError prop
 */
export class ErrorHandlerWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    this.props.onError?.(error)
  }

  render() {
    if (this.state.hasError) {
      return null
    }
    this.props.onNoError?.()
    return this.props.children
  }
}
