import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { flyout } from '@utilities/Animation'

export const FlyoutContainer = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
  animation-name: ${flyout};
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-duration: 0.1s;
  background-color: ${themeGet('flyout.bg', '#fff')};
  border-radius: ${themeGet('flyout.radius', '0px')};
  filter: drop-shadow(${themeGet('shadow.dropdown', '0 5px 10px #00000040')});
  min-width: ${(props) => props.minWidth && props.minWidth};
  opacity: 0;
  margin: 0px;
  position: absolute;
  width: ${(props) => props.width && props.width};
  z-index: 9999;
`
