import conversions from './conversions'
import route from './route'

var convert = {}

var models = Object.keys(conversions)

function wrapRaw(fn) {
	var wrappedFn = function (args) {
		if (args === undefined || args === null) {
			return args
		}

		if (arguments.length > 1) {
			args = Array.prototype.slice.call(arguments)
		}

		return fn(args)
	}

	// preserve .conversion property if there is one
	if ('conversion' in fn) {
		wrappedFn.conversion = fn.conversion
	}

	return wrappedFn
}

function wrapRounded(fn) {
	var wrappedFn = function (args) {
		if (args === undefined || args === null) {
			return args
		}

		if (arguments.length > 1) {
			args = Array.prototype.slice.call(arguments)
		}

		var result = fn(args)

		// we're assuming the result is an array here.
		// see notice in conversions.js; don't use box types
		// in conversion functions.
		if (typeof result === 'object') {
			for (var len = result.length, i = 0; i < len; i++) {
				result[i] = Math.round(result[i])
			}
		}

		return result
	}

	// preserve .conversion property if there is one
	if ('conversion' in fn) {
		wrappedFn.conversion = fn.conversion
	}

	return wrappedFn
}

models.forEach(function (fromModel) {
	convert[fromModel] = {}

	Object.defineProperty(convert[fromModel], 'channels', { value: conversions[fromModel].channels })
	Object.defineProperty(convert[fromModel], 'labels', { value: conversions[fromModel].labels })

	var routes = route(fromModel)
	var routeModels = Object.keys(routes)

	routeModels.forEach(function (toModel) {
		var fn = routes[toModel]

		convert[fromModel][toModel] = wrapRounded(fn)
		convert[fromModel][toModel].raw = wrapRaw(fn)
	})
})

export default convert
