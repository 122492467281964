import React from 'react'
import styled, { useTheme } from 'styled-components'
import { space } from 'styled-system'

import { FlexContainer } from '@layout/BuildingBlocks'

import { getThemeValue } from 'theming'

const StyledComponent = styled(FlexContainer)`
  ${space}
`

export const ModalFooter = ({ children }) => {
  const theme = useTheme()
  const spaceX = getThemeValue(theme, 'modal.footer.space.x')
  let spaceY = getThemeValue(theme, 'modal.footer.space.y')

  if (!children || typeof children?.props?.children === 'undefined') {
    spaceY = Math.round(parseInt(spaceY) / 2) + 'px'
  }

  return (
    <StyledComponent alignItems="center" justifyContent="center" px={spaceX} py={spaceY}>
      {children}
    </StyledComponent>
  )
}
ModalFooter.displayName = 'modalFooter'
