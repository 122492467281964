import { useEffect, useRef } from 'react'

/** Returns what the value was in the previous render */
export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
