import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonLink } from '@ui/Buttons/ButtonLink'

import appConfig from 'config/appConfig'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <ButtonLink
      linkType="htmlAnchorElement"
      appearance="bare"
      href={`${appConfig.appBaseUrl}/RIWIS-Datenschutzerklaerung.pdf`}
      text={t('menu.privacyPolicy')}
      target="_blank"
      rel="noopener noreferrer"
    />
  )
}
