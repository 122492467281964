import { useEffect } from 'react'

import { useKeyPressEvent } from 'lib/hooks'

const getDirection = (left, right, up, down) => {
  return (left && 'left') || (right && 'right') || (up && 'up') || (down && 'down')
}
export const useKeyboardNavigation = ({
  focused = false,
  active = false,
  spacePreventDefault = false,
  closeOnTab = true,
  closeDropdown,
  openDropdown,
  selectCursor,
  moveCursor,
}) => {
  const escapePressed = useKeyPressEvent('Escape', {
    active,
    once: true,
    stopPropagation: true,
    preventDefault: true,
  })
  const tabPressed = useKeyPressEvent('Tab', { active: focused || active, once: true })
  const enterPressed = useKeyPressEvent('Enter', {
    active: focused || active,
    once: true,
    stopPropagation: true,
    preventDefault: true,
  })
  const spacePressed = useKeyPressEvent('Space', {
    active: focused || active,
    once: true,
    preventDefault: focused || spacePreventDefault,
  })
  const arrowUpPressed = useKeyPressEvent('ArrowUp', {
    active: focused || active,
    once: true,
    stopPropagation: true,
    preventDefault: true,
  })
  const arrowDownPressed = useKeyPressEvent('ArrowDown', {
    active: focused || active,
    once: true,
    stopPropagation: true,
    preventDefault: true,
  })
  const arrowLeftPressed = useKeyPressEvent('ArrowLeft', {
    active: focused || active,
    once: true,
    stopPropagation: true,
    preventDefault: true,
  })
  const arrowRightPressed = useKeyPressEvent('ArrowRight', {
    active: focused || active,
    once: true,
    stopPropagation: true,
    preventDefault: true,
  })

  useEffect(() => {
    if (escapePressed || (closeOnTab && tabPressed)) {
      closeDropdown(!!escapePressed)
    }
  }, [escapePressed, tabPressed, closeOnTab, closeDropdown])

  useEffect(() => {
    if (enterPressed || spacePressed) {
      if (active) {
        selectCursor(enterPressed ? 'enter' : 'space')
      } else if (focused) {
        openDropdown(false)
      }
    }
  }, [enterPressed, spacePressed, active, focused, selectCursor, openDropdown])

  useEffect(() => {
    if (focused && (arrowDownPressed || arrowUpPressed || arrowLeftPressed || arrowRightPressed)) {
      openDropdown(getDirection(arrowLeftPressed, arrowRightPressed, arrowUpPressed, arrowDownPressed))
    }
  }, [arrowDownPressed, arrowUpPressed, arrowLeftPressed, arrowRightPressed, focused, openDropdown])

  useEffect(() => {
    if (active && (arrowDownPressed || arrowUpPressed || arrowLeftPressed || arrowRightPressed)) {
      moveCursor(getDirection(arrowLeftPressed, arrowRightPressed, arrowUpPressed, arrowDownPressed))
    }
  }, [arrowDownPressed, arrowUpPressed, arrowLeftPressed, arrowRightPressed, active, moveCursor])
}
