import { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const customScrollbar = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: ${(props) => themeGet('sizes.scrollbar.default.thumb.width', '5px')(props)};
  }

  ::-webkit-scrollbar-thumb {
    visibility: hidden;
    border-radius: ${(props) => themeGet('sizes.scrollbar.default.thumb.radius', '5px')(props)};
    background-color: ${(props) => themeGet('scrollbar.default.thumb.bg', 'gray')(props)};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ::-webkit-scrollbar-track {
    visibility: hidden;
    background-color: ${(props) => themeGet('scrollbar.default.track.bg', 'lightgray')(props)};
    border-radius: ${(props) => themeGet('sizes.scrollbar.default.track.radius', '5px')(props)};
  }

  :hover {
    ::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }

  :hover {
    ::-webkit-scrollbar-track {
      visibility: visible;
    }
  }
`

export const customScrollbarNotHidden = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: ${(props) => themeGet('sizes.scrollbar.default.thumb.width', '5px')(props)};
  }

  ::-webkit-scrollbar-thumb {
    visibility: visible;
    border-radius: ${(props) => themeGet('sizes.scrollbar.default.thumb.radius', '5px')(props)};
    background-color: ${(props) => themeGet('scrollbar.default.thumb.bg', 'gray')(props)};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ::-webkit-scrollbar-track {
    visibility: visible;
    background-color: ${(props) => themeGet('scrollbar.default.track.bg', 'lightgray')(props)};
    border-radius: ${(props) => themeGet('sizes.scrollbar.default.track.radius', '5px')(props)};
  }
`

export const customScrollbarSmall = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: ${(props) => themeGet('sizes.scrollbar.small.thumb.width', '5px')(props)};
  }

  ::-webkit-scrollbar-thumb {
    visibility: hidden;
    border-radius: ${(props) => themeGet('sizes.scrollbar.small.thumb.radius', '5px')(props)};
    background-color: ${(props) => themeGet('scrollbar.small.thumb.bg', 'gray')(props)};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ::-webkit-scrollbar-track {
    visibility: hidden;
    background-color: ${(props) => themeGet('scrollbar.small.track.bg', 'lightgray')(props)};
    border-radius: ${(props) => themeGet('sizes.scrollbar.small.track.radius', '5px')(props)};
  }

  :hover {
    ::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }

  :hover {
    ::-webkit-scrollbar-track {
      visibility: visible;
    }
  }
`

export const customScrollbarNotHiddenSmall = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: ${(props) => themeGet('sizes.scrollbar.small.thumb.width', '5px')(props)};
  }

  ::-webkit-scrollbar-thumb {
    visibility: visible;
    border-radius: ${(props) => themeGet('sizes.scrollbar.small.thumb.radius', '5px')(props)};
    background-color: ${(props) => themeGet('scrollbar.small.thumb.bg', 'gray')(props)};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ::-webkit-scrollbar-track {
    visibility: visible;
    background-color: ${(props) => themeGet('scrollbar.small.track.bg', 'lightgray')(props)};
    border-radius: ${(props) => themeGet('sizes.scrollbar.small.track.radius', '5px')(props)};
  }
`
