import { PosAbsolute, PosRelative } from '@layout/BuildingBlocks'
import React from 'react'

export const HotelIcon = ({
  size = 32,
  width,
  iconColor = '#4C4D4C',
  backgroundColor = '#B198BD',
  strokeWidth,
  shadow = true,
}) => {
  return (
    <PosRelative width={width || size} height="100%">
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="17.5" cy="15.5" r="12.5" fill={backgroundColor} />
        </svg>
      </PosAbsolute>
      <PosAbsolute>
        <svg
          width={width || size}
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ filter: shadow && 'drop-shadow(0 5px 3px #bfbfbf)' }}
        >
          <path
            d="M27.5 11C27.3674 11 27.2402 11.0527 27.1464 11.1464C27.0527 11.2402 27 11.3674 27 11.5V14H24C23.4696 14 22.9609 14.2107 22.5858 14.5858C22.2107 14.9609 22 15.4696 22 16C22.0019 16.3521 22.0976 16.6973 22.2774 17H18.9082C18.8057 16.7082 18.6152 16.4553 18.363 16.2762C18.1108 16.0971 17.8093 16.0006 17.5 16H9C8.43025 16.0015 7.86747 16.1255 7.34986 16.3636C6.83224 16.6017 6.37188 16.9483 6 17.38V15C6 14.7348 5.89464 14.4804 5.70711 14.2929C5.51957 14.1054 5.26522 14 5 14H4C3.73478 14 3.48043 14.1054 3.29289 14.2929C3.10536 14.4804 3 14.7348 3 15V24C3 24.2652 3.10536 24.5196 3.29289 24.7071C3.48043 24.8946 3.73478 25 4 25H5C5.26522 25 5.51957 24.8946 5.70711 24.7071C5.89464 24.5196 6 24.2652 6 24V23H18C18.2652 23 18.5196 22.8946 18.7071 22.7071C18.8946 22.5196 19 22.2652 19 22H27V24.5C27 24.6326 27.0527 24.7598 27.1464 24.8536C27.2402 24.9473 27.3674 25 27.5 25C27.6326 25 27.7598 24.9473 27.8536 24.8536C27.9473 24.7598 28 24.6326 28 24.5V11.5C28 11.3674 27.9473 11.2402 27.8536 11.1464C27.7598 11.0527 27.6326 11 27.5 11ZM5 24H4V15H5V24ZM15 22H6V20C6.0009 19.2046 6.31726 18.4421 6.87967 17.8797C7.44209 17.3173 8.20463 17.0009 9 17H15V22ZM18 22H16V17H17.5C17.6326 17 17.7598 17.0527 17.8536 17.1464C17.9473 17.2402 18 17.3674 18 17.5V22ZM27 21H19V18H27V21ZM27 17H24C23.7348 17 23.4804 16.8946 23.2929 16.7071C23.1054 16.5196 23 16.2652 23 16C23 15.7348 23.1054 15.4804 23.2929 15.2929C23.4804 15.1054 23.7348 15 24 15H27V17Z"
            fill={iconColor}
            stroke={iconColor}
            strokeWidth={strokeWidth}
          />
        </svg>
      </PosAbsolute>
    </PosRelative>
  )
}
