import React, { useEffect } from 'react'
import { useTheme } from 'styled-components'

import { GlobalStyle } from 'theming/styles/GlobalStyle'
import { useLayoutStore } from 'stores/LayoutStore'
import { useSlots } from 'lib/hooks/useSlots'

import { Header } from '@layout/Header'
import { Footer } from '@layout/Footer'
import { SubHeader } from '@layout/SubHeader'
import { ControlsPanel } from './SubComponents/ControlsPanel'
import { MainStage } from './SubComponents/MainStage'
import { ScrollContainer } from './SubComponents/ScrollContainer'

import { FlexRow, FlexContainer } from '@layout/BuildingBlocks'

import { getThemeValue } from 'theming'

import { defaultProps } from './defaultProps'
import {
  hasMainPaddingTop,
  hasMainPaddingRight,
  hasMainPaddingBottom,
  hasMainPaddingLeft,
  media,
} from './defaultPropTypes'

export const Layout = ({
  children,
  hasMainPaddingTop,
  hasMainPaddingRight,
  hasMainPaddingBottom,
  hasMainPaddingLeft,
  media,
}) => {
  const theme = useTheme()
  const [{ isLoading }, { loadState, setThemeConstants }] = useLayoutStore()

  const [header, subHeader, left, leftFullscreen, main, mainFullscreen] = useSlots(
    ['header', 'subHeader', 'left', 'leftFullscreen', 'main', 'mainFullscreen'],
    children
  )

  useEffect(() => {
    setThemeConstants(theme.layout)
  }, [theme, setThemeConstants])

  useEffect(() => {
    loadState()
  }, [loadState])

  const pageMarginLeft = left && getThemeValue(theme, 'layout.page.mx')

  const scrollRef = main?.props?.scrollRef ?? null

  return (
    !isLoading && (
      <>
        <GlobalStyle media={media} />
        <FlexContainer
          height="100vh"
          flexDirection="column"
          backgroundColor={getThemeValue(theme, 'colors.theme.app.bg')}
        >
          {header && <Header>{header.props.children}</Header>}
          {subHeader && <SubHeader>{subHeader.props.children}</SubHeader>}

          <FlexRow height="100%" overflowY="hidden" ml={pageMarginLeft}>
            {left && left.props?.children && <ControlsPanel>{left.props?.children}</ControlsPanel>}
            {leftFullscreen && <ControlsPanel fullscreen={true}>{leftFullscreen}</ControlsPanel>}
            {main && (
              <MainStage
                hasMainPaddingTop={hasMainPaddingTop}
                hasMainPaddingRight={hasMainPaddingRight}
                hasMainPaddingBottom={hasMainPaddingBottom}
                hasMainPaddingLeft={hasMainPaddingLeft}
                height="100%"
              >
                <ScrollContainer
                  disabled={main.props.scrollDisabled ?? false}
                  forcePadding={main.props.forcePadding ?? false}
                  scrollBehavior={main.props.scrollBehavior}
                  ref={scrollRef}
                >
                  {main}
                </ScrollContainer>
              </MainStage>
            )}
            {mainFullscreen && <MainStage fullscreen={true}>{mainFullscreen}</MainStage>}
          </FlexRow>

          {!mainFullscreen && <Footer />}
        </FlexContainer>
      </>
    )
  )
}

Layout.Header = ({ children }) => children
Layout.Header.displayName = 'header'

Layout.SubHeader = ({ children }) => children
Layout.SubHeader.displayName = 'subHeader'

Layout.Side = ({ children }) => children
Layout.Side.displayName = 'side'

Layout.Left = ({ children }) => children
Layout.Left.displayName = 'left'

Layout.LeftFullscreen = ({ children }) => children
Layout.LeftFullscreen.displayName = 'leftFullscreen'

Layout.Main = ({ children }) => children
Layout.Main.displayName = 'main'

Layout.MainFullscreen = ({ children }) => children
Layout.MainFullscreen.displayName = 'mainFullscreen'

Layout.propTypes = {
  hasMainPaddingTop,
  hasMainPaddingRight,
  hasMainPaddingBottom,
  hasMainPaddingLeft,
  media,
}

Layout.defaultProps = defaultProps
