import React from 'react'
import { useTheme } from 'styled-components'

import { IconWrapper } from '@utilities/IconWrapper'

import { getThemeValue } from 'theming'

export const IconRight = ({ ...props }) => {
  const theme = useTheme()
  const iconSpaceX = getThemeValue(theme, 'badge.icon.space.x')

  return (
    <IconWrapper
      height={props.iconRightHeight}
      icon={props.iconRight}
      iconPosition={props.iconRightPosition}
      iconVariantSourceName={props.iconVariantSourceName}
      ml={iconSpaceX}
      size={props.size}
      width={props.iconRightWidth}
    />
  )
}
