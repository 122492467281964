import React from 'react'

import { StyledText } from '../StyledText'
import { typeStyles } from '../typeStyles'

import { defaultProps } from '../defaultProps'
import { defaultPropTypes } from '../defaultPropTypes'

export const GreatPrimer = ({ children, tag, ...props }) => (
  <StyledText scale="greatPrimer" {...props} {...typeStyles.greatPrimer} as={tag}>
    {children}
  </StyledText>
)

export const GreatPrimerMedium = ({ children, tag, ...props }) => (
  <StyledText scale="greatPrimer" {...props} {...typeStyles.greatPrimerMedium} as={tag}>
    {children}
  </StyledText>
)

export const GreatPrimerSemiBold = ({ children, tag, ...props }) => (
  <StyledText scale="greatPrimer" {...props} {...typeStyles.greatPrimerSemiBold} as={tag}>
    {children}
  </StyledText>
)

export const GreatPrimerAbstract = ({ children, tag, ...props }) => (
  <StyledText scale="greatPrimer" {...props} {...typeStyles.greatPrimerAbstract} as={tag}>
    {children}
  </StyledText>
)

GreatPrimer.propTypes = defaultPropTypes
GreatPrimerMedium.propTypes = defaultPropTypes
GreatPrimerSemiBold.propTypes = defaultPropTypes
GreatPrimerAbstract.propTypes = defaultPropTypes

GreatPrimer.defaultProps = defaultProps
GreatPrimerMedium.defaultProps = defaultProps
GreatPrimerSemiBold.defaultProps = defaultProps
GreatPrimerAbstract.defaultProps = defaultProps
